<script>
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';

export default {
  components: {
    WootDropdownItem,
    Thumbnail,
  },
  props: {
    contactId: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['toggle', 'notFound', 'found'],
  data() {
    return {
      exists: undefined,
      contactCache: null,
    };
  },
  computed: {
    contactReactive() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    contact() {
      return this.contactCache ?? this.contactReactive;
    },
    loading() {
      return !this.contactCache;
    },
  },
  watch: {
    contactReactive: {
      handler(value) {
        if (!this.contactCache || value.id === this.contactId) {
          this.contactCache = value;
        }
      },
      immediate: true,
    },
    contactCache: {
      handler(value) {
        if (!value) return;
        if (value.id === this.contactId) {
          this.exists = true;
          this.$emit('found');
        } else {
          this.exists = false;
          this.$emit('notFound');
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('contacts/show', { id: this.contactId });
  },
  methods: {
    onclick(contact) {
      this.$emit('toggle', contact);
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="exists">
    <div
      v-if="loading"
      class="h-10 bg-slate-100 dark:bg-slate-500/25 wgpt-animate-pulse rounded mb-1"
    />

    <WootDropdownItem v-else>
      <woot-button
        class="multiselect-dropdown--item active"
        variant="hollow"
        color-scheme="secondary"
        @click="() => onclick(contact)"
      >
        <div class="flex items-center">
          <Thumbnail
            :src="contact.thumbnail"
            size="24px"
            :username="contact.name"
            :status="contact.availability_status"
            has-border
          />
          <div class="flex items-center justify-between w-full min-w-0">
            <span
              class="leading-4 my-0 mx-2 overflow-hidden whitespace-nowrap text-ellipsis text-sm"
              :title="contact.name"
            >
              {{ contact.name }}
            </span>
            <fluent-icon class="shrink-0" icon="checkmark" />
          </div>
        </div>
      </woot-button>
    </WootDropdownItem>
  </div>
</template>

<style lang="scss" scoped>
.multiselect-dropdown--item {
  @apply justify-between w-full;

  &.active {
    @apply bg-slate-25 dark:bg-slate-700 border-slate-50 dark:border-slate-900 font-medium;
  }

  &:focus {
    @apply bg-slate-25 dark:bg-slate-700;
  }

  &:hover {
    @apply bg-slate-50 dark:bg-slate-800 text-slate-800 dark:text-slate-100;
  }
}

.wgpt-animate-pulse {
  animation: wgptAnimatePulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes wgptAnimatePulse {
  50% {
    opacity: 0.5;
  }
}
</style>

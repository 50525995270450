<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import Thumbnail from '../../../../../../../../components/widgets/Thumbnail.vue';
import FullEditor from '../../components/FullEditor.vue';
import canDismissMixin from '../../mixins/preventDismissMixin';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import { emitter } from 'shared/helpers/mitt';

export default {
  components: {
    Thumbnail,
    FullEditor,
  },
  mixins: [canDismissMixin, keyboardEventListenerMixins],
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    uploadFile: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      content: '',
      contentEditorVisible: false,
      selectedActivity: {},
      isUploading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      uiFlags: 'wgptBoardListCardActivities/getUIFlags',
    }),
    preventDismiss() {
      return this.contentEditorVisible;
    },
  },
  mounted() {
    emitter.on('wgptCloseAllBoardListCardActivityEditor', () => {
      this.hideMessageEditor();
    });
  },
  beforeUnmount() {
    emitter.off('wgptCloseAllBoardListCardActivityEditor');
  },
  methods: {
    showMessageEditor() {
      emitter.emit('wgptCloseAllBoardListCardActivityEditor');
      this.contentEditorVisible = true;
    },
    hideMessageEditor() {
      this.contentEditorVisible = false;
    },
    async submitComment() {
      try {
        if (!this.content) return;

        const boardId = this.board.id;
        const listId = this.list.id;
        const cardId = this.card.id;
        const data = {
          content: this.content,
        };
        await this.$store.dispatch('wgptBoardListCardActivities/create', {
          boardId,
          listId,
          cardId,
          ...data,
        });

        this.content = '';
        this.selectedActivity = {};
        this.hideMessageEditor();
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.FORM.API.ERROR_MESSAGE'
          );
        useAlert(errorMessage);
      }
    },
    onFileUploadStart() {
      this.isUploading = true;
    },
    onFileUploadSuccess() {
      this.isUploading = false;
    },
    onFileUploadError() {
      this.isUploading = false;
    },
    getKeyboardEvents() {
      return {
        Escape: {
          action: () => this.hideMessageEditor(),
          allowOnFocusedInput: true,
        },
      };
    },
  },
};
</script>

<template>
  <div class="flex gap-3">
    <Thumbnail
      :src="currentUser.avatar_url"
      :username="currentUser.name"
      size="32px"
    />
    <div class="flex-1">
      <div v-if="contentEditorVisible">
        <FullEditor
          v-model.trim="content"
          v-on-clickaway="hideMessageEditor"
          :placeholder="
            $t(
              'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.COMMENT.PLACEHOLDER'
            )
          "
          :upload-file="uploadFile"
          @file-upload-start="onFileUploadStart"
          @file-upload-success="onFileUploadSuccess"
          @file-upload-error="onFileUploadError"
        />
        <div class="flex gap-2">
          <woot-button
            :is-disabled="uiFlags.isCreating || isUploading"
            :is-loading="uiFlags.isCreating"
            size="small"
            data-testid="board-add-list-submit"
            class="mt-2"
            @click="submitComment"
          >
            {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.SAVE') }}
          </woot-button>
          <woot-button
            :is-disabled="uiFlags.isCreating"
            size="small"
            variant="smooth"
            color-scheme="secondary"
            data-testid="board-add-list-card-description-cancel"
            class="mt-2"
            @click="hideMessageEditor"
          >
            {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DESCRIPTION.CANCEL') }}
          </woot-button>
        </div>
      </div>
      <label v-else @click="showMessageEditor">
        <div
          class="px-3 py-1 rounded-md cursor-pointer bg-slate-50 dark:bg-slate-900 text-slate-400 dark:text-slate-500 shadow"
        >
          {{
            $t(
              'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.COMMENT.PLACEHOLDER'
            )
          }}
        </div>
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.message-editor {
  @apply px-3 outline-none;
  outline-color: #6cb8ff;
  outline-offset: -2px;

  ::v-deep {
    .ProseMirror-menubar {
      @apply rounded-tl-[4px] mt-1;
    }
  }
}
</style>

<script>
import { useAlert } from 'dashboard/composables';
import MultiselectDropdown from '../../../../../components/MultiselectDropdown.vue';
import Avatar from '../components/Avatar.vue';
import Dropdown from '../components/Dropdown.vue';

export default {
  components: {
    MultiselectDropdown,
    Avatar,
    Dropdown,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    agents: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSearchDropdown: false,
    };
  },
  computed: {
    selectedMembers() {
      const memberIds = this.card.config?.members ?? [];
      return this.agents.filter(agent => memberIds.includes(agent.id));
    },
  },
  methods: {
    async onUpdate(selectedItems) {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          config: {
            ...this.card.config,
            members: selectedItems,
          },
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.MEMBERS.API.ERROR_MESSAGE')
        );
      }
    },
    toggleDropdown() {
      this.showSearchDropdown = !this.showSearchDropdown;
    },
    closeDropdown() {
      this.showSearchDropdown = false;
    },
    addItem({ id }) {
      const result = this.selectedMembers.map(
        selectedMember => selectedMember.id
      );
      result.push(id);
      this.onUpdate(result);
    },
    removeItem({ id }) {
      const result = this.selectedMembers
        .filter(item => item.id !== id)
        .map(item => item.id);
      this.onUpdate(result);

      if (result.length > 0) return;
      this.closeDropdown();
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="selectedMembers.length > 0">
    <label>
      {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.MEMBERS.LABEL') }}
    </label>

    <div class="flex gap-1 overflow-hidden flex-wrap">
      <Avatar
        v-for="selectedMember in selectedMembers"
        :key="selectedMember.id"
        :user-id="selectedMember.id"
        :users="agents"
      />

      <woot-button
        v-if="!readonly"
        size="small"
        data-testid="board-add-list-card-member-add"
        color-scheme="secondary"
        variant="smooth"
        icon="add"
        @click="toggleDropdown"
      />
    </div>

    <Dropdown :show-dropdown="showSearchDropdown" @close="closeDropdown">
      <MultiselectDropdown
        :options="agents"
        :selected-items="selectedMembers"
        :multiselector-title="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.TITLE'
          )
        "
        :multiselector-placeholder="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.PLACEHOLDER'
          )
        "
        :no-search-result="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.NO_RESULT'
          )
        "
        :input-placeholder="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.INPUT_PLACEHOLDER'
          )
        "
        @add="addItem"
        @remove="removeItem"
        @close="closeDropdown"
        @toggle="toggleDropdown"
      />
    </Dropdown>
  </div>
</template>

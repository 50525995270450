<script>
import { url } from '@vuelidate/validators';
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import { useAlert } from 'dashboard/composables';
import { getRegexp } from 'shared/helpers/Validators';
import canDismissMixin from '../../mixins/preventDismissMixin';
import DatePicker from 'vue-datepicker-next';
import format from 'date-fns/format';
import { useVuelidate } from '@vuelidate/core';

const DATE_FORMAT = 'yyyy-MM-dd';

export default {
  components: {
    DatePicker,
  },
  mixins: [canDismissMixin],
  props: {
    label: { type: String, required: true },
    description: { type: String, default: '' },
    values: { type: Array, default: () => [] },
    value: { type: [String, Number, Boolean], default: '' },
    attributeType: { type: String, default: 'text' },
    attributeRegex: {
      type: String,
      default: null,
    },
    regexCue: { type: String, default: null },
    attributeKey: { type: String, required: true },
    readonly: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    onUpdate: { type: Function, required: true },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isEditing: false,
      editedValue: null,
      showDateDropdown: false,
    };
  },
  computed: {
    displayValue() {
      if (this.isAttributeTypeDate) {
        return this.value ? format(new Date(this.value), DATE_FORMAT) : '';
      }
      if (this.isAttributeTypeCheckbox) {
        return this.value === 'false' ? false : this.value;
      }
      return this.value;
    },
    formattedValue() {
      if (!this.value) return this.value;
      if (this.isAttributeTypeDate) return new Date(this.value);
      if (this.isAttributeTypeCheckbox)
        return this.value === true ? 'true' : 'false';
      return this.value;
    },
    isAttributeTypeCheckbox() {
      return this.attributeType === 'checkbox';
    },
    isAttributeTypeList() {
      return this.attributeType === 'list';
    },
    isAttributeTypeLink() {
      return this.attributeType === 'link';
    },
    isAttributeTypeDate() {
      return this.attributeType === 'date';
    },
    isAttributeTypeNumber() {
      return this.attributeType === 'number';
    },
    notAttributeTypeCheckboxAndListAndDate() {
      return (
        !this.isAttributeTypeCheckbox &&
        !this.isAttributeTypeList &&
        !this.isAttributeTypeDate
      );
    },
    shouldShowErrorMessage() {
      return this.v$.editedValue.$error;
    },
    shouldShowActionButtons() {
      if (this.isEditing) return false;
      if (this.isAttributeTypeNumber) {
        return Number.isFinite(this.value);
      }
      return !!this.value;
    },
    errorMessage() {
      if (this.v$.editedValue.url === false) {
        return this.$t('CUSTOM_ATTRIBUTES.VALIDATIONS.INVALID_URL');
      }
      if (this.v$.editedValue.numeric === false) {
        return this.$t(
          'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CUSTOM_FIELDS.VALIDATIONS.INVALID_NUMBER'
        );
      }
      if (this.v$.editedValue.regexValidation === false) {
        return this.regexCue
          ? this.regexCue
          : this.$t('CUSTOM_ATTRIBUTES.VALIDATIONS.INVALID_INPUT');
      }
      return this.$t('CUSTOM_ATTRIBUTES.VALIDATIONS.REQUIRED');
    },
    preventDismiss() {
      return this.isEditing || this.showDateDropdown;
    },
  },
  watch: {
    value() {
      this.editedValue = this.formattedValue;
    },
  },
  validations() {
    if (this.isAttributeTypeLink) {
      return {
        editedValue: {
          url,
        },
      };
    }
    if (this.isAttributeTypeNumber) {
      return {
        editedValue: {
          numeric: value => {
            if (!value) return true;
            const valueNumber = Number(value);
            return !Number.isNaN(valueNumber);
          },
        },
      };
    }
    return {
      editedValue: {
        regexValidation: value => {
          if (!value) return true;
          if (!this.attributeRegex) return true;
          return getRegexp(this.attributeRegex).test(value);
        },
      },
    };
  },
  mounted() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      this.editedValue = this.formattedValue;
    },
    focusInput() {
      if (this.$refs.inputfield) {
        this.$refs.inputfield.focus();
      }
    },
    blurInput() {
      if (this.$refs.inputfield) {
        this.$refs.inputfield.blur();
      }
    },
    onFocus() {
      if (this.readonly) return;
      this.isEditing = true;
    },
    onBlur(e) {
      if (e.relatedTarget?.classList?.contains('submit-button')) {
        this.onUpdateValue();
      } else {
        this.isEditing = false;
        this.setDefault();
      }
    },
    onInput(event) {
      if (this.isAttributeTypeNumber) {
        const value = event.target.value;
        const valueNumber = Number(value);
        if (Number.isNaN(valueNumber)) {
          this.editedValue = value.slice(0, value.length - 1);
        }
      }
    },
    getKeyboardEvents() {
      return {
        Escape: {
          action: () => {
            this.blurInput();
            this.hideDateDropdown();
          },
          allowOnFocusedInput: true,
        },
      };
    },
    async onUpdateValue() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.focusInput();
        return;
      }

      let updatedValue = this.editedValue;
      if (this.isAttributeTypeNumber) {
        updatedValue = this.editedValue ? Number(this.editedValue) : null;
      }

      try {
        await this.onUpdate(this.attributeKey, updatedValue);
        this.isEditing = false;
        this.blurInput();
      } catch (error) {
        this.focusInput();
      }
    },
    onUpdateListValue(value) {
      this.editedValue = value;
      this.onUpdateValue();
    },
    onDateChange(value) {
      this.editedValue = value;
      this.showDateDropdown = false;
      this.onUpdateValue();
    },
    openDateDropdown() {
      this.showDateDropdown = true;
    },
    hideDateDropdown() {
      this.showDateDropdown = false;
    },
    async onCopy() {
      await copyTextToClipboard(this.displayValue);
      useAlert(this.$t('CUSTOM_ATTRIBUTES.COPY_SUCCESSFUL'));
    },
  },
};
</script>

<template>
  <div>
    <div class="group flex items-center mb-1">
      <h4 class="text-sm flex items-center m-0 error gap-1 overflow-hidden">
        <div
          class="grow inline-flex items-start font-semibold text-[13px] mb-0 overflow-hidden"
          :class="
            v$.editedValue.$error
              ? 'text-red-400 dark:text-red-500'
              : 'text-slate-800 dark:text-slate-100'
          "
        >
          <span class="truncate">{{ label }}</span>
        </div>
        <div class="hidden group-hover:flex">
          <fluent-icon
            v-if="description"
            v-tooltip.top="{
              content: description,
              container: false,
            }"
            icon="info"
            size="14"
            class="text-slate-600 dark:text-slate-400 shrink-0"
          />
        </div>
      </h4>
    </div>

    <div v-if="notAttributeTypeCheckboxAndListAndDate" class="group relative">
      <div>
        <label class="w-full flex items-center" for="inputField">
          <input
            ref="inputfield"
            v-model="editedValue"
            type="text"
            class="text-field"
            :readonly="readonly || loading"
            :class="{ error: v$.editedValue.$error, focus: isEditing }"
            @keyup.enter="onUpdateValue"
            @focus="onFocus"
            @blur="onBlur"
          />
          <woot-button
            v-if="isEditing || loading"
            size="small"
            icon="checkmark"
            tabindex="0"
            class="submit-button shrink-0 !w-7 !h-7 rounded-l-none rtl:rounded-r-none"
            :is-disabled="loading"
            @click="onUpdateValue"
          />
        </label>
        <span
          v-if="shouldShowErrorMessage"
          class="text-red-400 dark:text-red-500 text-[13px] block font-normal -mt-px w-full"
        >
          {{ errorMessage }}
        </span>
      </div>

      <div
        v-if="shouldShowActionButtons"
        class="absolute top-0 right-0 flex flex-wrap-reverse gap-0.5 m-1"
      >
        <woot-button
          v-tooltip="$t('CUSTOM_ATTRIBUTES.ACTIONS.COPY')"
          size="small"
          color-scheme="secondary"
          icon="clipboard"
          class-names="dark:!bg-slate-900/80 dark:hover:!bg-slate-900 hidden group-hover:flex !w-5 !h-5 flex-shrink-0"
          @click="onCopy"
        />
        <a v-if="isAttributeTypeLink" :href="displayValue" target="blank">
          <woot-button
            size="small"
            color-scheme="secondary"
            icon="open"
            class-names="dark:!bg-slate-900/80 dark:hover:!bg-slate-900 hidden group-hover:flex !w-5 !h-5 flex-shrink-0"
          />
        </a>
      </div>
    </div>
    <div v-if="isAttributeTypeDate" class="group relative">
      <div class="flex items-center min-h-[1.75rem]">
        <DatePicker
          class="date-picker"
          type="date"
          :confirm="false"
          :clearable="false"
          :editable="false"
          :disabled="readonly || loading"
          :open="showDateDropdown"
          :value="editedValue"
          append-to-body
          @change="onDateChange"
          @clear="onDateChange"
          @open="openDateDropdown"
          @confirm="hideDateDropdown"
        >
          <template #footer>
            <div>
              <woot-button
                size="small"
                class="button clear"
                @click="onDateChange(null)"
              >
                {{
                  $t(
                    'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CUSTOM_FIELDS.CLEAR_BUTTON_TEXT'
                  )
                }}
              </woot-button>
            </div>
            <div
              class="modal-mask z-[-1] !bg-transparent cursor-pointer"
              @click="hideDateDropdown"
            />
          </template>
        </DatePicker>

        <div
          v-if="shouldShowActionButtons"
          class="absolute top-0 right-0 flex flex-wrap-reverse gap-0.5 m-1"
        >
          <woot-button
            v-tooltip="$t('CUSTOM_ATTRIBUTES.ACTIONS.COPY')"
            size="small"
            color-scheme="secondary"
            icon="clipboard"
            class-names="dark:!bg-slate-900/80 dark:hover:!bg-slate-900 hidden group-hover:flex !w-5 !h-5 flex-shrink-0"
            @click="onCopy"
          />
        </div>
      </div>
    </div>
    <div v-if="isAttributeTypeCheckbox">
      <div class="flex items-center min-h-[1.75rem]">
        <input
          v-model="editedValue"
          class="!m-0 !w-[18px] !h-[18px] cursor-pointer"
          type="checkbox"
          :disabled="readonly || loading"
          @change="onUpdateValue"
        />
      </div>
    </div>
    <div v-if="isAttributeTypeList">
      <input
        v-if="readonly"
        readonly
        :value="displayValue"
        class="bg-slate-50 dark:bg-slate-700 inline-block rounded-[5px] mb-0 break-all py-0.5 pl-2 pr-0 min-h-[1.75rem] font-semibold text-sm w-full cursor-pointer read-only:cursor-text caret-transparent text-slate-800 dark:text-slate-50"
      />
      <select
        v-else
        v-model="editedValue"
        class="bg-slate-50 dark:bg-slate-700 rounded-[5px] mb-0 break-all h-auto py-0 pl-2 min-h-[1.75rem] font-semibold border-none truncate text-sm text-slate-800 dark:text-slate-50 cursor-pointer"
        :disabled="readonly || loading"
        @change="onUpdateListValue($event.target.value)"
      >
        <option selected value="">---</option>
        <option v-for="(option, index) in values" :key="index" :value="option">
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .selector-wrap {
    @apply m-0 top-1;
    .selector-name {
      @apply ml-0;
    }
  }
  .name {
    @apply ml-0;
  }
}

.date-picker {
  ::v-deep {
    .mx-input {
      @apply h-auto min-h-[1.75rem] py-0 pr-[1.75rem] mb-0 rounded-[5px] text-sm truncate bg-slate-50 disabled:bg-slate-50 disabled:text-slate-800 border-none focus:border-solid font-semibold disabled:cursor-text;
    }
    .mx-icon-calendar {
      @apply pointer-events-none;
    }
  }
}

:is(.dark .date-picker) {
  ::v-deep {
    .mx-input {
      @apply bg-slate-700 disabled:bg-slate-700 disabled:text-slate-50;
    }
  }
}

.submit-button ::v-deep .spinner.small {
  @apply m-0;
}

input.text-field {
  @apply h-7 mb-0 pr-0 text-sm transition-none font-semibold;
  &.focus {
    @apply ltr:rounded-r-none rtl:rounded-l-none border-r-0;
  }
  &:not(.focus) {
    @apply bg-slate-50 dark:bg-slate-700 text-slate-800 dark:text-slate-50 border-none cursor-pointer read-only:cursor-text;
  }
}
</style>

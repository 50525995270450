<script>
import { useAlert } from 'dashboard/composables';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import { MAXIMUM_FILE_UPLOAD_SIZE } from '../../helpers/contants';

export default {
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSearchDropdown: false,
      uploading: false,
    };
  },
  computed: {
    selectedContacts() {
      const contactIds = this.card.config?.contacts ?? [];
      return this.contacts.filter(contact => contactIds.includes(contact.id));
    },
  },
  methods: {
    openFileBrowser() {
      this.$refs.imageUploadInput.click();
    },
    onFileChange() {
      const file = this.$refs.imageUploadInput.files[0];

      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.uploadImageToStorage(file);
      } else {
        useAlert(
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.API.FILE_SIZE_ERROR',
            {
              size: MAXIMUM_FILE_UPLOAD_SIZE,
            }
          )
        );
      }

      this.$refs.imageUploadInput.value = '';
    },
    async uploadImageToStorage(file) {
      try {
        this.uploading = true;
        const data = {
          file,
          boardId: this.board.id,
          listId: this.list.id,
          id: this.card.id,
        };
        await this.$store.dispatch('wgptBoardListCards/addAttachment', data);
      } catch (error) {
        if (error.message === 'FILE_SIZE_ERROR') {
          useAlert(
            this.$t(
              'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.API.FILE_SIZE_ERROR',
              {
                size: MAXIMUM_FILE_UPLOAD_SIZE,
              }
            )
          );
        } else {
          useAlert(
            this.$t(
              'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.API.UPLOAD_ERROR'
            )
          );
        }
      } finally {
        this.uploading = false;
      }
    },
  },
};
</script>

<template>
  <div class="relative">
    <li
      class="font-medium h-7 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md bg-slate-50 hover:bg-slate-100 dark:bg-slate-700 dark:hover:bg-slate-600 cursor-pointer"
      :class="{ disabled: disabled || uploading }"
      @click="openFileBrowser"
    >
      <a class="inline-flex text-left max-w-full w-full items-center">
        <div
          class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full gap-2"
        >
          <fluent-icon
            icon="attach"
            class="min-w-[1rem] mt-0.5 text-slate-700 dark:text-slate-100"
            size="14"
          />
          <span
            class="text-sm text-slate-700 dark:text-slate-100 overflow-hidden text-truncate"
          >
            {{
              $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.ATTACHMENTS.LABEL')
            }}
          </span>
        </div>
      </a>
    </li>
    <input ref="imageUploadInput" type="file" hidden @change="onFileChange" />
  </div>
</template>

<style lang="scss" scoped>
li.disabled {
  @apply opacity-50 pointer-events-none select-none;
}
</style>

<script>
import { messageTimestamp } from 'shared/helpers/timeHelper';

export default {
  props: {
    createdAt: {
      type: [String, Date, Number],
      default: '',
    },
    updatedAt: {
      type: [String, Date, Number],
      default: '',
    },
  },
  data() {
    return {
      content: '',
      contentEditorVisible: false,
    };
  },
  computed: {
    createdAtTimestamp() {
      return new Date(this.createdAt).getTime() / 1000;
    },
    lastActivityTimestamp() {
      return new Date(this.updatedAt).getTime() / 1000;
    },
    readableCreatedAt() {
      return messageTimestamp(this.createdAtTimestamp, "LLL d 'at' h:mm a");
    },
    readableUpdatedAt() {
      return messageTimestamp(
        this.lastActivityTimestamp,
        `'${this.$t(
          'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.COMMENT.TOOLTIP.UPDATED'
        )}' LLL d 'at' h:mm a`
      );
    },
    isEdited() {
      return this.createdAtTimestamp !== this.lastActivityTimestamp;
    },
  },
};
</script>

<template>
  <div class="text-xs text-slate-500 dark:text-slate-500 leading-4 flex gap-1">
    <span>{{ readableCreatedAt }}</span>
    <span
      v-if="isEdited"
      v-tooltip="{
        content: readableUpdatedAt,
        container: false,
        delay: { show: 250 },
      }"
      class="hover:text-slate-900 dark:hover:text-slate-100"
    >
      &nbsp;({{
        $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.COMMENT.TOOLTIP.EDITED')
      }})
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import SearchHeader from './SearchHeader.vue';
import SourceChunk from '../components/chunks/Index.vue';
import EditChunk from '../components/chunks/edit/EditChunk.vue';
import SearchResultSection from './SearchResultSection.vue';

export default {
  components: {
    SearchHeader,
    SourceChunk,
    SearchResultSection,
    EditChunk,
  },
  props: {
    bot: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: {},
      query: '',
      selectedChunk: {},
      showEditChunkPopup: false,
      showDeleteChunkConfirmationPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      searchResults: 'wgptBots/getSearchResults',
      searchMeta: 'wgptBots/getSearchMeta',
      uiFlags: 'wgptBots/getUIFlags',
      botSourceChunksUiFlags: 'wgptBotSourceChunks/getUIFlags',
    }),
    totalSearchResultsCount() {
      return this.searchMeta.total ?? 0;
    },
    currentSearchResultsPage() {
      return this.searchMeta.page ?? 1;
    },
    totalSearchResultsPages() {
      return this.searchMeta.limit
        ? Math.ceil(this.searchMeta.total / this.searchMeta.limit)
        : 1;
    },
    showEmptySearchResults() {
      return (
        this.totalSearchResultsCount === 0 &&
        this.uiFlags.isSearchCompleted &&
        !this.uiFlags.isFetching &&
        this.query
      );
    },
    showResultsSection() {
      return (
        (this.uiFlags.isSearchCompleted &&
          this.totalSearchResultsCount !== 0) ||
        this.uiFlags.isFetching
      );
    },
  },
  watch: {
    bot() {
      this.setDefaults();
    },
  },
  mounted() {
    this.$store.dispatch('wgptBots/clearSearch');
  },
  beforeUnmount() {
    this.query = '';
  },
  methods: {
    onSearch(q, page = 1, limit = 5) {
      this.query = q;
      if (!q) return;
      const id = this.bot.id;
      this.$store.dispatch('wgptBots/search', { id, q, page, limit });
    },
    nextPage() {
      this.onSearch(this.query, this.searchMeta.page + 1);
    },
    previousPage() {
      this.onSearch(this.query, this.searchMeta.page - 1);
    },
    openEditChunkPopup(chunk) {
      this.selectedChunk = chunk;
      this.showEditChunkPopup = true;
    },
    hideEditChunkPopup(source) {
      this.showEditChunkPopup = false;
      if (source) {
        this.onSearch(this.query, this.searchMeta.page, this.searchMeta.limit);
      }
    },
    openDeleteChunkPopup(chunk) {
      this.selectedChunk = chunk;
      this.showDeleteChunkConfirmationPopup = true;
    },
    hideDeleteChunkPopup() {
      this.showDeleteChunkConfirmationPopup = false;
    },
    confirmChunkDeletion() {
      this.loading[this.selectedChunk.id] = true;
      this.hideDeleteChunkPopup();
      this.deleteChunk();
    },
    async deleteChunk() {
      const botId = this.$route.params.botId;
      const sourceId = this.selectedChunk.sourceId;
      const id = this.selectedChunk.id;
      try {
        await this.$store.dispatch('wgptBotSourceChunks/delete', {
          botId,
          sourceId,
          id,
        });
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.API.SUCCESS_MESSAGE'
          )
        );
        this.onSearch(this.query, this.searchMeta.page, this.searchMeta.limit);
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.API.ERROR_MESSAGE'
          )
        );
      } finally {
        this.loading[id] = false;
      }
    },
    async toggleChunkStatus(chunk) {
      try {
        const botId = this.$route.params.botId;
        const sourceId = chunk.sourceId;
        const id = chunk.id;
        const data = {
          status: chunk.status === 'draft' ? 1 : 0,
        };

        await this.$store.dispatch('wgptBotSourceChunks/update', {
          botId,
          sourceId,
          id,
          ...data,
        });

        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.API.SUCCESS_MESSAGE'
          )
        );
        this.onSearch(this.query, this.searchMeta.page, this.searchMeta.limit);
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.API.ERROR_MESSAGE'
          )
        );
      }
    },
  },
};
</script>

<template>
  <div class="flex-1 overflow-auto h-full">
    <div class="h-full overflow-hidden">
      <section class="search-root">
        <header class="bg-white dark:bg-transparent">
          <SearchHeader @search="onSearch" />
        </header>
        <div class="search-results">
          <SearchResultSection
            v-if="query"
            :title="
              $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.SEARCH.HEADER.TITLE')
            "
            :empty="searchMeta.total === 0"
            :query="query"
            show-title
            :is-fetching="
              uiFlags.isSearching ||
              botSourceChunksUiFlags.isUpdating ||
              botSourceChunksUiFlags.isDeleting
            "
            :page="currentSearchResultsPage"
            :total-pages="totalSearchResultsPages"
            @next-page="nextPage"
            @previous-page="previousPage"
          >
            <SourceChunk
              v-for="chunkRecord of searchResults"
              :key="chunkRecord.id"
              :chunk="chunkRecord"
              @delete="openDeleteChunkPopup(chunkRecord)"
              @toggle-status="toggleChunkStatus(chunkRecord)"
              @edit="openEditChunkPopup(chunkRecord)"
            />
          </SearchResultSection>

          <div v-else class="empty text-center">
            <fluent-icon icon="info" size="16px" class="icon inline" />
            <p class="empty-state__text">
              {{
                $t(
                  'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.SEARCH.EMPTY_STATE_DEFAULT'
                )
              }}
            </p>
          </div>
        </div>
      </section>
    </div>

    <woot-modal v-model:show="showEditChunkPopup" @close="hideEditChunkPopup">
      <EditChunk
        :source-id="selectedChunk.sourceId"
        :chunk="selectedChunk"
        @close="hideEditChunkPopup"
      />
    </woot-modal>

    <woot-delete-modal
      v-model:show="showDeleteChunkConfirmationPopup"
      :on-close="hideDeleteChunkPopup"
      :on-confirm="confirmChunkDeletion"
      message-value=""
      :title="
        $t(
          'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.CONFIRM.TITLE'
        )
      "
      :message="
        $t(
          'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.CONFIRM.MESSAGE'
        )
      "
      :confirm-text="
        $t(
          'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.CONFIRM.YES'
        )
      "
      :reject-text="
        $t(
          'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.CONFIRM.NO'
        )
      "
    />
  </div>
</template>

<style lang="scss" scoped>
// Label API supports only lowercase letters
.label-name--input {
  ::v-deep {
    input {
      @apply lowercase;
    }
  }
}
.search-root {
  @apply flex my-4 p-4 mt-6 relative mx-auto max-w-[45rem] min-h-[20rem] flex-col w-full h-full;

  .search-results {
    @apply flex-grow h-full overflow-y-auto py-0;

    .search-results {
      @apply flex-grow h-full overflow-y-auto py-0 px-2;
    }
  }
}

.empty {
  @apply flex items-center justify-center py-6 px-4 my-8 rounded-md;
  .icon {
    @apply text-slate-500 dark:text-slate-300;
  }
  .empty-state__text {
    @apply text-slate-500 dark:text-slate-300 text-center my-0 mx-2;
  }
}
</style>

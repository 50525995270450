<script>
import { useAlert } from 'dashboard/composables';
import { mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  emits: ['close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
    };
  },
  validations: {
    name: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardLists/getUIFlags',
    }),
  },
  mounted() {
    this.autoFocusNameField();
  },
  methods: {
    autoFocusNameField() {
      this.$nextTick(() => {
        const inputEl = this.$refs.addCardNameField.$el.querySelector('input');
        if (inputEl) {
          inputEl.focus();
        }
      });
    },
    onClose() {
      this.$emit('close');
    },
    async onSubmit() {
      try {
        if (this.v$.$invalid) {
          this.v$.$touch();
          return;
        }

        const boardId = this.$route.params.boardId;
        const boardList = {
          name: this.name,
        };
        await this.$store.dispatch('wgptBoardLists/create', {
          boardId,
          ...boardList,
        });

        useAlert(this.$t('WGPT_BOARDS.ITEM.LIST.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('WGPT_BOARDS.ITEM.LIST.ADD.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
  },
};
</script>

<template>
  <form class="mx-0 flex flex-wrap w-full" @submit.prevent="onSubmit">
    <div class="w-full">
      <label :class="{ error: v$.name.$error }">
        <woot-input
          ref="addCardNameField"
          v-model.trim="name"
          data-testid="board-name"
          :placeholder="$t('WGPT_BOARDS.ITEM.LIST.ADD.FORM.NAME.PLACEHOLDER')"
          @input="v$.name.$touch"
        />
      </label>
    </div>

    <div class="gap-2 w-full flex">
      <woot-button
        :is-disabled="uiFlags.isCreating"
        :is-loading="uiFlags.isCreating"
        size="small"
        data-testid="board-add-list-submit"
      >
        {{ $t('WGPT_BOARDS.ITEM.LIST.ADD.FORM.CREATE') }}
      </woot-button>
      <woot-button
        icon="dismiss"
        class="button clear"
        color-scheme="secondary"
        size="small"
        data-testid="board-add-list-cancel"
        @click.prevent="onClose"
      />
    </div>
  </form>
</template>

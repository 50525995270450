<script>
import { useAlert } from 'dashboard/composables';
import PreChatFields from './PreChatFields.vue';
import { useVuelidate } from '@vuelidate/core';
import { mapGetters } from 'vuex';
import { getPreChatFields } from './helpers/preChat';

export default {
  components: { PreChatFields },
  props: {
    bot: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      preChatEnabled: false,
      preChatFields: [],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBots/getUIFlags',
      customAttributes: 'attributes/getAttributes',
    }),
    preChatFieldOptions() {
      const { pre_chat_options: preChatOptions } = this.bot;
      return getPreChatFields({
        preChatFields: preChatOptions?.fields,
        customAttributes: this.customAttributes,
      });
    },
  },
  watch: {
    bot() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  validations: {},
  methods: {
    setDefaults() {
      const { pre_chat_enabled: preChatEnabled } = this.bot;
      this.preChatEnabled = preChatEnabled;
      this.preChatFields = this.preChatFieldOptions || {};
    },
    handlePreChatFieldOptions(event, type, item) {
      this.preChatFields.forEach((field, index) => {
        if (field.key === item.key) {
          this.preChatFields[index][type] = !item[type];
        }
      });
    },
    changePreChatFieldFieldsOrder(updatedPreChatFieldOptions) {
      this.preChatFields = updatedPreChatFieldOptions;
    },
    async updateBot() {
      try {
        const payload = {
          id: this.bot.id,
          pre_chat_enabled: this.preChatEnabled,
          pre_chat_options: {
            fields: this.preChatFields,
          },
        };
        await this.$store.dispatch('wgptBots/update', payload);
        useAlert(this.$t('WGPT_BOTS.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        useAlert(this.$t('WGPT_BOTS.EDIT.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<template>
  <div class="my-2 mx-8 text-base">
    <div class="mt-6 mx-0 mb-3">
      {{ $t('WGPT_BOTS.EDIT.TABS.PRE_CHAT.DESCRIPTION') }}
    </div>
    <form class="flex flex-col" @submit.prevent="updateBot">
      <label class="w-1/4">
        {{ $t('WGPT_BOTS.EDIT.TABS.PRE_CHAT.FORM.ENABLE.LABEL') }}
        <select v-model="preChatEnabled">
          <option :value="true">
            {{ $t('WGPT_BOTS.EDIT.TABS.PRE_CHAT.FORM.ENABLE.OPTIONS.ENABLED') }}
          </option>
          <option :value="false">
            {{
              $t('WGPT_BOTS.EDIT.TABS.PRE_CHAT.FORM.ENABLE.OPTIONS.DISABLED')
            }}
          </option>
        </select>
      </label>
      <div v-if="preChatEnabled">
        <div class="w-3/4">
          <label>{{
            $t('WGPT_BOTS.EDIT.TABS.PRE_CHAT.FORM.SET_FIELDS.DESCRIPTION')
          }}</label>
          <table class="table table-striped w-full">
            <thead class="thead-dark">
              <tr>
                <th scope="col" />
                <th scope="col" />
                <th scope="col">
                  {{
                    $t(
                      'WGPT_BOTS.EDIT.TABS.PRE_CHAT.FORM.SET_FIELDS.TABLE_HEADERS.NAME'
                    )
                  }}
                </th>
                <th scope="col">
                  {{
                    $t(
                      'WGPT_BOTS.EDIT.TABS.PRE_CHAT.FORM.SET_FIELDS.TABLE_HEADERS.DESCRIPTION'
                    )
                  }}
                </th>
                <th scope="col">
                  {{
                    $t(
                      'WGPT_BOTS.EDIT.TABS.PRE_CHAT.FORM.SET_FIELDS.TABLE_HEADERS.TYPE'
                    )
                  }}
                </th>
              </tr>
            </thead>
            <PreChatFields
              :pre-chat-fields="preChatFields"
              @update="handlePreChatFieldOptions"
              @drag-end="changePreChatFieldFieldsOrder"
            />
          </table>
        </div>
      </div>
      <div class="my-4 w-auto">
        <woot-submit-button
          type="submit"
          :button-text="$t('WGPT_BOTS.EDIT.SUBMIT')"
          :disabled="v$.$invalid || uiFlags.isUpdating"
          :loading="uiFlags.isUpdating"
        />
      </div>
    </form>
  </div>
</template>

export const MAXIMUM_FILE_UPLOAD_SIZE = 50;

export const WGPT_AUTOMATIONS_RICHTEXT_EDITOR_MENU_OPTIONS = [
  'strong',
  'em',
  'link',
  'undo',
  'redo',
  'bulletList',
  'orderedList',
  'h1',
  'h2',
  'h3',
  'code',
];

<script>
import FluentIcon from 'shared/components/FluentIcon/WgptIcon.vue';

export default {
  components: {
    FluentIcon,
  },
};
</script>

<template>
  <label
    class="px-4 py-2 absolute ltr:right-0 rtl:left-0 top-0 text-xs flex items-center rtl:flex-row-reverse gap-0.5 text-red-400"
  >
    <FluentIcon icon="prohibited" size="16" />
    <i>{{
      $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.ITEM.DISABLED')
    }}</i>
  </label>
</template>

<script>
import translateMixins from '../../../mixins/translateMixins';
import dynamicField from '../dynamicField/Index.vue';
import OutputMappingContent from './OutputMappingContent.vue';

export default {
  components: {
    DynamicField: dynamicField,
    OutputMappingContent,
  },
  mixins: [translateMixins],
  props: {
    step: {
      type: Object,
      default: () => {},
    },
    variables: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['stepChange'],
  data() {
    return {
      defaultName: this.$t(
        'WGPT_AUTOMATIONS.EDITOR.ACTION.FALLBACK_ACTION_NAME'
      ),
      updatedName: this.step.name,
      isEditing: false,
    };
  },
  computed: {
    availableVariables() {
      return this.variables.filter(variable => {
        if (variable.source) return true;
        if (variable.wrapper) return true;
        if (!variable.model) return true;
        return false;
      });
    },
    stepName() {
      return this.step.name || this.defaultName;
    },
    actionRegistry() {
      return (
        this.$store.getters['wgptAutomationRegistry/getAction'](
          this.step.action
        ) || {}
      );
    },
    latestVersion() {
      return (
        this.$store.getters['wgptAutomationRegistry/getLatestVersion'](
          this.step.action
        ) || {}
      );
    },
    fields() {
      return this.actionRegistry.form_fields || [];
    },
    attributes() {
      return this.step.attributes || {};
    },
    outputMapping() {
      return this.step.output_mapping || {};
    },
    outputMappingFields() {
      return this.actionRegistry.output_mapping_fields || [];
    },
    versionDisplay() {
      const actionName = this.step.action;
      if (!actionName) return '';
      const lastPart = actionName.split('_').pop();
      const hasVersion = /^v\d+$/.test(lastPart);
      return hasVersion ? lastPart : 'v1';
    },
    showUpdateButton() {
      const versionPart = this.versionDisplay;
      const currentVersion = Number(versionPart.slice(1));
      const latestVersion = this.latestVersion.version;
      return currentVersion < latestVersion;
    },
  },
  watch: {
    step(step, stepBefore) {
      if (step.id !== stepBefore.id || step.name !== this.updatedName) {
        this.updatedName = step.name;
      }
    },
  },
  methods: {
    isVisible(field) {
      const conditions = field.show_if ?? [];
      const results = conditions.map(({ field: fieldName, value }) => {
        const currentValue =
          this.attributes[fieldName] ?? this.getDefaultFieldValue(fieldName);
        if (currentValue == null) return false;
        if (value.includes('*'))
          return `${currentValue}`.includes(value.replace(/\*/g, ''));
        return currentValue === value;
      });
      return results.every(result => result);
    },
    getDefaultFieldValue(fieldName) {
      const field = this.fields.find(({ name }) => name === fieldName);
      return field?.default;
    },
    onFieldChange(key, value) {
      const attributesBefore = this.step?.attributes ?? {};
      const stepUpdates = {
        attributes: {
          ...attributesBefore,
          [key]: value,
        },
      };
      this.$emit('stepChange', this.step.id, stepUpdates);
    },
    onOutputMappingFieldChange(outputMapping) {
      const stepUpdates = {
        output_mapping: outputMapping,
      };
      this.$emit('stepChange', this.step.id, stepUpdates);
    },
    editName() {
      this.isEditing = true;
      this.$nextTick(() => {
        this.$refs.nameInput.focus();
      });
    },
    onNameChange() {
      const stepUpdates = {
        name: this.updatedName,
      };
      this.$emit('stepChange', this.step.id, stepUpdates);
      this.isEditing = false;
      this.$refs.nameInput.blur();
    },
    cancelNameEditing() {
      this.isEditing = false;
    },
    updateAction() {
      const stepUpdates = {
        action: this.latestVersion.action,
      };
      this.$emit('stepChange', this.step.id, stepUpdates);
    },
  },
};
</script>

<template>
  <div>
    <div class="px-3 pt-4 pb-16">
      <header class="group pe-10">
        <div class="flex items-center gap-2">
          <input
            v-show="isEditing"
            ref="nameInput"
            v-model.trim="updatedName"
            :placeholder="defaultName"
            class="name-input ignore-drag !ps-1 !bg-transparent disabled:!bg-transparent disabled:!cursor-text !text-slate-900 dark:!text-slate-100 !border-none !outline !outline-2 !outline-transparent -outline-offset-2 !m-0 text-xl !font-semibold !h-6"
            dir="auto"
            type="text"
            @blur="onNameChange"
            @keyup.enter="onNameChange"
            @keyup.esc="cancelNameEditing"
          />
          <h5
            v-if="!isEditing"
            class="grow text-base font-semibold text-black-900 dark:text-slate-200 select-none inline-flex items-center gap-1.5 overflow-hidden"
          >
            <span class="truncate">{{ stepName }}</span>
            <woot-button
              color-scheme="secondary"
              size="tiny"
              icon="edit"
              variant="clear"
              class-names="invisible group-hover:visible ignore-drag"
              @click="editName"
            />
          </h5>
        </div>
        <p v-if="actionRegistry.name" class="text-xs">
          <span>{{ t(actionRegistry.name) }}</span>
          <span class="before:content-['('] after:content-[')']">
            {{ versionDisplay }}
            <template v-if="showUpdateButton">
              <span>&nbsp;-&nbsp;</span>
              <woot-button
                size="small"
                variant="link"
                type="button"
                @click="updateAction"
              >
                {{
                  $t(
                    'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.ACTIONS.UPDATE_BUTTON_TXT'
                  )
                }}
              </woot-button>
            </template>
          </span>
        </p>
        <p
          class="text-xs pb-4 border-b border-slate-100 dark:border-slate-800/50"
        >
          <span v-if="actionRegistry.description">
            {{ t(actionRegistry.description) }}
          </span>
          <span v-else class="space-y-2 animate-loader-pulse">
            <div class="bg-slate-100 dark:bg-slate-800/50 rounded w-1/2">
              &nbsp;
            </div>
          </span>
        </p>
      </header>

      <div class="form-fields">
        <DynamicField
          v-for="(field, index) of fields"
          :key="index"
          :field="field"
          :value="attributes[field.name]"
          :visible="isVisible(field)"
          :variables="availableVariables"
          @change="value => onFieldChange(field.name, value)"
        />
      </div>

      <OutputMappingContent
        :output-mapping="outputMapping"
        :output-mapping-fields="outputMappingFields"
        :variables="availableVariables"
        @change="onOutputMappingFieldChange"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.name-input:focus {
  outline-color: #6cb8ff !important;
}

.error {
  @apply bg-red-50 dark:bg-red-800/50 border border-solid border-red-100 dark:border-red-700/50 mb-4;
}
</style>

<script>
import SourceChunkContextMenu from './ContextMenu.vue';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import DraftIndicator from './DraftIndicator.vue';

export default {
  components: {
    SourceChunkContextMenu,
    DraftIndicator,
  },
  mixins: [globalConfigMixin],
  props: {
    chunk: {
      type: Object,
      required: true,
    },
  },
  emits: ['edit', 'toggleStatus', 'delete'],
  computed: {
    isDraft() {
      return this.chunk.status === 'draft';
    },
  },
  methods: {
    confirmChunkDeletion() {
      this.$emit('delete');
    },
    confirmToggleChunkStatus() {
      this.$emit('toggleStatus');
    },
    confirmEditChunk() {
      this.$emit('edit');
    },
  },
};
</script>

<template>
  <div class="flex gap-2">
    <template v-if="chunk.chunk_type === 'text'">
      <div
        class="chunk-item relative"
        :class="{
          'opacity-50': isDraft,
        }"
      >
        <DraftIndicator v-if="isDraft" />
        <label class="px-3 py-2">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.ITEM.TEXT.LABEL'
            )
          }}
          <p class="text-sm text-slate-600 dark:text-slate-400 break-all">
            {{ chunk.text }}
          </p>
        </label>
      </div>
    </template>

    <template v-else-if="chunk.chunk_type === 'qna'">
      <div
        class="chunk-item relative"
        :class="{
          'opacity-50': isDraft,
        }"
      >
        <DraftIndicator v-if="isDraft" />

        <label class="px-3 py-2">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.ITEM.QUESTION.LABEL'
            )
          }}
          <p class="text-sm text-slate-600 dark:text-slate-400 break-all">
            {{ chunk.question }}
          </p>
        </label>

        <hr class="my-0" />

        <label class="px-3 py-2">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.ITEM.ANSWER.LABEL'
            )
          }}
          <p class="text-sm text-slate-600 dark:text-slate-400 break-all">
            {{ chunk.answer }}
          </p>
        </label>
      </div>
    </template>

    <SourceChunkContextMenu
      :status="chunk.status"
      @delete="confirmChunkDeletion"
      @toggle-status="confirmToggleChunkStatus"
      @edit="confirmEditChunk"
    />
  </div>
</template>

<style scoped lang="scss">
.chunk-item {
  @apply grow bg-white dark:bg-slate-700 rounded-lg border border-slate-75 dark:border-slate-600;
}
</style>

<script>
import { useAlert } from 'dashboard/composables';
import { mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  props: {
    canManageLists: {
      type: Boolean,
      default: false,
    },
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete', 'close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
      description: '',
      isArchive: false,
    };
  },
  validations: {
    name: {
      required,
    },
    description: {
      required,
    },
    isArchive: {},
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardLists/getUIFlags',
    }),
    readonly() {
      return this.board.closed || !this.canManageLists;
    },
  },
  mounted() {
    this.name = this.list.name;
    this.description = this.list.description;
    this.isArchive = this.list.archive;
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onDelete() {
      this.$emit('delete');
    },
    async onSubmit() {
      try {
        const boardId = this.$route.params.boardId;
        const id = this.list.id;
        const boardList = {
          name: this.name,
          description: this.description,
          archive: this.isArchive,
        };
        await this.$store.dispatch('wgptBoardLists/update', {
          boardId,
          id,
          ...boardList,
        });

        useAlert(this.$t('WGPT_BOARDS.ITEM.LIST.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('WGPT_BOARDS.ITEM.LIST.EDIT.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
  },
};
</script>

<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('WGPT_BOARDS.ITEM.LIST.EDIT.TITLE')"
      :header-content="$t('WGPT_BOARDS.ITEM.LIST.EDIT.DESC')"
    />

    <form class="w-full" @submit.prevent="onSubmit">
      <div>
        <label :class="{ error: v$.name.$error }">
          {{ $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.NAME.LABEL') }}
          <input
            v-model.trim="name"
            data-testid="board-list-card-name"
            class="disabled:opacity-50 disabled:!bg-white disabled:dark:!bg-slate-900 disabled:dark:text-white"
            type="text"
            :disabled="readonly"
            @input="v$.name.$touch"
          />
          <span v-if="v$.name.$error" class="message">
            {{ $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.NAME.ERROR') }}
          </span>
        </label>
      </div>

      <div
        class="border-b border-solid border-slate-50 dark:border-slate-700/30 pb-8"
      >
        <label :class="{ error: v$.description.$error }">
          {{ $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.DESCRIPTION.LABEL') }}
          <textarea
            v-model.trim="description"
            class="disabled:opacity-50 disabled:bg-white disabled:dark:bg-slate-900"
            data-testid="board-list-description"
            rows="3"
            :disabled="readonly"
            @input="v$.description.$touch"
          />
        </label>
      </div>

      <div class="py-8">
        <div class="flex items-center gap-2">
          <label>
            <input
              v-model="isArchive"
              type="checkbox"
              class="ltr:mr-2 rtl:ml-2"
              name="toggle-imap-enable"
              :disabled="readonly"
              @change="v$.isArchive.$touch"
            />
            {{ $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.ARCHIVE.LABEL') }}
          </label>
        </div>
        <p class="pb-1 text-sm not-italic text-slate-600 dark:text-slate-400">
          {{ $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.ARCHIVE.SUBHEADER') }}
        </p>
      </div>

      <div class="flex justify-between items-center py-2 px-0 w-full">
        <aside class="flex gap-2">
          <woot-button
            :is-disabled="!v$.$anyDirty || v$.$error || uiFlags.isUpdating"
            :is-loading="uiFlags.isUpdating"
            data-testid="board-submit"
          >
            {{ $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.SAVE') }}
          </woot-button>
          <woot-button class="button clear" @click.prevent="onClose">
            {{ $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.CANCEL') }}
          </woot-button>
        </aside>
        <aside>
          <woot-button
            v-if="canManageLists"
            icon="delete"
            class="button clear"
            color-scheme="alert"
            @click.prevent="onDelete"
          >
            {{ $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.DELETE') }}
          </woot-button>
        </aside>
      </div>
    </form>
  </div>
</template>

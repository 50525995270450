<script>
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { containsVariable } from './helpers/validations';
import format from 'date-fns/format';
import DatePicker from 'vue-datepicker-next';
import translateMixins from '../../../mixins/translateMixins';
import variablesMixins from '../variablesDropdown/variablesMixins';
import WgptFluentIcon from 'shared/components/FluentIcon/WgptIcon.vue';
import VariablesDropdown from '../variablesDropdown/Index.vue';

export default {
  components: {
    WgptFluentIcon,
    DatePicker,
    VariablesDropdown,
  },
  mixins: [variablesMixins, translateMixins],
  props: {
    value: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    defaultValue: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    type: {
      type: String,
      default: 'date',
    },
    label: {
      type: String,
      default: '',
    },
    fallbackLabel: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['dropdownClose', 'dropdownOpen', 'change'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showDateDropdown: false,
      updatedValue: this.value ?? this.defaultValue,
    };
  },
  validations() {
    return {
      updatedValue: {
        required: requiredIf(this.required),
      },
    };
  },
  computed: {
    datepickerConfirm() {
      return this.type === 'time' || this.type === 'datetime';
    },
    datepickerFormat() {
      if (this.type === 'date') {
        return 'YYYY-MM-DD';
      }
      if (this.type === 'time') {
        return 'HH:mm';
      }
      if (this.type === 'datetime') {
        return 'YYYY-MM-DD HH:mm:ss';
      }
      return undefined;
    },
  },
  watch: {
    value(value) {
      if (containsVariable(value)) {
        this.updatedValue = value;
      } else if (this.type === 'datetime') {
        this.updatedValue = value
          ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss')
          : '';
      }
    },
  },
  methods: {
    onDateChange(value) {
      this.updatedValue = value;
      this.$emit(
        'change',
        this.type === 'datetime' ? new Date(value).toISOString() : value
      );
      if (this.type === 'date') {
        this.hideDateDropdown();
      }
    },
    onVariableAdd(_, variableName, prefix) {
      const updatedValue = `${prefix}{${variableName}}`;
      this.updatedValue = updatedValue;
      this.$emit('change', updatedValue);
      this.hideDateDropdown();
    },
    openDateDropdown() {
      this.showDateDropdown = true;
      this.$emit('dropdownOpen');
    },
    hideDateDropdown() {
      this.showDateDropdown = false;
      this.$emit('dropdownClose');
    },
  },
};
</script>

<template>
  <div>
    <div class="date-label" :class="{ error: v$.updatedValue.$error }">
      <label class="flex items-center gap-2">
        <div v-if="label" class="block truncate">
          {{ t(label, fallbackLabel) }}<span v-if="required">*</span>
        </div>
        <fluent-icon
          v-if="description"
          v-tooltip.top="{
            content: t(description),
            popperClass: 'max-w-[300px]',
            boundary: 'body',
          }"
          icon="info"
          size="14"
        />
      </label>

      <DatePicker
        class="date-picker"
        :type="type"
        :confirm="datepickerConfirm"
        :format="datepickerFormat"
        :clearable="false"
        :editable="false"
        :open="showDateDropdown"
        :value="updatedValue"
        :append-to-body="false"
        :disabled="disabled"
        :placeholder="placeholder"
        value-type="format"
        @change="onDateChange"
        @open="openDateDropdown"
        @confirm="hideDateDropdown"
      >
        <template #input>
          <input
            v-model="updatedValue"
            type="text"
            readonly
            class="cursor-pointer"
          />
        </template>
        <template #footer>
          <div>
            <woot-button
              v-if="showVariablesSelector"
              size="small"
              color-scheme="secondary"
              variant="clear"
              class-names="variables-dropdown-button"
              @click="openVariablesDropdown"
            >
              <WgptFluentIcon icon="braces-variable" size="20" />
            </woot-button>
            <div
              class="modal-mask z-[-1] !bg-transparent cursor-pointer"
              @click="hideDateDropdown"
            />
          </div>
        </template>
      </DatePicker>

      <VariablesDropdown
        :show-dropdown="showVariablesDropdown"
        :event="variablesDropdownEvent"
        :variables="variables"
        :filter-by-prefix="variablesFilter"
        @close="closeVariablesDropdown"
        @add="addVariable"
      />

      <span v-if="v$.updatedValue.$error" class="message">
        <template v-if="v$.updatedValue.required.$invalid">{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.REQUIRED')
        }}</template>
        <template v-else>{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.INVALID')
        }}</template>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.date-picker {
  @apply w-full z-40;
  ::v-deep {
    input[name='date'] {
      @apply rounded-md dark:bg-slate-900 dark:text-slate-200 text-sm border border-slate-500 p-2 pe-7 truncate;
    }
    .mx-datepicker-footer {
      @apply flex justify-between items-center;
    }
  }
}
[dir='rtl'] .date-picker {
  ::v-deep {
    .mx-icon-calendar {
      right: unset;
      left: 8px;
    }
  }
}
.variables-dropdown-button {
  @apply hover:border hover:border-woot-500 hover:text-woot-500;
  &:hover {
    background-color: transparent !important;
    --color: var(--w-500);
  }
  ::v-deep .button__content {
    @apply text-center text-lg;
    color: var(--color, #73879c);
  }
}
.error input {
  @apply border-red-500;
}
:is(.dark .error input) {
  @apply border-red-500;
}
</style>

<script>
import AddStepButton from '../../../components/addStepDropdown/AddStepButton.vue';
import translateMixins from '../../../../mixins/translateMixins';

export default {
  components: {
    AddStepButton,
  },
  mixins: [translateMixins],
  props: {
    branch: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: -1,
    },
  },
  emits: ['stepChange'],
  computed: {
    // comparisonOperators() {
    //   return this.$store.getters[
    //     'wgptAutomationRegistry/getComparisonOperators'
    //   ];
    // },
    // logicalOperators() {
    //   return this.$store.getters['wgptAutomationRegistry/getLogicalOperators'];
    // },
    steps() {
      return this.branch.steps || [];
    },
    branchName() {
      if (this.branch.name) return this.branch.name;
      if (this.index === -1)
        return this.$t('WGPT_AUTOMATIONS.EDITOR.BRANCH.DEFAULT_BRANCH_NAME');
      return this.$t('WGPT_AUTOMATIONS.EDITOR.BRANCH.FALLBACK_BRANCH_NAME', {
        index: this.index + 1,
      });
    },
    // branchTooltip() {
    //   const conditions = this.branch.conditions || [];
    //   const validConditions = conditions.filter(condition => {
    //     if (!condition.variable_name) return false;
    //     if (!condition.operator) return false;
    //     if (['empty', 'not_empty'].includes(condition.operator)) return true;
    //     if (!condition.value) return false;
    //     return true;
    //   });
    //   if (validConditions.length === 0) return '';

    //   const logicalOperatorRegistry =
    //     this.logicalOperators.find(
    //       ({ value }) => value === this.branch.logical_operator
    //     ) ?? {};
    //   const logicalOperator = `\n${this.t(logicalOperatorRegistry.label)}\n`;

    //   return validConditions
    //     .map(
    //       ({
    //         variable_name,
    //         operator: conditionOperator,
    //         value: conditionValue,
    //       }) => {
    //         const comparisonOperator =
    //           this.comparisonOperators.find(
    //             ({ value }) => value === conditionOperator
    //           ) ?? {};
    //         const operator = `${this.t(
    //           comparisonOperator.label
    //         )}`.toLowerCase();

    //         const value = conditionValue;

    //         if (['empty', 'not_empty'].includes(conditionOperator)) {
    //           return this.$t(
    //             `WGPT_AUTOMATIONS.EDITOR.BRANCH.TOOLTIP.EMPTINESS_CONDITION_STATEMENT`,
    //             { variable_name, operator }
    //           );
    //         }
    //         return this.$t(
    //           `WGPT_AUTOMATIONS.EDITOR.BRANCH.TOOLTIP.CONDITION_STATEMENT`,
    //           { variable_name, operator, value }
    //         );
    //       }
    //     )
    //     .join(logicalOperator);
    // },
  },
  methods: {
    addStepAt(index, newStep) {
      let steps = Array.from(this.steps);
      if (newStep.action === 'branch') {
        newStep.default = {
          ...newStep.default,
          steps: steps.slice(index),
        };
        steps = steps.slice(0, index).concat(newStep);
      } else {
        steps.splice(index, 0, newStep);
      }
      const branch = {
        ...this.branch,
        steps,
      };
      this.$emit('stepChange', this.branch.id, branch);
    },
  },
};
</script>

<template>
  <div class="branch">
    <div class="connector-bridge" />

    <div class="connector-top">
      <div class="branch-label !mx-0 !my-4 pointer-events-auto">
        <woot-label
          :title="branchName"
          color-scheme="primary"
          class="max-w-[200px] cursor-default"
        />
      </div>
      <AddStepButton
        class="m-0"
        :class="{ 'mb-4': steps.length > 0 }"
        @add-step="e => addStepAt(0, e)"
      />
    </div>

    <div class="steps">
      <slot name="step" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.branch {
  @apply w-max min-w-[216px];
}

.connector-top {
  @apply flex flex-col items-end w-1/2 pointer-events-none rounded-none border-dashed border-slate-400 dark:border-slate-600;
  --transform: translateX(50%);
}

.connector-bridge {
  @apply w-full border-t-[1px] border-dashed border-slate-400 dark:border-slate-600;
}

.branch-label {
  transform: var(--transform);
}
</style>

<script>
import { mapGetters } from 'vuex';
import BoardList from './list/Index.vue';
import { useAdmin } from 'dashboard/composables/useAdmin';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';

export default {
  components: {
    BoardList,
    EmptyState,
  },
  setup() {
    const { isAdmin } = useAdmin();
    return {
      isAdmin,
    };
  },
  computed: {
    ...mapGetters({
      currentUserID: 'getCurrentUserID',
    }),
    boardId() {
      return this.$route.params.boardId;
    },
    board() {
      return this.$store.getters['wgptBoards/getBoard'](this.boardId);
    },
    canReadBoard() {
      if (this.isAdmin) return true;
      const managers = this.board?.config?.authorization?.managers ?? [];
      if (managers.includes(this.currentUserID)) return true;
      const operators = this.board?.config?.authorization?.operators ?? [];
      if (operators.includes(this.currentUserID)) return true;
      const readers = this.board?.config?.authorization?.readers ?? [];
      if (readers.includes(this.currentUserID)) return true;
      return false;
    },
  },
};
</script>

<template>
  <div class="h-full flex-1 overflow-auto w-full">
    <BoardList v-if="canReadBoard" :board="board" />

    <EmptyState
      v-else
      :title="$t('WGPT_BOARDS.UNAUTHORIZED_MESSAGE')"
      class="m-3"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import EditSourceForm from './EditSourceForm.vue';

export default {
  components: {
    EditSourceForm,
  },
  props: {
    source: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close'],
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBotSources/getUIFlags',
    }),
  },
  methods: {
    async updateSource(data) {
      try {
        const botId = this.$route.params.botId;
        const id = this.source.id;
        const source = await this.$store.dispatch('wgptBotSources/update', {
          botId,
          id,
          ...data,
        });

        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.EDIT.API.SUCCESS_MESSAGE'
          )
        );
        this.$emit('close', source);
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.EDIT.API.ERROR_MESSAGE'
          )
        );
      }
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.EDIT.TITLE', {
          sourceName: source.name,
        })
      "
    />

    <EditSourceForm
      :on-submit="updateSource"
      :on-close="onClose"
      :form-data="source"
      :submit-in-progress="uiFlags.isUpdating"
      :cancel-button-text="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.EDIT.CANCEL')
      "
      :submit-button-text="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.EDIT.SAVE')
      "
    />
  </div>
</template>

<script>
import Dropdown from '../../../components/Dropdown.vue';
import AddStepDropdown from './AddStepDropdown.vue';

export default {
  components: {
    Dropdown,
    AddStepDropdown,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['addStep'],
  data() {
    return {
      showDropdown: false,
      event: {},
    };
  },
  methods: {
    openDropdown(event) {
      this.showDropdown = true;
      this.event = event;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>

<template>
  <div>
    <div class="add-step-button">
      <woot-button
        size="tiny"
        variant="smooth"
        icon="add-circle"
        color-scheme="success"
        class-names="rounded-full p-0 z-10 pointer-events-auto"
        :disabled="disabled"
        @click="openDropdown"
      />
    </div>

    <Dropdown
      class="pointer-events-auto"
      :show-dropdown="showDropdown"
      :event="event"
      @close="closeDropdown"
    >
      <AddStepDropdown @close="closeDropdown" @add="e => $emit('addStep', e)" />
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
.add-step-button {
  @apply bg-white dark:bg-slate-900 rounded-full;
  transform: var(--transform);
  ::v-deep .icon {
    @apply w-full h-full;
  }
}
</style>

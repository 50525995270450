<script>
import translateMixins from '../../../mixins/translateMixins';
import variablesMixins from '../variablesDropdown/variablesMixins';
import { requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import VariablesDropdown from '../variablesDropdown/Index.vue';

export default {
  components: {
    VariablesDropdown,
  },
  mixins: [variablesMixins, translateMixins],
  props: {
    value: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    defaultValue: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    valueType: {
      type: String,
      default: 'string',
    },
    label: {
      type: String,
      default: '',
    },
    fallbackLabel: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      updatedValue: this.value ?? this.defaultValue ?? '',
    };
  },
  computed: {
    defaultOptionLabel() {
      if (this.placeholder) return this.placeholder;
      if (this.required)
        return this.$t(
          'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.SELECT.DEFAULT_OPTION_REQUIRED'
        );
      return this.$t(
        'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.SELECT.DEFAULT_OPTION'
      );
    },
    options() {
      return this.variables
        .filter(variable => !variable.wrapper)
        .map(variable => {
          const label = this.t(variable.label, variable.name);
          const value = variable.name;
          return { label, value };
        });
    },
    optionGroups() {
      return this.variables
        .filter(variable => variable.wrapper)
        .map(variable => {
          const label = this.t(variable.label, variable.name);
          const prefixLabel = label + '/';

          if (variable.model) {
            const modelDynamicField =
              this.$store.getters['wgptAutomationRegistry/getDynamicOptions'](
                variable.model
              ) || {};
            const dynamicFieldOptions = modelDynamicField.options || [];
            const groupOptions = dynamicFieldOptions.map(option => {
              const groupLabel = this.t('', prefixLabel + option.label);
              const groupValue = variable.name + '.' + option.value;
              return { ...option, label: groupLabel, value: groupValue };
            });
            return {
              label,
              ...modelDynamicField,
              options: groupOptions,
            };
          }

          const customObjects =
            this.$store.getters['wgptAutomationRegistry/getCustomObjects'](
              variable.custom_object
            ) || {};
          const customObjectsOptions = customObjects.options || [];
          const groupOptions = customObjectsOptions.map(option => {
            const groupLabel = this.t('', prefixLabel + this.t(option.label));
            const groupValue = variable.name + '.' + option.name;
            return { ...option, label: groupLabel, value: groupValue };
          });
          return {
            label,
            ...customObjects,
            options: groupOptions,
          };
        });
    },
  },
  validations() {
    return {
      updatedValue: {
        required: requiredIf(this.required),
      },
    };
  },
  watch: {
    value(value) {
      this.updatedValue = value ?? '';
    },
  },
  methods: {
    onVariableAdd(_, updatedValue) {
      this.updatedValue = updatedValue;
      this.updateValue();
    },
    updateValue() {
      this.v$.$touch();
      this.$emit('change', this.updatedValue);
    },
    formatSelectValue(value) {
      if (value === '') return null;

      switch (this.valueType) {
        case 'number':
          return Number(value);
        case 'boolean':
          return value === 'true';
        case 'date':
          return new Date(value);
        default:
          return value;
      }
    },
  },
};
</script>

<template>
  <div>
    <label class="select-label" :class="{ error: v$.updatedValue.$error }">
      <div class="flex items-center gap-2">
        <div v-if="label" class="block truncate">
          {{ t(label, fallbackLabel) }}<span v-if="required">*</span>
        </div>
        <fluent-icon
          v-if="description"
          v-tooltip.top="{
            content: t(description),
            popperClass: 'max-w-[300px]',
            boundary: 'body',
          }"
          icon="info"
          size="14"
        />
      </div>

      <div class="flex relative cursor-pointer disabled:cursor-not-allowed">
        <select
          v-model="updatedValue"
          :disabled="disabled"
          class="pointer-events-none truncate !mb-4"
          @focus="openVariablesDropdown"
          @input="updateValue"
        >
          <option value="" :disabled="required" selected>
            {{ defaultOptionLabel }}
          </option>
          <option
            v-for="(option, index) of options"
            :key="index"
            :value="option.value"
          >
            {{ option.label }}
          </option>
          <optgroup
            v-for="(optionGroup, groupIndex) of optionGroups"
            :key="options.length + groupIndex"
            :label="optionGroup.label"
          >
            <option
              v-for="(option, index) of optionGroup.options"
              :key="index"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </optgroup>
        </select>
      </div>

      <span v-if="v$.updatedValue.$error" class="message">
        <template v-if="v$.updatedValue.required.$invalid">{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.REQUIRED')
        }}</template>
        <template v-else>{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.INVALID')
        }}</template>
      </span>
    </label>

    <VariablesDropdown
      :show-dropdown="showVariablesDropdown"
      :event="variablesDropdownEvent"
      :variables="variables"
      :selected="updatedValue"
      :filter-by-prefix="variablesFilter"
      @close="closeVariablesDropdown"
      @add="addVariable"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import ModalForm from './ModalForm.vue';

export default {
  components: {
    ModalForm,
  },
  props: {
    stage: {
      type: Object,
      default: () => ({}),
    },
    workflow: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close'],
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBotWorkflowStages/getUIFlags',
    }),
  },
  methods: {
    async onSubmit(data) {
      try {
        const botId = this.$route.params.botId;
        const workflowId = this.workflow.id;
        const id = this.stage.id;
        const stage = {
          botId,
          workflowId,
          id,
          ...data,
        };

        const source = await this.$store.dispatch(
          'wgptBotWorkflowStages/update',
          stage
        );

        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.EDIT.API.SUCCESS_MESSAGE'
          )
        );
        this.$emit('close', source);
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.EDIT.API.ERROR_MESSAGE')
        );
      }
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div class="h-auto flex flex-col">
    <woot-modal-header
      :header-title="
        $t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.EDIT.TITLE', {
          name: workflow.name,
        })
      "
    />

    <ModalForm
      :on-submit="onSubmit"
      :on-close="onClose"
      :form-data="stage"
      :submit-in-progress="uiFlags.isCreating"
      :cancel-button-text="
        $t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.EDIT.CANCEL')
      "
      :submit-button-text="$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.EDIT.SAVE')"
    />
  </div>
</template>

<script>
import WootSubmitButton from '../../../../../components/buttons/FormSubmitButton.vue';
import validations from './helpers/validations';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: {
    WootSubmitButton,
  },

  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    const formData = this.formData || {};
    const {
      name = '',
      display_name: displayName = '',
      description = '',
    } = formData;

    return {
      name,
      description,
      displayName,
    };
  },
  validations,
  methods: {
    handleSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.onSubmit({
        name: this.name,
        display_name: this.displayName,
        description: this.description,
      });
    },
  },
};
</script>

<template>
  <div class="mx-0 flex flex-wrap">
    <div class="flex-shrink-0 flex-grow-0 w-[65%]">
      <form class="mx-0 flex flex-wrap" @submit.prevent="handleSubmit">
        <woot-input
          v-model.trim="name"
          :class="{ error: v$.name.$error }"
          class="w-full"
          :label="$t('WGPT_BOTS.NEW_FLOW.CREATE.FORM.NAME.LABEL')"
          :placeholder="$t('WGPT_BOTS.NEW_FLOW.CREATE.FORM.NAME.PLACEHOLDER')"
          @input="v$.name.$touch"
        />

        <woot-input
          v-model.trim="displayName"
          :class="{ error: v$.displayName.$error }"
          class="w-full"
          :label="$t('WGPT_BOTS.NEW_FLOW.CREATE.FORM.DISPLAY_NAME.LABEL')"
          :placeholder="
            $t('WGPT_BOTS.NEW_FLOW.CREATE.FORM.DISPLAY_NAME.PLACEHOLDER')
          "
          @input="v$.displayName.$touch"
        />

        <woot-input
          v-model.trim="description"
          :class="{ error: v$.description.$error }"
          class="w-full"
          :label="$t('WGPT_BOTS.NEW_FLOW.CREATE.FORM.DESCRIPTION.LABEL')"
          :placeholder="
            $t('WGPT_BOTS.NEW_FLOW.CREATE.FORM.DESCRIPTION.PLACEHOLDER')
          "
          @input="v$.description.$touch"
        />

        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <div class="w-full">
            <WootSubmitButton
              :disabled="v$.name.$invalid || submitInProgress"
              :button-text="submitButtonText"
              :loading="submitInProgress"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';

export default {
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
    };
  },
  validations: {
    name: {
      required,
    },
  },
  watch: {
    card() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.name = this.card.name ?? '';
    },
    async onSubmit(event) {
      try {
        const name = this.name;
        if (!name) return;

        const input = event.target;
        input.blur();

        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          name: this.name,
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.NAME.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
  },
};
</script>

<template>
  <div class="flex gap-4 mb-4">
    <fluent-icon
      icon="credit-card-person"
      class="min-w-[1rem] mt-2.5"
      size="20"
    />
    <div class="flex-1 w-full">
      <label :class="{ error: v$.name.$error }">
        <input
          v-model.trim="name"
          class="name-input !bg-transparent disabled:!bg-transparent disabled:!cursor-text !text-slate-900 dark:!text-slate-100 !border-none !outline !outline-2 !outline-transparent -outline-offset-2 !m-0 text-xl !font-semibold h-8"
          dir="auto"
          type="text"
          data-testid="board-list-card-name"
          :disabled="readonly"
          @change="onSubmit"
          @keyup.enter="onSubmit"
          @keyup.esc="onSubmit"
        />
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.name-input:focus {
  outline-color: #6cb8ff !important;
}
</style>

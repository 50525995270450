<script>
import { mapGetters } from 'vuex';
import { frontendURL } from '../../../../../helper/URLHelper';
import { useAccount } from 'dashboard/composables/useAccount';
import { useAdmin } from 'dashboard/composables/useAdmin';
import SecondarySidebar from './Secondary.vue';

export default {
  components: {
    SecondarySidebar,
  },
  props: {
    showSecondarySidebar: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'showBoardSettingsPopup',
    'showDeleteBoardPopup',
    'showAddBoardPopup',
    'toggleAccountModal',
  ],
  setup() {
    const { isAdmin } = useAdmin();
    const { accountId } = useAccount();
    return {
      isAdmin,
      accountId,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      boards: 'wgptBoards/getBoards',
    }),
    activeSecondaryMenu() {
      return {
        parentNav: 'boards',
        routes: ['boards_overview', 'board_item'],
        menuItems: [
          {
            icon: 'wgpt-board',
            label: 'WGPT_BOARDS_OVERVIEW',
            key: 'boards',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${this.accountId}/wgpt-boards`),
            toStateName: 'boards_overview',
          },
        ],
      };
    },
  },
  mounted() {
    this.$store.dispatch('wgptBoards/get');
  },
  methods: {
    toggleAccountModal() {
      this.$emit('toggleAccountModal');
    },
    showAddBoardPopup() {
      this.$emit('showAddBoardPopup');
    },
    showDeleteBoardPopup() {
      this.$emit('showDeleteBoardPopup');
    },
    showOpenBoardSettingsPopup(board) {
      this.$emit('showBoardSettingsPopup', board);
    },
  },
};
</script>

<template>
  <aside class="flex h-full">
    <SecondarySidebar
      v-if="showSecondarySidebar"
      :account-id="accountId"
      :current-user="currentUser"
      :boards="boards"
      :menu-config="activeSecondaryMenu"
      :is-admin="isAdmin"
      @add-board="showAddBoardPopup"
      @delete-board="showDeleteBoardPopup"
      @open-settings="showOpenBoardSettingsPopup"
      @toggle-accounts="toggleAccountModal"
    />
  </aside>
</template>

import WgptBots from './Index.vue';
import CreateStepWrap from './create/Index.vue';
import CreateBot from './create/CreateBot.vue';
import AssignInboxes from './create/AssignInboxes.vue';
import FinishSetup from './create/FinishSetup.vue';
import Editor from './edit/Index.vue';
import { frontendURL } from '../../../../helper/URLHelper';
import SettingsContent from '../Wrapper.vue';
import SettingsWrapper from '../SettingsWrapper.vue';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/wgpt-bots'),
      component: SettingsWrapper,
      children: [
        {
          path: '',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'wgpt_bots_list',
          component: WgptBots,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/settings/wgpt-bots'),
      component: SettingsContent,
      props: () => {
        return {
          headerTitle: 'WGPT_BOTS.HEADER',
          headerButtonText: 'WGPT_BOTS.HEADER_BTN_TXT',
          icon: 'bot',
          newButtonRoutes: ['wgpt_bots_new'],
          showBackButton: true,
        };
      },
      children: [
        {
          path: 'new',
          component: CreateStepWrap,
          children: [
            {
              path: '',
              name: 'wgpt_bots_new',
              component: CreateBot,
              meta: {
                permissions: ['administrator'],
              },
            },
            {
              path: ':botId/inboxes',
              name: 'wgpt_bots_add_inboxes',
              component: AssignInboxes,
              meta: {
                permissions: ['administrator'],
              },
            },
            {
              path: ':botId/finish',
              name: 'wgpt_bots_finish',
              component: FinishSetup,
              meta: {
                permissions: ['administrator'],
              },
            },
          ],
        },
        {
          path: ':botId/edit',
          name: 'wgpt_bots_edit',
          component: Editor,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
  ],
};

export function getVariables(outputMapping, outputMappingFields) {
  const values = [];
  if (Array.isArray(outputMapping)) {
    outputMapping.forEach(outputMappingItem => {
      const outputMappingFieldItem =
        outputMappingFields?.controls?.[0]?.controls;
      values.push(...getVariables(outputMappingItem, outputMappingFieldItem));
    });
  } else if (typeof outputMapping === 'object') {
    Object.keys(outputMapping).forEach(key => {
      const outputMappingValue = outputMapping[key];
      const outputMappingFieldItem = outputMappingFields.find(
        ({ name }) => name === key
      );
      values.push(...getVariables(outputMappingValue, outputMappingFieldItem));
    });
  } else if (outputMappingFields && outputMappingFields.is_variable) {
    values.push(outputMapping);
  }
  return values;
}

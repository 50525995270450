<script>
import TriggerContent from './TriggerContent.vue';
import ActionContent from './ActionContent.vue';
import ConditionContent from './ConditionContent.vue';
import SettingsContent from './SettingsContent.vue';
import { findStep } from '../../helpers/automationUtils';

export default {
  components: {
    TriggerContent,
    ActionContent,
    ConditionContent,
    SettingsContent,
  },
  props: {
    automation: {
      type: Object,
      default: () => {},
    },
    variables: {
      type: Array,
      default: () => [],
    },
    disableTriggerChange: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['settingsChange', 'triggerChange', 'stepChange'],
  computed: {
    isSettingsActive() {
      return this.$route.query.selected === 'settings';
    },
    isTriggerActive() {
      return !this.$route.query.selected;
    },
    trigger() {
      return this.automation.trigger || {};
    },
    selectedStep() {
      const selectedStepId = this.$route.query.selected;
      if (!selectedStepId) return this.trigger;

      const steps = this.automation.steps?.v || [];
      return findStep(steps, selectedStepId) || {};
    },
    isSelectedConditionType() {
      return this.selectedStep?.action === 'branch';
    },
  },
  methods: {
    onStepChange(...step) {
      this.$emit('stepChange', ...step);
    },
  },
};
</script>

<template>
  <aside
    class="w-2/5 h-full relative bg-white dark:bg-slate-900 ltr:border-l rtl:border-r border-slate-50 dark:border-slate-800/50 overflow-auto"
  >
    <TriggerContent
      v-if="isTriggerActive"
      :trigger="trigger"
      :variables="variables"
      :disable-trigger-change="disableTriggerChange"
      @trigger-change="e => $emit('triggerChange', e)"
    />

    <SettingsContent
      v-else-if="isSettingsActive"
      :automation="automation"
      @settings-change="e => $emit('settingsChange', e)"
    />

    <ConditionContent
      v-else-if="isSelectedConditionType"
      :step="selectedStep"
      :variables="variables"
      @step-change="onStepChange"
    />

    <ActionContent
      v-else
      :step="selectedStep"
      :variables="variables"
      @step-change="onStepChange"
    />
  </aside>
</template>

<script>
import TextField from './TextField.vue';
import EmailField from './EmailField.vue';
import UrlField from './UrlField.vue';
import NumberField from './NumberField.vue';
import DateField from './DateField.vue';
import SelectField from './SelectField.vue';
import MultiSelectField from './MultiSelectionField.vue';
import ArrayField from './ArrayField.vue';
import VariableField from './VariableField.vue';
import RichtextField from './RichtextField.vue';
import AttachmentField from './AttachmentField.vue';
import CheckboxField from './CheckboxField.vue';
import translateMixins from '../../../mixins/translateMixins';
import validationMixins from './helpers/validationMixins';
import { useVuelidate } from '@vuelidate/core';

export default {
  name: 'DynamicField',
  components: {
    TextField,
    EmailField,
    UrlField,
    NumberField,
    DateField,
    SelectField,
    MultiSelectField,
    ArrayField,
    VariableField,
    RichtextField,
    AttachmentField,
    CheckboxField,
  },
  mixins: [translateMixins, validationMixins],
  props: {
    value: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    field: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
      default: () => [],
    },
    showVariablesSelector: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['change'],
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    isFieldDynamic() {
      return !!this.field.model;
    },
    isFieldModelWrapper() {
      return this.field.wrapper && this.isFieldDynamic;
    },
    dynamicField() {
      if (this.isFieldModelWrapper) {
        const dynamicField =
          this.$store.getters['wgptAutomationRegistry/getDynamicOptions'](
            this.field.model
          ) || {};
        return {
          ...this.field,
          ...dynamicField,
        };
      }
      if (this.isFieldDynamic) {
        const dynamicField =
          this.$store.getters['wgptAutomationRegistry/getDynamicOptions'](
            this.field.model
          ) || {};
        return {
          ...this.field,
          ...dynamicField,
        };
      }
      return this.field;
    },
    isTextType() {
      return (
        this.dynamicField.type === 'text' ||
        this.dynamicField.type === 'textarea'
      );
    },
    isRichtextType() {
      return this.dynamicField.type === 'richtext';
    },
    isNumberType() {
      return this.dynamicField.type === 'number';
    },
    isEmailType() {
      return this.dynamicField.type === 'email';
    },
    isUrlType() {
      return this.dynamicField.type === 'url';
    },
    isDateType() {
      return (
        this.dynamicField.type === 'date' ||
        this.dynamicField.type === 'datetime' ||
        this.dynamicField.type === 'time'
      );
    },
    isSelectType() {
      return this.dynamicField.type === 'select';
    },
    isMultiSelectType() {
      return this.dynamicField.type === 'multiselect';
    },
    isArrayType() {
      return this.dynamicField.type === 'array';
    },
    isGroupInlineType() {
      return (
        this.dynamicField.type === 'group' &&
        this.dynamicField.style === 'inline'
      );
    },
    isGroupType() {
      return this.dynamicField.type === 'group';
    },
    isVariableType() {
      return this.dynamicField.type === 'variable';
    },
    isAttachmentType() {
      return this.dynamicField.type === 'attachment';
    },
    isCheckboxType() {
      return this.dynamicField.type === 'checkbox';
    },
    options() {
      if (this.isFieldDynamic) {
        return this.dynamicField.options || [];
      }
      const options = this.field.options || [];
      return options.map(option => {
        const label = this.t(
          option.label,
          option.fallbackLabel ?? option.value
        );
        return { ...option, label };
      });
    },
    optionGroups() {
      const optionGroups = this.field.optionGroups || [];
      return optionGroups.map(optionGroup => {
        const label = this.t(
          optionGroup.label,
          optionGroup.fallbackLabel ?? optionGroup.value
        );
        const optionGroupOptions = optionGroup.options || [];
        const options = optionGroupOptions.map(option => {
          const optionLabel = this.t(
            option.label,
            option.fallbackLabel ?? option.value
          );
          return { ...option, label: optionLabel };
        });
        return { label, options };
      });
    },
    groupValue() {
      return this.value || this.dynamicField.default || {};
    },
    arrayValue() {
      return this.value || this.dynamicField.default || [];
    },
    valueType() {
      return this.dynamicField.value_type;
    },
    disabledAddArrayControl() {
      return (
        this.dynamicField.max && this.arrayValue.length >= this.dynamicField.max
      );
    },
    disabledRemoveArrayControl() {
      return (
        this.dynamicField.min && this.arrayValue.length <= this.dynamicField.min
      );
    },
  },
  watch: {
    field: {
      handler(field, fieldBefore = {}) {
        this.$nextTick(() => {
          this.resetValidation();
        });

        const { model, custom_object } = field;
        const { model: modelBefore, custom_object: customObjectBefore } =
          fieldBefore;

        if (
          !this.disabled &&
          (model !== modelBefore || custom_object !== customObjectBefore)
        ) {
          this.dispatchWrappers();
        }
      },
      immediate: true,
    },
    disabled(disabled, disabledBefore) {
      if (disabled === disabledBefore) return;
      this.dispatchWrappers();
    },
  },
  validations() {
    return {
      arrayValue: {
        duplicate: this.duplicateIf(this.isArrayType, this.dynamicField),
      },
    };
  },
  methods: {
    dispatchWrappers() {
      const field = this.field;
      if (field.wrapper) {
        if (field.model) {
          this.$store.dispatch(
            'wgptAutomationRegistry/getDynamicOptions',
            field.model
          );
        } else if (field.custom_object) {
          this.$store.dispatch(
            'wgptAutomationRegistry/getCustomObjects',
            field.custom_object
          );
        }
        return;
      }

      if (field.model) {
        const args = {
          fieldName: field.model,
          params: this.params,
        };
        this.$store.dispatch('wgptAutomationRegistry/getDynamicOptions', args);
      }
    },
    resetValidation() {
      this.v$.$reset();
    },
    isDisabled(field) {
      if (field.disabled) return true;
      const results = this.resolveConditions(field.disabled_unless);
      return !results.every(result => result);
    },
    isVisible(field) {
      const results = this.resolveConditions(field.show_if);
      return results.every(result => result);
    },
    resolveConditions(conditions = []) {
      const attributes = this.value ?? {};
      return conditions.map(({ field: fieldName, value }) => {
        const currentValue =
          attributes[fieldName] ?? this.getDefaultFieldValue(fieldName);
        if (currentValue == null) return false;
        if (value.includes('*')) {
          if (value === '*') return currentValue;
          return `${currentValue}`.includes(value.replace(/\*/g, ''));
        }
        return currentValue === value;
      });
    },
    getDefaultFieldValue(fieldName) {
      const field = this.field.controls.find(({ name }) => name === fieldName);
      return field?.default;
    },
    groupControlChange(value, controlName) {
      const groupValue = {
        ...this.groupValue,
        [controlName]: value,
      };
      this.$emit('change', groupValue);
    },
    onChange(value) {
      this.$emit('change', value, this.dynamicField);
    },
    constructParams(field) {
      const params = {};
      const paramFields = field.params ?? [];
      const value = this.value ?? {};
      paramFields.forEach(paramField => {
        params[paramField] = value[paramField];
      });
      return params;
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="visible">
    <TextField
      v-if="isTextType"
      :label="dynamicField.label"
      :description="dynamicField.description"
      :fallback-label="dynamicField.fallbackLabel"
      :placeholder="dynamicField.placeholder"
      :value="value"
      :default-value="dynamicField.default"
      :type="dynamicField.type"
      :required="dynamicField.required"
      :disabled="disabled"
      :variables="variables"
      :show-variables-selector="showVariablesSelector"
      :alphanumeric-plus-underscore-only="dynamicField.is_variable"
      @change="onChange"
    />
    <NumberField
      v-else-if="isNumberType"
      :label="dynamicField.label"
      :description="dynamicField.description"
      :fallback-label="dynamicField.fallbackLabel"
      :placeholder="dynamicField.placeholder"
      :value="value"
      :default-value="dynamicField.default"
      :required="dynamicField.required"
      :min="dynamicField.min"
      :max="dynamicField.max"
      :disabled="disabled"
      :variables="variables"
      :show-variables-selector="showVariablesSelector"
      @change="onChange"
    />
    <EmailField
      v-else-if="isEmailType"
      :label="dynamicField.label"
      :description="dynamicField.description"
      :fallback-label="dynamicField.fallbackLabel"
      :placeholder="dynamicField.placeholder"
      :value="value"
      :default-value="dynamicField.default"
      :required="dynamicField.required"
      :disabled="disabled"
      :variables="variables"
      :show-variables-selector="showVariablesSelector"
      @change="onChange"
    />
    <UrlField
      v-else-if="isUrlType"
      :label="dynamicField.label"
      :description="dynamicField.description"
      :fallback-label="dynamicField.fallbackLabel"
      :placeholder="dynamicField.placeholder"
      :value="value"
      :default-value="dynamicField.default"
      :required="dynamicField.required"
      :disabled="disabled"
      :variables="variables"
      :show-variables-selector="showVariablesSelector"
      @change="onChange"
    />
    <RichtextField
      v-else-if="isRichtextType"
      :label="dynamicField.label"
      :description="dynamicField.description"
      :fallback-label="dynamicField.fallbackLabel"
      :placeholder="dynamicField.placeholder"
      :value="value"
      :default-value="dynamicField.default"
      :required="dynamicField.required"
      :variables="variables"
      :show-variables-selector="showVariablesSelector"
      @change="onChange"
    />
    <DateField
      v-else-if="isDateType"
      :label="dynamicField.label"
      :description="dynamicField.description"
      :fallback-label="dynamicField.fallbackLabel"
      :placeholder="dynamicField.placeholder"
      :value="value"
      :default-value="dynamicField.default"
      :required="dynamicField.required"
      :variables="variables"
      :type="dynamicField.type"
      :disabled="disabled"
      @change="onChange"
    />
    <SelectField
      v-else-if="isSelectType"
      :label="dynamicField.label"
      :description="dynamicField.description"
      :fallback-label="dynamicField.fallbackLabel"
      :placeholder="dynamicField.placeholder"
      :options="options"
      :option-groups="optionGroups"
      :value="value"
      :default-value="dynamicField.default"
      :required="dynamicField.required"
      :value-type="valueType"
      :disabled="disabled"
      @change="onChange"
    />
    <MultiSelectField
      v-else-if="isMultiSelectType"
      :label="dynamicField.label"
      :description="dynamicField.description"
      :fallback-label="dynamicField.fallbackLabel"
      :placeholder="dynamicField.placeholder"
      :options="options"
      :values="arrayValue"
      :default-value="dynamicField.default"
      :required="dynamicField.required"
      :disabled="disabled"
      @change="onChange"
    />
    <VariableField
      v-else-if="isVariableType"
      :label="dynamicField.label"
      :description="dynamicField.description"
      :fallback-label="dynamicField.fallbackLabel"
      :placeholder="dynamicField.placeholder"
      :value="value"
      :default-value="dynamicField.default"
      :required="dynamicField.required"
      :value-type="valueType"
      :disabled="disabled"
      :variables="variables"
      @change="onChange"
    />
    <CheckboxField
      v-else-if="isCheckboxType"
      :label="dynamicField.label"
      :option-label="dynamicField.option_label"
      :description="dynamicField.description"
      :fallback-label="dynamicField.fallbackLabel"
      :placeholder="dynamicField.placeholder"
      :value="value"
      :default-value="dynamicField.default"
      :required="dynamicField.required"
      :value-type="valueType"
      :disabled="disabled"
      :variables="variables"
      @change="onChange"
    />

    <div v-else-if="isGroupInlineType">
      <div class="flex gap-2">
        <dynamic-field
          v-for="control in dynamicField.controls"
          :key="control.name"
          class="flex-1 shrink-0"
          :field="control"
          :value="groupValue[control.name]"
          :visible="isVisible(control)"
          :disabled="disabled"
          :variables="variables"
          :show-variables-selector="showVariablesSelector"
          :alphanumeric-plus-underscore-only="dynamicField.is_variable"
          @change="value => groupControlChange(value, control.name)"
        />
      </div>
    </div>

    <div v-else-if="isGroupType">
      <label class="flex items-center gap-2">
        <span v-if="dynamicField.label" class="block truncate">{{
          t(dynamicField.label, dynamicField.fallbackLabel)
        }}</span>
        <fluent-icon
          v-if="dynamicField.description"
          v-tooltip.top="{
            content: t(dynamicField.description),
            popperClass: 'max-w-[300px]',
            boundary: 'body',
          }"
          icon="info"
          size="14"
        />
      </label>
      <dynamic-field
        v-for="control in dynamicField.controls"
        :key="control.name"
        :field="control"
        :value="groupValue[control.name]"
        :visible="isVisible(control)"
        :disabled="isDisabled(control)"
        :variables="variables"
        :show-variables-selector="showVariablesSelector"
        :alphanumeric-plus-underscore-only="dynamicField.is_variable"
        :params="constructParams(control)"
        @change="value => groupControlChange(value, control.name)"
      />
    </div>

    <ArrayField
      v-else-if="isArrayType"
      v-slot="slotProps"
      :field="dynamicField"
      :values="arrayValue"
      :disabled="disabled"
      @change="onChange"
    >
      <dynamic-field
        v-for="control in dynamicField.controls"
        :key="control.name"
        :field="control"
        :disabled="disabled"
        :variables="variables"
        :show-variables-selector="showVariablesSelector"
        :value="slotProps.value"
        :alphanumeric-plus-underscore-only="dynamicField.is_variable"
        class="flex-1"
        @change="slotProps.change"
      />
    </ArrayField>

    <AttachmentField
      v-else-if="isAttachmentType"
      :label="dynamicField.label"
      :description="dynamicField.description"
      :fallback-label="dynamicField.fallbackLabel"
      :placeholder="dynamicField.placeholder"
      :value="value"
      :default-value="dynamicField.default"
      :required="dynamicField.required"
      :value-type="valueType"
      :disabled="disabled"
      :variables="variables"
      @change="onChange"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import MenuItem from '../../../../../../../components/widgets/conversation/contextMenu/menuItem.vue';
import ContextMenu from 'dashboard/components/ui/ContextMenu.vue';

export default {
  components: {
    MenuItem,
    ContextMenu,
  },
  emits: ['delete', 'edit'],
  setup() {
    const contextMenuPosition = ref({});
    const isOpen = ref(false);

    return {
      contextMenuPosition,
      isOpen,
    };
  },
  methods: {
    handleOpen(e) {
      this.isOpen = true;
      this.contextMenuPosition = {
        x: e.pageX || e.clientX,
        y: e.pageY || e.clientY,
      };
    },
    handleClose() {
      this.isOpen = false;
    },
    handleEdit(e) {
      this.$emit('edit', e);
      this.handleClose();
    },
    handleDelete(e) {
      this.$emit('delete', e);
      this.handleClose();
    },
  },
};
</script>

<template>
  <div class="context-menu">
    <woot-button
      class="dark:hover:bg-slate-950"
      icon="more-vertical"
      color-scheme="secondary"
      variant="clear"
      size="small"
      @click="handleOpen"
    />
    <ContextMenu
      v-if="isOpen"
      :x="contextMenuPosition.x"
      :y="contextMenuPosition.y"
      @close="handleClose"
    >
      <div class="menu-container">
        <MenuItem
          :option="{
            icon: 'edit',
            label: 'Edit',
          }"
          variant="icon"
          @click.stop="handleEdit"
        />
        <hr />
        <MenuItem
          :option="{
            icon: 'delete',
            label: 'Delete',
          }"
          variant="icon"
          @click.stop="handleDelete"
        />
      </div>
    </ContextMenu>
  </div>
</template>

<style lang="scss" scoped>
.menu-container {
  @apply p-1 bg-white dark:bg-slate-900 shadow-xl rounded-md;

  hr:first-child {
    @apply hidden;
  }

  hr {
    @apply m-1 border-b border-solid border-slate-50 dark:border-slate-800/50;
  }
}
</style>

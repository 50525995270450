<script>
import { debounce } from '@chatwoot/utils';
import { SEARCH_MAX_LENGTH } from './components/FilterBoard/helpers/constant';

export default {
  props: {
    headerTitle: {
      default: '',
      type: String,
    },
    showSidemenuIcon: {
      type: Boolean,
      default: true,
    },
    showSearchbox: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => {},
    },
    hasAppliedFilters: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit', 'openFiltersModal'],
  data() {
    return {
      search: '',
      searching: false,
      onSearch: debounce(() => {
        this.submitSearch();
      }, 750),
    };
  },
  computed: {
    searchFilter() {
      return this.filters.search ?? '';
    },
    boardId() {
      return this.$route.params.boardId;
    },
  },
  watch: {
    searchFilter(value) {
      if (value === this.search) return;
      this.search = value;
    },
  },
  methods: {
    onInput() {
      if (this.search.length > SEARCH_MAX_LENGTH) {
        this.search = this.search.slice(0, SEARCH_MAX_LENGTH);
      }
      if (this.search) {
        this.searching = true;
      }
      this.onSearch();
    },
    clearSearch() {
      this.search = '';
      this.onSearch();
    },
    async submitSearch() {
      try {
        const search = this.search || undefined;
        const filterOptions = {
          boardId: this.boardId,
          filters: {
            ...this.filters,
            search,
          },
        };
        await this.$store.dispatch('wgptBoardListCards/filter', filterOptions);
      } catch (error) {
        // ignore error
      } finally {
        this.searching = false;
      }
    },
    openEditPopup() {
      this.$emit('edit');
    },
    toggleFilter(e) {
      this.$emit('openFiltersModal', e);
    },
    clearFilters() {
      const boardId = this.boardId;
      this.$store.dispatch('wgptBoardListCards/clearFilters', boardId);
    },
  },
};
</script>

<template>
  <div
    class="flex justify-between items-center h-14 min-h-[3.5rem] px-4 py-2 bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800/50"
  >
    <h1
      class="text-2xl mb-0 flex items-center text-slate-900 dark:text-slate-100"
    >
      <woot-sidemenu-icon v-if="showSidemenuIcon" class="rtl:ml-0 rtl:-mr-3" />
      <span class="text-slate-900 dark:text-slate-100 text-truncate">{{
        headerTitle
      }}</span>
    </h1>

    <div class="flex items-center gap-2">
      <template v-if="showSearchbox">
        <div
          class="max-w-[400px] min-w-[150px] flex items-center relative mx-2 search-wrap"
        >
          <div class="flex items-center absolute h-full left-2.5">
            <fluent-icon
              icon="search"
              class="h-5 leading-9 text-sm text-slate-700 dark:text-slate-200"
            />
          </div>
          <input
            v-model.trim="search"
            type="text"
            :placeholder="$t('WGPT_BOARDS.FILTER.SEARCH.PLACEHOLDER')"
            class="contact-search border-slate-100 dark:border-slate-600"
            @input="onInput"
          />
          <div
            v-if="searching"
            class="text-center absolute right-0 leading-none"
          >
            <span class="spinner" />
          </div>
        </div>

        <div class="flex gap-2">
          <div class="relative">
            <div
              v-if="hasAppliedFilters"
              class="absolute w-2 h-2 rounded-full top-1 right-3 bg-slate-500 dark:bg-slate-500"
            />
            <woot-button
              class="clear [&>span]:hidden xs:[&>span]:block [&>*]:pointer-events-none"
              color-scheme="secondary"
              icon="filter"
              class-names="filter-button"
              @click="toggleFilter"
            >
              {{ $t('WGPT_BOARDS.FILTER.BUTTON_TXT.FILTER_BUTTON') }}
            </woot-button>
          </div>
          <woot-button
            v-if="hasAppliedFilters"
            class="clear [&>span]:hidden xs:[&>span]:block [&>*]:pointer-events-none"
            color-scheme="alert"
            variant="smooth"
            class-names="filter-clear-button"
            @click="clearFilters"
          >
            {{ $t('WGPT_BOARDS.FILTER.BUTTON_TXT.CLEAR_FILTER') }}
          </woot-button>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-wrap {
  .contact-search {
    @apply pl-9 pr-[3.75rem] text-sm w-full h-[2.375rem] m-0;
  }

  .button {
    transition: transform 100ms linear;
    @apply ml-2 h-8 right-1 absolute py-0 px-2 opacity-0 -translate-x-px invisible;
  }

  .button.show {
    @apply opacity-100 translate-x-0 visible;
  }
}
</style>

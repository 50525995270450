<script>
import ParameterInput from './ParameterInput.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    ParameterInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    hasDuplicate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['remove', 'change'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      updatedKey: this.value.key,
      updatedValue: this.value.value,
    };
  },
  watch: {
    updatedKey() {
      this.onChange();
    },
    updatedValue() {
      this.onChange();
    },
  },
  validations() {
    return {
      updatedKey: {
        required,
      },
      updatedValue: {
        required,
      },
    };
  },
  methods: {
    onChange() {
      this.$emit('change', {
        key: this.updatedKey,
        value: this.updatedValue,
      });
    },
  },
};
</script>

<template>
  <div class="w-full flex gap-2">
    <div class="flex-1">
      <label :class="{ error: v$.updatedKey.$error || hasDuplicate }">
        <span
          v-if="showLabel"
          class="text-sm text-slate-600 dark:text-slate-300"
        >
          {{ $t('WGPT_BOTS.EDIT.PARAMETER_ROW.FORM.KEY.LABEL') }}
        </span>
        <ParameterInput
          v-model="updatedKey"
          :disabled="disabled"
          class="!mt-0 !mb-1"
          @blur="v$.updatedKey.$touch"
        />
        <template v-if="v$.updatedKey.$error || hasDuplicate">
          <span v-if="v$.updatedKey.required.$invalid" class="message">
            {{ $t('WGPT_BOTS.EDIT.PARAMETER_ROW.FORM.KEY.ERROR') }}
          </span>
          <span v-else-if="hasDuplicate" class="message">
            {{ $t('WGPT_BOTS.EDIT.PARAMETER_ROW.FORM.KEY.DUPLICATE_ERROR') }}
          </span>
        </template>
      </label>
    </div>

    <div class="flex-1">
      <label :class="{ error: v$.updatedValue.$error }">
        <span
          v-if="showLabel"
          class="text-sm text-slate-600 dark:text-slate-300"
        >
          {{ $t('WGPT_BOTS.EDIT.PARAMETER_ROW.FORM.VALUE.LABEL') }}
        </span>
        <input
          v-model="updatedValue"
          class="!mt-0 !mb-1"
          rows="2"
          type="text"
          @blur="v$.updatedValue.$touch"
        />
        <span v-if="v$.updatedValue.$error" class="message">
          {{ $t('WGPT_BOTS.EDIT.PARAMETER_ROW.FORM.VALUE.ERROR') }}
        </span>
      </label>
    </div>
    <div class="mt-1 mb-auto" :class="{ 'mt-8': showLabel }">
      <woot-button
        icon="dismiss"
        color-scheme="secondary"
        variant="clear"
        size="small"
        type="button"
        @click="$emit('remove')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  props: {
    board: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete', 'close', 'open'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      confirmation: '',
    };
  },
  validations: {
    confirmation: {
      required,
      shouldBeTheSameName(value) {
        try {
          return this.board.name === value;
        } catch (error) {
          return false;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoards/getUIFlags',
    }),
  },
  methods: {
    onOpen() {
      this.$emit('open');
    },
    onClose() {
      this.$emit('close');
    },
    onDelete() {
      this.$emit('delete');
    },
    async onSubmit() {
      try {
        const id = this.board.id;
        await this.$store.dispatch('wgptBoards/delete', id);
        useAlert(this.$t('WGPT_BOARDS.DELETE.API.SUCCESS_MESSAGE'));
        this.$router.replace({ name: 'boards_overview' });
      } catch (error) {
        useAlert(this.$t('WGPT_BOARDS.DELETE.API.ERROR_MESSAGE'));
      } finally {
        this.onClose();
      }
    },
  },
};
</script>

<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('WGPT_BOARDS.DELETE.TITLE', { boardName: board.name })"
      :header-content="$t('WGPT_BOARDS.DELETE.DESC')"
    />
    <form class="mx-0 flex flex-wrap w-full" @submit.prevent="onSubmit">
      <div class="w-full">
        <label :class="{ error: v$.confirmation.$error }">
          <woot-input
            v-model.trim="confirmation"
            data-testid="board-confirmation"
            :placeholder="
              $t('WGPT_BOARDS.DELETE.FORM.CONFIRMATION.PLACEHOLDER', {
                boardName: board.name,
              })
            "
            @input="v$.confirmation.$touch"
          />
        </label>
      </div>

      <div class="flex justify-end items-center py-2 px-0 gap-2 w-full">
        <woot-button
          :is-disabled="uiFlags.isDeleting || v$.confirmation.$invalid"
          :is-loading="uiFlags.isDeleting"
          data-testid="board-submit"
          color-scheme="alert"
        >
          {{ $t('WGPT_BOARDS.DELETE.FORM.DELETE') }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('WGPT_BOARDS.DELETE.FORM.CANCEL') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

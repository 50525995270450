<script>
import translateMixins from '../../../mixins/translateMixins';
import DynamicField from '../dynamicField/Index.vue';
import ConditionField from '../conditionField/Index.vue';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';
import getUuid from 'widget/helpers/uuid';

export default {
  components: {
    FluentIcon,
    DynamicField,
    ConditionField,
  },
  mixins: [translateMixins],
  props: {
    branch: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    variables: {
      type: Array,
      default: () => [],
    },
    disableRemove: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['branchChange', 'moveUp', 'moveDown', 'remove'],
  data() {
    return {
      fallbackName: this.$t(
        'WGPT_AUTOMATIONS.EDITOR.BRANCH.FALLBACK_BRANCH_NAME',
        {
          index: this.index + 1,
        }
      ),
      updatedName: this.branch.name,
      isEditing: false,
    };
  },
  computed: {
    branchName() {
      return this.branch.name || this.fallbackName;
    },
    branchRegistry() {
      return this.$store.getters['wgptAutomationRegistry/getAction']('branch');
    },
    branchFields() {
      return this.branchRegistry.branch_fields || [];
    },
    conditions() {
      return this.branch.conditions || [];
    },
    disabledRemoveCondition() {
      return this.conditions.length <= 1;
    },
    logicalOperators() {
      return this.$store.getters['wgptAutomationRegistry/getLogicalOperators'];
    },
    logicalOperatorField() {
      return {
        name: 'logical_operator',
        type: 'select',
        options: this.logicalOperators,
        required: true,
      };
    },
  },
  watch: {
    branch: {
      handler(branch) {
        if (branch.name !== this.updatedName) {
          this.updatedName = branch.name || `Branch ${this.index + 1}`;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onBranchChange(fieldName, value) {
      const branchUpdates = {
        [fieldName]: value,
      };
      this.$emit('branchChange', branchUpdates);
    },
    onConditionChange(conditionIndex, value) {
      const conditionsBefore = this.conditions;
      const conditions = conditionsBefore.map((condition, index) => {
        if (index === conditionIndex) {
          return {
            ...condition,
            ...value,
          };
        }
        return condition;
      });
      const branchUpdates = {
        conditions,
      };
      this.$emit('branchChange', branchUpdates);
    },
    addCondition() {
      const condition = {
        variable_name: '',
        operator: 'equals',
        value: '',
      };

      const conditionsBefore = this.conditions;
      const conditions = conditionsBefore.concat(condition);
      const branchUpdates = {
        conditions,
      };
      this.$emit('branchChange', branchUpdates);
    },
    removeCondition(conditionIndex) {
      const conditionsBefore = this.conditions;
      const conditions = conditionsBefore.filter(
        (condition, index) => index !== conditionIndex
      );
      const branchUpdates = {
        conditions,
      };
      this.$emit('branchChange', branchUpdates);
    },
    editName() {
      this.isEditing = true;
      this.$nextTick(() => {
        this.$refs.branchNameInput.focus();
      });
    },
    onNameChange() {
      this.onBranchChange('name', this.updatedName);
      this.isEditing = false;
      this.$refs.branchNameInput.blur();
    },
    cancelNameEditing() {
      this.isEditing = false;
    },
    getUuid() {
      return getUuid();
    },
  },
};
</script>

<template>
  <div class="rounded-md bg-slate-50 dark:bg-slate-800">
    <header class="cursor-grab group py-2 ps-3 pe-1 flex items-center gap-2">
      <FluentIcon icon="drag" size="16" class="mt-1 shrink-0" />
      <input
        v-show="isEditing"
        ref="branchNameInput"
        v-model.trim="updatedName"
        :placeholder="fallbackName"
        class="branch-name-input !text-sm ignore-drag !ps-1 !bg-transparent disabled:!bg-transparent disabled:!cursor-text !text-slate-900 dark:!text-slate-100 !border-none !outline !outline-2 !outline-transparent -outline-offset-2 !m-0 !font-semibold !h-8"
        dir="auto"
        type="text"
        @blur="onNameChange"
        @keyup.enter="onNameChange"
        @keyup.esc="cancelNameEditing"
      />
      <h5
        v-if="!isEditing"
        class="px-1 grow text-sm font-semibold text-black-900 dark:text-slate-200 select-none inline-flex items-center gap-1.5 overflow-hidden"
      >
        <span class="truncate">{{ branchName }}</span>
        <woot-button
          color-scheme="secondary"
          size="tiny"
          icon="edit"
          variant="clear"
          class-names="invisible group-hover:visible ignore-drag"
          @click="editName"
        />
      </h5>
      <aside class="ms-auto shrink-0">
        <template v-if="!isEditing">
          <woot-button
            size="small"
            icon="arrow-up"
            variant="clear"
            :disabled="isFirst"
            color-scheme="secondary"
            class-names="ignore-drag"
            @click="$emit('moveUp')"
          />
          <woot-button
            size="small"
            icon="arrow-up"
            variant="clear"
            :disabled="isLast"
            color-scheme="secondary"
            class-names="ignore-drag rotate-180"
            @click="$emit('moveDown')"
          />
        </template>
        <woot-button
          size="small"
          icon="dismiss"
          variant="clear"
          color-scheme="secondary"
          :disabled="disableRemove"
          class-names="ignore-drag"
          @click="$emit('remove')"
        />
      </aside>
    </header>

    <div
      class="ignore-drag ps-3 pe-1 border-t border-slate-100 dark:border-slate-700/50"
    >
      <h4 class="my-4 text-base font-medium text-black-900 dark:text-slate-200">
        {{ $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.BRANCH.CONDITIONS.TITLE') }}
      </h4>
      <div>
        <div
          v-for="(condition, conditionIndex) of conditions"
          :key="`${conditionIndex}-${getUuid()}`"
          class="condition-field"
        >
          <ConditionField
            :value="condition"
            :variables="variables"
            :disable-remove="disabledRemoveCondition"
            @change="value => onConditionChange(conditionIndex, value)"
            @remove="removeCondition(conditionIndex)"
          >
            <template #operator-field>
              <DynamicField
                class="operator-field"
                :field="logicalOperatorField"
                :value="branch[logicalOperatorField.name]"
                :visible="conditions.length > 1"
                :disabled="conditionIndex > 0"
                @change="e => onBranchChange(logicalOperatorField.name, e)"
              />
            </template>
          </ConditionField>
        </div>
      </div>
      <woot-button
        class="my-4"
        color-scheme="primary"
        size="small"
        icon="add"
        @click="addCondition()"
      >
        {{
          $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.BRANCH.ADD_CONDITION_BUTTON_TEXT')
        }}
      </woot-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.branch-name-input:focus {
  outline-color: #6cb8ff !important;
}

.condition-field:last-child .operator-field {
  @apply hidden;
}
</style>

<script>
import { useAlert } from 'dashboard/composables';
import { mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
    };
  },
  validations: {
    name: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardListCards/getUIFlags',
    }),
  },
  mounted() {
    this.autoFocusNameField();
  },
  methods: {
    autoFocusNameField() {
      this.$nextTick(() => {
        const inputEl = this.$refs.addCardNameField.$el.querySelector('input');
        if (inputEl) {
          inputEl.focus();
        }
      });
    },
    onClose() {
      this.$emit('close');
    },
    async handleSubmit() {
      try {
        if (this.v$.$invalid) {
          this.v$.$touch();
          return;
        }

        const card = {
          name: this.name,
        };
        await this.onSubmit(card);

        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.ADD.FORM.API.SUCCESS_MESSAGE')
        );
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.ADD.FORM.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
  },
};
</script>

<template>
  <form class="mx-0 flex flex-wrap w-full" @submit.prevent="handleSubmit">
    <div class="w-full">
      <label :class="{ error: v$.name.$error }">
        <woot-input
          ref="addCardNameField"
          v-model.trim="name"
          class="[&>input]:!rounded-lg"
          data-testid="board-name"
          :placeholder="
            $t('WGPT_BOARDS.ITEM.LIST.CARD.ADD.FORM.NAME.PLACEHOLDER')
          "
          @input="v$.name.$touch"
        />
      </label>
    </div>

    <div class="gap-2 w-full flex">
      <woot-button
        :is-disabled="uiFlags.isCreating"
        :is-loading="uiFlags.isCreating"
        size="small"
        data-testid="board-add-list-submit"
      >
        {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.ADD.FORM.SUBMIT') }}
      </woot-button>
      <woot-button
        icon="dismiss"
        class="button clear"
        color-scheme="secondary"
        size="small"
        data-testid="board-add-list-cancel"
        @click.prevent="onClose"
      />
    </div>
  </form>
</template>

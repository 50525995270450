<script>
export default {
  props: {
    board: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<template>
  <div
    class="h-full rounded-xl text-slate-700 dark:text-slate-100 bg-white dark:bg-slate-800 border border-slate-100 dark:border-slate-500/50 overflow-hidden cursor-pointer"
  >
    <div class="flex items-center justify-between">
      <div class="px-3 pt-2 overflow-hidden">
        <h4 class="text-lg text-inherit m-0 truncate">
          {{ board.name }}
        </h4>
        <p v-if="board.description" class="text-xs line-clamp-2 mt-2">
          {{ board.description }}
        </p>
      </div>
    </div>
  </div>
</template>

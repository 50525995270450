<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

import SourceContextMenu from './SourceContextMenu.vue';
import SourceItem from './SourceItem.vue';
import SourceType from './SourceType.vue';
import AddSource from './components/create/AddSource.vue';
import EditSource from './components/edit/EditSource.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';

export default {
  components: {
    SourceContextMenu,
    SourceItem,
    SourceType,
    AddSource,
    EditSource,
    EmptyState,
  },
  mixins: [globalConfigMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      selectedSource: {},
    };
  },
  computed: {
    ...mapGetters({
      sources: 'wgptBotSources/getSources',
    }),
    deleteMessage() {
      return ` ${this.selectedResponse.name}?`;
    },
    tableHeaders() {
      return [
        this.$t(
          'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.LIST.TABLE_HEADER.ITEM'
        ),
        this.$t(
          'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.LIST.TABLE_HEADER.TYPE'
        ),
      ];
    },
  },
  mounted() {
    const botId = this.$route.params.botId;
    this.$store.dispatch('wgptBotSources/get', botId);
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup(selectedSource) {
      this.selectedSource = selectedSource || {};
      this.showAddPopup = false;
    },
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedResponse = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteSourceChunk(this.selectedResponse.id);
    },
    deleteSourceChunk(id) {
      const botId = this.$route.params.botId;
      this.$store
        .dispatch('wgptBotSources/delete', { botId, id })
        .then(() => {
          useAlert(
            this.$t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.DELETE.API.SUCCESS_MESSAGE'
            )
          );
        })
        .catch(() => {
          useAlert(
            this.$t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.DELETE.API.ERROR_MESSAGE'
            )
          );
        })
        .finally(() => {
          this.loading[this.selectedResponse.id] = false;
        });
    },
  },
};
</script>

<template>
  <div class="flex-1 overflow-auto h-full">
    <div class="flex flex-row h-full overflow-hidden">
      <div class="w-[30%] relative">
        <template v-if="!sources">
          <woot-loading-state
            :message="
              $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.LIST.LOADING')
            "
          />
        </template>

        <template v-else-if="sources.length === 0">
          <div class="py-16 px-1 my-4 flex items-center flex-col">
            <p>
              {{ $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.LIST.404') }}
            </p>
            <woot-button
              class="mt-3 px-1"
              variant="clear"
              icon="add"
              @click="openAddPopup"
            >
              {{
                $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.LIST.CREATE')
              }}
            </woot-button>
          </div>
        </template>

        <template v-else>
          <div class="ps-6 pe-3">
            <table class="mt-3 woot-table">
              <thead class="border-x-none">
                <th
                  v-for="thHeader in tableHeaders"
                  :key="thHeader"
                  class="pl-2 pr-0"
                >
                  {{ thHeader }}
                </th>
              </thead>
              <tbody class="border-x-none">
                <tr
                  v-for="sourceItem in sources"
                  :key="sourceItem.id"
                  :class="{ active: selectedSource.id == sourceItem.id }"
                  class="hover:bg-slate-25 dark:hover:bg-slate-800 hover:text-slate-700 dark:hover:text-slate-100"
                >
                  <td
                    class="cursor-pointer pl-2 py-1 rounded-l-lg break-all hover:underline"
                    @click="selectedSource = sourceItem"
                  >
                    <span v-if="sourceItem.name">{{ sourceItem.name }}</span>
                    <SourceType v-else :source-type="sourceItem.source_type" />
                  </td>
                  <td class="pl-2">
                    <SourceType :source-type="sourceItem.source_type" />
                  </td>
                  <td class="px-0 py-1 rounded-r-lg">
                    <div class="button-wrapper">
                      <SourceContextMenu
                        @edit="openEditPopup(sourceItem)"
                        @delete="openDeletePopup(sourceItem)"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <woot-button
              class="mt-3 px-1"
              variant="clear"
              icon="add"
              @click="openAddPopup"
            >
              {{
                $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.LIST.CREATE')
              }}
            </woot-button>
          </div>
        </template>
      </div>

      <div class="w-[70%] bg-slate-25 dark:bg-slate-800">
        <SourceItem v-if="selectedSource.id" :source-id="selectedSource.id" />
        <EmptyState
          v-else
          :title="
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.LIST.SELECT_A_SOURCE'
            )
          "
          class="m-3"
        />
      </div>
    </div>

    <woot-modal v-model:show="showAddPopup" @close="hideAddPopup">
      <AddSource @close="hideAddPopup" />
    </woot-modal>

    <woot-modal v-model:show="showEditPopup" @close="hideEditPopup">
      <EditSource :source="selectedResponse" @close="hideEditPopup" />
    </woot-modal>

    <woot-delete-modal
      v-model:show="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :message-value="deleteMessage"
      :title="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.DELETE.CONFIRM.TITLE')
      "
      :message="
        $t(
          'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.DELETE.CONFIRM.MESSAGE'
        )
      "
      :confirm-text="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.DELETE.CONFIRM.YES')
      "
      :reject-text="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.DELETE.CONFIRM.NO')
      "
    />
  </div>
</template>

<style lang="scss" scoped>
.button-wrapper {
  min-width: unset;
  justify-content: flex-end;
}
</style>

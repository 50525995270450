<script>
import dynamicField from '../dynamicField/Index.vue';
import translateMixins from '../../../mixins/translateMixins';

const STRING_FIELD_TYPES = ['text', 'textarea', 'richtext', 'email', 'url'];

export default {
  components: {
    DynamicField: dynamicField,
  },
  mixins: [translateMixins],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    variables: {
      type: Array,
      default: () => [],
    },
    disableRemove: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['remove', 'change'],
  data() {
    return {
      updatedValue: this.value,
    };
  },
  computed: {
    conditionRegistry() {
      return this.$store.getters['wgptAutomationRegistry/getAction']('branch');
    },
    conditionFields() {
      return this.conditionRegistry.condition_fields || [];
    },
    comparisonOperators() {
      return this.$store.getters[
        'wgptAutomationRegistry/getComparisonOperators'
      ];
    },

    variableField() {
      return {
        name: 'variable_name',
        type: 'variable',
        label: 'FORM.CONDITION_FIELDS.VARIABLE_NAME.LABEL',
        required: true,
      };
    },
    operatorField() {
      return {
        name: 'operator',
        type: 'select',
        label: 'FORM.CONDITION_FIELDS.OPERATOR.LABEL',
        required: true,
        options: this.comparisonOperators,
      };
    },
    valueField() {
      let type = this.selectedVariableField.type ?? 'text';
      if (STRING_FIELD_TYPES.includes(type)) {
        type = 'text';
      }

      return {
        ...this.selectedVariableField,
        type,
        required: true,
        name: 'value',
        label: 'FORM.CONDITION_FIELDS.VALUE.LABEL',
      };
    },
    selectedVariableField() {
      const selectedVariableFieldValue = this.updatedValue.variable_name;
      return this.getSelectedVariable(selectedVariableFieldValue) ?? {};
    },
    selectedComparisonField() {
      const selectedComparisonFieldValue =
        this.updatedValue[this.operatorField.name];
      const comparisonField = this.comparisonOperators.find(
        ({ value }) => value === selectedComparisonFieldValue
      );
      return comparisonField || {};
    },
    showValueField() {
      return !this.selectedComparisonField.no_value;
    },
  },
  methods: {
    onChange(fieldName, value, field) {
      const updatedValueUpdates = {
        [fieldName]: value,
      };
      if (fieldName === this.variableField.name) {
        updatedValueUpdates[this.valueField.name] = '';
      }
      if (fieldName === this.valueField.name) {
        updatedValueUpdates.data_type =
          field.value_type ?? this.getValueDataType() ?? 'string';
      }
      this.updatedValue = {
        ...this.updatedValue,
        ...updatedValueUpdates,
      };
      this.$emit('change', this.updatedValue);
    },
    getValueDataType() {
      const type = this.selectedVariableField.type;
      if (STRING_FIELD_TYPES.includes(type)) return 'string';
      return type;
    },
    getSelectedVariable(variablePath) {
      const options = this.variables
        .filter(variable => !variable.wrapper)
        .map(variable => {
          const label = this.t(variable.label, variable.name);
          const value = variable.name;
          return {
            ...variable,
            label,
            value,
          };
        });
      const valueInOptions = options.find(
        ({ value }) => value === variablePath
      );
      if (valueInOptions) return valueInOptions;

      const optionGroups = this.variables
        .filter(variable => variable.wrapper)
        .map(variable => {
          const label = this.t(variable.label, variable.name);
          const prefixLabel = label + '/';

          if (variable.model) {
            const modelDynamicField =
              this.$store.getters['wgptAutomationRegistry/getDynamicOptions'](
                variable.model
              ) || {};
            const dynamicFieldOptions = modelDynamicField.options || [];
            const groupOptions = dynamicFieldOptions.map(option => {
              const groupLabel = this.t('', prefixLabel + option.label);
              const groupValue = variable.name + '.' + option.value;
              const groupType = variable.type;
              return {
                ...option,
                label: groupLabel,
                value: groupValue,
                type: groupType,
              };
            });
            return {
              label,
              ...modelDynamicField,
              options: groupOptions,
            };
          }

          const customObjects =
            this.$store.getters['wgptAutomationRegistry/getCustomObjects'](
              variable.custom_object
            ) || {};
          const customObjectsOptions = customObjects.options || [];
          const groupOptions = customObjectsOptions.map(option => {
            const groupLabel = this.t('', prefixLabel + this.t(option.label));
            const groupValue = variable.name + '.' + option.name;
            return { ...option, label: groupLabel, value: groupValue };
          });
          return {
            label,
            ...customObjects,
            options: groupOptions,
          };
        });

      let valueInGroupOptions = {};
      optionGroups.some(group => {
        const groupOptions = group.options;
        const valueInGroupOption = groupOptions.find(
          ({ value }) => value === variablePath
        );
        if (valueInGroupOption) {
          valueInGroupOptions = valueInGroupOption;
          return true;
        }
        return false;
      });
      return valueInGroupOptions;
    },
  },
};
</script>

<template>
  <div>
    <div class="flex gap-1">
      <div class="flex-1 grid grid-cols-3 gap-1.5">
        <DynamicField
          :field="variableField"
          :value="updatedValue[variableField.name]"
          :variables="variables"
          @change="
            (value, variable) => onChange(variableField.name, value, variable)
          "
        />
        <DynamicField
          :class="{ 'col-span-2': !showValueField }"
          :field="operatorField"
          :value="updatedValue[operatorField.name]"
          @change="e => onChange(operatorField.name, e)"
        />
        <DynamicField
          v-if="showValueField"
          :field="valueField"
          :value="updatedValue[valueField.name]"
          :variables="variables"
          @change="
            (value, dynamicField) =>
              onChange(valueField.name, value, dynamicField)
          "
        />

        <div class="col-start-2">
          <slot name="operator-field" />
        </div>
      </div>
      <woot-button
        :disabled="disableRemove"
        icon="dismiss"
        variant="clear"
        size="tiny"
        color-scheme="secondary"
        class-names="mt-9"
        @click="$emit('remove')"
      />
    </div>
  </div>
</template>

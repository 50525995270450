<script>
import { useAlert } from 'dashboard/composables';
import MultiselectDropdownItems from './ContactMultiselectDropdownItems.vue';
import filterQueryGenerator from 'dashboard/helper/filterQueryGenerator';

export default {
  components: {
    MultiselectDropdownItems,
  },
  props: {
    selectedIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['add', 'remove', 'close'],
  data() {
    return {};
  },
  methods: {
    onCloseDropdown() {
      this.$emit('close');
    },
    onClickSelectItem(value) {
      if (this.selectedIds.some(selectedId => selectedId === value.id)) {
        this.$emit('remove', value);
        return;
      }

      this.$emit('add', value);
    },
    async onSearch(query) {
      try {
        if (!query) return;

        const attributeKeys = ['name', 'email', 'phone_number'];
        const payload = attributeKeys.map(attributeKey => {
          return {
            attribute_key: attributeKey,
            attribute_model: 'standard',
            custom_attribute_type: '',
            filter_operator: 'contains',
            query_operator: 'or',
            values: query,
          };
        });
        const queryPayload = filterQueryGenerator(payload);
        this.$store.dispatch('contacts/filter', { queryPayload });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="flex justify-between items-center mb-1">
      <h4
        class="text-sm text-slate-800 dark:text-slate-100 m-0 overflow-hidden whitespace-nowrap text-ellipsis"
      >
        {{
          $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.MULTI_SELECTOR.TITLE')
        }}
      </h4>
      <woot-button
        icon="dismiss"
        size="tiny"
        color-scheme="secondary"
        variant="clear"
        @click="onCloseDropdown"
      />
    </div>
    <MultiselectDropdownItems
      :selected-ids="selectedIds"
      @click="onClickSelectItem"
      @search="onSearch"
    />
  </div>
</template>

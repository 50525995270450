<script>
import Action from './Action.vue';
import Condition from './Condition.vue';
import Branch from './Branch.vue';

export default {
  name: 'Step',
  components: {
    Action,
    Condition,
    Branch,
  },
  props: {
    step: {
      type: Object,
      default: () => {},
    },
    trigger: {
      type: Object,
      default: () => {},
    },
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'stepChange',
    'stepDelete',
    'select',
    'addStepBefore',
    'addStepAfter',
  ],
  computed: {
    isConditionStep() {
      return this.step.action === 'branch';
    },
    branches() {
      return this.step.branches || [];
    },
    defaultBranch() {
      return this.step.default || {};
    },
  },
  methods: {
    onStepChange(...step) {
      this.$emit('stepChange', ...step);
    },
    onStepDelete(...step) {
      this.$emit('stepDelete', ...step);
    },
    onBranchChange(index, data) {
      const step = this.step;
      if (index === null) {
        step.default = {
          ...step.default,
          ...data,
        };
      } else {
        step.branches = step.branches.map((branch, _index) => {
          if (index !== _index) return data;
          return {
            ...branch,
            ...data,
          };
        });
      }
      this.onStepChange(this.step.id, step);
    },
    addBranchStepAt(branchIndex, stepIndex, newStep) {
      const branchBefore =
        branchIndex === null ? this.defaultBranch : this.branches[branchIndex];
      let steps = branchBefore.steps;
      if (newStep.action === 'branch') {
        newStep.default = {
          ...newStep.default,
          steps: steps.slice(stepIndex),
        };
        steps = steps.slice(0, stepIndex).concat(newStep);
      } else {
        steps.splice(stepIndex, 0, newStep);
      }
      const branch = {
        ...branchBefore,
        steps,
      };
      this.onStepChange(branch.id, branch);
    },
    deleteBranchStepAt(branchIndex, stepIndex) {
      const branchBefore =
        branchIndex === null ? this.defaultBranch : this.branches[branchIndex];
      const steps = branchBefore.steps.filter(
        (_, index) => stepIndex !== index
      );
      const branch = {
        ...branchBefore,
        steps,
      };
      this.onBranchChange(branchIndex, branch);
    },
  },
};
</script>

<template>
  <Condition
    v-if="isConditionStep"
    :step="step"
    :trigger="trigger"
    :first="first"
    :last="last"
    @select="e => $emit('select', e)"
    @add-step-before="e => $emit('addStepBefore', e)"
    @step-change="onStepChange"
    @step-delete="onStepDelete"
  >
    <template #branch>
      <Branch
        v-for="(branch, branchIndex) of branches"
        :key="branchIndex"
        :branch="branch"
        :index="branchIndex"
        @select="e => $emit('select', e)"
        @step-change="onStepChange"
      >
        <template #step>
          <step
            v-for="(branchStep, stepIndex) of branch.steps"
            :key="stepIndex"
            :step="branchStep"
            :trigger="trigger"
            :last="stepIndex === branch.steps.length - 1"
            :variables="variables"
            @add-step-before="e => addBranchStepAt(branchIndex, stepIndex, e)"
            @add-step-after="
              e => addBranchStepAt(branchIndex, stepIndex + 1, e)
            "
            @step-change="onStepChange"
            @step-delete="onStepDelete"
          />
        </template>
      </Branch>
      <Branch
        :branch="defaultBranch"
        @select="e => $emit('select', e)"
        @step-change="onStepChange"
      >
        <template #step>
          <step
            v-for="(branchStep, stepIndex) of defaultBranch.steps"
            :key="stepIndex"
            :step="branchStep"
            :trigger="trigger"
            :last="stepIndex === defaultBranch.steps.length - 1"
            :variables="variables"
            @add-step-before="e => addBranchStepAt(null, stepIndex, e)"
            @add-step-after="e => addBranchStepAt(null, stepIndex + 1, e)"
            @step-change="onStepChange"
            @step-delete="onStepDelete"
          />
        </template>
      </Branch>
    </template>
  </Condition>

  <Action
    v-else
    :step="step"
    :trigger="trigger"
    :first="first"
    :last="last"
    :variables="variables"
    @select="e => $emit('select', e)"
    @add-step-before="e => $emit('addStepBefore', e)"
    @add-step-after="e => $emit('addStepAfter', e)"
    @step-delete="onStepDelete"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import WorkflowNode from './Workflow.vue';

export default {
  components: {
    WorkflowNode,
  },
  props: {
    bot: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'addWorkflow',
    'deleteStage',
    'editStage',
    'addStage',
    'deleteWorkflow',
    'editWorkflow',
  ],
  data() {
    return {
      selectedTabIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      workflows: 'wgptBotWorkflows/getWorkflows',
    }),
  },
  watch: {
    bot() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.$store.dispatch('wgptBotWorkflows/get', this.bot.id);
    },
  },
};
</script>

<template>
  <div>
    <div class="workflow-nodes pt-12">
      <div
        class="workflow-node-header"
        :style="{ 'grid-column-end': workflows.length + 2 }"
      >
        <woot-label
          class="!m-1 !mb-9"
          :title="bot.name"
          color-scheme="primary"
        />
      </div>

      <WorkflowNode
        v-for="(workflow, index) in workflows"
        :key="workflow.id"
        :workflow="workflow"
        :class="{ 'right-node': index > workflows.length / 2 }"
        class="workflow-node"
        @edit="$emit('editWorkflow', workflow)"
        @delete="$emit('deleteWorkflow', workflow)"
        @add-stage="$emit('addStage', workflow)"
        @edit-stage="stage => $emit('editStage', stage, workflow)"
        @delete-stage="stage => $emit('deleteStage', stage, workflow)"
      />

      <div
        class="workflow-node workflow-node-button"
        :class="{ 'right-node': workflows.length }"
      >
        <woot-button
          class="macros__action-button mt-9 pointer-events-auto"
          color-scheme="success"
          variant="smooth"
          icon="add-circle"
          disabled="true"
          @click="$emit('addWorkflow')"
        >
          {{ $t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.BUTTON_TEXT.CREATE_WORKFLOW') }}
        </woot-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.workflow-nodes {
  @apply grid grid-flow-col relative;
  grid-template-rows: auto minmax(0, 1fr);
  grid-auto-columns: minmax(375px, 1fr);
}

.workflow-node {
  @apply flex flex-col items-center row-start-2 relative pointer-events-none;
  min-width: 50%;

  &::before {
    @apply absolute;
    content: '';
    height: var(--space-large);
    width: 50%;
    border-top: 1px dashed var(--s-500);
  }
  &:not(.right-node)::before {
    border-left: 1px dashed var(--s-500);
    @apply left-1/2;
  }
  &.right-node::before {
    border-right: 1px dashed var(--s-500);
    @apply right-1/2;
  }

  &::after {
    @apply absolute;
    content: '';
    height: var(--space-large);
    width: 50%;
    border-top: 1px dashed var(--s-500);
  }
  &:not(.right-node)::after {
    @apply left-0;
  }
  &.right-node::after {
    @apply right-0;
  }

  &:nth-child(2):after,
  &:last-child::after {
    display: none;
  }
}

.workflow-node-header {
  @apply flex justify-center row-start-1 col-start-1 relative;
  &::after {
    @apply absolute left-1/2 bottom-0;
    content: '';
    height: var(--space-large);
    width: 0;
    border-left: 1px dashed var(--s-500);
  }
}

.workflow-node-button {
  @apply flex flex-col items-center relative;
  &:not(.right-node)::before {
    width: 0;
    @apply left-1/2;
  }
}

[dir='rtl'] {
  .workflow-node {
    // @apply flex flex-col items-center row-start-2 relative pointer-events-none;
    // min-width: 50%;

    // &::before {
    //   @apply absolute;
    //   content: '';
    //   height: var(--space-large);
    //   width: 50%;
    //   border-top: 1px dashed var(--s-500);
    // }
    &:not(.right-node)::before {
      border-right: 1px dashed var(--s-500);
      border-left: unset;
      @apply right-1/2;
    }
    &.right-node::before {
      border-left: 1px dashed var(--s-500);
      border-right: unset;
      @apply left-1/2 right-auto;
    }

    // &::after {
    //   @apply absolute;
    //   content: '';
    //   height: var(--space-large);
    //   width: 50%;
    //   border-top: 1px dashed var(--s-500);
    // }
    &:not(.right-node)::after {
      @apply right-0;
    }
    &.right-node::after {
      @apply left-0 right-auto;
    }

    // &:nth-child(2):after,
    // &:last-child::after {
    //   display: none;
    // }
  }

  // .workflow-node-header {
  //   @apply flex justify-center row-start-1 col-start-1 relative;
  //   &::after {
  //     @apply absolute left-1/2 bottom-0;
  //     content: '';
  //     height: var(--space-large);
  //     width: 0;
  //     border-left: 1px dashed var(--s-500);
  //   }
  // }

  // .workflow-node-button {
  //   @apply flex flex-col items-center relative;
  //   &:not(.right-node)::before {
  //     width: 0;
  //     @apply left-1/2;
  //   }
  // }
}
</style>

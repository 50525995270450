<script>
import { frontendURL } from '../../../../../helper/URLHelper';
import SecondaryNavItem from './SecondaryNavItem.vue';
import AccountContext from '../../../../../components/layout/sidebarComponents/AccountContext.vue';

export default {
  components: {
    AccountContext,
    SecondaryNavItem,
  },
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
    currentUser: {
      type: Object,
      default: () => {},
    },
    boards: {
      type: Array,
      default: () => [],
    },
    menuConfig: {
      type: Object,
      default: () => {},
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openSettings', 'addBoard', 'toggleAccounts'],
  computed: {
    openBoardSection() {
      return {
        icon: 'number-symbol',
        label: 'WGPT_ALL_BOARDS',
        hasSubMenu: false,
        showNewButton: this.isAdmin,
        visibleWhenEmpty: true,
        key: 'wgpt-boards',
        toState: frontendURL(`accounts/${this.accountId}/wgpt-boards`),
        toStateName: 'boards_overview',
        showModalForNewItem: true,
        modalName: 'AddBoard',
        dataTestid: 'sidebar-new-board-button',
        children: this.boards
          .filter(board => {
            return !board.closed;
          })
          .map(board => ({
            id: board.id,
            label: board.name,
            showSettingsButton: this.hasMinimalAccessToBoard(board),
            toState: frontendURL(
              `accounts/${this.accountId}/wgpt-boards/${board.id}`
            ),
            ...board,
          })),
      };
    },
    closedBoardSection() {
      return {
        icon: 'number-symbol',
        label: 'WGPT_CLOSED_BOARDS',
        hasSubMenu: false,
        showNewButton: false,
        key: 'wgpt-closed-boards',
        toState: frontendURL(`accounts/${this.accountId}/wgpt-boards`),
        toStateName: 'boards_overview',
        showModalForNewItem: true,
        modalName: 'AddBoard',
        dataTestid: 'sidebar-new-board-button',
        children: this.boards
          .filter(board => {
            return board.closed;
          })
          .map(board => ({
            id: board.id,
            label: board.name,
            showSettingsButton: this.hasMinimalAccessToBoard(board),
            toState: frontendURL(
              `accounts/${this.accountId}/wgpt-boards/${board.id}`
            ),
            ...board,
          })),
      };
    },
    additionalSecondaryMenuItems() {
      let openBoardMenuItems = [this.openBoardSection, this.closedBoardSection];
      return {
        boards: openBoardMenuItems,
      };
    },
  },
  methods: {
    hasMinimalAccessToBoard(board) {
      const authorization = board.config?.authorization || {};
      if (this.isAdmin) return true;
      const managers = authorization?.managers || [];
      if (managers.includes(this.currentUser.id)) return true;
      const operators = authorization?.operators || [];
      if (operators.includes(this.currentUser.id)) return true;
      const readers = authorization?.readers || [];
      if (readers.includes(this.currentUser.id)) return true;
      return false;
    },
    toggleAccountModal() {
      this.$emit('toggleAccounts');
    },
    showAddBoardPopup() {
      this.$emit('addBoard');
    },
    showOpenBoardSettingsPopup(board) {
      this.$emit('openSettings', board);
    },
  },
};
</script>

<template>
  <div
    class="h-full overflow-auto w-48 flex flex-col bg-white dark:bg-slate-900 border-r dark:border-slate-800/50 rtl:border-r-0 rtl:border-l border-slate-50 text-sm px-2 pb-8"
  >
    <AccountContext @toggle-accounts="toggleAccountModal" />
    <transition-group
      name="menu-list"
      tag="ul"
      class="pt-2 list-none ml-0 mb-0"
    >
      <SecondaryNavItem
        v-for="menuItem in menuConfig.menuItems"
        :key="menuItem.toState"
        :menu-item="menuItem"
      />
      <SecondaryNavItem
        v-for="menuItem in additionalSecondaryMenuItems[menuConfig.parentNav]"
        :key="menuItem.key"
        :menu-item="menuItem"
        @add-board="showAddBoardPopup"
        @open-settings="showOpenBoardSettingsPopup"
      />
    </transition-group>
  </div>
</template>

function getObjectValues(valueMap, registryMap = []) {
  const values = [];
  if (Array.isArray(valueMap)) {
    valueMap.forEach(valueItemMap => {
      const registryItemMap = registryMap?.controls?.[0]?.controls;
      values.push(...getObjectValues(valueItemMap, registryItemMap));
    });
  } else if (typeof valueMap === 'object') {
    Object.keys(valueMap).forEach(key => {
      const valueFieldMap = valueMap[key];
      const registryFieldMap = registryMap.find(({ name }) => name === key);
      values.push(...getObjectValues(valueFieldMap, registryFieldMap));
    });
  } else if (registryMap.is_variable && valueMap) {
    values.push(valueMap);
  }
  return values;
}

const getStepsVariables = (selectedStepId, steps, actions) => {
  const variables = [];
  for (let i = 0; i < steps.length; i += 1) {
    const step = steps[i];
    const action =
      actions.find(({ action: actionName }) => actionName === step.action) ??
      {};

    if (step.id === selectedStepId) {
      if (action.available_data) {
        const availableDataFields = action.available_data ?? [];
        const actionLevelVariables = Object.keys(availableDataFields).map(
          name => {
            return {
              ...availableDataFields[name],
              name,
              source: step.id,
              prefix: '#',
            };
          }
        );
        variables.push(...actionLevelVariables);
      }

      return [variables, true];
    }

    if (step.output_mapping) {
      const stepVariables = getObjectValues(
        step.output_mapping,
        action.output_mapping_fields
      );
      variables.push(
        ...stepVariables.map(name => {
          return {
            name,
            source: step.id,
          };
        })
      );
    }

    if (step.branches) {
      // eslint-disable-next-line no-use-before-define
      const [branchVariables, found] = getBranchVariables(
        selectedStepId,
        step.branches,
        actions
      );
      variables.push(...branchVariables);
      if (found) return [variables, true];
    }

    if (step.default) {
      if (step.default.id === selectedStepId) return [variables, true];
      const [defaultBranchVariables, found] = getStepsVariables(
        selectedStepId,
        step.default.steps,
        actions
      );
      variables.push(...defaultBranchVariables);
      if (found) return [variables, true];
    }
  }
  return [variables, false];
};

const getBranchVariables = (selectedStepId, branches, actions) => {
  const variables = [];
  for (let i = 0; i < branches.length; i += 1) {
    const branch = branches[i];
    if (branch.id === selectedStepId) return [variables, true];
    const [stepsVariables, found] = getStepsVariables(
      selectedStepId,
      branch.steps,
      actions
    );
    if (found) {
      variables.push(...stepsVariables);
      return [variables, true];
    }
  }
  return [variables, false];
};

export function findStepAndUpdate(steps, stepId, data) {
  if (stepId == null) return data;

  return steps.map(step => {
    if (step.id === stepId) {
      return {
        ...step,
        ...data,
      };
    }

    if (step.default) {
      if (step.default.id === stepId) {
        step.default = {
          ...step.default,
          ...data,
        };
      } else {
        step.default = {
          ...step.default,
          steps: findStepAndUpdate(step.default.steps, stepId, data),
        };
      }
    }

    if (step.branches) {
      step.branches = step.branches.map(branch => {
        if (branch.id === stepId) {
          return {
            ...branch,
            ...data,
          };
        }
        return {
          ...branch,
          steps: findStepAndUpdate(branch.steps, stepId, data),
        };
      });
    }

    return step;
  });
}

export function findStepAndDelete(steps, stepId) {
  return steps.filter(step => {
    if (step.id === stepId) return false;

    if (step.default) {
      step.default = {
        ...step.default,
        steps: findStepAndDelete(step.default.steps, stepId),
      };
    }

    if (step.branches) {
      step.branches = step.branches.map(branch => {
        return {
          ...branch,
          steps: findStepAndDelete(branch.steps, stepId),
        };
      });
    }

    return step;
  });
}

export function findStep(steps, stepId) {
  for (let i = 0; i < steps.length; i += 1) {
    const step = steps[i];
    if (step.id === stepId) return step;

    if (step.default) {
      if (step.default.id === stepId) return step.default;
      const found = findStep(step.default.steps, stepId);
      if (found) return found;
    }

    if (step.branches) {
      for (let j = 0; j < step.branches.length; j += 1) {
        const branch = step.branches[j];
        if (branch.id === stepId) return branch;
        const found = findStep(branch.steps, stepId);
        if (found) return found;
      }
    }
  }
  return null;
}

export function getStepPath(steps, stepId, parentPath = []) {
  const path = Array.from(parentPath);
  for (let i = 0; i < steps.length; i += 1) {
    const step = steps[i];
    path.push(step.id);
    if (step.id === stepId) return path;

    if (step.default) {
      const found = getStepPath(step.default.steps, stepId, path);
      if (found) return found;
    }

    if (step.branches) {
      for (let j = 0; j < step.branches.length; j += 1) {
        const branch = step.branches[j];
        const found = getStepPath(branch.steps, stepId, path);
        if (found) return found;
      }
    }
  }
  return null;
}

export function getVariables(selectedStepId, steps, actions) {
  const [variables] = getStepsVariables(selectedStepId, steps, actions);
  return variables;
}

<script>
import translateMixins from '../../../mixins/translateMixins';
import validationMixins from './helpers/validationMixins';
import { useVuelidate } from '@vuelidate/core';

function hasAnyLabel(field) {
  if (field.controls) {
    return field.controls.some(hasAnyLabel);
  }
  return !!field.label;
}

export default {
  mixins: [translateMixins, validationMixins],
  props: {
    field: {
      type: Object,
      required: true,
    },
    values: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      arrayValue: this.values ?? this.field.default,
    };
  },
  computed: {
    hasAnyLabel() {
      return hasAnyLabel(this.field);
    },
    required() {
      return this.field.required;
    },
  },
  validations() {
    return {
      arrayValue: {
        required: arrayValue => {
          if (!this.required) return true;

          return arrayValue.length > 0;
        },
        duplicate: this.duplicateIf(true, this.field),
      },
    };
  },
  watch: {
    values: {
      handler(values) {
        this.arrayValue = values;
      },
      immediate: true,
    },
  },
  methods: {
    arrayControlChange(value, index) {
      const arrayValue = this.arrayValue.map((item, i) => {
        if (i === index) return { ...item, ...value };
        return item;
      });
      this.arrayValue = arrayValue;
      this.v$.arrayValue.$touch();
      this.$emit('change', arrayValue);
    },
    removeArrayControlAt(index) {
      if (this.field.min && this.arrayValue.length <= this.field.min) return;

      const arrayValue = this.arrayValue.filter((_, i) => i !== index);
      this.arrayValue = arrayValue;
      this.v$.arrayValue.$touch();
      this.$emit('change', arrayValue);
    },
    addArrayControl() {
      if (this.field.max && this.arrayValue.length >= this.field.max) return;

      const arrayControls = Array.from(this.arrayValue);
      this.field.controls.forEach(control => {
        if (control.type === 'group') {
          const groupControl = {};
          control.controls.forEach(c => {
            groupControl[c.name] = c.default || '';
          });
          arrayControls.push(groupControl);
        }
      });

      this.arrayValue = arrayControls;
      this.v$.arrayValue.$touch();
      this.$emit('change', arrayControls);
    },
  },
};
</script>

<template>
  <div class="array-field">
    <label class="flex items-center gap-2">
      <span v-if="field.label" class="block truncate">{{
        t(field.label, field.fallbackLabel)
      }}</span>
      <fluent-icon
        v-if="field.description"
        v-tooltip.top="{
          content: t(field.description),
          popperClass: 'max-w-[300px]',
          boundary: 'body',
        }"
        icon="info"
        size="14"
      />
    </label>
    <div
      class="array-fields ps-2 py-2 rounded-md"
      :class="{ error: v$.arrayValue.$error }"
    >
      <div
        v-for="(arrayValueItem, index) of arrayValue"
        :key="index"
        class="array-field-item"
      >
        <slot
          :value="arrayValueItem"
          :change="value => arrayControlChange(value, index)"
          :disabled="disabled"
        />
        <div>
          <div class="h-10 flex items-center" :class="{ 'mt-7': hasAnyLabel }">
            <woot-button
              icon="dismiss"
              variant="clear"
              size="tiny"
              color-scheme="secondary"
              class-names="remove-array-field-item-button shrink-0 !w-6"
              :disabled="disabled"
              @click="removeArrayControlAt(index)"
            />
          </div>
        </div>
      </div>

      <span v-if="v$.arrayValue.$error" class="message !mb-0">
        <template v-if="v$.arrayValue.required.$invalid">{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.REQUIRED')
        }}</template>
        <template v-else-if="v$.arrayValue.duplicate.$invalid">{{
          v$.arrayValue.duplicate.$message
        }}</template>
        <template v-else>{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.INVALID')
        }}</template>
      </span>
    </div>
    <woot-button
      color-scheme="primary"
      size="small"
      icon="add"
      class-names="add-array-field-item-button mb-3"
      :disabled="disabled"
      @click="addArrayControl"
    >
      {{ t(field.add_label || 'FORM.ARRAY.ADD') }}
    </woot-button>
  </div>
</template>

<style lang="scss" scoped>
.array-fields.error {
  @apply bg-red-50 dark:bg-red-800/50 border border-solid border-red-100 dark:border-red-700/50 mb-4;
}

.array-field-item {
  @apply flex gap-2;
}
</style>

<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import AddChunk from '../components/chunks/create/AddChunk.vue';
import EditChunk from '../components/chunks/edit/EditChunk.vue';
import SourceChunk from '../components/chunks/Index.vue';
import FeaturePlaceholder from 'dashboard/components/widgets/conversation/EmptyState/FeaturePlaceholder.vue';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

export default {
  components: {
    SourceChunk,
    AddChunk,
    EditChunk,
    FeaturePlaceholder,
  },
  mixins: [globalConfigMixin],
  props: {
    sourceId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      parameters: [],
      selectedChunk: {},
      showAddChunkPopup: false,
      showEditChunkPopup: false,
      showDeleteChunkConfirmationPopup: false,
      loading: {},
    };
  },
  computed: {
    ...mapGetters({
      chunks: 'wgptBotSourceChunks/getSourceChunks',
    }),
    source() {
      return this.$store.getters['wgptBotSources/getSource'](this.sourceId);
    },
  },
  watch: {
    sourceId: {
      handler() {
        this.getChunks();
      },
      immediate: true,
    },
    source: {
      handler() {
        this.setDefaults();
      },
      immediate: true,
    },
  },
  methods: {
    setDefaults() {
      const parameters = this.source.parameters || {};
      this.parameters = Object.keys(parameters).map(key => {
        const value = this.source.parameters[key];
        return { key, value };
      });
    },
    getChunks() {
      const botId = this.$route.params.botId;
      const sourceId = this.sourceId;
      this.$store.dispatch('wgptBotSourceChunks/get', { botId, sourceId });
    },
    openAddChunkPopup() {
      this.showAddChunkPopup = true;
    },
    hideAddChunkPopup() {
      this.showAddChunkPopup = false;
    },
    openEditChunkPopup(chunk) {
      this.selectedChunk = chunk;
      this.showEditChunkPopup = true;
    },
    hideEditChunkPopup() {
      this.showEditChunkPopup = false;
    },
    openDeleteChunkPopup(chunk) {
      this.selectedChunk = chunk;
      this.showDeleteChunkConfirmationPopup = true;
    },
    hideDeleteChunkPopup() {
      this.showDeleteChunkConfirmationPopup = false;
    },
    confirmChunkDeletion() {
      this.loading[this.selectedChunk.id] = true;
      this.hideDeleteChunkPopup();
      this.deleteChunk(this.selectedChunk.id);
    },
    async deleteChunk(id) {
      try {
        const botId = this.$route.params.botId;
        const sourceId = this.source.id;
        await this.$store.dispatch('wgptBotSourceChunks/delete', {
          botId,
          sourceId,
          id,
        });
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.API.SUCCESS_MESSAGE'
          )
        );
        this.loading[id] = false;
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.API.ERROR_MESSAGE'
          )
        );
      } finally {
        this.loading[id] = false;
      }
    },
    async toggleChunkStatus(chunk) {
      try {
        const botId = this.$route.params.botId;
        const sourceId = this.source.id;
        const id = chunk.id;
        const data = {
          status: chunk.status === 'draft' ? 1 : 0,
        };

        await this.$store.dispatch('wgptBotSourceChunks/update', {
          botId,
          sourceId,
          id,
          ...data,
        });

        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.API.SUCCESS_MESSAGE'
          )
        );
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.API.ERROR_MESSAGE'
          )
        );
      }
    },
  },
};
</script>

<template>
  <div class="wrapper h-full px-4 flex-1 overflow-y-auto position-relative">
    <template v-if="source">
      <div class="border-b border-slate-25 dark:border-slate-700 pb-5">
        <label class="mt-2">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.SOURCE.NAME.LABEL'
            )
          }}
          <p class="text-sm text-slate-600 dark:text-slate-400">
            {{ source.name || '—' }}
          </p>
        </label>

        <label class="mt-4">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.SOURCE.DESCRIPTION.LABEL'
            )
          }}
          <p class="text-sm text-slate-600 dark:text-slate-400">
            {{ source.description || '—' }}
          </p>
        </label>

        <label class="mt-4">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.SOURCE.PARAMETERS.LABEL'
            )
          }}
        </label>

        <template v-if="parameters.length > 0">
          <woot-button
            v-for="parameter in parameters"
            :key="parameter.key"
            variant="smooth"
            class="label--add mt-2 me-1"
            size="tiny"
          >
            {{ parameter.key }}: {{ parameter.value }}
          </woot-button>
        </template>
        <template v-else>
          <p class="text-sm text-slate-600 dark:text-slate-400">
            {{
              $t(
                'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.SOURCE.PARAMETERS.EMPTY'
              )
            }}
          </p>
        </template>
      </div>

      <div class="pt-6">
        <p class="text-base text-woot-500 dark:text-woot-500 mb-0 font-medium">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.SOURCE.SOURCE_CHUNKS.LABEL'
            )
          }}
        </p>
        <p class="text-sm mb-4">
          <slot name="subTitle">
            {{
              $t(
                'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.SOURCE.SOURCE_CHUNKS.SUB_LABEL'
              )
            }}
          </slot>
        </p>

        <template v-if="chunks">
          <template v-if="chunks.length > 0">
            <SourceChunk
              v-for="chunk of chunks"
              :key="chunk.id"
              :chunk="chunk"
              class="mb-4"
              @delete="openDeleteChunkPopup(chunk)"
              @toggle-status="toggleChunkStatus(chunk)"
              @edit="openEditChunkPopup(chunk)"
            />
          </template>

          <template v-else>
            <p class="my-6 text-sm text-slate-600 dark:text-slate-400">
              {{
                $t(
                  'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.SOURCE.SOURCE_CHUNKS.EMPTY'
                )
              }}
            </p>
          </template>

          <woot-button
            class="mt-3"
            icon="add"
            type="button"
            @click="openAddChunkPopup"
          >
            {{
              $t(
                'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.SOURCE.SOURCE_CHUNKS.ADD'
              )
            }}
          </woot-button>
        </template>

        <woot-loading-state
          v-else
          :message="
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.SOURCE.SOURCE_CHUNKS.LOADING'
            )
          "
        />

        <woot-modal
          v-model:show="showAddChunkPopup"
          class="chunk-woot-modal"
          @close="hideAddChunkPopup"
        >
          <AddChunk :source-id="sourceId" @close="hideAddChunkPopup" />
        </woot-modal>

        <woot-modal
          v-model:show="showEditChunkPopup"
          class="chunk-woot-modal"
          @close="hideEditChunkPopup"
        >
          <EditChunk
            :source-id="sourceId"
            :chunk="selectedChunk"
            @close="hideEditChunkPopup"
          />
        </woot-modal>

        <woot-delete-modal
          v-model:show="showDeleteChunkConfirmationPopup"
          :on-close="hideDeleteChunkPopup"
          :on-confirm="confirmChunkDeletion"
          message-value=""
          :title="
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.CONFIRM.TITLE'
            )
          "
          :message="
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.CONFIRM.MESSAGE'
            )
          "
          :confirm-text="
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.CONFIRM.YES'
            )
          "
          :reject-text="
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.DELETE.CONFIRM.NO'
            )
          "
        />
      </div>
    </template>

    <template v-else>
      <div
        class="position-absolute w-full border-b border-slate-25 dark:border-slate-700 pb-5 flex flex-col items-center justify-center h-full"
      >
        <span
          class="text-sm text-slate-800 dark:text-slate-200 font-medium text-center"
        >
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.LIST.SELECT_A_SOURCE'
            )
          }}
          <br />
        </span>
        <!-- Cmd bar, keyboard shortcuts placeholder -->
        <FeaturePlaceholder />
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.wrapper > :last-child {
  margin-bottom: 120px;
}

.chunk-woot-modal {
  ::v-deep {
    .modal-container {
      @apply overflow-visible;
    }
  }
}
</style>

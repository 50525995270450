<script>
import {
  fullSchema,
  buildEditor,
  EditorView,
  ArticleMarkdownSerializer,
  ArticleMarkdownTransformer,
  EditorState,
  Selection,
} from '@chatwoot/prosemirror-schema';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import { useAlert } from 'dashboard/composables';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import { ARTICLE_EDITOR_MENU_OPTIONS } from 'dashboard/constants/editor';
import { MAXIMUM_FILE_UPLOAD_SIZE } from '../helpers/contants';

const createState = (
  content,
  placeholder,
  // eslint-disable-next-line default-param-last
  plugins = [],
  // eslint-disable-next-line default-param-last
  methods = {},
  enabledMenuOptions
) => {
  return EditorState.create({
    doc: new ArticleMarkdownTransformer(fullSchema).parse(content),
    plugins: buildEditor({
      schema: fullSchema,
      placeholder,
      methods,
      plugins,
      enabledMenuOptions,
    }),
  });
};

let editorView = null;
let state;

export default {
  mixins: [keyboardEventListenerMixins],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    editorId: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    uploadFile: {
      type: Function,
      default: () => {},
    },
  },
  emits: [
    'blur',
    'input',
    'update:modelValue',
    'keyup',
    'focus',
    'keydown',
    'fileUploadError',
    'fileUploadSuccess',
    'fileUploadStart',
  ],
  data() {
    return {
      plugins: [],
      enabledMenuOptions: ARTICLE_EDITOR_MENU_OPTIONS,
      isTextSelected: false, // Tracks text selection and prevents unnecessary re-renders on mouse selection
    };
  },
  watch: {
    modelValue(newValue = '') {
      if (newValue !== this.contentFromEditor()) {
        this.reloadState();
      }
    },
    editorId() {
      this.reloadState();
    },
  },

  created() {
    state = createState(
      this.modelValue,
      this.placeholder,
      this.plugins,
      { onImageUpload: this.openFileBrowser },
      this.enabledMenuOptions
    );
  },
  mounted() {
    this.createEditorView();

    editorView.updateState(state);
    if (this.autofocus) {
      this.focusEditorInputField();
    }
  },
  methods: {
    contentFromEditor() {
      if (editorView) {
        return ArticleMarkdownSerializer.serialize(editorView.state.doc);
      }
      return '';
    },
    openFileBrowser() {
      this.$refs.imageUploadInput.click();
    },
    onFileChange() {
      const file = this.$refs.imageUploadInput.files[0];

      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.uploadImageToStorage(file);
      } else {
        useAlert(
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.API.FILE_SIZE_ERROR',
            {
              size: MAXIMUM_FILE_UPLOAD_SIZE,
            }
          )
        );
      }

      this.$refs.imageUploadInput.value = '';
    },
    async uploadImageToStorage(file) {
      this.$emit('fileUploadStart');
      try {
        const fileUrl = await this.uploadFile(file);

        if (fileUrl) {
          this.onImageUploadStart(fileUrl);
        }

        this.$emit('fileUploadSuccess');
      } catch (error) {
        this.$emit('fileUploadError', error);
        useAlert(
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.API.UPLOAD_ERROR'
          )
        );
      }
    },
    onImageUploadStart(fileUrl) {
      const { selection } = editorView.state;
      const from = selection.from;
      const node = editorView.state.schema.nodes.image.create({
        src: fileUrl,
      });
      const paragraphNode = editorView.state.schema.node('paragraph');
      if (node) {
        // Insert the image and the caption wrapped inside a paragraph
        const tr = editorView.state.tr
          .replaceSelectionWith(paragraphNode)
          .insert(from + 1, node);

        editorView.dispatch(tr.scrollIntoView());
        this.focusEditorInputField();
      }
    },
    reloadState() {
      state = createState(
        this.modelValue,
        this.placeholder,
        this.plugins,
        { onImageUpload: this.openFileBrowser },
        this.enabledMenuOptions
      );
      editorView.updateState(state);
      this.focusEditorInputField();
    },
    createEditorView() {
      editorView = new EditorView(this.$refs.editor, {
        state: state,
        dispatchTransaction: tx => {
          state = state.apply(tx);
          editorView.updateState(state);
          if (tx.docChanged) {
            this.emitOnChange();
          }
          this.checkSelection(state);
        },
        handleDOMEvents: {
          keyup: this.onKeyup,
          focus: this.onFocus,
          blur: this.onBlur,
          keydown: this.onKeydown,
          paste: (view, event) => {
            const data = event.clipboardData.files;
            if (data.length > 0) {
              data.forEach(file => {
                // Check if the file is an image
                if (file.type.includes('image')) {
                  this.uploadImageToStorage(file);
                }
              });
              event.preventDefault();
            }
          },
        },
      });
    },
    handleKeyEvents() {},
    focusEditorInputField() {
      const { tr } = editorView.state;
      const selection = Selection.atEnd(tr.doc);

      editorView.dispatch(tr.setSelection(selection));
      editorView.focus();
    },
    emitOnChange() {
      this.$emit('update:modelValue', this.contentFromEditor());
      this.$emit('input', this.contentFromEditor());
    },
    onKeyup() {
      this.$emit('keyup');
    },
    onKeydown() {
      this.$emit('keydown');
    },
    onBlur() {
      this.$emit('blur');
    },
    onFocus() {
      this.$emit('focus');
    },
    checkSelection(editorState) {
      const { from, to } = editorState.selection;
      // Check if there's a selection (from and to are different)
      const hasSelection = from !== to;
      // If the selection state is the same as the previous state, do nothing
      if (hasSelection === this.isTextSelected) return;
      // Update the selection state
      this.isTextSelected = hasSelection;

      const { editor } = this.$refs;

      // Toggle the 'has-selection' class based on whether there's a selection
      editor.classList.toggle('has-selection', hasSelection);
      // If there's a selection, update the menubar position
      if (hasSelection) this.setMenubarPosition(editorState);
    },
    setMenubarPosition(editorState) {
      if (!editorState.selection) return;

      // Get the start and end positions of the selection
      const { from, to } = editorState.selection;
      const { editor } = this.$refs;
      // Get the editor's position relative to the viewport
      const { left: editorLeft, top: editorTop } =
        editor.getBoundingClientRect();

      // Get the editor's width
      const editorWidth = editor.offsetWidth;
      const menubarWidth = 480; // Menubar width (adjust as needed (px))

      // Get the end position of the selection
      const { bottom: endBottom, right: endRight } = editorView.coordsAtPos(to);
      // Get the start position of the selection
      const { left: startLeft } = editorView.coordsAtPos(from);

      // Calculate the top position for the menubar (10px below the selection)
      const top = endBottom - editorTop + 10;
      // Calculate the left position for the menubar
      // This centers the menubar on the selection while keeping it within the editor's bounds
      const left = Math.max(
        0,
        Math.min(
          (startLeft + endRight) / 2 - editorLeft,
          editorWidth - menubarWidth
        )
      );
      // Set the CSS custom properties for positioning the menubar
      editor.style.setProperty('--selection-top', `${top}px`);
      editor.style.setProperty('--selection-left', `${left}px`);
    },
  },
};
</script>

<template>
  <div class="dark:bg-slate-900 rounded-md">
    <div class="editor-root wgpt-boards-editor">
      <input
        ref="imageUploadInput"
        type="file"
        accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
        hidden
        @change="onFileChange"
      />
      <div ref="editor" dir="auto" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.editor-root {
  position: relative;
  width: 100%;
}

.wgpt-boards-editor ::v-deep {
  .ProseMirror-menubar-wrapper {
    display: flex;
    flex-direction: column;

    > .ProseMirror-menubar {
      @apply absolute m-0 left-0.5 right-auto top-0.5 rounded-md;
    }

    > .ProseMirror {
      @apply px-3 pt-10 pb-2 rounded-md break-words outline-none;
      outline-color: #6cb8ff;
      outline-offset: -2px;
    }
  }

  .ProseMirror-woot-style {
    min-height: 8rem;
    max-height: unset;
    overflow: auto;
  }
}
</style>

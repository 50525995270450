<script>
import { useAlert } from 'dashboard/composables';
import FluentIcon from 'shared/components/FluentIcon/WgptIcon.vue';
import Sidebar from './components/sidebar/Index.vue';
import Trigger from './components/trigger/Index.vue';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';

import {
  findStep,
  findStepAndDelete,
  findStepAndUpdate,
  getStepPath,
  getVariables,
} from './helpers/automationUtils';

export default {
  components: {
    Trigger,
    Sidebar,
    FluentIcon,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      automationId: this.$route.params.automationId,
      automation: {},
      showActionsDropdown: false,
    };
  },
  computed: {
    ...mapGetters({
      actionsRegistry: 'wgptAutomationRegistry/getActions',
    }),
    record() {
      return this.$store.getters['wgptAutomations/getAutomation'](
        this.automationId
      );
    },
    isAutomationActive() {
      return this.automation.active;
    },
    trigger() {
      return this.automation.trigger || {};
    },
    triggerType() {
      return this.trigger.type;
    },
    triggerRegistry() {
      return this.$store.getters['wgptAutomationRegistry/getTrigger'](
        this.triggerType
      );
    },
    selectedStepId() {
      return this.$route.query.selected;
    },
    triggerVariables() {
      const trigger = this.triggerRegistry || {};
      const availableDataFields = trigger.available_data || {};
      const triggerVariables = Object.keys(availableDataFields).map(name => {
        return { ...availableDataFields[name], name };
      });
      return triggerVariables;
    },
    actionVariables() {
      const steps = this.automation.steps?.v || [];
      const actionsVariables = getVariables(
        this.selectedStepId,
        steps,
        this.actionsRegistry
      );
      return actionsVariables;
    },
    variables() {
      return [...this.triggerVariables, ...this.actionVariables];
    },
    disableTriggerChange() {
      if (!this.trigger.type) return false;
      const steps = this.automation.steps.v || [];
      return steps.length > 0;
    },
  },
  watch: {
    record: {
      handler(automation) {
        if (!automation) return;

        this.automation = {
          ...automation,
          name: this.automation.name ?? automation.name,
          description: this.automation.description ?? automation.description,
          steps: this.automation.steps ?? automation.steps,
          trigger: this.automation.trigger ?? automation.trigger,
        };
      },
      immediate: true,
    },
    triggerType: {
      handler(value) {
        this.$store.dispatch(
          'wgptAutomationRegistry/getActionsForTrigger',
          value
        );
      },
      immediate: true,
    },
    triggerRegistry: {
      handler(triggerRegistry) {
        const trigger = triggerRegistry || {};
        const availableDataFields = trigger.available_data || {};
        const variables = Object.keys(availableDataFields).map(name => {
          return { ...availableDataFields[name], name };
        });
        this.dispatchWrappers(variables ?? []);
      },
      immediate: true,
    },
    actionsRegistry: {
      handler(actionsRegistry) {
        const actions = actionsRegistry ?? [];
        const variables = [];
        actions.forEach(action => {
          const availableData = action.available_data ?? {};
          const actionVariables = Object.keys(availableData).map(name => {
            return { ...availableData[name], name };
          });
          variables.push(...actionVariables);
        });
        this.dispatchWrappers(variables ?? []);
      },
      immediate: true,
    },
  },
  validations: {
    automation: {},
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.$store.dispatch('wgptAutomations/show', this.automationId);
      this.$store.dispatch('wgptAutomationRegistry/getTriggers');
      this.$store.dispatch('wgptAutomationRegistry/getLogicalOperators');
      this.$store.dispatch('wgptAutomationRegistry/getComparisonOperators');
    },
    async onSubmit() {
      try {
        if (this.v$.$invalid) {
          this.v$.$touch();
          throw new Error(
            this.$t('WGPT_AUTOMATIONS.EDITOR.API.INVALID_ERROR_MESSAGE')
          );
        }

        const id = this.automationId;
        const data = {
          name: this.automation.name,
          description: this.automation.description,
          trigger: this.cleanAttributes(
            this.automation.trigger,
            this.triggerRegistry
          ),
          steps: this.automation.steps,
        };
        await this.$store.dispatch('wgptAutomations/update', { id, ...data });
        useAlert(this.$t('WGPT_AUTOMATIONS.EDITOR.API.SUCCESS_MESSAGE'));
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_AUTOMATIONS.EDITOR.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    onTriggerChange(triggerUpdates) {
      const triggerBefore = this.automation.trigger || {};
      this.automation.trigger = {
        ...triggerBefore,
        ...triggerUpdates,
      };
    },
    onSettingsChange(automationUpdates) {
      Object.keys(automationUpdates).forEach(key => {
        this.automation[key] = automationUpdates[key];
      });
    },
    onStepUpdate(stepId, data) {
      try {
        const stepsBefore = this.automation.steps.v || [];
        const steps = stepId
          ? findStepAndUpdate(stepsBefore, stepId, data)
          : data;

        this.automation.steps = {
          v: steps,
        };
      } catch (error) {
        const errorMessage = error.message || this.$t('WGPT_AUTOMATIONS');
        useAlert(errorMessage);
      }
    },
    onStepDelete(stepId) {
      try {
        const stepsBefore = this.automation.steps.v || [];
        const selectedStepPath =
          getStepPath(stepsBefore, this.selectedStepId) || [];
        const steps = findStepAndDelete(stepsBefore, stepId);

        for (let i = selectedStepPath.length; i >= 0; i -= 1) {
          const id = selectedStepPath[i];
          if (findStep(steps, id)) {
            this.$router.replace({ query: { selected: id } }).catch(() => {});
            break;
          }
          if (i === 0) {
            this.$router.replace({ query: {} }).catch(() => {});
          }
        }

        this.automation.steps = {
          v: steps,
        };
      } catch (error) {
        const errorMessage = error.message || this.$t('WGPT_AUTOMATIONS');
        useAlert(errorMessage);
      }
    },

    async toggleActiveStatus() {
      try {
        const id = this.automationId;
        const data = {
          active: !this.automation.active,
        };
        await this.$store.dispatch('wgptAutomations/update', { id, ...data });

        useAlert(this.$t('WGPT_AUTOMATIONS.EDITOR.API.SUCCESS_MESSAGE'));
        this.closeActionsDropdown();
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_AUTOMATIONS.EDITOR.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    openActionsDropdown() {
      this.showActionsDropdown = true;
    },
    closeActionsDropdown() {
      this.showActionsDropdown = false;
    },

    dispatchWrappers(variables) {
      variables.forEach(variable => {
        if (variable.wrapper) {
          if (variable.custom_object) {
            this.$store.dispatch(
              'wgptAutomationRegistry/getCustomObjects',
              variable.custom_object
            );
            return;
          }
        }

        if (variable.model) {
          this.$store.dispatch(
            'wgptAutomationRegistry/getDynamicOptions',
            variable.model
          );
        }
      });
    },
    cleanAttributes(data, registry = {}) {
      const cleanData = {
        ...data,
        attributes: {},
      };
      const attributes = data.attributes ?? {};
      const formFields = registry.form_fields ?? [];
      formFields.forEach(field => {
        cleanData.attributes[field.name] = attributes[field.name];
      });
      return cleanData;
    },
  },
};
</script>

<template>
  <div class="flex-1 flex overflow-hidden">
    <div class="absolute top-0 end-0 flex gap-2 m-3">
      <router-link
        :to="{ query: { selected: 'settings' } }"
        :class="{ 'settings-button-active': selectedStepId === 'settings' }"
        replace
      >
        <woot-button
          color-scheme="secondary"
          icon="settings"
          variant="clear"
          size="small"
          class-names="settings-button"
        />
      </router-link>

      <woot-button color-scheme="primary" size="small" @click="onSubmit">
        {{ $t('WGPT_AUTOMATIONS.EDITOR.SAVE') }}
      </woot-button>

      <div class="relative">
        <div class="button-group">
          <woot-button
            class-names="publish-button"
            size="small"
            icon="checkmark"
            color-scheme="success"
            :disabled="isAutomationActive"
            @click="toggleActiveStatus()"
          >
            {{ $t('WGPT_AUTOMATIONS.EDITOR.ACTIVATE') }}
          </woot-button>
          <woot-button
            class-names="publish-option-button"
            size="small"
            icon="chevron-down"
            color-scheme="success"
            :disabled="!isAutomationActive"
            @click="openActionsDropdown"
          />
        </div>
        <div
          v-if="showActionsDropdown"
          v-on-clickaway="closeActionsDropdown"
          class="dropdown-pane dropdown-pane--open end-0"
        >
          <woot-dropdown-menu>
            <woot-dropdown-item>
              <woot-button
                variant="clear"
                color-scheme="secondary"
                size="small"
                @click="toggleActiveStatus()"
              >
                <FluentIcon icon="prohibited" size="16" />
                {{ $t('WGPT_AUTOMATIONS.EDITOR.DEACTIVATE') }}
              </woot-button>
            </woot-dropdown-item>
          </woot-dropdown-menu>
        </div>
      </div>
    </div>

    <div
      class="automation-rule-designer flex-1 h-full overflow-auto macro-gradient-radial dark:macro-dark-gradient-radial macro-gradient-radial-size"
    >
      <Trigger
        class="mb-[50vh] w-max min-w-full"
        :automation="automation"
        :variables="variables"
        @step-change="onStepUpdate"
        @step-delete="onStepDelete"
      />
    </div>
    <Sidebar
      :trigger="automation.trigger"
      :variables="variables"
      :automation="automation"
      :disable-trigger-change="disableTriggerChange"
      @trigger-change="onTriggerChange"
      @step-change="onStepUpdate"
      @settings-change="onSettingsChange"
    />
  </div>
</template>

<style scoped>
.automation-rule-designer {
  --triggerThemeColor: #1f93ff;
  --actionThemeColor: #44ce4b;
  --branchThemeColor: #ffc532;
}

@tailwind components;
@layer components {
  .macro-gradient-radial {
    background-image: radial-gradient(#ebf0f5 1.2px, transparent 0);
  }
  .macro-dark-gradient-radial {
    background-image: radial-gradient(#293f51 1.2px, transparent 0);
  }
  .macro-gradient-radial-size {
    background-size: 1rem 1rem;
  }
}

.settings-button-active .settings-button {
  @apply outline-none;
  outline-color: #1f93ff;
  outline-offset: 1px;
}

[dir='rtl'] .publish-button {
  @apply rounded-e-none rounded-s-md;
}

[dir='rtl'] .publish-option-button {
  @apply rounded-e-md rounded-s-none;
}
</style>

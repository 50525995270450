<script>
export default {
  computed: {
    items() {
      const steps = ['CREATE', 'ASSIGN_INBOXES', 'FINISH'];

      const routes = {
        CREATE: 'wgpt_bots_new',
        ASSIGN_INBOXES: 'wgpt_bots_add_inboxes',
        FINISH: 'wgpt_bots_finish',
      };

      return steps.map(step => {
        return {
          title: this.$t(`WGPT_BOTS.NEW_FLOW.WIZARD.${step}.TITLE`),
          body: this.$t(`WGPT_BOTS.NEW_FLOW.WIZARD.${step}.BODY`),
          route: routes[step],
        };
      });
    },
  },
};
</script>

<template>
  <div
    class="overflow-auto p-4 max-w-full my-auto flex flex-row flex-nowrap h-full bg-slate-25 dark:bg-slate-800"
  >
    <woot-wizard class="hidden md:block w-1/4" :items="items" />
    <router-view />
  </div>
</template>

import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { validateField, skipValidation } from './fieldValidation';
import { getVariables } from '../../../../helpers/stepUtils';

function validateActionStepFormFields(step, actionRegistry) {
  const actionAttributes = step.attributes || {};
  const formFields = actionRegistry.form_fields || [];
  formFields.forEach(field => {
    const fieldName = field.name;
    const actionAttributeValue = actionAttributes[fieldName] ?? field.default;
    if (skipValidation(field, actionAttributes)) return;
    validateField(field, actionAttributeValue);
  });
}

function validateActionStepOutputMappingFields(step, actionRegistry) {
  const actionOutputMapping = step.output_mapping || {};
  const outputMappingFields = actionRegistry.output_mapping_fields || [];
  outputMappingFields.forEach(field => {
    const fieldName = field.name;
    const actionOutputMappingValue =
      actionOutputMapping[fieldName] ?? field.default;
    validateField(field, actionOutputMappingValue);
  });
}

const actionValidation = actionRegistry =>
  helpers.withParams(
    {
      value: actionRegistry,
    },
    step => {
      try {
        validateActionStepFormFields(step, actionRegistry);
        validateActionStepOutputMappingFields(step, actionRegistry);
        return true;
      } catch (error) {
        return false;
      }
    }
  );

const variablesValidation = params =>
  helpers.withParams(
    {
      value: params,
    },
    step => {
      try {
        const [actionRegistry, inheritedVariables] = params;
        const outputMappingFields = actionRegistry?.output_mapping_fields ?? [];
        const outputMapping = step.output_mapping ?? {};
        const stepVariables = getVariables(outputMapping, outputMappingFields);
        const invalidVariables = stepVariables.filter(stepVariableName => {
          if (!stepVariableName) return false;
          if (!/^[a-zA-Z0-9_]+$/.test(stepVariableName)) return true;
          return false;
        });
        if (invalidVariables.length > 0) {
          throw new Error(
            `Variables ${invalidVariables.join(
              ', '
            )} must not contain special characters.`
          );
        }
        const duplicateVariables = stepVariables.filter(stepVariableName => {
          const matched = inheritedVariables.filter(inheritedVariable => {
            if (inheritedVariables.wrapper) return false;
            return inheritedVariable.name === stepVariableName;
          });
          return matched.length > 1;
        });
        if (duplicateVariables.length > 0) {
          throw new Error(
            `Variables ${duplicateVariables.join(', ')} cannot be used anymore.`
          );
        }
        return true;
      } catch (error) {
        return false;
      }
    }
  );
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      step: {
        actionValidation: actionValidation(this.actionRegistry),
        variablesValidation: variablesValidation([
          this.actionRegistry,
          this.variables,
        ]),
      },
    };
  },
};

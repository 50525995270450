<script>
import { mapGetters } from 'vuex';

import { useAlert } from 'dashboard/composables';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader.vue';
import InboxesSelector from './InboxesSelector.vue';

export default {
  components: {
    PageHeader,
    InboxesSelector,
  },
  data() {
    return {
      selectedInboxes: [],
      isCreating: false,
    };
  },
  computed: {
    ...mapGetters({
      inboxList: 'inboxes/getInboxes',
    }),
    botId() {
      return this.$route.params.botId;
    },
    headerTitle() {
      return this.$t('WGPT_BOTS.NEW_FLOW.INBOXES.TITLE', {
        botName: this.currentBot.name,
      });
    },
    currentBot() {
      return this.$store.getters['wgptBots/getBot'](this.botId);
    },
  },

  mounted() {
    this.$store.dispatch('inboxes/get');
  },

  methods: {
    updateSelectedInboxes(newInboxList) {
      this.selectedInboxes = [...newInboxList];
    },
    selectAllInboxes() {
      this.selectedInboxes = this.inboxList.map(inbox => inbox.id);
    },
    async assignInboxes() {
      this.isCreating = true;
      const { botId, selectedInboxes } = this;
      try {
        await this.$store.dispatch('wgptBots/update', {
          id: botId,
          inbox_ids: selectedInboxes,
        });
        router.replace({
          name: 'wgpt_bots_finish',
          params: {
            page: 'new',
            botId,
          },
        });
        this.$store.dispatch('wgptBots/get');
      } catch (error) {
        useAlert(error.message);
      }
      this.isCreating = false;
    },
  },
};
</script>

<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 overflow-x-auto bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <form
      class="mx-0 flex flex-wrap overflow-x-auto"
      @submit.prevent="assignInboxes"
    >
      <div class="w-full">
        <PageHeader
          :header-title="headerTitle"
          :header-content="$t('WGPT_BOTS.NEW_FLOW.INBOXES.DESC')"
        />
      </div>

      <div class="w-full">
        <InboxesSelector
          :inbox-list="inboxList"
          :selected-inboxes="selectedInboxes"
          :update-selected-inboxes="updateSelectedInboxes"
          :is-working="isCreating"
          :submit-button-text="$t('WGPT_BOTS.NEW_FLOW.INBOXES.FORM.SUBMIT')"
        />
      </div>
    </form>
  </div>
</template>

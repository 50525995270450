<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import ModalForm from './ModalForm.vue';

export default {
  components: {
    ModalForm,
  },
  props: {},
  emits: ['close'],
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBotWorkflows/getUIFlags',
    }),
  },
  methods: {
    async onSubmit(data) {
      try {
        const botId = this.$route.params.botId;
        const workflow = {
          botId,
          analyzer_prompt: '',
          rag_prompt: '',
          main_prompt: '',
          ...data,
        };

        const source = await this.$store.dispatch(
          'wgptBotWorkflows/create',
          workflow
        );

        useAlert(
          this.$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.CREATE.API.SUCCESS_MESSAGE')
        );
        this.$emit('close', source);
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.CREATE.API.ERROR_MESSAGE')
        );
      }
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div class="h-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.CREATE.TITLE')"
    />

    <ModalForm
      :on-submit="onSubmit"
      :on-close="onClose"
      :submit-in-progress="uiFlags.isCreating"
      :cancel-button-text="$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.CREATE.CANCEL')"
      :submit-button-text="$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.CREATE.SAVE')"
    />
  </div>
</template>

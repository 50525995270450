<script>
import validations from './helpers/validations';
import Items from './items/Index.vue';
import Search from './search/Index.vue';

export default {
  components: {
    Items,
    Search,
  },
  props: {
    bot: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedTabIndex: 0,
    };
  },
  computed: {
    selectedTabKey() {
      return this.tabs[this.selectedTabIndex]?.key;
    },
    tabs() {
      return [
        {
          key: 'items',
          name: this.$t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.NAME'),
        },
        {
          key: 'search',
          name: this.$t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.SEARCH.NAME'),
        },
      ];
    },
  },
  watch: {
    bot() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  validations,
  methods: {
    setDefaults() {},
    onTabChange(selectedTabIndex) {
      this.selectedTabIndex = selectedTabIndex;
    },
  },
};
</script>

<template>
  <div
    class="h-full flex-grow flex-shrink w-full min-w-0 pl-0 pr-0 overflow-hidden bg-white dark:bg-slate-800 flex flex-col"
  >
    <woot-tabs
      class="px-4"
      :index="selectedTabIndex"
      :border="false"
      @change="onTabChange"
    >
      <woot-tabs-item
        v-for="(tab, index) in tabs"
        :key="tab.key"
        :index="index"
        :name="tab.name"
        :show-badge="false"
      />
    </woot-tabs>

    <div class="flex-1 overflow-hidden">
      <Items v-if="selectedTabKey === 'items'" :bot="bot" />
      <Search v-else-if="selectedTabKey === 'search'" :bot="bot" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  props: {
    board: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete', 'close', 'open'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      confirmation: '',
    };
  },
  validations: {
    confirmation: {
      required,
      shouldBeTheSameName(value) {
        try {
          return this.board.name === value;
        } catch (error) {
          return false;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoards/getUIFlags',
    }),
  },
  methods: {
    onOpen() {
      this.$emit('open');
    },
    onClose() {
      this.$emit('close');
    },
    onDelete() {
      this.$emit('delete');
    },
    async onSubmit() {
      try {
        if (this.v$.$invalid) {
          this.v$.$touch();
          return;
        }

        const id = this.board.id;
        const data = {
          closed: true,
        };
        await this.$store.dispatch('wgptBoards/update', { id, ...data });
        useAlert(this.$t('WGPT_BOARDS.CLOSE.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_BOARDS.CLOSE.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
  },
};
</script>

<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('WGPT_BOARDS.CLOSE.TITLE', { boardName: board.name })"
      :header-content="$t('WGPT_BOARDS.CLOSE.DESC')"
    />
    <form class="mx-0 flex flex-wrap w-full" @submit.prevent="onSubmit">
      <div class="w-full">
        <label :class="{ error: v$.confirmation.$error }">
          <woot-input
            v-model.trim="confirmation"
            data-testid="board-confirmation"
            :placeholder="
              $t('WGPT_BOARDS.CLOSE.FORM.CONFIRMATION.PLACEHOLDER', {
                boardName: board.name,
              })
            "
            @input="v$.confirmation.$touch"
          />
        </label>
      </div>

      <div class="flex justify-end items-center py-2 px-0 gap-2 w-full">
        <woot-button
          :is-disabled="uiFlags.isUpdating || v$.confirmation.$invalid"
          :is-loading="uiFlags.isUpdating"
          data-testid="board-submit"
          color-scheme="alert"
        >
          {{ $t('WGPT_BOARDS.CLOSE.FORM.CLOSE') }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('WGPT_BOARDS.CLOSE.FORM.CANCEL') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

import keyboardEventListenerMixins from './keyboardEventListenerMixins';
import { emitter } from 'shared/helpers/mitt';

export default {
  mixins: [keyboardEventListenerMixins],
  watch: {
    preventDismiss(value) {
      emitter.emit('wgptPreventDismiss', value);
    },
  },
};

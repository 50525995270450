<script>
import ContextMenu from './ContextMenu.vue';
import Stages from './Stages.vue';

export default {
  components: {
    ContextMenu,
    Stages,
  },
  props: {
    workflow: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['deleteStage', 'editStage', 'addStage', 'edit'],
};
</script>

<template>
  <div>
    <div class="flex items-center justify-end pointer-events-auto mt-9">
      <div class="workflow-node-item">
        <span class="text-slate-700 dark:text-slate-100 text-truncate">{{
          workflow.name
        }}</span>
      </div>
      <ContextMenu @edit="$emit('edit')" />
      <!-- @delete="$emit('delete')" -->
    </div>
    <Stages
      :workflow="workflow"
      @add-stage="$emit('addStage')"
      @edit-stage="stage => $emit('editStage', stage)"
      @delete-stage="stage => $emit('deleteStage', stage)"
    />
  </div>
</template>

<style scoped lang="scss">
.workflow-node-item {
  @apply min-w-[16rem] flex-grow bg-white dark:bg-slate-700 py-3 px-4 rounded-md shadow-sm border border-slate-100 dark:border-slate-500 rounded-lg;
}
</style>

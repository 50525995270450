<script>
import { useAlert } from 'dashboard/composables';
import MultiselectDropdown from '../../../../../components/MultiselectDropdown.vue';
import Dropdown from '../components/Dropdown.vue';

export default {
  components: {
    MultiselectDropdown,
    Dropdown,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    agents: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSearchDropdown: false,
    };
  },
  computed: {
    selectedMembers() {
      const memberIds = this.card.config?.members ?? [];
      return this.agents.filter(agent => memberIds.includes(agent.id));
    },
  },
  methods: {
    async onUpdate(selectedItems) {
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          config: {
            ...this.card.config,
            members: selectedItems,
          },
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.API.ERROR_MESSAGE')
        );
      }
    },
    toggleDropdown() {
      this.showSearchDropdown = !this.showSearchDropdown;
    },
    closeDropdown() {
      this.showSearchDropdown = false;
    },
    addItem({ id }) {
      const result = this.selectedMembers.map(
        selectedMember => selectedMember.id
      );
      result.push(id);
      this.onUpdate(result);
    },
    removeItem({ id }) {
      const result = this.selectedMembers
        .filter(item => item.id !== id)
        .map(item => item.id);
      this.onUpdate(result);

      if (result.length > 0) return;
      this.closeDropdown();
    },
  },
};
</script>

<template>
  <div class="relative">
    <li
      class="font-medium h-7 hover:text-bg-50 flex items-center px-2 rounded-md bg-slate-50 hover:bg-slate-100 dark:bg-slate-700 dark:hover:bg-slate-600 cursor-pointer"
      :class="{ disabled: disabled }"
      @click="toggleDropdown"
    >
      <a class="inline-flex text-left max-w-full w-full items-center">
        <div
          class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full gap-2"
        >
          <fluent-icon
            icon="people-team"
            class="min-w-[1rem] mt-0.5 text-slate-700 dark:text-slate-100"
            size="14"
          />
          <span
            class="text-sm text-slate-700 dark:text-slate-100 overflow-hidden text-truncate"
          >
            {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.LABEL') }}
          </span>
        </div>
      </a>
    </li>

    <Dropdown :show-dropdown="showSearchDropdown" @close="closeDropdown">
      <MultiselectDropdown
        :options="agents"
        :selected-items="selectedMembers"
        :multiselector-title="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.TITLE'
          )
        "
        :multiselector-placeholder="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.PLACEHOLDER'
          )
        "
        :no-search-result="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.NO_RESULT'
          )
        "
        :input-placeholder="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.INPUT_PLACEHOLDER'
          )
        "
        @add="addItem"
        @remove="removeItem"
        @close="closeDropdown"
        @toggle="toggleDropdown"
      />
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
li.disabled {
  @apply opacity-50 pointer-events-none select-none;
}
</style>

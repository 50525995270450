<script>
export default {
  props: {
    startDate: {
      type: [String, Number],
      default: '',
    },
    endDate: {
      type: [String, Number],
      default: '',
    },
    complete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      now: new Date(),
      clockInterval: null,
    };
  },
  computed: {
    showDates() {
      return this.startDate || this.endDate;
    },
    elapsed() {
      if (!this.endDate) return undefined;
      const date = new Date(this.endDate);
      const elapsed = this.now - date;
      const elapsedHr = elapsed / 1_000 / 60 / 60;
      return elapsedHr;
    },
    status() {
      if (this.complete) return 'complete';
      if (this.elapsed === undefined) return '';
      if (this.elapsed < -24) {
        return 'more-than-24-hours-to-go';
      }
      if (this.elapsed < 0) {
        return '24-hours-to-go';
      }
      if (this.elapsed < 24) {
        return '24-hours-ago';
      }
      return 'more-than-24-hours-ago';
      // if (this.elapsed > 24) {
      //   return "more-than-24-hours-ago"
      // }
      // return "complete";
    },
  },
  mounted() {
    this.clockInterval = setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.clockInterval);
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="status" class="flex">
    <woot-label
      v-if="status === 'complete'"
      :title="
        $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.DUE_BADGE_STATUSES.COMPLETE')
      "
      :show-close="false"
      color-scheme="success"
      class="pointer-events-none !m-0 rounded-md font-medium due-date-badge border-none"
    />
    <woot-label
      v-else-if="status === '24-hours-to-go'"
      :title="
        $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.DUE_BADGE_STATUSES.DUE_SOON')
      "
      :show-close="false"
      bg-color="rgb(234 179 8)"
      color="rgb(66 32 6)"
      color-scheme="alert"
      class="pointer-events-none !m-0 rounded-md font-medium due-date-badge"
    />
    <woot-label
      v-else-if="status === '24-hours-ago'"
      :title="
        $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.DUE_BADGE_STATUSES.OVERDUE')
      "
      :show-close="false"
      bg-color="rgb(239 68 68)"
      color-scheme="alert"
      class="pointer-events-none !m-0 rounded-md font-medium due-date-badge"
    />
    <template v-else-if="status === 'more-than-24-hours-ago'">
      <woot-label
        :title="
          $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.DUE_BADGE_STATUSES.OVERDUE')
        "
        :show-close="false"
        bg-color="rgb(254 242 242)"
        color="rgb(185 28 28)"
        color-scheme="alert"
        class="dark:hidden border !border-red-100 pointer-events-none !m-0 rounded-md font-medium due-date-badge"
      />
      <woot-label
        :title="
          $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.DUE_BADGE_STATUSES.OVERDUE')
        "
        :show-close="false"
        bg-color="rgb(127 29 29)"
        color="rgb(252 165 165)"
        color-scheme="alert"
        class="!hidden dark:!flex border !border-red-100 dark:!border-transparent pointer-events-none !m-0 rounded-md font-medium due-date-badge"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.due-date-badge ::v-deep {
  @apply px-1.5 gap-0;
}
</style>

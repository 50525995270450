<script>
import canDismissMixin from '../../mixins/preventDismissMixin';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';

export default {
  mixins: [canDismissMixin, keyboardEventListenerMixins],
  props: {
    showDropdown: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  computed: {
    preventDismiss() {
      return this.showDropdown;
    },
  },
  methods: {
    closeDropdown() {
      this.$emit('close');
    },
    getKeyboardEvents() {
      return {
        Escape: {
          action: () => this.closeDropdown(),
          allowOnFocusedInput: true,
        },
      };
    },
  },
};
</script>

<template>
  <div>
    <div v-if="showDropdown" class="dropdown-wrap">
      <div class="modal-mask !bg-transparent cursor-pointer" />
      <div
        v-on-clickaway="closeDropdown"
        :class="{ 'dropdown-pane--open': showDropdown }"
        class="dropdown-pane mt-1"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

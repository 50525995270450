<script>
import { required, requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import ChunkType from '../helpers/chunkType';
import MultiselectParametersDropdown from '../../multiselect/MultiselectParametersDropdown.vue';

export default {
  components: {
    MultiselectParametersDropdown,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    cancelButtonText: {
      type: String,
      default: '',
    },
    submitButtonText: {
      type: String,
      default: '',
    },
    parameters: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    const formData = this.formData || {};

    const {
      chunk_type: type = ChunkType.QNA,
      param = 'param1',
      question = '',
      answer = '',
      text = '',
    } = formData;

    return {
      type,
      param,
      question,
      answer,
      text,
      showOnSidebar: true,
      types: [
        {
          name: ChunkType.QNA,
          label: this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CHUNK_TYPES.Q_AND_A'
          ),
        },
        {
          name: ChunkType.TEXT,
          label: this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CHUNK_TYPES.TEXT'
          ),
        },
      ],
      params: [
        {
          name: 'param1',
          label: 'Param1',
        },
        {
          name: 'param2',
          label: 'Param2',
        },
        {
          name: 'param3',
          label: 'Param3',
        },
        {
          name: 'param4',
          label: 'Param4',
        },
      ],
    };
  },
  validations: {
    type: {
      required,
    },
    param: {
      required,
    },
    question: {
      requiredIf: requiredIf(form => form.type === ChunkType.QNA),
    },
    answer: {
      requiredIf: requiredIf(form => form.type === ChunkType.QNA),
    },
    text: {
      requiredIf: requiredIf(form => form.type === ChunkType.TEXT),
    },
  },
  methods: {
    handleSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      const sourceChunk = {
        source_type: this.type,
        param: this.param,
      };
      if (this.type === ChunkType.QNA) {
        sourceChunk.question = this.question;
        sourceChunk.answer = this.answer;
      } else if (this.type === ChunkType.TEXT) {
        sourceChunk.text = this.text;
      }
      this.onSubmit(sourceChunk);
    },
    handleClose() {
      this.onClose();
    },
    addItem(model, parameter, refName) {
      const inputEl = this.$refs[refName];
      const cursorPosition = inputEl.selectionStart;

      const formattedParameter = `%{${parameter.name.toUpperCase()}}`;
      const previousValue = model.$model;
      model.$model =
        previousValue.slice(0, cursorPosition) +
        formattedParameter +
        previousValue.slice(cursorPosition);

      this.$nextTick(() => {
        inputEl.setSelectionRange(
          cursorPosition,
          cursorPosition + formattedParameter.length
        );
      });
    },
  },
};
</script>

<template>
  <form class="w-full" @submit.prevent="handleSubmit">
    <div>
      <label :class="{ error: v$.type.$error }">
        {{
          $t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.FORM.CHUNK_TYPE.LABEL'
          )
        }}
        <select v-model="type" disabled class="appearance-none">
          <option
            v-for="typeItem in types"
            :key="typeItem.name"
            :value="typeItem.name"
          >
            {{ typeItem.label }}
          </option>
        </select>
        <span v-if="v$.type.$error" class="message">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.FORM.CHUNK_TYPE.ERROR'
            )
          }}
        </span>
      </label>
    </div>

    <template v-if="type === 'text'">
      <div>
        <label :class="{ error: v$.text.$error }">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.FORM.TEXT.LABEL'
            )
          }}
          <div class="flex relative">
            <textarea
              ref="textInput"
              v-model="text"
              class="my-1 ltr:pr-10 rtl:pl-10"
              rows="4"
              type="text"
              placeholder="Aa..."
              @blur="v$.text.$touch"
            />

            <MultiselectParametersDropdown
              class="absolute ltr:right-0 rtl:left-0 top-0 my-2 mx-1"
              :options="parameters"
              @add="e => addItem(v$.text, e, 'textInput')"
            />
          </div>
          <span v-if="v$.text.$error" class="message">
            {{
              $t(
                'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.FORM.TEXT.ERROR'
              )
            }}
          </span>
        </label>
      </div>
    </template>

    <template v-else>
      <div>
        <label :class="{ error: v$.question.$error }">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.FORM.QUESTION.LABEL'
            )
          }}
          <div class="flex relative">
            <textarea
              ref="questionInput"
              v-model="question"
              class="my-1 ltr:pr-10 rtl:pl-10"
              rows="4"
              type="text"
              placeholder="Aa..."
              @blur="v$.question.$touch"
            />

            <MultiselectParametersDropdown
              class="absolute ltr:right-0 rtl:left-0 top-0 my-2 mx-1"
              :options="parameters"
              @add="e => addItem(v$.question, e, 'questionInput')"
            />
          </div>
          <span v-if="v$.question.$error" class="message">
            {{
              $t(
                'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.FORM.QUESTION.ERROR'
              )
            }}
          </span>
        </label>
      </div>

      <div>
        <label :class="{ error: v$.answer.$error }">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.FORM.ANSWER.LABEL'
            )
          }}
          <div class="flex relative">
            <textarea
              ref="answerInput"
              v-model="answer"
              class="my-1 pr-10"
              rows="4"
              type="text"
              placeholder="Aa..."
              @blur="v$.answer.$touch"
            />

            <MultiselectParametersDropdown
              class="absolute ltr:right-0 rtl:left-0 top-0 my-2 mx-1"
              :options="parameters"
              @add="e => addItem(v$.answer, e, 'answerInput')"
            />
          </div>
          <span v-if="v$.answer.$error" class="message">
            {{
              $t(
                'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.FORM.ANSWER.ERROR'
              )
            }}
          </span>
        </label>
      </div>
    </template>

    <div class="mt-3 flex justify-end items-center py-2 px-0 gap-2 w-full">
      <woot-button
        :disabled="v$.$invalid || submitInProgress"
        :loading="submitInProgress"
        data-testid="sourceChunk-submit"
        @submit.prevent="handleSubmit"
      >
        {{ submitButtonText }}
      </woot-button>
      <woot-button class="button clear" @click.prevent="handleClose">
        {{ cancelButtonText }}
      </woot-button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    sourceType: {
      type: String,
      default: 'custom',
    },
  },
};
</script>

<template>
  <span>
    <template v-if="sourceType === 'url'">
      {{ $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.SOURCE_TYPE.URL') }}
    </template>
    <template v-else-if="sourceType === 'document'">
      {{ $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.SOURCE_TYPE.DOCUMENT') }}
    </template>
    <template v-else>
      {{ $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.SOURCE_TYPE.CUSTOM') }}
    </template>
  </span>
</template>

<script>
import Step from './step/Index.vue';
import AddStepButton from '../../components/addStepDropdown/AddStepButton.vue';
import translateMixins from '../../../mixins/translateMixins';

export default {
  components: {
    Step,
    AddStepButton,
  },
  mixins: [translateMixins],
  props: {
    automation: {
      type: Object,
      default: () => {},
    },
    variables: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['stepChange', 'stepDelete'],
  computed: {
    trigger() {
      return this.automation.trigger || {};
    },
    steps() {
      return this.automation.steps?.v || [];
    },
    hasTrigger() {
      return !!this.trigger.type;
    },
    triggerRegistry() {
      return this.$store.getters['wgptAutomationRegistry/getTrigger'](
        this.trigger.type
      );
    },
  },
  methods: {
    onStepChange(...args) {
      this.$emit('stepChange', ...args);
    },
    onStepDelete(...step) {
      this.$emit('stepDelete', ...step);
    },
    addStepAt(index, newStep) {
      let steps = Array.from(this.steps);
      if (newStep.action === 'branch') {
        newStep.default = {
          ...newStep.default,
          steps: steps.slice(index),
        };
        steps = steps.slice(0, index).concat(newStep);
      } else {
        steps.splice(index, 0, newStep);
      }
      this.$emit('stepChange', null, steps);
    },
  },
};
</script>

<template>
  <div class="trigger mt-8">
    <router-link
      :to="{ query: {} }"
      :class="{
        'step-active': !$route.query.selected,
      }"
      class="pointer-events-none"
      replace
    >
      <div
        class="trigger-content bg-white dark:bg-slate-700 rounded-md shadow-sm cursor-pointer pointer-events-auto"
      >
        <div
          class="py-2 px-3 border-b border-slate-100 dark:border-slate-600 font-semibold text-sm"
        >
          {{ $t('WGPT_AUTOMATIONS.EDITOR.TRIGGER.LABEL') }}
        </div>
        <div class="py-2 px-3">
          <p class="text-xs m-0">
            <span
              v-if="!hasTrigger"
              class="m-0 italic text-slate-400 dark:text-slate-400"
            >
              {{ $t('WGPT_AUTOMATIONS.EDITOR.TRIGGER.PLACEHOLDER') }}
            </span>
            <span v-else-if="triggerRegistry">
              {{ t(triggerRegistry.label) }}
            </span>
            <span v-else class="space-y-2 animate-loader-pulse">
              <div class="bg-slate-100 dark:bg-slate-800/50 rounded w-1/2">
                &nbsp;
              </div>
            </span>
          </p>
        </div>
      </div>
    </router-link>

    <div v-if="steps.length === 0" class="connector-bottom">
      <AddStepButton
        class="mt-4"
        :disabled="!hasTrigger"
        @add-step="e => addStepAt(0, e)"
      />
    </div>

    <div class="steps">
      <Step
        v-for="(step, index) of steps"
        :key="index"
        :step="step"
        :trigger="trigger"
        :first="index === 0"
        :last="index === steps.length - 1"
        :variables="variables"
        @add-step-before="e => addStepAt(index, e)"
        @add-step-after="e => addStepAt(index + 1, e)"
        @step-change="onStepChange"
        @step-delete="onStepDelete"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.trigger-content {
  @apply min-w-[200px] w-max mx-auto border break-all border-slate-400/75 dark:border-slate-600;
}

.step-active > .trigger-content {
  @apply outline-none;
  outline-color: var(--triggerThemeColor);
  outline-offset: 1px;
}

[dir='ltr'] {
  .connector-top,
  .connector-bottom {
    @apply flex flex-col items-end w-1/2 pointer-events-none rounded-none border-r-[1px] border-dashed border-slate-400 dark:border-slate-600;
    --transform: translateX(50%);
  }
}
[dir='rtl'] {
  .connector-top,
  .connector-bottom {
    @apply flex flex-col items-end w-1/2 pointer-events-none rounded-none border-l-[1px] border-dashed border-slate-400 dark:border-slate-600;
    --transform: translateX(-50%);
  }
}
</style>

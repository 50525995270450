<script>
import EmptyState from '../../../../../components/widgets/EmptyState.vue';

export default {
  components: {
    EmptyState,
  },
};
</script>

<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <EmptyState
      :title="$t('WGPT_BOTS.NEW_FLOW.FINISH.TITLE')"
      :message="$t('WGPT_BOTS.NEW_FLOW.FINISH.MESSAGE')"
      :button-text="$t('WGPT_BOTS.NEW_FLOW.FINISH.BUTTON_TEXT')"
    >
      <div class="w-full text-center">
        <router-link
          class="button success nice"
          :to="{
            name: 'wgpt_bots_list',
          }"
        >
          {{ $t('WGPT_BOTS.NEW_FLOW.FINISH.BUTTON_TEXT') }}
        </router-link>
      </div>
    </EmptyState>
  </div>
</template>

<style lang="scss" scoped>
.website--code {
  @apply my-4 mx-auto max-w-[70%];
}
</style>

<script>
import SecondaryChildNavItem from './SecondaryChildNavItem.vue';

export default {
  components: {
    SecondaryChildNavItem,
  },
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['openSettings', 'addBoard'],
  computed: {
    hasSubMenu() {
      return !!this.menuItem.children;
    },
    isMenuItemVisible() {
      if (
        this.menuItem.children &&
        this.menuItem.children.length === 0 &&
        !this.menuItem.visibleWhenEmpty
      )
        return false;
      return true;
    },
  },
  methods: {
    newLinkClick(e) {
      e.preventDefault();
      this.$emit('addBoard');
    },
    showOpenBoardSettingsPopup(board) {
      this.$emit('openSettings', board);
    },
  },
};
</script>

<template>
  <li v-show="isMenuItemVisible" class="mt-1">
    <div v-if="hasSubMenu" class="flex justify-between">
      <span
        class="text-sm text-slate-700 dark:text-slate-200 font-semibold my-2 px-2 pt-1"
      >
        {{ $t(`WGPT_BOARDS.SIDEBAR.${menuItem.label}`) }}
      </span>
    </div>
    <router-link
      v-else
      class="rounded-lg leading-4 font-medium flex items-center p-2 m-0 text-sm text-slate-700 dark:text-slate-100 hover:bg-slate-25 dark:hover:bg-slate-800"
      :to="menuItem && menuItem.toState"
    >
      <fluent-icon
        :icon="menuItem.icon"
        class="min-w-[1rem] mr-1.5 rtl:mr-0 rtl:ml-1.5"
        size="14"
      />
      {{ $t(`SIDEBAR.${menuItem.label}`) }}
    </router-link>

    <ul v-if="hasSubMenu" class="list-none ml-0 mb-0">
      <SecondaryChildNavItem
        v-for="child in menuItem.children"
        :key="child.id"
        :to="child.toState"
        :label="child.label"
        :show-settings-button="child.showSettingsButton"
        @open-settings="showOpenBoardSettingsPopup(child)"
      />
      <li v-if="menuItem.showNewButton" class="pl-1">
        <woot-button
          size="tiny"
          variant="clear"
          color-scheme="secondary"
          icon="add"
          @click="e => newLinkClick(e)"
        >
          {{ $t('WGPT_BOARDS.ITEM.ADD_BUTTON_TEXT') }}
        </woot-button>
      </li>
    </ul>
  </li>
</template>

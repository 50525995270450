<script>
import MultiselectDropdownItems from './MultiselectDropdownItems.vue';

export default {
  components: {
    MultiselectDropdownItems,
  },
  props: {
    showSearchbar: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    hasThumbnail: {
      type: Boolean,
      default: true,
    },
    multiselectorTitle: {
      type: String,
      default: '',
    },
    noSearchResult: {
      type: String,
      default: 'No results found',
    },
    inputPlaceholder: {
      type: String,
      default: 'Search',
    },
  },
  emits: ['add', 'remove', 'close', 'toggle'],
  data() {
    return {};
  },
  computed: {
    hasValue() {
      if (this.selectedItems.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    toggleDropdown() {
      this.$emit('toggle');
    },

    onCloseDropdown() {
      this.$emit('close');
    },

    onClickSelectItem(value) {
      if (
        this.selectedItems.some(selectedItem => selectedItem.id === value.id)
      ) {
        this.$emit('remove', value);
        return;
      }

      this.$emit('add', value);
    },
  },
};
</script>

<template>
  <div>
    <div class="flex justify-between items-center mb-1">
      <h4
        class="text-sm text-slate-800 dark:text-slate-100 m-0 overflow-hidden whitespace-nowrap text-ellipsis"
      >
        {{ multiselectorTitle }}
      </h4>
      <woot-button
        icon="dismiss"
        size="tiny"
        color-scheme="secondary"
        variant="clear"
        @click="onCloseDropdown"
      />
    </div>
    <MultiselectDropdownItems
      :show-searchbar="showSearchbar"
      :options="options"
      :selected-items="selectedItems"
      :has-thumbnail="hasThumbnail"
      :input-placeholder="inputPlaceholder"
      :no-search-result="noSearchResult"
      @click="onClickSelectItem"
    />
  </div>
</template>

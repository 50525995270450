<script>
import translateMixins from '../../../mixins/translateMixins';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  mixins: [translateMixins],
  props: {
    automation: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['settingsChange'],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      name: this.automation.name || '',
      description: this.automation.description || '',
    };
  },
  validations() {
    return {
      name: {
        required,
      },
      description: {},
    };
  },
  watch: {
    automation: {
      handler(automation) {
        this.name = automation.name;
        this.description = automation.description;
      },
      immediate: true,
    },
  },
  methods: {
    updateValue() {
      this.v$.$touch();
      if (this.v$.$invalid) return;

      const automationUpdates = {
        name: this.name,
        description: this.description,
      };
      this.$emit('settingsChange', automationUpdates);
    },
  },
};
</script>

<template>
  <div>
    <div class="px-3 pt-4 pb-16">
      <h5
        class="grow text-base font-semibold text-black-900 dark:text-slate-200 select-none inline-flex items-center gap-1.5 overflow-hidden"
      >
        <span class="truncate">{{
          $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.SETTINGS.TITLE')
        }}</span>
      </h5>
      <p
        class="text-xs pb-4 border-b border-slate-100 dark:border-slate-800/50"
      >
        {{ $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.SETTINGS.DESCRIPTION') }}
      </p>

      <form class="w-full" @submit.prevent="updateValue">
        <div>
          <label :class="{ error: v$.name.$error }">
            {{ $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.SETTINGS.FORM.NAME.LABEL') }}
            <input
              v-model.trim="name"
              type="text"
              @input="v$.name.$touch"
              @blur="updateValue"
            />
            <span v-if="v$.name.$error" class="message">
              {{
                $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.SETTINGS.FORM.NAME.ERROR')
              }}
            </span>
          </label>
        </div>

        <div>
          <label :class="{ error: v$.description.$error }">
            {{
              $t(
                'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.SETTINGS.FORM.DESCRIPTION.LABEL'
              )
            }}
            <textarea
              v-model.trim="description"
              class="disabled:opacity-50 disabled:bg-white disabled:dark:bg-slate-900"
              rows="3"
              @input="v$.description.$touch"
              @blur="updateValue"
            />
            <span v-if="v$.description.$error" class="message">
              {{
                $t(
                  'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.SETTINGS.FORM.DESCRIPTION.ERROR'
                )
              }}
            </span>
          </label>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FilterItem from './FilterItem.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import Dropdown from '../Dropdown.vue';
import ContactMultiselectDropdown from '../../item/list/card/edit/contacts/ContactMultiselectDropdown.vue';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';
import { FILTER_ITEM_MAX_LENGTH } from './helpers/constant';

export default {
  components: {
    FilterItem,
    Thumbnail,
    Dropdown,
    ContactMultiselectDropdown,
    FluentIcon,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      updatedValue: this.modelValue,
      showContactDropdown: false,
      event: {},
      initializing: true,
      allContacts: [],
      limit: FILTER_ITEM_MAX_LENGTH,
    };
  },
  computed: {
    contacts() {
      return this.allContacts.slice(0, this.limit);
    },
    unloadedContacts() {
      if (this.initializing) return [];
      return this.updatedValue.filter(
        id => !this.contacts.find(contact => contact.id === id)
      );
    },
    hasAnyItem() {
      return this.contacts.length > 0;
    },
    showBadge() {
      return this.unloadedContacts.length > 0;
    },
    showMoreOptions() {
      if (this.initializing) return false;
      return this.allContacts.length > this.limit;
    },
  },
  watch: {
    updatedValue(value) {
      this.$emit('update:modelValue', value);
    },
    modelValue(value, oldValue) {
      if (value.join() === oldValue.join()) return;
      this.updatedValue = value;
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    async setDefaults() {
      try {
        const requestParams = {
          // page,
          // sortAttr: this.getSortAttribute(),
          // label: this.label,
          // limit: 2,
        };
        await this.$store.dispatch('contacts/get', requestParams);
        this.allContacts = this.$store.getters['contacts/getContacts'];
      } catch (error) {
        // ignore error
      } finally {
        this.initializing = false;
      }
    },
    closeDropdown() {
      this.showContactDropdown = false;
    },
    openDropdown(e) {
      this.event = e;
      this.showContactDropdown = true;
    },
    addItem(contact) {
      this.updatedValue.push(contact.id);
    },
    removeItem({ id }) {
      this.updatedValue = this.updatedValue.filter(
        updatedValueItem => updatedValueItem !== id
      );

      if (this.updatedValue.length > 0) return;
      this.closeDropdown();
    },
  },
};
</script>

<template>
  <div v-show="initializing || hasAnyItem">
    <label class="text-sm mb-2 text-slate-600 dark:text-slate-400">{{
      $t('WGPT_BOARDS.FILTER.CONTACTS.TITLE')
    }}</label>
    <template v-if="initializing">
      <FilterItem v-for="index in limit" :key="index" class="group py-1.5">
        <template #start>
          <input
            type="checkbox"
            class="group-hover:bg-slate-50 group-hover:dark:bg-slate-800/50 me-1"
            disabled
          />
        </template>
        <div
          class="w-6 h-6 bg-slate-100 dark:bg-slate-500 rounded-full wgpt-animate-pulse"
        />
        <div
          class="w-16 h-2 bg-slate-100 dark:bg-slate-500 rounded-full wgpt-animate-pulse"
        />
      </FilterItem>
    </template>
    <template v-else>
      <FilterItem
        v-for="contact in contacts"
        :key="contact.id"
        class="group py-1.5"
      >
        <template #start>
          <input
            v-model="updatedValue"
            type="checkbox"
            :value="contact.id"
            class="group-hover:bg-slate-50 group-hover:dark:bg-slate-800/50 me-1"
          />
        </template>
        <div class="flex flex-row justify-between">
          <Thumbnail
            :src="contact.thumbnail"
            :username="contact.name"
            size="24px"
          />
        </div>
        <span class="truncate">{{ contact.name }}</span>
      </FilterItem>
    </template>

    <woot-button
      v-if="showMoreOptions"
      variant="link"
      class-names="!ms-6 !mt-2 [&>*]:pointer-events-none"
      @click="openDropdown"
    >
      <div class="flex items-center gap-2">
        <div v-if="showBadge" class="w-[24px] flex justify-center">
          <div class="badge">
            <span>
              {{ unloadedContacts.length }}
            </span>
          </div>
        </div>
        {{ $t('WGPT_BOARDS.FILTER.BUTTON_TXT.SHOW_MORE_OPTIONS') }}
        <FluentIcon icon="chevron-down" size="18" />
      </div>
    </woot-button>

    <Dropdown
      class="contact-dropdown"
      :show-dropdown="showContactDropdown"
      :event="event"
      @close="closeDropdown"
    >
      <ContactMultiselectDropdown
        :selected-ids="updatedValue"
        class="p-2"
        @add="addItem"
        @remove="removeItem"
        @close="closeDropdown"
      />
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
.wgpt-animate-pulse {
  animation: wgptAnimatePulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes wgptAnimatePulse {
  50% {
    opacity: 0.5;
  }
}

.badge {
  @apply inline-block bg-slate-50 dark:bg-woot-800 rounded-md text-slate-600 dark:text-slate-100 h-5 text-xxs font-semibold px-1 min-w-[16px] text-center;
}
</style>

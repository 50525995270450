<script>
import { debounce } from '@chatwoot/utils';
import { SEARCH_MAX_LENGTH } from './helpers/constant';

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      searching: false,
      updatedValue: this.modelValue,
      onSearch: debounce(() => {
        this.$emit('update:modelValue', this.updatedValue);
        this.searching = false;
      }, 750),
    };
  },
  watch: {
    updatedValue(value) {
      if (value.length > SEARCH_MAX_LENGTH) {
        this.updatedValue = value.slice(0, SEARCH_MAX_LENGTH);
        return;
      }
      if (value) {
        this.searching = true;
      }
      this.onSearch();
    },
    modelValue(value, valueBefore) {
      if (value === valueBefore) return;
      this.updatedValue = value;
    },
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      if (!this.$refs.searchbar) return;
      this.$refs.searchbar.focus();
    },
  },
};
</script>

<template>
  <div class="relative flex items-center mb-2">
    <input
      ref="searchbar"
      v-model="updatedValue"
      type="text"
      class="!m-0 !pe-10"
      autofocus="true"
      :placeholder="$t('WGPT_BOARDS.FILTER.SEARCH.PLACEHOLDER')"
    />
    <div v-if="searching" class="text-center absolute right-0 leading-none">
      <span class="spinner" />
    </div>
  </div>
</template>

<script>
import Thumbnail from '../../../../../../../../components/widgets/Thumbnail.vue';

export default {
  components: {
    Thumbnail,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: [String, Number],
      default: '',
    },
    users: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: '32px',
    },
  },
  computed: {
    avatarSrc() {
      if (this.user) return this.user.avatar_url ?? this.user.thumbnail;
      const user = this.users.find(({ id }) => id === this.userId);
      if (user) return user.avatar_url ?? user.thumbnail;
      return '';
    },
    avatarUsername() {
      if (this.user) return this.user.name;
      const user = this.users.find(({ id }) => id === this.userId);
      if (user) return user.name;
      return '';
    },
  },
};
</script>

<template>
  <Thumbnail :src="avatarSrc" :username="avatarUsername" :size="size" />
</template>

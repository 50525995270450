<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import AddChunkForm from './AddChunkForm.vue';

export default {
  components: {
    AddChunkForm,
  },
  props: {
    sourceId: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['close'],
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBotSourceChunks/getUIFlags',
    }),
    source() {
      return this.$store.getters['wgptBotSources/getSource'](this.sourceId);
    },
    parameters() {
      const parameters = [];
      Object.keys(this.source.parameters).forEach(key => {
        parameters.push({
          name: key,
          key,
          value: this.source.parameters[key] ?? '',
        });
      });
      return parameters;
    },
  },
  methods: {
    async createSourceChunk(data) {
      try {
        const botId = this.$route.params.botId;
        const sourceId = this.sourceId;
        const source = await this.$store.dispatch(
          'wgptBotSourceChunks/create',
          {
            botId,
            sourceId,
            ...data,
          }
        );

        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.API.SUCCESS_MESSAGE'
          )
        );
        this.$emit('close', source);
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.API.ERROR_MESSAGE'
          )
        );
      }
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div class="h-auto flex flex-col">
    <woot-modal-header
      :header-title="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.TITLE')
      "
    />

    <AddChunkForm
      :on-submit="createSourceChunk"
      :on-close="onClose"
      :submit-in-progress="uiFlags.isCreating"
      :parameters="parameters"
      :cancel-button-text="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.CANCEL')
      "
      :submit-button-text="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.CREATE.SAVE')
      "
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import Avatar from '../components/Avatar.vue';
import { useMessageFormatter } from 'shared/composables/useMessageFormatter';
import TimeAgo from './TimeAgo.vue';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';
import FullEditor from '../../components/FullEditor.vue';
import canDismissMixin from '../../mixins/preventDismissMixin';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import { emitter } from 'shared/helpers/mitt';

export default {
  components: {
    Avatar,
    TimeAgo,
    FluentIcon,
    FullEditor,
  },
  mixins: [canDismissMixin, keyboardEventListenerMixins],
  props: {
    currentUserId: {
      type: [String, Number],
      default: '',
    },
    users: {
      type: Array,
      default: () => [],
    },
    comment: {
      type: Object,
      default: () => {},
    },
    onEdit: {
      type: Function,
      default: () => {},
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
    canDeleteComments: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    uploadFile: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { formatMessage } = useMessageFormatter();
    return { formatMessage };
  },
  data() {
    return {
      content: '',
      contentEditorVisible: false,
      isUploading: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardListCardActivities/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    canEditComment() {
      if (this.readonly) return false;
      if (this.comment.created_by_id === this.currentUserId) return true;
      return false;
    },
    canDeleteComment() {
      if (this.readonly) return false;
      if (this.canDeleteComments) return true;
      if (this.comment.created_by_id === this.currentUserId) return true;
      return false;
    },
    createdBy() {
      if (this.comment.created_by_id === -1) {
        return {
          name: 'Bot',
        };
      }
      return this.users.find(user => {
        return user.id === this.comment.created_by_id;
      });
    },
    conversationMessageLink() {
      if (!this.comment.content_attributes.conversation_id) return '';

      const routeData = this.$router.resolve({
        name: 'inbox_conversation',
        params: {
          accountId: this.accountId,
          conversation_id: this.comment.content_attributes.conversation_id,
        },
        query: {
          messageId: this.comment.content_attributes.message_id,
        },
      });

      return routeData.href;
    },
    preventDismiss() {
      return this.contentEditorVisible;
    },
  },
  mounted() {
    emitter.on('wgptCloseAllBoardListCardActivityEditor', () => {
      this.hideMessageEditor();
    });
  },
  beforeUnmount() {
    emitter.off('wgptCloseAllBoardListCardActivityEditor');
  },
  methods: {
    showMessageEditor() {
      emitter.emit('wgptCloseAllBoardListCardActivityEditor');
      this.content = this.comment.content;
      this.contentEditorVisible = true;
    },
    hideMessageEditor() {
      this.contentEditorVisible = false;
    },
    deleteComment() {
      this.onDelete(this.comment);
    },
    async editComment() {
      try {
        if (!this.content) return;

        const data = {
          id: this.comment.id,
          content: this.content,
        };
        await this.onEdit(data);

        this.content = '';
        this.selectedActivity = {};
        this.hideMessageEditor();
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.FORM.API.ERROR_MESSAGE'
          );
        useAlert(errorMessage);
      }
    },
    onFileUploadStart() {
      this.isUploading = true;
    },
    onFileUploadSuccess() {
      this.isUploading = false;
    },
    onFileUploadError() {
      this.isUploading = false;
    },
    getKeyboardEvents() {
      return {
        Escape: {
          action: () => this.hideMessageEditor(),
          allowOnFocusedInput: true,
        },
      };
    },
  },
};
</script>

<template>
  <div class="flex gap-3">
    <Avatar :user="createdBy" :users="users" />
    <div class="flex-1 overflow-hidden text-wrap">
      <div class="flex items-center mb-1">
        <label class="font-bold leading-normal">{{ createdBy.name }}</label>
        <TimeAgo
          class="mx-3"
          :created-at="comment.created_at"
          :updated-at="comment.updated_at"
        />
      </div>
      <div v-if="contentEditorVisible">
        <FullEditor
          v-model.trim="content"
          v-on-clickaway="hideMessageEditor"
          :placeholder="
            $t(
              'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.COMMENT.PLACEHOLDER'
            )
          "
          :upload-file="uploadFile"
          @file-upload-start="onFileUploadStart"
          @file-upload-success="onFileUploadSuccess"
          @file-upload-error="onFileUploadError"
        />
        <div class="flex gap-2">
          <woot-button
            :is-disabled="uiFlags.isUpdating || isUploading"
            :is-loading="uiFlags.isUpdating"
            size="small"
            data-testid="board-add-list-activity-edit-submit"
            class="mt-2"
            @click="editComment"
          >
            {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.SAVE') }}
          </woot-button>
          <woot-button
            :is-disabled="uiFlags.isUpdating"
            size="small"
            data-testid="board-add-list-activity-edit-cancel"
            color-scheme="secondary"
            class="mt-2"
            variant="smooth"
            @click="hideMessageEditor"
          >
            {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.DISCARD') }}
          </woot-button>
        </div>
      </div>
      <div v-else>
        <label>
          <div
            v-dompurify-html="formatMessage(comment.content)"
            class="dompurified-html shadow px-3 py-2 rounded-md bg-slate-50 dark:bg-slate-900 [&>p]:text-slate-900 [&>p]:dark:text-slate-300 [&>p:first-child]:mt-0 [&>p:last-child]:mb-0"
            dir="auto"
          />
        </label>

        <div class="flex justify-between">
          <div class="actions flex items-center">
            <div v-if="canEditComment">
              <woot-button
                size="tiny"
                variant="clear"
                class="px-0 underline opacity-75"
                color-scheme="secondary"
                @click="showMessageEditor"
              >
                {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.EDIT') }}
              </woot-button>
            </div>
            <div v-if="canDeleteComment">
              <woot-button
                size="tiny"
                variant="clear"
                class="px-0 underline opacity-75"
                color-scheme="secondary"
                @click="deleteComment"
              >
                {{
                  $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.DELETE')
                }}
              </woot-button>
            </div>
          </div>
          <div>
            <template v-if="conversationMessageLink">
              <div>
                <a :href="conversationMessageLink" target="blank">
                  <woot-button
                    size="tiny"
                    variant="clear"
                    class="px-0 underline opacity-75"
                    color-scheme="primary"
                  >
                    {{
                      $t(
                        'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.GO_TO_CONVERSATION'
                      )
                    }}
                    <FluentIcon icon="open" size="20" class="pl-1.5" />
                  </woot-button>
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.actions > :nth-child(1n + 2)::before {
  content: ' · ';
  @apply px-1;
  display: inline-block;
}
.dompurified-html {
  ::v-deep {
    ul {
      @apply px-4;
    }
  }
}
</style>

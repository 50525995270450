<script>
import StageNode from './Stage.vue';

export default {
  components: {
    StageNode,
  },
  props: {
    workflow: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['addStage', 'deleteStage', 'editStage'],
  computed: {
    stages() {
      return this.$store.getters['wgptBotWorkflowStages/getStages'](
        this.workflow.id
      );
    },
  },
  watch: {
    bot() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      const botId = this.$route.params.botId;
      const workflowId = this.workflow.id;
      this.$store.dispatch('wgptBotWorkflowStages/get', { botId, workflowId });
    },
  },
};
</script>

<template>
  <div class="grid grid-cols-2">
    <div class="col-start-2 stage-nodes pointer-events-auto">
      <div v-for="stage in stages" :key="stage.id" class="stage-node">
        <div class="stage-node-content py-3">
          <StageNode
            :stage="stage"
            @edit="$emit('editStage', stage)"
            @delete="$emit('deleteStage', stage)"
          />
        </div>
      </div>

      <div class="stage-node">
        <div class="stage-node-content py-3">
          <woot-button
            class="my-0"
            color-scheme="primary"
            variant="smooth"
            icon="add-circle"
            @click="$emit('addStage')"
          >
            {{
              $t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.BUTTON_TEXT.CREATE_STAGE')
            }}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.stage-nodes::before {
  content: '';
  height: var(--space-large);
  width: var(--space-large);
  border-left: 1px dashed var(--s-500);
}

.stage-node {
  @apply relative;
  &::before {
    @apply absolute;
    content: '';
    height: 50%;
    width: var(--space-large);
    border-left: 1px dashed var(--s-500);
    border-bottom: 1px dashed var(--s-500);
  }
  &:not(:last-child)::after {
    @apply absolute top-1/2;
    content: '';
    height: 50%;
    width: var(--space-large);
    border-left: 1px dashed var(--s-500);
  }
  .stage-node-content {
    @apply ml-9;
  }
}

[dir='rtl'] {
  .stage-nodes::before {
    // content: '';
    // height: var(--space-large);
    // width: var(--space-large);
    border-left: unset;
    border-right: 1px dashed var(--s-500);
  }

  .stage-node {
    // @apply relative;
    &::before {
      // @apply absolute;
      // content: '';
      // height: 50%;
      // width: var(--space-large);
      border-left: unset;
      border-right: 1px dashed var(--s-500);
      // border-bottom: 1px dashed var(--s-500);
    }
    &:not(:last-child)::after {
      // @apply absolute top-1/2;
      // content: '';
      // height: 50%;
      // width: var(--space-large);
      border-left: unset;
      border-right: 1px dashed var(--s-500);
    }
    .stage-node-content {
      @apply ml-0 mr-9;
    }
  }
}
</style>

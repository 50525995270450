import { required, minLength, minValue } from '@vuelidate/validators';

export default {
  name: {
    required,
    minLength: minLength(3),
  },
  displayName: {},
  description: {},
  autoResolveDuration: {
    minValue: minValue(5),
  },
};

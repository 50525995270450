<script>
import { mapGetters } from 'vuex';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WgptDropdownItem from './ContactDropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import { debounce } from '@chatwoot/utils';

export default {
  components: {
    WootDropdownItem,
    WootDropdownMenu,
    Thumbnail,
    WgptDropdownItem,
  },
  props: {
    selectedIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['click', 'search'],
  data() {
    return {
      search: '',
      searching: false,
      isTyping: false,
      onSearch: debounce(event => {
        this.search = event.target.value;
        this.$emit('search', this.search);
        this.isTyping = false;
      }, 750),
      notFoundContactIds: [],
    };
  },
  computed: {
    ...mapGetters({
      contacts: 'contacts/getContacts',
      uiFlags: 'contacts/getUIFlags',
    }),
    contactIds() {
      return this.selectedIds.filter(
        selectedId => !this.notFoundContactIds.includes(selectedId)
      );
    },
    noResult() {
      return this.contacts.length === 0 && this.search !== '';
    },
    noSelectedIds() {
      if (!this.contactIds.length) return true;
      return this.contactIds.every(contactId =>
        this.notFoundContactIds.includes(contactId)
      );
    },
    isSearching() {
      return this.search !== '';
    },
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    onclick(contact) {
      this.$emit('click', contact);
    },
    onInput(event) {
      const value = event.target.value.trim();
      if (value === this.search) return;

      if (value === '') {
        this.search = '';
      }
      if (value.length === 1) {
        this.search = value;
      }
      this.isTyping = true;
      this.onSearch(event);
    },
    focusInput() {
      if (!this.$refs.searchbar) return;
      this.$refs.searchbar.focus();
    },
    isActive(contact) {
      return this.selectedIds.some(selectedId => selectedId === contact.id);
    },
    onContactNotFound(contactId) {
      if (this.notFoundContactIds.includes(contactId)) return;
      this.notFoundContactIds.push(contactId);
    },
    onContactFound(contactId) {
      if (!this.notFoundContactIds.includes(contactId)) return;
      this.notFoundContactIds = this.notFoundContactIds.filter(
        notFoundContactId => notFoundContactId !== contactId
      );
    },
  },
};
</script>

<template>
  <div class="dropdown-wrap">
    <div class="mb-2 flex-shrink-0 flex-grow-0 flex-auto max-h-8">
      <input
        ref="searchbar"
        type="text"
        class="search-input"
        autofocus="true"
        :placeholder="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.MULTI_SELECTOR.INPUT_PLACEHOLDER'
          )
        "
        @input="onInput"
      />
    </div>
    <div class="mt-2 flex justify-start items-start flex-auto overflow-auto">
      <div class="w-full max-h-[10rem]">
        <template v-if="isSearching">
          <h4
            v-if="isTyping || uiFlags.isFetching"
            class="text-slate-500 dark:text-slate-300 py-2 text-center text-wrap text-sm"
          >
            {{
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.MULTI_SELECTOR.SEARCH_LOADER'
              )
            }}
          </h4>
          <template v-else>
            <WootDropdownMenu>
              <WootDropdownItem v-for="contact in contacts" :key="contact.id">
                <woot-button
                  class="multiselect-dropdown--item"
                  :variant="isActive(contact) ? 'hollow' : 'clear'"
                  color-scheme="secondary"
                  :class="{
                    active: isActive(contact),
                  }"
                  @click="() => onclick(contact)"
                >
                  <div class="flex items-center">
                    <Thumbnail
                      :src="contact.thumbnail"
                      size="24px"
                      :username="contact.name"
                      :status="contact.availability_status"
                      has-border
                    />
                    <div
                      class="flex items-center justify-between w-full min-w-0"
                    >
                      <span
                        class="leading-4 my-0 mx-2 overflow-hidden whitespace-nowrap text-ellipsis text-sm"
                        :title="contact.name"
                      >
                        {{ contact.name }}
                      </span>
                      <fluent-icon v-if="isActive(contact)" icon="checkmark" />
                    </div>
                  </div>
                </woot-button>
              </WootDropdownItem>
            </WootDropdownMenu>
            <h4
              v-if="noResult"
              class="text-slate-500 dark:text-slate-300 py-2 text-center text-wrap text-sm"
            >
              {{
                $t(
                  'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.MULTI_SELECTOR.NO_RESULT'
                )
              }}
            </h4>
          </template>
        </template>

        <WootDropdownMenu v-show="!isSearching">
          <WgptDropdownItem
            v-for="contactId in contactIds"
            :key="contactId"
            :contact-id="contactId"
            @toggle="onclick"
            @found="onContactFound"
            @not-found="onContactNotFound"
          />
          <h4
            v-if="noSelectedIds"
            class="text-slate-500 dark:text-slate-300 py-2 text-center text-wrap text-sm"
          >
            {{
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.MULTI_SELECTOR.SELECTED.EMPTY'
              )
            }}
          </h4>
        </WootDropdownMenu>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-wrap {
  @apply w-full flex flex-col max-h-[12.5rem];
}

.search-input {
  @apply m-0 w-full border border-solid border-transparent h-8 text-sm text-slate-700 dark:text-slate-100 rounded-md focus:border-woot-500 bg-slate-50 dark:bg-slate-900;
}

.multiselect-dropdown--item {
  @apply justify-between w-full;

  &.active {
    @apply bg-slate-25 dark:bg-slate-700 border-slate-50 dark:border-slate-900 font-medium;
  }

  &:focus {
    @apply bg-slate-25 dark:bg-slate-700;
  }

  &:hover {
    @apply bg-slate-50 dark:bg-slate-800 text-slate-800 dark:text-slate-100;
  }
}
</style>

<script>
import { useVuelidate } from '@vuelidate/core';
import ContactFilter from './ContactFilter.vue';
import DateFilter from './DateFilter.vue';
import LabelFilter from './LabelFilter.vue';
import MemberFilter from './MemberFilter.vue';
import SearchFilter from './SearchFilter.vue';

export default {
  components: {
    ContactFilter,
    DateFilter,
    LabelFilter,
    MemberFilter,
    SearchFilter,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    initialFilters: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      filters: {
        members: this.initialFilters.members ?? [],
        contacts: this.initialFilters.contacts ?? [],
        labels: this.initialFilters.labels ?? [],
        dates: this.initialFilters.dates ?? [],
        search: this.initialFilters.search ?? '',
      },
    };
  },
  computed: {
    hasAppliedFilters() {
      return this.$store.getters['wgptBoardListCards/hasFilters'](
        this.board.id
      );
    },
    memberIds() {
      const config = this.board.config ?? {};
      const authorization = config.authorization ?? {};
      const managers = authorization?.managers ?? [];
      const operators = authorization?.operators ?? [];
      const readers = authorization?.readers ?? [];
      return [...managers, ...operators, ...readers];
    },
  },
  watch: {
    filters: {
      handler(filters) {
        this.submitFilters(filters);
      },
      deep: true,
    },
  },
  methods: {
    submitFilters(filters) {
      const boardId = this.board.id;
      const filterOptions = {
        boardId,
        filters,
      };
      this.$store.dispatch('wgptBoardListCards/filter', filterOptions);
    },
    clearFilters() {
      const boardId = this.board.id;
      this.$store.dispatch('wgptBoardListCards/clearFilters', boardId);
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div class="filter-wrapper">
    <header class="flex justify-between items-center">
      <h2 class="m-4">{{ $t('WGPT_BOARDS.FILTER.TITLE') }}</h2>
      <div class="flex items-center gap-1">
        <woot-button
          v-if="hasAppliedFilters"
          color-scheme="alert"
          variant="clear"
          size="small"
          class-names="m-0"
          @click="clearFilters"
        >
          {{ $t('WGPT_BOARDS.FILTER.BUTTON_TXT.CLEAR_FILTER') }}
        </woot-button>
        <woot-button
          icon="dismiss"
          color-scheme="secondary"
          variant="clear"
          size="small"
          class-names="me-2"
          @click="$emit('close')"
        />
      </div>
    </header>

    <div
      class="flex-1 overflow-auto px-4 pb-8 flex flex-col items-stretch gap-4"
    >
      <SearchFilter v-model="filters.search" />

      <MemberFilter v-model="filters.members" :member-ids="memberIds" />

      <ContactFilter v-model="filters.contacts" />

      <LabelFilter v-model="filters.labels" />

      <DateFilter v-model="filters.dates" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filter-wrapper {
  @apply w-96 flex flex-col;
  max-height: min(590px, 75vh);
}
</style>

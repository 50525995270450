<script>
export default {
  components: {},
  props: {
    inboxList: {
      type: Array,
      default: () => [],
    },
    selectedInboxes: {
      type: Array,
      default: () => [],
    },
    updateSelectedInboxes: {
      type: Function,
      default: () => {},
    },
    isWorking: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    selectedInboxesCount() {
      return this.selectedInboxes.length;
    },
    allInboxesSelected() {
      return this.selectedInboxes.length === this.inboxList.length;
    },
  },
  methods: {
    isInboxSelected(inboxId) {
      return this.selectedInboxes.includes(inboxId);
    },
    handleSelectInbox(inboxId) {
      const shouldRemove = this.isInboxSelected(inboxId);

      let result = [];
      if (shouldRemove) {
        result = this.selectedInboxes.filter(item => item !== inboxId);
      } else {
        result = [...this.selectedInboxes, inboxId];
      }

      this.updateSelectedInboxes(result);
    },
    selectAllInboxes() {
      const result = this.allInboxesSelected
        ? []
        : this.inboxList.map(item => item.id);
      this.updateSelectedInboxes(result);
    },
  },
};
</script>

<template>
  <div>
    <table class="woot-table">
      <thead>
        <tr>
          <td class="ltr:pl-2.5 rtl:pr-2.5">
            <div class="flex items-center">
              <input
                name="select-all-inboxes"
                type="checkbox"
                :checked="allInboxesSelected ? true : false"
                :title="$t('WGPT_BOTS.NEW_FLOW.INBOXES.FORM.SELECT_ALL')"
                @click.self="selectAllInboxes"
              />
            </div>
          </td>
          <td class="text-slate-800 dark:text-slate-100 ltr:pl-2.5 rtl:pr-2.5">
            {{ $t('WGPT_BOTS.NEW_FLOW.INBOXES.FORM.NAME') }}
          </td>
          <td class="text-slate-800 dark:text-slate-100 ltr:pl-2.5 rtl:pr-2.5">
            {{ $t('WGPT_BOTS.NEW_FLOW.INBOXES.FORM.TYPE') }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="inbox in inboxList" :key="inbox.id">
          <td class="w-12">
            <div class="flex items-center">
              <input
                type="checkbox"
                :checked="isInboxSelected(inbox.id)"
                :title="$t('WGPT_BOTS.NEW_FLOW.INBOXES.FORM.SELECT')"
                @click.self="() => handleSelectInbox(inbox.id)"
              />
            </div>
          </td>
          <td>
            <div class="flex items-center gap-2">
              <img
                v-if="inbox.avatar_url"
                class="woot-thumbnail max-h-[30px] max-w-[30px]"
                :src="inbox.avatar_url"
                alt="No Page Image"
              />
              <img
                v-else
                class="woot-thumbnail max-h-[30px] max-w-[30px]"
                src="~dashboard/assets/images/flag.svg"
                alt="No Page Image"
              />

              <h4 class="text-base mb-0 text-slate-800 dark:text-slate-100">
                {{ inbox.name }}
              </h4>
            </div>
          </td>
          <td>
            <span v-if="inbox.channel_type === 'Channel::FacebookPage'">
              {{ 'Facebook' }}
            </span>
            <span v-if="inbox.channel_type === 'Channel::WebWidget'">
              {{ 'Website' }}
            </span>
            <span v-if="inbox.channel_type === 'Channel::TwitterProfile'">
              {{ 'Twitter' }}
            </span>
            <span v-if="inbox.channel_type === 'Channel::TwilioSms'">
              {{ medium === 'whatsapp' ? 'WhatsApp' : 'Twilio SMS' }}
            </span>
            <span v-if="inbox.channel_type === 'Channel::Whatsapp'">
              {{ 'Whatsapp' }}
            </span>
            <span v-if="inbox.channel_type === 'Channel::Sms'">
              {{ 'Sms' }}
            </span>
            <span v-if="inbox.channel_type === 'Channel::Email'">
              {{ 'Email' }}
            </span>
            <span v-if="inbox.channel_type === 'Channel::Telegram'">
              {{ 'Telegram' }}
            </span>
            <span v-if="inbox.channel_type === 'Channel::Line'">{{
              'Line'
            }}</span>
            <span v-if="inbox.channel_type === 'Channel::Api'">{{
              'API'
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex items-center justify-between mt-2">
      <p>
        {{
          $t('WGPT_BOTS.NEW_FLOW.INBOXES.FORM.SELECTED_COUNT', {
            selected: selectedInboxes.length,
            total: inboxList.length,
          })
        }}
      </p>
      <woot-submit-button
        :button-text="submitButtonText"
        :loading="isWorking"
      />
    </div>
  </div>
</template>

<style scoped>
input {
  @apply mb-0;
}
</style>

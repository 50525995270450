<script>
import { mapGetters } from 'vuex';
import Boards from './Boards.vue';
import ClosedBoards from './ClosedBoards.vue';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  components: {
    Boards,
    ClosedBoards,
  },
  emits: ['addBoard'],
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      allBoards: 'wgptBoards/getBoards',
    }),
    boards() {
      return this.allBoards
        .filter(board => !board.closed)
        .map(board => ({
          ...board,
          toState: frontendURL(
            `accounts/${this.accountId}/wgpt-boards/${board.id}`
          ),
        }));
    },
    closedBoards() {
      return this.allBoards
        .filter(board => board.closed)
        .map(board => ({
          ...board,
          toState: frontendURL(
            `accounts/${this.accountId}/wgpt-boards/${board.id}`
          ),
        }));
    },
  },
  methods: {
    addBoard() {
      this.$emit('addBoard');
    },
  },
};
</script>

<template>
  <div class="flex-1 overflow-auto p-4">
    <Boards :boards="boards" @add-board="addBoard" />
    <ClosedBoards :closed-boards="closedBoards" />
  </div>
</template>

<script>
import { useAlert } from 'dashboard/composables';
import ContactMultiselectDropdown from './ContactMultiselectDropdown.vue';
import Dropdown from '../components/Dropdown.vue';
import ContactAvatar from './ContactAvatar.vue';
import ContactInfo from './ContactInfo.vue';

export default {
  components: {
    ContactMultiselectDropdown,
    Dropdown,
    ContactAvatar,
    ContactInfo,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSearchDropdown: false,
      showContactInfo: false,
      notFoundContactIds: [],
      selectedContact: {},
    };
  },
  computed: {
    contactIds() {
      return this.card.config?.contacts ?? [];
    },
    hasAnyContactVisible() {
      if (!this.contactIds.length) return false;
      return this.contactIds.some(
        contactId => !this.notFoundContactIds.includes(contactId)
      );
    },
  },
  methods: {
    async onUpdate(selectedItems) {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          config: {
            ...this.card.config,
            contacts: selectedItems,
          },
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.API.ERROR'));
      }
    },
    toggleDropdown() {
      this.showSearchDropdown = !this.showSearchDropdown;
    },
    closeDropdown() {
      this.showSearchDropdown = false;
    },
    addItem({ id }) {
      const result = this.contactIds.concat(id);
      this.onUpdate(result);
    },
    removeItem({ id }) {
      const result = this.contactIds.filter(
        selectedContact => selectedContact !== id
      );
      this.onUpdate(result);

      if (result.length > 0) return;
      this.closeDropdown();
    },
    onContactNotFound(contactId) {
      if (this.notFoundContactIds.includes(contactId)) return;
      this.notFoundContactIds.push(contactId);
    },
    onContactFound(contactId) {
      if (!this.notFoundContactIds.includes(contactId)) return;
      this.notFoundContactIds = this.notFoundContactIds.filter(
        notFoundContactId => notFoundContactId !== contactId
      );
    },
    openContactInfo(contact) {
      this.selectedContact = contact;
      this.showContactInfo = true;
    },
    closeContactInfo() {
      this.showContactInfo = false;
    },
  },
};
</script>

<template>
  <div v-show="hasAnyContactVisible">
    <label>
      {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.LABEL') }}
    </label>

    <div class="flex gap-1 overflow-hidden flex-wrap">
      <ContactAvatar
        v-for="contactId in contactIds"
        :key="contactId"
        :contact-id="contactId"
        @found="onContactFound(contactId)"
        @not-found="onContactNotFound(contactId)"
        @open="openContactInfo"
      />

      <woot-button
        v-if="!readonly"
        size="small"
        data-testid="board-add-list-card-contact-add"
        color-scheme="secondary"
        variant="smooth"
        icon="add"
        @click="toggleDropdown"
      />
    </div>

    <Dropdown
      class="contact-dropdown"
      :show-dropdown="showSearchDropdown"
      @close="closeDropdown"
    >
      <ContactMultiselectDropdown
        :selected-ids="contactIds"
        @add="addItem"
        @remove="removeItem"
        @close="closeDropdown"
      />
    </Dropdown>

    <Dropdown
      class="contact-info-popup"
      :show-dropdown="showContactInfo"
      @close="closeContactInfo"
    >
      <ContactInfo :contact="selectedContact" @close="closeContactInfo" />
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
.contact-dropdown ::v-deep {
  .dropdown-pane {
    @apply w-44;
  }
}

.contact-info-popup ::v-deep {
  .dropdown-pane {
    @apply w-80 p-4;
  }
}
</style>

<script>
import { useAlert } from 'dashboard/composables';
import EditDates from '../dates/EditDates.vue';
import Dropdown from '../components/Dropdown.vue';

export default {
  components: {
    EditDates,
    Dropdown,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDropdown: false,
      innerDropdownOpen: false,
    };
  },
  methods: {
    openDropdown() {
      this.showDropdown = true;
    },
    closeDropdown() {
      if (this.innerDropdownOpen) return;
      this.showDropdown = false;
    },
    async onDatesChange(data) {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.API.ERROR_MESSAGE')
        );
      }
    },
    onDropdownOpen() {
      this.innerDropdownOpen = true;
    },
    onDropdownClose() {
      this.innerDropdownOpen = false;
    },
  },
};
</script>

<template>
  <div class="relative">
    <li
      class="font-medium h-7 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md bg-slate-50 hover:bg-slate-100 dark:bg-slate-700 dark:hover:bg-slate-600 cursor-pointer"
      :class="{ disabled: disabled }"
      @click="openDropdown"
    >
      <a class="inline-flex text-left max-w-full w-full items-center">
        <div
          class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full gap-2"
        >
          <fluent-icon
            icon="calendar-clock"
            class="min-w-[1rem] mt-0.5 text-slate-700 dark:text-slate-100"
            size="14"
          />
          <span
            class="text-sm text-slate-700 dark:text-slate-100 overflow-hidden text-truncate"
          >
            {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.DATES.LABEL') }}
          </span>
        </div>
      </a>
    </li>

    <Dropdown :show-dropdown="showDropdown" @close="closeDropdown">
      <EditDates
        :board="board"
        :list="list"
        :card="card"
        :on-submit="onDatesChange"
        @close="closeDropdown"
        @dropdown-open="onDropdownOpen"
        @dropdown-close="onDropdownClose"
      />
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
li.disabled {
  @apply opacity-50 pointer-events-none select-none;
}
</style>

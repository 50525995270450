<script>
import { mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import { useAdmin } from 'dashboard/composables/useAdmin';
import languages from 'dashboard/components/widgets/conversation/advancedFilterItems/languages.js';

export default {
  props: {
    board: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete', 'dismiss', 'close'],
  setup() {
    const { isAdmin } = useAdmin();
    return { v$: useVuelidate(), isAdmin };
  },
  data() {
    return {
      name: '',
      key: '',
      description: '',
      closed: false,
      managers: [],
      cardCustomFields: [],
      operators: [],
      readers: [],
      autoCardManagementEnabled: false,
      autoCardManagementLanguage: 'English',
      autoCardManagementLabels: [],
      autoCardManagementInboxes: [],
      languages: languages,
    };
  },
  validations: {
    name: {
      required,
    },
    key: {
      isFormatted(value) {
        if (!value) return true;
        if (/^[A-Z][A-Z0-9]*$/.test(value)) return true;
        return false;
      },
      between(value) {
        if (!value) return true;
        if (value.length >= 2 && value.length <= 10) return true;
        return false;
      },
    },
    description: {},
    managers: {},
    operators: {},
    readers: {},
    autoCardManagementEnabled: {},
    autoCardManagementLanguage: {},
    autoCardManagementLabels: {},
    autoCardManagementInboxes: {},
  },
  computed: {
    ...mapGetters({
      allLabels: 'labels/getLabels',
      agents: 'agents/getAgents',
      uiFlags: 'wgptBoards/getUIFlags',
      currentUserID: 'getCurrentUserID',
      customAttributes: 'attributes/getAttributes',
    }),
    agentList() {
      const boardManagerIds = this.managers.map(({ id }) => id);
      const boardOperatorsIds = this.operators.map(({ id }) => id);
      const boardReadersIds = this.readers.map(({ id }) => id);
      return this.agents.filter(({ id }) => {
        if (boardManagerIds.includes(id)) return false;
        if (boardOperatorsIds.includes(id)) return false;
        if (boardReadersIds.includes(id)) return false;
        return true;
      });
    },
    customFieldsList() {
      const conversationAttributes =
        this.customAttributes?.filter(
          ({ attribute_model }) => attribute_model === 'conversation_attribute'
        ) || [];
      return conversationAttributes.map(
        ({ attribute_key, attribute_display_name }) => ({
          id: attribute_key,
          name: attribute_display_name,
        })
      );
    },
    readonly() {
      return this.closed || !this.canEditBoard;
    },
    canDeleteBoard() {
      return this.isAdmin;
    },
    canEditBoard() {
      if (this.isAdmin) return true;
      const boardManagerIds = this.board.config?.authorization?.managers || [];
      return boardManagerIds.includes(this.currentUserID);
    },
    labels() {
      return this.allLabels.map(label => {
        label.name = label.title;
        return label;
      });
    },
    inboxes() {
      return this.$store.getters['inboxes/getInboxes'];
    },
  },
  watch: {
    agents() {
      this.setDefaultManagers();
      this.setDefaultOperators();
      this.setDefaultReaders();
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.$store.dispatch('inboxes/get');
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.name = this.board.name;
      this.key = this.board.key || '';
      this.description = this.board.description;
      this.closed = this.board.closed;

      this.setDefaultCardCustomFields();

      const autoCardManagement = this.board.config?.auto_card_management ?? {};
      this.autoCardManagementEnabled = autoCardManagement.enabled || false;
      this.autoCardManagementLanguage =
        autoCardManagement.language || 'English';
      const autoCardManagementLabels = autoCardManagement.labels || [];
      this.autoCardManagementLabels = this.labels.filter(label =>
        autoCardManagementLabels.includes(label.title)
      );
      const autoCardManagementInboxes = autoCardManagement.inboxes || [];
      this.autoCardManagementInboxes = this.inboxes.filter(inbox =>
        autoCardManagementInboxes.includes(inbox.id)
      );
    },
    setDefaultManagers() {
      const boardManagerIds = this.board.config?.authorization?.managers || [];
      this.managers = this.agents.filter(agent =>
        boardManagerIds.includes(agent.id)
      );
    },
    setDefaultOperators() {
      const boardOperatorsIds =
        this.board.config?.authorization?.operators || [];
      this.operators = this.agents.filter(agent =>
        boardOperatorsIds.includes(agent.id)
      );
    },
    setDefaultReaders() {
      const boardReadersIds = this.board.config?.authorization?.readers || [];
      this.readers = this.agents.filter(agent =>
        boardReadersIds.includes(agent.id)
      );
    },
    setDefaultCardCustomFields() {
      const boardCardCustomFieldIds =
        this.board.config?.card_custom_fields?.fields || [];
      const selectedAttributes = this.customAttributes.filter(attr =>
        boardCardCustomFieldIds.includes(attr.attribute_key)
      );
      this.cardCustomFields = selectedAttributes.map(
        ({ attribute_key, attribute_display_name }) => ({
          id: attribute_key,
          name: attribute_display_name,
        })
      );
    },
    async onReopen() {
      try {
        const id = this.board.id;
        const data = {
          closed: false,
        };
        await this.$store.dispatch('wgptBoards/update', { id, ...data });

        useAlert(this.$t('WGPT_BOARDS.EDIT.API.SUCCESS_MESSAGE'));
        this.closed = false;
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_BOARDS.EDIT.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    onClose() {
      this.$emit('close');
    },
    onDismiss() {
      this.$emit('dismiss');
    },
    onDelete() {
      this.$emit('delete');
    },
    async onSubmit() {
      try {
        if (!this.v$.$anyDirty || this.v$.$invalid) {
          this.v$.$touch();
          return;
        }

        const managers = this.managers.map(({ id }) => id);
        const operators = this.operators.map(({ id }) => id);
        const readers = this.readers.map(({ id }) => id);
        const cardCustomFields = this.cardCustomFields.map(({ id }) => id);
        const id = this.board.id;
        const data = {
          name: this.name,
          key: this.key || null,
          description: this.description,
          config: {
            authorization: {
              managers,
              operators,
              readers,
            },
            card_custom_fields: {
              fields: cardCustomFields,
            },
            auto_card_management: {
              enabled: this.autoCardManagementEnabled,
              language: this.autoCardManagementLanguage,
              labels: this.autoCardManagementLabels.map(({ title }) => title),
              inboxes: this.autoCardManagementInboxes.map(
                ({ id: inboxId }) => inboxId
              ),
            },
          },
        };

        await this.$store.dispatch('wgptBoards/update', { id, ...data });
        useAlert(this.$t('WGPT_BOARDS.EDIT.API.SUCCESS_MESSAGE'));
        this.onDismiss();
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_BOARDS.EDIT.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    onKeyInput(ev) {
      this.$nextTick(() => {
        const value = ev.target.value;
        this.key = value.toUpperCase();
        this.v$.key.$touch();
      });
    },
    closeMultiSelect(target) {
      this.$refs[target].deactivate();
    },
  },
};
</script>

<template>
  <div class="h-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('WGPT_BOARDS.EDIT.TITLE')"
      :header-content="$t('WGPT_BOARDS.EDIT.DESC')"
    />

    <form class="w-full" @submit.prevent="onSubmit">
      <div>
        <label :class="{ error: v$.name.$error }">
          {{ $t('WGPT_BOARDS.EDIT.FORM.NAME.LABEL') }}
          <input
            v-model.trim="name"
            class="disabled:opacity-50 disabled:!bg-white disabled:dark:!bg-slate-900 disabled:dark:text-white"
            type="text"
            data-testid="board-name"
            :disabled="readonly"
            @input="v$.name.$touch"
          />
          <span v-if="v$.name.$error" class="message">
            {{ $t('WGPT_BOARDS.EDIT.FORM.NAME.ERROR') }}
          </span>
        </label>

        <label :class="{ error: v$.key.$error }">
          {{ $t('WGPT_BOARDS.EDIT.FORM.KEY.LABEL') }}
          <input
            v-model.trim="key"
            class="disabled:opacity-50 disabled:!bg-white disabled:dark:!bg-slate-900 disabled:dark:text-white"
            type="text"
            data-testid="board-key"
            :disabled="readonly"
            @input="onKeyInput"
          />
          <template v-if="v$.key.$error">
            <span v-if="v$.key.isFormatted.$invalid" class="message">
              {{ $t('WGPT_BOARDS.ADD.FORM.KEY.ERROR_FORMAT') }}
            </span>
            <span v-else-if="v$.key.between.$invalid" class="message">
              {{ $t('WGPT_BOARDS.ADD.FORM.KEY.ERROR_BETWEEN') }}
            </span>
          </template>
        </label>

        <label :class="{ error: v$.description.$error }">
          {{ $t('WGPT_BOARDS.EDIT.FORM.DESCRIPTION.LABEL') }}
          <textarea
            v-model.trim="description"
            class="disabled:opacity-50 disabled:bg-white disabled:dark:bg-slate-900"
            data-testid="board-description"
            :disabled="readonly"
            rows="3"
            @input="v$.description.$touch"
          />
        </label>
      </div>

      <hr class="my-8 border-slate-50 dark:border-slate-700/30" />

      <div>
        <p class="text-base text-woot-500 dark:text-woot-500 mb-0 font-medium">
          {{ $t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.HEADER') }}
        </p>
        <p
          class="text-sm mb-2 text-slate-700 dark:text-slate-300 leading-5 tracking-normal mt-2"
        >
          {{ $t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.SUBHEADER') }}
        </p>

        <div>
          <label>
            {{ $t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.MANAGERS.LABEL') }}
          </label>
          <multiselect
            ref="managersMultiSelect"
            v-model="managers"
            track-by="id"
            label="name"
            selected-label
            placeholder=""
            :options="agentList"
            multiple
            :close-on-select="false"
            :clear-on-select="false"
            hide-selected
            :select-label="
              $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')
            "
            :deselect-label="
              $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_REMOVE')
            "
            :disabled="readonly || !agents"
            @select="v$.managers.$touch"
            @remove="v$.managers.$touch"
          >
            <template #beforeList>
              <div
                class="modal-mask z-[-1] cursor-pointer !bg-transparent"
                @click="closeMultiSelect('managersMultiSelect')"
              />
            </template>
          </multiselect>
        </div>

        <div>
          <label>
            {{ $t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.OPERATORS.LABEL') }}
          </label>
          <multiselect
            ref="operatorsMultiSelect"
            v-model="operators"
            track-by="id"
            label="name"
            selected-label
            placeholder=""
            :options="agentList"
            multiple
            :close-on-select="false"
            :clear-on-select="false"
            hide-selected
            :select-label="
              $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')
            "
            :deselect-label="
              $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_REMOVE')
            "
            :disabled="readonly || !agents"
            @select="v$.operators.$touch"
            @remove="v$.operators.$touch"
          >
            <template #beforeList>
              <div
                class="modal-mask z-[-1] cursor-pointer !bg-transparent"
                @click="closeMultiSelect('operatorsMultiSelect')"
              />
            </template>
          </multiselect>
        </div>

        <div>
          <label>
            {{ $t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.READERS.LABEL') }}
          </label>
          <multiselect
            ref="readersMultiSelect"
            v-model="readers"
            track-by="id"
            label="name"
            selected-label
            placeholder=""
            :options="agentList"
            multiple
            :close-on-select="false"
            :clear-on-select="false"
            hide-selected
            :select-label="
              $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')
            "
            :deselect-label="
              $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_REMOVE')
            "
            :disabled="readonly || !agents"
            @select="v$.readers.$touch"
            @remove="v$.readers.$touch"
          >
            <template #beforeList>
              <div
                class="modal-mask z-[-1] cursor-pointer !bg-transparent"
                @click="closeMultiSelect('readersMultiSelect')"
              />
            </template>
          </multiselect>
        </div>
      </div>

      <hr class="my-8 border-slate-50 dark:border-slate-700/30" />

      <div>
        <p class="text-base text-woot-500 dark:text-woot-500 mb-0 font-medium">
          {{ $t('WGPT_BOARDS.EDIT.FORM.CARD_CUSTOM_FIELDS.HEADER') }}
        </p>
        <p
          class="text-sm mb-2 text-slate-700 dark:text-slate-300 leading-5 tracking-normal mt-2"
        >
          {{ $t('WGPT_BOARDS.EDIT.FORM.CARD_CUSTOM_FIELDS.SUBHEADER') }}
        </p>

        <label>
          {{ $t('WGPT_BOARDS.EDIT.FORM.CARD_CUSTOM_FIELDS.FIELDS.LABEL') }}
          <multiselect
            v-model="cardCustomFields"
            track-by="id"
            label="name"
            selected-label
            placeholder=""
            :options="customFieldsList"
            multiple
            :close-on-select="false"
            :clear-on-select="false"
            hide-selected
            :select-label="
              $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')
            "
            :deselect-label="
              $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_REMOVE')
            "
            :disabled="readonly || !customFieldsList"
            @select="v$.managers.$touch"
            @remove="v$.managers.$touch"
          />
        </label>
      </div>

      <hr class="my-8 border-slate-50 dark:border-slate-700/30" />

      <div>
        <p class="text-base text-woot-500 dark:text-woot-500 mb-0 font-medium">
          {{ $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.HEADER') }}
        </p>
        <p
          class="text-sm mb-2 text-slate-700 dark:text-slate-300 leading-5 tracking-normal mt-2"
        >
          {{ $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.SUBHEADER') }}
        </p>

        <label class="mb-3">
          <input
            v-model="autoCardManagementEnabled"
            type="checkbox"
            class="ltr:mr-2 rtl:ml-2"
            name="toggle-imap-enable"
            :disabled="readonly"
            @change="v$.autoCardManagementEnabled.$touch"
          />
          {{ $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.ENABLED.LABEL') }}
        </label>

        <template v-if="autoCardManagementEnabled">
          <div>
            <label>
              {{
                $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.INBOXES.LABEL')
              }}
            </label>
            <multiselect
              ref="autoCardManagementInboxesMultiSelect"
              v-model="autoCardManagementInboxes"
              track-by="id"
              label="name"
              selected-label
              placeholder=""
              :options="inboxes"
              multiple
              :close-on-select="false"
              :clear-on-select="false"
              hide-selected
              :select-label="
                $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')
              "
              :deselect-label="
                $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_REMOVE')
              "
              :disabled="readonly"
              @select="v$.autoCardManagementInboxes.$touch"
              @remove="v$.autoCardManagementInboxes.$touch"
            >
              <template #beforeList>
                <div
                  class="modal-mask z-[-1] cursor-pointer !bg-transparent"
                  @click="
                    closeMultiSelect('autoCardManagementInboxesMultiSelect')
                  "
                />
              </template>
            </multiselect>
            <p
              class="pb-1 text-sm not-italic text-slate-600 dark:text-slate-400"
            >
              {{
                $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.INBOXES.DESC')
              }}
            </p>
          </div>

          <label>
            {{
              $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.LANGUAGE.LABEL')
            }}
            <select
              v-model="autoCardManagementLanguage"
              :disabled="readonly"
              class="disabled:opacity-50"
              @change="v$.autoCardManagementLanguage.$touch"
            >
              <option
                v-for="lang in languages"
                :key="lang.id"
                :value="lang.name"
              >
                {{ lang.name }}
              </option>
            </select>
          </label>

          <div>
            <label>
              {{
                $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.LABELS.LABEL')
              }}
            </label>
            <multiselect
              ref="autoCardManagementLabelsMultiSelect"
              v-model="autoCardManagementLabels"
              track-by="id"
              label="name"
              selected-label
              placeholder=""
              :options="labels"
              multiple
              :close-on-select="false"
              :clear-on-select="false"
              hide-selected
              :select-label="
                $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')
              "
              :deselect-label="
                $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_REMOVE')
              "
              :disabled="readonly || !labels"
              @select="v$.autoCardManagementLabels.$touch"
              @remove="v$.autoCardManagementLabels.$touch"
            >
              <template #beforeList>
                <div
                  class="modal-mask z-[-1] cursor-pointer !bg-transparent"
                  @click="
                    closeMultiSelect('autoCardManagementLabelsMultiSelect')
                  "
                />
              </template>
            </multiselect>
            <p
              class="pb-1 text-sm not-italic text-slate-600 dark:text-slate-400"
            >
              {{ $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.LABELS.DESC') }}
            </p>
          </div>
        </template>
      </div>

      <div class="mt-6 flex justify-between items-center py-2 px-0 w-full">
        <aside class="flex items-center gap-2">
          <woot-button
            :is-disabled="uiFlags.isUpdating || !v$.$anyDirty || v$.$invalid"
            :is-loading="uiFlags.isUpdating"
            data-testid="board-submit"
          >
            {{ $t('WGPT_BOARDS.EDIT.FORM.SAVE') }}
          </woot-button>
          <woot-button class="button clear" @click.prevent="onDismiss">
            {{ $t('WGPT_BOARDS.EDIT.FORM.DISCARD') }}
          </woot-button>
        </aside>
        <aside class="flex flex-row-reverse gap-2">
          <template v-if="closed">
            <woot-button
              v-if="canEditBoard"
              :is-disabled="uiFlags.isUpdating"
              class="button clear"
              @click.prevent="onReopen"
            >
              {{ $t('WGPT_BOARDS.EDIT.FORM.OPEN') }}
            </woot-button>
            <woot-button
              v-if="canDeleteBoard"
              icon="delete"
              class="button clear"
              color-scheme="alert"
              @click.prevent="onDelete"
            >
              {{ $t('WGPT_BOARDS.EDIT.FORM.DELETE') }}
            </woot-button>
          </template>

          <woot-button
            v-else-if="canEditBoard"
            :is-disabled="uiFlags.isUpdating"
            class="button clear"
            color-scheme="alert"
            @click.prevent="onClose"
          >
            {{ $t('WGPT_BOARDS.EDIT.FORM.CLOSE') }}
          </woot-button>
        </aside>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.multiselect {
  ::v-deep {
    .multiselect__tags {
      z-index: 10;
    }
    .multiselect__content-wrapper {
      z-index: -1;
    }
  }
}
</style>

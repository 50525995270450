<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import EditChunkForm from './EditChunkForm.vue';

export default {
  components: {
    EditChunkForm,
  },
  props: {
    sourceId: {
      type: [String, Number],
      default: '',
    },
    chunk: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close'],
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBotSourceChunks/getUIFlags',
    }),
    source() {
      return this.$store.getters['wgptBotSources/getSource'](this.sourceId);
    },
    parameters() {
      const parameters = [];
      Object.keys(this.source.parameters).forEach(key => {
        parameters.push({
          name: key,
          key,
          value: this.source.parameters[key] ?? '',
        });
      });
      return parameters;
    },
  },
  mounted() {
    const botId = this.$route.params.botId;
    const id = this.sourceId;
    if (!this.source) {
      this.$store.dispatch('wgptBotSources/show', { botId, id });
    }
  },
  methods: {
    async updateSourceChunk(data) {
      try {
        const botId = this.$route.params.botId;
        const sourceId = this.sourceId;
        const id = this.chunk.id;

        const source = await this.$store.dispatch(
          'wgptBotSourceChunks/update',
          {
            botId,
            sourceId,
            id,
            ...data,
          }
        );

        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.API.SUCCESS_MESSAGE'
          )
        );
        this.$emit('close', source);
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.API.ERROR_MESSAGE'
          )
        );
      }
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div class="h-auto flex flex-col">
    <woot-modal-header
      :header-title="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.TITLE')
      "
    />

    <EditChunkForm
      :on-submit="updateSourceChunk"
      :on-close="onClose"
      :form-data="chunk"
      :submit-in-progress="uiFlags.isUpdating"
      :parameters="parameters"
      :cancel-button-text="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.CANCEL')
      "
      :submit-button-text="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.SAVE')
      "
    />
  </div>
</template>

<script>
import ContextMenu from './ContextMenu.vue';

export default {
  components: {
    ContextMenu,
  },
  props: {
    stage: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete', 'edit'],
};
</script>

<template>
  <div>
    <div
      class="min-w-[16rem] border border-slate-100 dark:border-slate-500 rounded-lg flex items-center"
    >
      <div class="stage-node-item">
        <span class="text-slate-700 dark:text-slate-100 text-truncate">{{
          stage.name
        }}</span>
      </div>
      <ContextMenu @edit="$emit('edit')" @delete="$emit('delete')" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.stage-node-item {
  @apply flex-grow bg-white dark:bg-slate-700 py-3 px-4 rounded-md shadow-sm;
}
</style>

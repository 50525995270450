<script>
import AddLabel from './Labels.vue';
import AddContacts from './Contacts.vue';
import AddMembers from './Members.vue';
import AddDate from './Dates.vue';
import AddAttachments from './Attachments.vue';
import { useAlert } from 'dashboard/composables';
import canDismissMixin from '../../mixins/preventDismissMixin';

export default {
  components: {
    AddLabel,
    AddContacts,
    AddMembers,
    AddDate,
    AddAttachments,
  },
  mixins: [canDismissMixin],
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    agents: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onDelete'],
  data() {
    return {
      showOptionsMenu: false,
      showDeleteCardConfirmationPopup: false,
    };
  },
  computed: {
    additionalActions() {
      const additionalActions = [
        {
          label: this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.DELETE'),
          icon: 'delete',
          handler: this.openDeleteCardPopup,
          disabled: this.readonly,
        },
      ];
      return additionalActions;
    },
    confirmDeleteCardMessage() {
      return ` ${this.card.name}?`;
    },
    preventDismiss() {
      return this.showDeleteCardConfirmationPopup;
    },
  },
  methods: {
    openDeleteCardPopup() {
      this.showDeleteCardConfirmationPopup = true;
    },
    hideDeleteCardPopup() {
      this.showDeleteCardConfirmationPopup = false;
    },
    async confirmCardDeletion() {
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        await this.$store.dispatch('wgptBoardListCards/delete', {
          boardId,
          listId,
          id,
        });
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.API.SUCCESS_MESSAGE')
        );
        this.hideDeleteCardPopup();
        this.$nextTick(() => {
          this.$emit('onDelete');
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="flex justify-between">
      <label>
        {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ADD_TO_CARD') }}
      </label>
    </div>
    <ul class="list-none ml-0 mb-0 flex flex-col gap-1.5">
      <AddMembers
        :board="board"
        :list="list"
        :card="card"
        :agents="agents"
        :disabled="readonly"
      />
      <AddLabel
        :board="board"
        :list="list"
        :card="card"
        :labels="labels"
        :disabled="readonly"
      />
      <AddDate
        :board="board"
        :list="list"
        :card="card"
        :labels="labels"
        :disabled="readonly"
      />
      <AddContacts
        :board="board"
        :list="list"
        :card="card"
        :disabled="readonly"
      />
      <AddAttachments
        :board="board"
        :list="list"
        :card="card"
        :disabled="readonly"
      />
    </ul>

    <div class="flex justify-between mt-5">
      <label>
        {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.ACTIONS') }}
      </label>
    </div>
    <ul class="list-none ml-0 mb-0 flex flex-col gap-1.5">
      <li
        v-for="(action, index) in additionalActions"
        :key="index"
        class="font-medium h-7 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md bg-slate-50 hover:bg-slate-100 dark:bg-slate-700 dark:hover:bg-slate-600 cursor-pointer"
        :class="{ disabled: action.disabled }"
        @click="action.handler"
      >
        <a class="inline-flex text-left max-w-full w-full items-center">
          <div
            class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full gap-2"
          >
            <fluent-icon
              :icon="action.icon"
              class="min-w-[1rem] mt-0.5 text-slate-700 dark:text-slate-100"
              size="14"
            />
            <span
              class="text-sm text-slate-700 dark:text-slate-100 overflow-hidden text-truncate"
            >
              {{ action.label }}
            </span>
          </div>
        </a>
      </li>
    </ul>

    <woot-delete-modal
      v-if="showDeleteCardConfirmationPopup"
      v-model:show="showDeleteCardConfirmationPopup"
      :on-close="hideDeleteCardPopup"
      :on-confirm="confirmCardDeletion"
      :title="$t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.TITLE')"
      :message="$t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.DESC')"
      :message-value="confirmDeleteCardMessage"
      :confirm-text="$t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.FORM.DELETE')"
      :reject-text="$t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.FORM.CANCEL')"
    />
  </div>
</template>

<style lang="scss" scoped>
li.disabled {
  @apply opacity-50 pointer-events-none select-none;
}
</style>

<script>
import BotForm from './BotForm.vue';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader.vue';
import { useAlert } from 'dashboard/composables';

export default {
  components: {
    BotForm,
    PageHeader,
  },
  methods: {
    async createBot(data) {
      try {
        const bot = await this.$store.dispatch('wgptBots/create', {
          ...data,
        });

        router.replace({
          name: 'wgpt_bots_add_inboxes',
          params: {
            page: 'new',
            botId: bot.id,
          },
        });
      } catch (error) {
        useAlert(this.$t('WGPT_BOTS.NEW_FLOW.CREATE.FORM.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 overflow-x-auto bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <PageHeader
      :header-title="$t('WGPT_BOTS.NEW_FLOW.CREATE.TITLE')"
      :header-content="$t('WGPT_BOTS.NEW_FLOW.CREATE.DESC')"
    />
    <div class="flex flex-wrap">
      <BotForm
        :on-submit="createBot"
        :submit-in-progress="false"
        :submit-button-text="$t('WGPT_BOTS.NEW_FLOW.CREATE.FORM.SUBMIT')"
      />
    </div>
  </div>
</template>

<script>
import BoardItem from './BoardItem.vue';

export default {
  components: {
    BoardItem,
  },
  props: {
    closedBoards: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="closedBoards.length">
    <hr class="mx-4 my-12 opacity-25" />
    <div
      class="p-5 border-slate-25 dark:border-slate-700 text-black-900 dark:text-slate-300"
    >
      <div class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 mb-3">
        <h4 class="text-lg font-medium text-black-900 dark:text-slate-200">
          {{ $t('WGPT_BOARDS.OVERVIEW.CLOSED_BOARDS.HEADER') }}
        </h4>
      </div>
      <div class="flex-[75%] flex flex-wrap gap-4">
        <router-link
          v-for="board in closedBoards"
          :key="board.id"
          :to="board.toState"
        >
          <BoardItem class="card-item" :board="board" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-item {
  @apply h-24 w-60 hover:opacity-75;
}
</style>

<script>
import { Dropdown } from 'floating-vue';

export default {
  components: {
    Dropdown,
  },
};
</script>

<template>
  <Dropdown
    :triggers="['hover', 'focus']"
    :popper-triggers="['hover', 'focus']"
    popper-class="v-popper--wgpt-automations"
    placement="right-start"
    :distance="-2"
    :delay="{ show: 0, hide: 400 }"
    :auto-hide="false"
  >
    <slot />

    <template #popper>
      <slot name="popper" />
    </template>
  </Dropdown>
</template>

<style>
.v-popper--wgpt-automations .v-popper__arrow-outer {
  visibility: hidden !important;
}

.v-popper--wgpt-automations .v-popper__arrow-inner {
  visibility: hidden !important;
}

.v-popper--wgpt-automations .v-popper__inner {
  border: 0px !important;
}
</style>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import ContactInfoRow from 'dashboard/routes/dashboard/conversation/contact/ContactInfoRow.vue';
import SocialIcons from 'dashboard/routes/dashboard/conversation/contact/SocialIcons.vue';
import { getCountryFlag } from 'dashboard/helper/flag';

export default {
  components: {
    Thumbnail,
    ContactInfoRow,
    SocialIcons,
  },
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close'],
  computed: {
    contactProfileLink() {
      return `/app/accounts/${this.$route.params.accountId}/contacts/${this.contact.id}`;
    },
    additionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    location() {
      const {
        country = '',
        city = '',
        country_code: countryCode,
      } = this.additionalAttributes;
      const cityAndCountry = [city, country].filter(item => !!item).join(', ');

      if (!cityAndCountry) {
        return '';
      }
      return this.findCountryFlag(countryCode, cityAndCountry);
    },
    socialProfiles() {
      const {
        social_profiles: socialProfiles,
        screen_name: twitterScreenName,
      } = this.additionalAttributes;

      return { twitter: twitterScreenName, ...(socialProfiles || {}) };
    },
  },
  methods: {
    findCountryFlag(countryCode, cityAndCountry) {
      try {
        const countryFlag = countryCode ? getCountryFlag(countryCode) : '🌎';
        return `${cityAndCountry} ${countryFlag}`;
      } catch (error) {
        return '';
      }
    },
  },
};
</script>

<template>
  <div class="flex flex-col w-full gap-2 text-left rtl:text-right">
    <div class="flex flex-row justify-between gap-2">
      <div class="flex gap-2 items-center">
        <Thumbnail
          :src="contact.thumbnail"
          size="56px"
          :username="contact.name"
          :status="contact.availability_status"
        />
        <div class="flex items-start w-full min-w-0 gap-2">
          <h3
            class="line-clamp-2 break-all font-bold flex-shrink max-w-full min-w-0 my-0 text-base capitalize break-words text-slate-800 dark:text-slate-100"
          >
            {{ contact.name }}
          </h3>
        </div>
      </div>
      <div class="flex gap-0.5">
        <a
          :href="contactProfileLink"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          <woot-button
            size="tiny"
            icon="open"
            variant="clear"
            color-scheme="secondary"
          />
        </a>
        <woot-button
          size="tiny"
          icon="dismiss"
          class="clear secondary rtl:rotate-180"
          @click="$emit('close')"
        />
      </div>
    </div>

    <div class="flex flex-col items-start gap-1.5 min-w-0 w-full">
      <p
        v-if="additionalAttributes.description"
        class="line-clamp-2 break-words mb-0.5"
      >
        {{ additionalAttributes.description }}
      </p>
      <div class="flex flex-col items-start w-full gap-2">
        <ContactInfoRow
          :href="contact.email ? `mailto:${contact.email}` : ''"
          :value="contact.email"
          icon="mail"
          emoji="✉️"
          :title="$t('CONTACT_PANEL.EMAIL_ADDRESS')"
          show-copy
        />
        <ContactInfoRow
          :href="contact.phone_number ? `tel:${contact.phone_number}` : ''"
          :value="contact.phone_number"
          icon="call"
          emoji="📞"
          :title="$t('CONTACT_PANEL.PHONE_NUMBER')"
          show-copy
        />
        <ContactInfoRow
          v-if="contact.identifier"
          :value="contact.identifier"
          icon="contact-identify"
          emoji="🪪"
          :title="$t('CONTACT_PANEL.IDENTIFIER')"
        />
        <ContactInfoRow
          :value="additionalAttributes.company_name"
          icon="building-bank"
          emoji="🏢"
          :title="$t('CONTACT_PANEL.COMPANY')"
        />
        <ContactInfoRow
          v-if="location || additionalAttributes.location"
          :value="location || additionalAttributes.location"
          icon="map"
          emoji="🌍"
          :title="$t('CONTACT_PANEL.LOCATION')"
        />
        <SocialIcons :social-profiles="socialProfiles" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.multiselect-dropdown--item {
  @apply justify-between w-full;

  &.active {
    @apply bg-slate-25 dark:bg-slate-700 border-slate-50 dark:border-slate-900 font-medium;
  }

  &:focus {
    @apply bg-slate-25 dark:bg-slate-700;
  }

  &:hover {
    @apply bg-slate-50 dark:bg-slate-800 text-slate-800 dark:text-slate-100;
  }
}

.wgpt-animate-pulse {
  animation: wgptAnimatePulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes wgptAnimatePulse {
  50% {
    opacity: 0.5;
  }
}
</style>

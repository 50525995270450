<script>
import { isSameYear } from 'date-fns';
import { useAlert } from 'dashboard/composables';
import { messageTimestamp } from 'shared/helpers/timeHelper';
import DueDateBadge from './DueDateBadge.vue';
import EditDates from './EditDates.vue';
import Dropdown from '../components/Dropdown.vue';

export default {
  components: {
    DueDateBadge,
    EditDates,
    Dropdown,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDropdownDates: false,
      innerDropdownOpen: false,
      complete: false,
    };
  },
  computed: {
    startDate() {
      return this.card.start_date;
    },
    endDate() {
      return this.card.end_date;
    },
    showDates() {
      return this.startDate || this.endDate;
    },
    showDueDateBadge() {
      return this.endDate;
    },
    readableStartDate() {
      const startDateInput = this.card.start_date;
      if (!startDateInput) return '';
      const now = new Date();
      const startDate = new Date(startDateInput);
      return messageTimestamp(
        startDate.getTime() / 1_000,
        isSameYear(startDate, now) ? 'LLL d' : 'LLL d, y'
      );
    },
    readableEndDate() {
      const endDateInput = this.card.end_date;
      if (!endDateInput) return '';
      const now = new Date();
      const endDate = new Date(endDateInput);
      return messageTimestamp(
        endDate.getTime() / 1_000,
        isSameYear(endDate, now) ? 'LLL d, h:mm aa' : 'LLL d, y, h:mm aa'
      );
    },
  },
  watch: {
    card() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.complete = this.card.complete ?? false;
    },
    async onCompleteToggle() {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          complete: this.complete,
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.API.ERROR_MESSAGE')
        );
      }
    },
    async onDatesChange(data) {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.API.ERROR_MESSAGE')
        );
      }
    },
    toggleDateDropdown() {
      this.showDropdownDates = true;
    },
    closeDateDropdown() {
      if (this.innerDropdownOpen) return;
      this.showDropdownDates = false;
    },
    onDropdownOpen() {
      this.innerDropdownOpen = true;
    },
    onDropdownClose() {
      this.innerDropdownOpen = false;
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="showDates">
    <div class="relative">
      <label>{{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.LABEL') }}</label>

      <div class="flex items-center gap-2">
        <input
          v-if="!readonly && endDate"
          v-model="complete"
          class="m-0"
          type="checkbox"
          :disabled="readonly"
          @change="onCompleteToggle"
        />

        <div
          class="bg-slate-50 hover:bg-slate-100 dark:bg-slate-700 dark:hover:bg-slate-600 h-8 rounded-[5px] flex items-center px-2 cursor-pointer"
          :class="{ disabled: readonly }"
          @click="toggleDateDropdown"
        >
          <label class="date-labels pointer-events-none">
            <span v-if="readableStartDate">{{ readableStartDate }}</span>
            <span v-if="readableEndDate">{{ readableEndDate }}</span>
          </label>

          <DueDateBadge
            v-if="showDueDateBadge"
            class="dates-badge ml-2 mr-1"
            :start-date="startDate"
            :end-date="endDate"
            :complete="complete"
          />

          <fluent-icon
            v-if="!readonly"
            icon="chevron-down"
            class="ml-1 min-w-[1rem] mt-0.5"
            size="16"
          />
        </div>
      </div>

      <Dropdown
        class="edit-dates-dropdown"
        :show-dropdown="showDropdownDates"
        @close="closeDateDropdown"
      >
        <EditDates
          :board="board"
          :list="list"
          :card="card"
          :on-submit="onDatesChange"
          @close="closeDateDropdown"
          @dropdown-open="onDropdownOpen"
          @dropdown-close="onDropdownClose"
        />
      </Dropdown>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.date-labels > *:nth-child(1n + 2)::before {
  content: ' - ';
  display: inline;
}

.dates-badge {
  ::v-deep {
    .due-date-badge {
      height: 16px;
      @apply py-0 rounded-sm px-1;
      span {
        @apply pointer-events-none;
      }
    }
  }
}

.disabled {
  @apply pointer-events-none;
}

.edit-dates-dropdown ::v-deep .dropdown-pane {
  @apply fixed;
}
</style>

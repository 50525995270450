<script>
import { useAlert } from 'dashboard/composables';
import validations from './helpers/validations';
import { useVuelidate } from '@vuelidate/core';
import SettingsSection from '../../../../../../components/SettingsSection.vue';

import { mapGetters } from 'vuex';

export default {
  components: { SettingsSection },
  props: {
    bot: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      avatarFile: null,
      avatarUrl: '',
      name: '',
      description: '',
      displayName: '',
      autoResolveDuration: 0,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBots/getUIFlags',
    }),
  },
  watch: {
    bot() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  validations,
  methods: {
    setDefaults() {
      this.avatarUrl = this.bot.avatar_url;
      this.name = this.bot.name;
      this.displayName = this.bot.display_name;
      this.description = this.bot.description;
      this.autoResolveDuration = this.bot.config?.autoResolveDuration;
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async handleAvatarDelete() {
      try {
        await this.$store.dispatch('wgptBots/deleteAvatar', this.bot.id);
        this.avatarFile = null;
        this.avatarUrl = '';
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.BASIC.FORM.AVATAR.DELETE.API.SUCCESS_MESSAGE'
          )
        );
      } catch (error) {
        useAlert(
          error.message
            ? error.message
            : this.$t(
                'WGPT_BOTS.EDIT.TABS.BASIC.FORM.AVATAR.DELETE.API.ERROR_MESSAGE'
              )
        );
      }
    },
    async updateBot() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      try {
        const payload = {
          id: this.bot.id,
          name: this.name,
          display_name: this.displayName,
          description: this.description,
          config: {
            autoResolveDuration: Number(this.autoResolveDuration) || null,
          },
        };
        if (this.avatarFile) {
          payload.avatar = this.avatarFile;
          payload.isFormData = true;
        }
        await this.$store.dispatch('wgptBots/update', payload);
        useAlert(this.$t('WGPT_BOTS.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        useAlert(this.$t('WGPT_BOTS.EDIT.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<template>
  <div>
    <SettingsSection
      :title="$t('WGPT_BOTS.EDIT.TABS.BASIC.SECTIONS.INDEX.TITLE')"
      :sub-title="$t('WGPT_BOTS.EDIT.TABS.BASIC.SECTIONS.INDEX.SUB_TITLE')"
      :show-border="false"
    >
      <woot-avatar-uploader
        :label="$t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.AVATAR.LABEL')"
        :src="avatarUrl"
        class="pb-4"
        delete-avatar
        @on-avatar-select="handleImageUpload"
        @on-avatar-delete="handleAvatarDelete"
      />

      <woot-input
        v-model.trim="name"
        class="w-3/4 pb-4"
        :class="{ error: v$.name.$error }"
        :label="$t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.NAME.LABEL')"
        :placeholder="$t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.NAME.PLACEHOLDER')"
        :error="
          v$.name.$error ? $t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.NAME.ERROR') : ''
        "
        @blur="v$.name.$touch"
      />

      <woot-input
        v-model.trim="displayName"
        class="w-3/4 pb-4"
        :class="{ error: v$.displayName.$error }"
        :label="$t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.DISPLAY_NAME.LABEL')"
        :placeholder="
          $t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.DISPLAY_NAME.PLACEHOLDER')
        "
        :error="
          v$.displayName.$error
            ? $t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.DISPLAY_NAME.ERROR')
            : ''
        "
        @blur="v$.displayName.$touch"
      />

      <woot-input
        v-model.trim="description"
        class="w-3/4 pb-4"
        :class="{ error: v$.description.$error }"
        :label="$t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.DESCRIPTION.LABEL')"
        :placeholder="
          $t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.DESCRIPTION.PLACEHOLDER')
        "
        :error="
          v$.description.$error
            ? $t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.DESCRIPTION.ERROR')
            : ''
        "
        @blur="v$.description.$touch"
      />

      <woot-input
        v-model.trim="autoResolveDuration"
        type="number"
        class="w-3/4 pb-4"
        :class="{ error: v$.autoResolveDuration.$error }"
        :label="
          $t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.AUTO_RESOLVE_DURATION.LABEL')
        "
        :placeholder="
          $t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.AUTO_RESOLVE_DURATION.PLACEHOLDER')
        "
        :error="
          v$.autoResolveDuration.$error
            ? $t('WGPT_BOTS.EDIT.TABS.BASIC.FORM.AUTO_RESOLVE_DURATION.ERROR')
            : ''
        "
        @blur="v$.autoResolveDuration.$touch"
      />
    </SettingsSection>
    <SettingsSection :show-border="false">
      <woot-submit-button
        type="submit"
        :disabled="v$.$invalid || uiFlags.isUpdating"
        :button-text="$t('WGPT_BOTS.EDIT.SUBMIT')"
        :loading="uiFlags.isUpdating"
        @click="updateBot"
      />
    </SettingsSection>
  </div>
</template>

<script>
import BoardItem from './BoardItem.vue';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';
import { useAdmin } from 'dashboard/composables/useAdmin';

export default {
  components: {
    BoardItem,
    FluentIcon,
  },
  props: {
    boards: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['addBoard'],
  setup() {
    const { isAdmin } = useAdmin();
    return {
      isAdmin,
    };
  },
};
</script>

<template>
  <div
    class="p-5 border-slate-25 dark:border-slate-700 text-black-900 dark:text-slate-300"
  >
    <div class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 mb-3">
      <h4 class="text-lg font-medium text-black-900 dark:text-slate-200">
        {{ $t('WGPT_BOARDS.OVERVIEW.BOARDS.HEADER') }}
      </h4>
    </div>
    <div class="flex-[75%] flex flex-wrap gap-4">
      <router-link v-for="board in boards" :key="board.id" :to="board.toState">
        <BoardItem class="card-item" :board="board" />
      </router-link>

      <div v-if="isAdmin">
        <div
          class="card-item border-dashed h-full flex justify-center items-center rounded-xl bg-white dark:bg-slate-800/25 border border-slate-200 dark:border-slate-500 overflow-hidden cursor-pointer"
          @click="$emit('addBoard')"
        >
          <div
            class="flex font-medium flex-col items-center text-slate-600 dark:text-slate-200"
          >
            <span class="text-xs">{{
              $t('WGPT_BOARDS.ITEM.ADD_BUTTON_TEXT')
            }}</span>
            <FluentIcon icon="add" size="16" class="mt-0.5" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-item {
  @apply h-24 w-60 hover:opacity-75;
}
</style>

<script>
import { useAlert } from 'dashboard/composables';
import { mapGetters } from 'vuex';
import InboxesSelector from '../../create/InboxesSelector.vue';
import SettingsSection from '../../../../../../components/SettingsSection.vue';

export default {
  components: {
    InboxesSelector,
    SettingsSection,
  },
  props: {
    bot: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedInboxes: [],
      isCreating: false,
    };
  },
  computed: {
    ...mapGetters({
      inboxList: 'inboxes/getInboxes',
    }),
    headerDesc() {
      return this.$t('WGPT_BOTS.EDIT.TABS.INBOXES.DESC', {
        botName: this.bot.name,
      });
    },
  },
  mounted() {
    this.$store.dispatch('inboxes/get');
    this.updateSelectedInboxes(this.bot.inbox_ids);
  },
  methods: {
    updateSelectedInboxes(newInboxList) {
      this.selectedInboxes = [...newInboxList];
    },
    selectAllInboxes() {
      this.selectedInboxes = this.inboxList.map(inbox => inbox.id);
    },
    async assignInboxes() {
      this.isCreating = true;
      try {
        await this.$store.dispatch('wgptBots/update', {
          id: this.bot.id,
          inbox_ids: this.selectedInboxes,
        });
        this.$store.dispatch('wgptBots/get');
        useAlert(this.$t('WGPT_BOTS.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        useAlert(error.message);
      }
      this.isCreating = false;
    },
  },
};
</script>

<template>
  <div>
    <SettingsSection
      :title="$t('WGPT_BOTS.EDIT.TABS.INBOXES.TITLE')"
      :sub-title="headerDesc"
      :show-border="false"
    >
      <form
        class="mx-0 flex flex-wrap overflow-x-auto"
        @submit.prevent="assignInboxes"
      >
        <InboxesSelector
          :inbox-list="inboxList"
          :selected-inboxes="selectedInboxes"
          :update-selected-inboxes="updateSelectedInboxes"
          :is-working="isCreating"
          :submit-button-text="$t('WGPT_BOTS.EDIT.TABS.INBOXES.FORM.SUBMIT')"
          class="w-full bg-white dark:bg-slate-800"
        />
      </form>
    </SettingsSection>
  </div>
</template>

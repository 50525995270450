<script>
import translateMixins from '../../../mixins/translateMixins';
import dynamicField from '../dynamicField/Index.vue';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: {
    DynamicField: dynamicField,
  },
  mixins: [translateMixins],
  props: {
    outputMapping: {
      type: Object,
      default: () => {},
    },
    outputMappingFields: {
      type: Array,
      default: () => [],
    },
    // eslint-disable-next-line vue/no-unused-properties
    variables: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      updatedOutputMapping: this.outputMapping,
    };
  },
  computed: {
    showOutputMappingFields() {
      return this.outputMappingFields.length > 0;
    },
  },
  watch: {
    outputMapping: {
      handler(value) {
        this.updatedOutputMapping = value;
      },
      immediate: true,
    },
  },
  validations() {
    return {
      updatedOutputMapping: {
        duplicate: (value, { outputMappingFields, variables }) => {
          const inheritedVariables = variables.map(({ name }) => name);
          const outputMappingVariables = this.getVariables(
            value,
            outputMappingFields
          );
          const allVariables = [
            ...inheritedVariables,
            ...outputMappingVariables,
          ];
          const duplicateVariables = allVariables
            .filter(v => v)
            .filter((item, i, items) => items.indexOf(item) !== i);
          return duplicateVariables.length === 0;
        },
      },
    };
  },
  methods: {
    isVisible(field) {
      if (!field.show_if) return true;

      return field.show_if.every(({ field: fieldName, value }) => {
        const currentValue =
          this.step[fieldName] || this.getField(fieldName)?.default;
        if (!currentValue && currentValue !== 0 && currentValue !== false)
          return false;
        if (value.includes('*')) {
          return `${currentValue}`.includes(value.replace(/\*/g, ''));
        }
        return currentValue === value;
      });
    },
    getField(fieldName) {
      return this.outputMappingFields.find(({ name }) => name === fieldName);
    },
    onOutputMappingFieldChange(key, value) {
      const outputMappingBefore = this.updatedOutputMapping ?? {};
      const outputMapping = {
        ...outputMappingBefore,
        [key]: value,
      };
      this.updatedOutputMapping = outputMapping;
      this.v$.updatedOutputMapping.$touch();
      this.$emit('change', outputMapping);
    },
    getVariables(outputMapping, outputMappingFields) {
      const values = [];
      if (Array.isArray(outputMapping)) {
        outputMapping.forEach(outputMappingItem => {
          const outputMappingFieldItem =
            outputMappingFields?.controls?.[0]?.controls;
          values.push(
            ...this.getVariables(outputMappingItem, outputMappingFieldItem)
          );
        });
      } else if (typeof outputMapping === 'object') {
        Object.keys(outputMapping).forEach(key => {
          const outputMappingValue = outputMapping[key];
          const outputMappingFieldItem = outputMappingFields.find(
            ({ name }) => name === key
          );
          values.push(
            ...this.getVariables(outputMappingValue, outputMappingFieldItem)
          );
        });
      } else if (outputMappingFields.is_variable) {
        values.push(outputMapping);
      }
      return values;
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="showOutputMappingFields" class="output-mapping-fields mt-8">
    <h5 class="text-base font-medium text-black-900 dark:text-slate-200">
      {{ $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.ACTIONS.OUTPUT_MAPPING.TITLE') }}
    </h5>
    <p class="text-xs pb-4 border-b border-slate-100 dark:border-slate-800/50">
      {{
        $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.ACTIONS.OUTPUT_MAPPING.DESCRIPTION')
      }}
    </p>
    <div class="rounded-md" :class="{ error: v$.updatedOutputMapping.$error }">
      <DynamicField
        v-for="(field, index) of outputMappingFields"
        :key="index"
        :field="field"
        :value="updatedOutputMapping[field.name]"
        :visible="isVisible(field)"
        :show-variables-selector="false"
        @change="value => onOutputMappingFieldChange(field.name, value)"
      />

      <span v-if="v$.updatedOutputMapping.$error" class="message">
        <template v-if="v$.updatedOutputMapping.duplicate.$invalid">{{
          $t(
            'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.ACTIONS.OUTPUT_MAPPING.ERRORS.DUPLICATE'
          )
        }}</template>
        <template v-else>{{
          $t(
            'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.ACTIONS.OUTPUT_MAPPING.ERRORS.INVALID'
          )
        }}</template>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.name-input:focus {
  outline-color: #6cb8ff !important;
}

.error {
  @apply p-2 my-2 bg-red-50 dark:bg-red-800/50 border border-solid border-red-100 dark:border-red-700/50 mb-4;
}
</style>

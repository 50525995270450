<script>
import { mapGetters } from 'vuex';
import DatePicker from 'vue-datepicker-next';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: {
    DatePicker,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['dropdownClose', 'dropdownOpen', 'close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    const enableStartDate = !!this.card.start_date;
    const startDate = new Date(this.card.start_date);
    const startDateInput = startDate;

    const enableEndDate = !!this.card.end_date;
    const endDate = new Date(this.card.end_date);
    const endDateInput = endDate;

    return {
      startDateInput,
      enableStartDate,
      endDateInput,
      enableEndDate,
      showStartDateDropdown: false,
      showEndDateDropdown: false,
    };
  },
  validations: {
    startDateInput: {
      isEarlierThanEndDate(value) {
        if (!this.enableStartDate) return true;
        if (!this.enableEndDate) return true;
        if (!this.endDateInput) return true;
        const endDate = new Date(this.endDateInput);
        const startDateExact = new Date(value);
        const startDate = new Date(
          startDateExact.getFullYear(),
          startDateExact.getMonth(),
          startDateExact.getDate()
        );
        return startDate < endDate;
      },
    },
    endDateInput: {
      isLaterThanStartDate(value) {
        if (!this.enableEndDate) return true;
        if (!this.enableStartDate) return true;
        if (!this.startDateInput) return true;
        const startDateExact = new Date(this.startDateInput);
        const startDate = new Date(
          startDateExact.getFullYear(),
          startDateExact.getMonth(),
          startDateExact.getDate()
        );
        const endDate = new Date(value);
        return endDate > startDate;
      },
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardListCards/getUIFlags',
    }),
  },
  watch: {
    enableStartDate(value) {
      if (!value) return;
      let startDateInput;
      if (this.endDateInput) {
        const endDate = new Date(this.endDateInput);
        startDateInput = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate() - 1
        );
      } else {
        startDateInput = new Date();
      }
      this.startDateInput = startDateInput;
    },
    enableEndDate(value) {
      if (!value) return;
      let endDateInput;
      if (this.enableStartDate) {
        const startDate = new Date(this.startDateInput);
        endDateInput = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + 1,
          8
        );
      } else {
        const now = new Date();
        endDateInput = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1,
          8
        );
      }
      this.endDateInput = endDateInput;
    },
    startDateInput(value) {
      if (!this.enableEndDate) return;
      const startDateExact = new Date(value);
      const startDate = new Date(
        startDateExact.getFullYear(),
        startDateExact.getMonth(),
        startDateExact.getDate()
      );
      const endDate = new Date(this.endDateInput);
      if (startDate < endDate) return;
      this.enableEndDate = false;
    },
    endDateInput(value) {
      if (!this.enableStartDate) return;
      const endDate = new Date(value);
      const startDateExact = new Date(this.startDateInput);
      const startDate = new Date(
        startDateExact.getFullYear(),
        startDateExact.getMonth(),
        startDateExact.getDate()
      );
      if (endDate > startDate) return;
      this.enableStartDate = false;
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onKeydown);
    if (!this.enableStartDate && !this.enableEndDate) {
      this.enableEndDate = true;
    }
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    changeDates() {
      this.v$.endDateInput.$touch();
      if (this.v$.endDateInput.$error) return;

      const data = {
        start_date: this.enableStartDate ? new Date(this.startDateInput) : null,
        end_date: this.enableEndDate ? new Date(this.endDateInput) : null,
      };
      if (!this.enableStartDate && !this.enableEndDate) {
        data.complete = false;
      }
      this.$emit('close');
      this.onSubmit(data);
    },
    removeDates() {
      const data = {
        start_date: null,
        end_date: null,
        complete: false,
      };
      this.$emit('close');
      this.onSubmit(data);
    },
    onStartDateChange(value) {
      this.startDateInput = new Date(value);
    },
    onEndDateChange(value) {
      this.endDateInput = new Date(value);
    },
    openStartDateDropdown() {
      this.showStartDateDropdown = true;
      this.$emit('dropdownOpen');
    },
    hideStartDateDropdown() {
      this.showStartDateDropdown = false;
      this.$emit('dropdownClose');
    },
    openEndDateDropdown() {
      this.showEndDateDropdown = true;
      this.$emit('dropdownOpen');
    },
    hideEndDateDropdown() {
      this.showEndDateDropdown = false;
      this.$emit('dropdownClose');
    },
    onKeydown(e) {
      if (e.key === 'Escape') {
        this.hideEndDateDropdown();
        this.hideStartDateDropdown();
      }
    },
  },
};
</script>

<template>
  <div>
    <div :class="{ error: v$.startDateInput.$error }">
      <div class="flex items-center justify-center mb-1">
        <h4
          class="text-sm text-slate-800 dark:text-slate-100 m-0 overflow-hidden whitespace-nowrap text-ellipsis flex-grow"
        >
          {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.LABEL') }}
        </h4>
      </div>

      <div>
        <div class="flex items-center mb-0 gap-2">
          <input v-model="enableStartDate" class="m-0" type="checkbox" />
          <DatePicker
            v-if="enableStartDate"
            class="date-picker"
            type="date"
            confirm
            :clearable="false"
            :editable="false"
            :open="showStartDateDropdown"
            :confirm-text="
              $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.CONFIRM')
            "
            :placeholder="
              $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.PLACEHOLDER')
            "
            :value="startDateInput"
            :append-to-body="false"
            @change="onStartDateChange"
            @open="openStartDateDropdown"
            @confirm="hideStartDateDropdown"
          >
            <template #footer>
              <div
                class="modal-mask z-[-1] !bg-transparent cursor-pointer"
                @click="hideStartDateDropdown"
              />
            </template>
          </DatePicker>
          <div
            v-else
            class="bg-slate-50 dark:bg-slate-700 h-8 px-3 rounded-[5px] flex items-center w-56"
          >
            <label class="text-slate-500 dark:text-slate-600">
              {{
                $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.DISABLED')
              }}
            </label>
          </div>
        </div>
        <span v-if="v$.startDateInput.$error" class="message !mb-0 pl-5">
          <template v-if="!v$.startDateInput.isLaterThanStartDate">{{
            $t(
              'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.IS_LATER_THEN_START_DATE'
            )
          }}</template>
          <template v-else>{{
            $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.ERROR')
          }}</template>
        </span>
      </div>
    </div>

    <div class="mt-4" :class="{ error: v$.endDateInput.$error }">
      <div class="flex items-center justify-center mb-1">
        <h4
          class="text-sm text-slate-800 dark:text-slate-100 m-0 overflow-hidden whitespace-nowrap text-ellipsis flex-grow"
        >
          {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.LABEL') }}
        </h4>
      </div>

      <div>
        <div class="flex items-center mb-0 gap-2">
          <input v-model="enableEndDate" class="m-0" type="checkbox" />
          <DatePicker
            v-if="enableEndDate"
            class="date-picker"
            type="datetime"
            confirm
            :clearable="false"
            :editable="false"
            :open="showEndDateDropdown"
            :confirm-text="
              $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.CONFIRM')
            "
            :placeholder="
              $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.PLACEHOLDER')
            "
            :value="endDateInput"
            :append-to-body="false"
            @change="onEndDateChange"
            @open="openEndDateDropdown"
            @confirm="hideEndDateDropdown"
          >
            <template #footer>
              <div
                class="modal-mask z-[-1] !bg-transparent cursor-pointer"
                @click="hideEndDateDropdown"
              />
            </template>
          </DatePicker>
          <div
            v-else
            class="bg-slate-50 dark:bg-slate-700 h-8 px-3 rounded-[5px] flex items-center w-56"
          >
            <label class="text-slate-500 dark:text-slate-600">
              {{
                $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.DISABLED')
              }}
            </label>
          </div>
        </div>
        <span v-if="v$.endDateInput.$error" class="message !mb-0 pl-5">
          <template v-if="!v$.endDateInput.isLaterThanStartDate">{{
            $t(
              'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.IS_LATER_THEN_START_DATE'
            )
          }}</template>
          <template v-else>{{
            $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.ERROR')
          }}</template>
        </span>
      </div>
    </div>

    <div class="gap-2 w-full flex pt-5 justify-end">
      <woot-button
        :is-disabled="
          uiFlags.isUpdating || showEndDateDropdown || showStartDateDropdown
        "
        class="button clear"
        color-scheme="secondary"
        size="small"
        @click.prevent="removeDates"
      >
        {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.FORM.REMOVE') }}
      </woot-button>
      <woot-button
        :is-disabled="
          uiFlags.isUpdating || showEndDateDropdown || showStartDateDropdown
        "
        :is-loading="uiFlags.isUpdating"
        size="small"
        @click="changeDates"
      >
        {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.FORM.SAVE') }}
      </woot-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.date-picker {
  @apply w-56;
  ::v-deep {
    input[name='date'] {
      @apply rounded-md dark:bg-slate-900 dark:text-slate-200 text-sm border border-slate-500 p-2 m-0 h-8;
    }
  }
}
[dir='rtl'] .date-picker {
  ::v-deep {
    .mx-icon-calendar {
      right: unset;
      left: 8px;
    }
  }
}
</style>

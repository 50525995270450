<script>
import { mapGetters } from 'vuex';
import GalleryView from 'dashboard/components/widgets/conversation/components/GalleryView.vue';
import { ALLOWED_FILE_TYPES } from '../../helpers/contants';
import canDismissMixin from '../../mixins/preventDismissMixin';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import { emitter } from 'shared/helpers/mitt';

export default {
  components: {
    GalleryView,
  },
  mixins: [canDismissMixin, keyboardEventListenerMixins],
  props: {
    attachment: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isUploading: false,
      isContextMenuOpen: false,
      showGalleryViewer: false,
    };
  },
  computed: {
    ...mapGetters({}),
    isImage() {
      return this.attachment.file_type.startsWith('image');
    },
    isVideo() {
      return this.attachment.file_type.startsWith('video');
    },
    isAudio() {
      return this.attachment.file_type.startsWith('audio');
    },
    galleryAttachment() {
      const data_url = this.attachment.file_url;
      const message_id = Math.floor(Math.random() * 100);
      if (this.isImage) {
        return {
          file_type: ALLOWED_FILE_TYPES.IMAGE,
          data_url,
          message_id,
        };
      }
      if (this.isVideo) {
        return {
          file_type: ALLOWED_FILE_TYPES.VIDEO,
          data_url,
          message_id,
        };
      }
      if (this.isAudio) {
        return {
          file_type: ALLOWED_FILE_TYPES.AUDIO,
          data_url,
          message_id,
        };
      }
      return {
        file_type: this.attachment.file_type,
        data_url,
        message_id,
      };
    },
    galleryAllAttachments() {
      return [this.attachment];
    },
    extensionName() {
      return this.attachment.filename.split('.').pop();
    },
    preventDismiss() {
      return this.showGalleryViewer;
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    onContextMenuOpen() {
      this.isContextMenuOpen = true;
    },
    onContextMenuClose() {
      this.isContextMenuOpen = false;
    },
    openImagePreview() {
      if (this.isImage || this.isVideo || this.isAudio) {
        this.showGalleryViewer = true;
        emitter.emit('wgptBoardListCardPopupToggle', true);
        return;
      }
      const link = document.createElement('a');
      link.href = this.attachment.file_url;
      link.target = '_blank';
      link.click();
    },
    onGalleryClose() {
      this.showGalleryViewer = false;
      emitter.emit('wgptBoardListCardPopupToggle', false);
    },
    actionClicked(action, ev) {
      ev.preventDefault();
      this.$emit(action, this.attachment);
    },
    onKeyDown(e) {
      if (e.code === 'Escape') {
        this.onGalleryClose();
      }
    },
    getKeyboardEvents() {
      return {
        Escape: this.onGalleryClose,
      };
    },
  },
};
</script>

<template>
  <div
    v-tooltip.top="{
      content: attachment.filename,
      container: false,
      delay: {
        show: 250,
      },
    }"
  >
    <div
      class="attachment w-28 h-20 rounded-md overflow-hidden bg-slate-50 dark:bg-white cursor-pointer flex items-center justify-center relative shrink-0 shadow text-truncate"
    >
      <div class="z-10 contextmenu absolute top-1 right-1">
        <woot-button
          class="!w-7 !h-7 dark:!bg-slate-800 dark:hover:!bg-slate-900"
          color-scheme="secondary"
          icon="download"
          size="small"
          @click="e => actionClicked('download', e)"
        />
        <woot-button
          v-if="!readonly"
          class="!w-7 !h-7 dark:!bg-slate-800 dark:hover:!bg-slate-900"
          color-scheme="secondary"
          icon="delete"
          size="small"
          @click="e => actionClicked('delete', e)"
        />
      </div>
      <div
        class="backdrop bg-slate-500 dark:bg-slate-800 absolute inset-0 opacity-25"
        @click="openImagePreview"
      />

      <template v-if="isImage">
        <img :src="attachment.file_url" />
      </template>

      <template v-else>
        <div class="truncate text-2xl font-bold px-3">
          {{ extensionName }}
        </div>
      </template>

      <footer
        class="absolute left-0 right-0 bottom-0 bg-white dark:bg-slate-900 py-1 px-2 leading-none overflow-hidden truncate pointer-events-none"
      >
        <span class="text-xs">{{ attachment.filename }}</span>
      </footer>

      <GalleryView
        v-if="showGalleryViewer"
        v-model:show="showGalleryViewer"
        class="gallery-view"
        :attachment="galleryAttachment"
        :all-attachments="galleryAllAttachments"
        @error="onGalleryClose"
        @close="onGalleryClose"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.attachment {
  .contextmenu,
  .backdrop {
    @apply transition-opacity;
  }
  &:not(:hover) {
    .contextmenu,
    .backdrop {
      opacity: 0;
    }
  }
}

.gallery-view v::deep {
  .modal-container {
    @apply w-auto;
  }
}
</style>

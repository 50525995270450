import { frontendURL } from '../../../helper/URLHelper';
import BoardsHome from './overview/Index.vue';
import Board from './item/Index.vue';
import Index from './Index.vue';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/wgpt-boards'),
      component: Index,
      children: [
        {
          path: '',
          name: 'boards_overview',
          component: BoardsHome,
          meta: {
            permissions: ['administrator', 'agent'],
          },
        },
        {
          path: ':boardId',
          name: 'board_item',
          component: Board,
          meta: {
            permissions: ['administrator', 'agent'],
          },
        },
      ],
      props: route => {
        return {
          showSearchbox: route.name === 'board_item',
          headerTitle:
            route.name === 'boards_overview'
              ? 'WGPT_BOARDS.OVERVIEW.HEADER'
              : '',
        };
      },
    },
  ],
};

<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    showSettingsButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openSettings'],
  computed: {
    menuTitle() {
      return this.label;
    },
  },
  methods: {
    showOpenBoardSettingsPopup(e) {
      e.preventDefault();
      this.$emit('openSettings');
    },
  },
};
</script>

<template>
  <router-link
    v-slot="{ href, isActive, navigate }"
    :to="to"
    custom
    active-class="active"
  >
    <li
      class="font-medium h-7 my-1 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md dark:hover:bg-slate-800"
      :class="{
        'bg-woot-25 dark:bg-slate-800': isActive,
      }"
      @click="navigate"
    >
      <a
        :href="href"
        class="inline-flex text-left max-w-full w-full items-center"
      >
        <div
          class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"
        >
          <span
            :title="menuTitle"
            class="text-sm text-slate-700 dark:text-slate-100 overflow-hidden text-truncate"
            :class="{
              'text-woot-500 dark:text-woot-500': isActive,
            }"
          >
            {{ label }}
          </span>
          <woot-button
            v-if="showSettingsButton"
            class="board-settings-button"
            icon="settings"
            color-scheme="secondary"
            variant="clear"
            size="small"
            @click="e => showOpenBoardSettingsPopup(e)"
          />
        </div>
      </a>
    </li>
  </router-link>
</template>

<style lang="scss" scoped>
li:not(:hover) .board-settings-button {
  @apply hidden;
}
</style>

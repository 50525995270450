<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import AddSourceForm from './AddSourceForm.vue';

export default {
  components: {
    AddSourceForm,
  },
  emits: ['close'],
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBotSources/getUIFlags',
    }),
  },
  methods: {
    async createSource(data) {
      const botId = this.$route.params.botId;
      try {
        const source = await this.$store.dispatch('wgptBotSources/create', {
          botId,
          ...data,
        });

        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CREATE.API.SUCCESS_MESSAGE'
          )
        );
        this.$emit('close', source);
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CREATE.API.ERROR_MESSAGE'
          )
        );
      }
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CREATE.TITLE')
      "
    />

    <AddSourceForm
      :on-submit="createSource"
      :on-close="onClose"
      :submit-in-progress="uiFlags.isCreating"
      :cancel-button-text="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CREATE.CANCEL')
      "
      :submit-button-text="
        $t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CREATE.SAVE')
      "
    />
  </div>
</template>

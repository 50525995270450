<script>
import { mapGetters } from 'vuex';
import FilterItem from './FilterItem.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';
import Dropdown from '../Dropdown.vue';
import MultiselectDropdown from '../MultiselectDropdown.vue';
import { FILTER_ITEM_MAX_LENGTH } from './helpers/constant';

export default {
  components: {
    FilterItem,
    Thumbnail,
    FluentIcon,
    Dropdown,
    MultiselectDropdown,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    memberIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      updatedValue: this.modelValue,
      showSearchDropdown: false,
      event: {},
    };
  },
  computed: {
    ...mapGetters({
      agents: 'agents/getAgents',
    }),
    allMembers() {
      return this.agents.filter(({ id }) => {
        return this.memberIds.includes(id);
      });
    },
    selectedMembers() {
      return this.allMembers.filter(({ id }) => {
        return this.updatedValue.includes(id);
      });
    },
    members() {
      return this.allMembers.slice(0, FILTER_ITEM_MAX_LENGTH);
    },
    unloadedMembers() {
      return this.updatedValue.filter(
        id => !this.members.find(member => member.id === id)
      );
    },
    hasAnyItem() {
      return this.members.length > 0;
    },
    showBadge() {
      return this.unloadedMembers.length > 0;
    },
    showMoreOptions() {
      return this.allMembers.length > FILTER_ITEM_MAX_LENGTH;
    },
  },
  watch: {
    updatedValue(value) {
      this.$emit('update:modelValue', value);
    },
    modelValue(value, oldValue) {
      if (value.join() === oldValue.join()) return;
      this.updatedValue = value;
    },
  },
  methods: {
    toggleDropdown(e) {
      this.event = e;
      this.showSearchDropdown = !this.showSearchDropdown;
    },
    closeDropdown() {
      this.showSearchDropdown = false;
    },
    addItem({ id }) {
      this.updatedValue.push(id);
    },
    removeItem({ id }) {
      this.updatedValue = this.updatedValue.filter(value => value !== id);
    },
  },
};
</script>

<template>
  <div v-show="hasAnyItem">
    <label class="text-sm mb-2 text-slate-600 dark:text-slate-400">{{
      $t('WGPT_BOARDS.FILTER.MEMBERS.TITLE')
    }}</label>
    <FilterItem v-for="member in members" :key="member.id" class="group py-1.5">
      <template #start>
        <input
          v-model="updatedValue"
          type="checkbox"
          :value="member.id"
          class="group-hover:bg-slate-50 group-hover:dark:bg-slate-800/50 me-1"
        />
      </template>
      <div class="flex flex-row justify-between">
        <Thumbnail
          :src="member.thumbnail"
          :username="member.name"
          size="24px"
        />
      </div>
      <span class="truncate">{{ member.name }}</span>
    </FilterItem>

    <woot-button
      v-if="showMoreOptions"
      variant="link"
      class-names="!ms-6 !mt-2 [&>*]:pointer-events-none"
      @click="toggleDropdown"
    >
      <div class="flex items-center gap-2">
        <div v-if="showBadge" class="w-[24px] flex justify-center">
          <div class="badge">
            <span>
              {{ unloadedMembers.length }}
            </span>
          </div>
        </div>
        {{ $t('WGPT_BOARDS.FILTER.BUTTON_TXT.SHOW_MORE_OPTIONS') }}
        <FluentIcon icon="chevron-down" size="18" />
      </div>
    </woot-button>

    <Dropdown
      :show-dropdown="showSearchDropdown"
      :event="event"
      @close="closeDropdown"
    >
      <MultiselectDropdown
        class="p-2"
        :options="allMembers"
        :selected-items="selectedMembers"
        :multiselector-title="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.TITLE'
          )
        "
        :multiselector-placeholder="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.PLACEHOLDER'
          )
        "
        :no-search-result="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.NO_RESULT'
          )
        "
        :input-placeholder="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.MULTI_SELECTOR.INPUT_PLACEHOLDER'
          )
        "
        @add="addItem"
        @remove="removeItem"
        @close="closeDropdown"
        @toggle="toggleDropdown"
      />
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
.badge {
  @apply inline-block bg-slate-50 dark:bg-woot-800 rounded-md text-slate-600 dark:text-slate-100 h-5 text-xxs font-semibold px-1 min-w-[16px] text-center;
}
</style>

<script>
import { mapGetters } from 'vuex';
import Draggable from 'vuedraggable';
import AddList from './AddList.vue';
import EditList from './EditList.vue';
import DeleteList from './DeleteList.vue';
import EditCardModal from './card/edit/Index.vue';
import ListItem from './Item.vue';
import { useAlert } from 'dashboard/composables';
import { useAdmin } from 'dashboard/composables/useAdmin';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import { useUISettings } from 'dashboard/composables/useUISettings';
import { emitter } from 'shared/helpers/mitt';

export default {
  components: {
    Draggable,
    ListItem,
    AddList,
    EditList,
    DeleteList,
    EditCardModal,
    EmptyState,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { uiSettings, updateUISettings } = useUISettings();
    const { isAdmin } = useAdmin();
    return {
      uiSettings,
      updateUISettings,
      isAdmin,
    };
  },
  data() {
    return {
      loading: {},
      showAddListPopup: false,
      showEditListPopup: false,
      showDeleteListConfirmationPopup: false,
      showEditCardModalPopup: false,
      preventEditCardModelPopupDismissal: false,
      selectedList: {},
      drag: false,
      now: new Date(),
    };
  },
  computed: {
    ...mapGetters({
      allLabels: 'labels/getLabels',
      allAgents: 'agents/getAgents',
      currentUserID: 'getCurrentUserID',
      selectedCard: 'wgptBoardListCards/getActiveCard',
    }),
    boardId() {
      return this.board.id;
    },
    list: {
      get() {
        return this.$store.getters['wgptBoardLists/getLists'];
      },
      set(data) {
        this.$store.dispatch('wgptBoardLists/setBoardLists', data);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        ghostClass: 'ghost',
      };
    },
    canManageLists() {
      if (this.board.closed) return false;
      if (this.isAdmin) return true;
      const managers = this.board?.config?.authorization?.managers ?? [];
      if (managers.includes(this.currentUserID)) return true;
      return false;
    },
    canManageCards() {
      if (this.board.closed) return false;
      if (this.isAdmin) return true;
      const managers = this.board?.config?.authorization?.managers ?? [];
      if (managers.includes(this.currentUserID)) return true;
      const operators = this.board?.config?.authorization?.operators ?? [];
      if (operators.includes(this.currentUserID)) return true;
      return false;
    },
    canDeleteCards() {
      if (this.board.closed) return false;
      if (this.isAdmin) return true;
      const managers = this.board?.config?.authorization?.managers ?? [];
      if (managers.includes(this.currentUserID)) return true;
      return false;
    },
    wgptMinimizeBoardListCardLabels() {
      return this.uiSettings.wgpt_minimize_board_list_card_labels ?? true;
    },
    showEditCardModalPopupModel: {
      get() {
        if (!this.selectedCard.id) return false;
        return (
          this.showEditCardModalPopup || this.preventEditCardModelPopupDismissal
        );
      },
      set(value) {
        this.showEditCardModalPopup =
          value || this.preventEditCardModelPopupDismissal;
      },
    },
    selectedCardId() {
      if (!this.boardId) return null;
      const selectedCardId = this.$route.query.selectedCard;
      if (!selectedCardId) return null;
      return Number(selectedCardId);
    },
  },
  watch: {
    boardId: {
      handler() {
        this.fetchLists();
      },
      immediate: true,
    },
    selectedCardId: {
      handler(id) {
        if (id) {
          this.fetchSelectedCard();
          this.openEditCardModalPopup();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    emitter.on('wgptBoardListCardPreventDismiss', prevent => {
      this.preventEditCardModelPopupDismissal = prevent;
    });
  },
  beforeUnmount() {
    emitter.off('wgptBoardListCardPopupToggle');
  },
  methods: {
    fetchLists() {
      if (!this.boardId) return;
      this.$store.dispatch('wgptBoardLists/clear');
      this.$store.dispatch('wgptBoardListCards/clear');
      this.$store.dispatch('wgptBoardLists/get', this.boardId);
    },
    fetchSelectedCard() {
      const cachedCard = this.$store.getters['wgptBoardListCards/getCard'](
        this.selectedCardId
      );
      if (cachedCard) {
        this.$store.dispatch('wgptBoardListCards/setActiveCard', cachedCard);
        return;
      }
      const getCardOptions = {
        boardId: this.boardId,
        id: this.selectedCardId,
      };
      this.$store.dispatch(
        'wgptBoardListCards/loadSelectedCard',
        getCardOptions
      );
    },
    async onListOrderChange({ moved: event }) {
      try {
        const { element: listItem, oldIndex, newIndex } = event;
        const list = this.list;
        const index = this.list.findIndex(({ id }) => id === listItem.id);

        let order = newIndex + 1;
        if (newIndex > oldIndex) {
          const previousListItem = list[index - 1];
          order = previousListItem.order;
        } else if (newIndex < oldIndex) {
          const nextListItem = list[index + 1];
          order = nextListItem.order;
        }

        const boardList = {
          order,
        };
        const boardId = this.$route.params.boardId;
        const id = listItem.id;
        await this.$store.dispatch('wgptBoardLists/update', {
          boardId,
          id,
          ...boardList,
        });
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('WGPT_BOARDS.EDIT_BOARD_LIST.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    openAddListPopup() {
      this.showAddListPopup = true;
    },
    hideAddListPopup() {
      this.showAddListPopup = false;
    },
    openEditListPopup(list) {
      this.selectedList = list;
      this.showEditListPopup = true;
    },
    hideEditListPopup() {
      this.showEditListPopup = false;
    },
    openDeleteListPopup() {
      this.hideEditListPopup();
      this.showDeleteListConfirmationPopup = true;
    },
    hideDeleteListPopup() {
      this.showDeleteListConfirmationPopup = false;
    },
    openEditCardModalPopup() {
      this.showEditCardModalPopup = true;
    },
    hideEditCardModalPopup() {
      if (this.preventEditCardModelPopupDismissal) return;
      this.showEditCardModalPopup = false;
      this.now = new Date();
      this.$router.push({ query: { selectedCard: undefined } });
    },
    toggleLabelLayout() {
      const previousState =
        this.uiSettings.wgpt_minimize_board_list_card_labels ?? true;
      this.updateUISettings({
        wgpt_minimize_board_list_card_labels: !previousState,
      });
    },
  },
};
</script>

<template>
  <div
    class="h-full w-max min-w-full flex gap-4 px-4 py-8"
    :class="{
      wgptMinimizeBoardListCardLabels: wgptMinimizeBoardListCardLabels,
    }"
  >
    <Draggable
      v-if="list.length > 0"
      v-model="list"
      item-key="id"
      class="flex rtl:flex-row-reverse gap-4 items-start"
      v-bind="dragOptions"
      group="lists"
      :disabled="!canManageLists"
      force-fallback
      :fallback-tolerance="20"
      dir="ltr"
      @start="drag = true"
      @end="drag = false"
      @change="onListOrderChange"
    >
      <template #item="{ element: listItem }">
        <ListItem
          :list="listItem"
          :can-manage-cards="canManageCards"
          :can-manage-lists="canManageLists"
          :labels="allLabels"
          :agents="allAgents"
          :now="now"
          @edit="openEditListPopup(listItem)"
          @toggle-label-layout="toggleLabelLayout"
        />
      </template>
    </Draggable>

    <div v-else-if="!canManageLists" class="flex-1 overflow-auto">
      <EmptyState class="py-11" :title="$t('WGPT_BOARDS.LIST_EMPTY_MESSAGE')" />
    </div>

    <div
      v-if="canManageLists"
      class="filtered add-board-list-item rounded-lg text-slate-700 dark:text-slate-100 bg-slate-50 dark:bg-slate-900 border border-slate-300 dark:border-slate-500 h-max overflow-hidden"
    >
      <div
        v-if="showAddListPopup"
        v-on-clickaway="hideAddListPopup"
        class="text-slate-700 dark:text-slate-100 bg-white dark:bg-slate-800 p-2"
      >
        <AddList @close="hideAddListPopup" />
      </div>
      <woot-button
        v-else-if="canManageCards"
        class="button clear px-2 w-full"
        variant="clear"
        color-scheme="secondary"
        icon="add"
        @click="openAddListPopup"
      >
        {{ $t('WGPT_BOARDS.ITEM.LIST.BUTTON_TEXT.ADD_LIST') }}
      </woot-button>
    </div>

    <woot-modal v-model:show="showEditListPopup" @close="hideEditListPopup">
      <EditList
        :board="board"
        :list="selectedList"
        :can-manage-lists="canManageLists"
        @close="hideEditListPopup"
        @delete="openDeleteListPopup"
      />
    </woot-modal>

    <woot-modal
      v-model:show="showDeleteListConfirmationPopup"
      @close="hideDeleteListPopup"
    >
      <DeleteList
        :board="board"
        :list="selectedList"
        @close="hideDeleteListPopup"
      />
    </woot-modal>

    <woot-modal
      v-model:show="showEditCardModalPopupModel"
      class="edit-card-woot-modal"
      @close="hideEditCardModalPopup"
    >
      <EditCardModal
        :card="selectedCard"
        :can-manage-cards="canManageCards"
        :can-delete-cards="canDeleteCards"
        @close="hideEditCardModalPopup"
      />
    </woot-modal>
  </div>
</template>

<style lang="scss" scoped>
.add-board-list-item {
  width: 300px;
}

.edit-card-woot-modal {
  @apply overflow-auto flex-wrap pt-14 pb-28;
  ::v-deep {
    .modal-container {
      @apply w-max max-h-none overflow-visible;
    }
  }
}

.wgptMinimizeBoardListCardLabels ::v-deep .board-card-label {
  height: 8px;
  width: 42px;
  span {
    display: none;
  }
}
</style>

<script>
import translateMixins from '../../../../mixins/translateMixins';
import AddStepButton from '../../../components/addStepDropdown/AddStepButton.vue';
import conditionValidationMixins from './helpers/conditionValidationMixins';
import Dropdown from '../../Dropdown.vue';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: {
    AddStepButton,
    Dropdown,
  },
  mixins: [translateMixins, conditionValidationMixins],
  props: {
    step: {
      type: Object,
      default: () => {},
    },
    first: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['stepDelete', 'addStepBefore'],
  setup() {
    return { v$: useVuelidate() };
  },
};
</script>

<template>
  <div class="condition mx-auto w-max">
    <div v-if="first" class="connector-top">
      <AddStepButton class="my-4" @add-step="e => $emit('addStepBefore', e)" />
    </div>

    <Dropdown class="mx-auto w-max">
      <router-link
        :to="{ query: { selected: step.id } }"
        :class="{
          'step-active': $route.query.selected === step.id,
        }"
        replace
      >
        <div class="condition-content" :class="{ error: v$.step.$error }">
          <div
            class="py-2 px-3 border-b border-slate-100 dark:border-slate-600 font-semibold text-sm"
          >
            {{
              step.name ||
              $t('WGPT_AUTOMATIONS.EDITOR.BRANCH.FALLBACK_CONDITION_NAME')
            }}
          </div>
          <div class="py-2 px-3">
            <p class="m-0 text-xs">
              {{ $t('WGPT_AUTOMATIONS.REGISTRY.ACTIONS.BRANCH.NAME') }}
            </p>
          </div>
        </div>
      </router-link>

      <template #popper>
        <div class="flex gap-1">
          <div
            class="bg-white dark:bg-slate-700 rounded-md shadow-sm border border-slate-100 dark:border-slate-600"
          >
            <woot-button
              class="!w-7 !h-7 dark:!bg-slate-800 dark:hover:!bg-slate-900"
              color-scheme="secondary"
              icon="delete"
              size="small"
              variant="clear"
              @click="$emit('stepDelete', step.id)"
            />
          </div>
        </div>
      </template>
    </Dropdown>

    <div class="connector-bottom" />

    <div class="branches">
      <slot name="branch" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.step-active > .condition-content:not(.error) {
  @apply outline-none;
  outline-color: var(--branchThemeColor);
  outline-offset: 1px;
}

.condition-content.error {
  @apply outline-none outline-red-500 dark:outline-red-300;
  outline-offset: 1px;
}

.condition-content {
  @apply w-[200px] mx-2 relative bg-white dark:bg-slate-700 rounded-md shadow-sm cursor-pointer border border-slate-400/75 dark:border-slate-600;
}

[dir='ltr'] {
  .connector-top {
    @apply flex flex-col items-end w-1/2 pointer-events-none rounded-none border-r-[1px] border-dashed border-slate-400 dark:border-slate-600;
    --transform: translateX(50%);
  }

  .connector-bottom {
    @apply flex flex-col items-end w-1/2 pointer-events-none rounded-none h-8 border-r-[1px] border-dashed border-slate-400 dark:border-slate-600;
  }

  .branches {
    @apply flex justify-center;
    ::v-deep {
      > :first-child {
        > .connector-bridge {
          @apply hidden;
        }
        > .connector-top {
          @apply ms-auto items-start rounded-tl-2xl border-l-[1px] border-t-[1px];
          --transform: translateX(-50%);
        }
        > .connector-bottom {
          @apply ms-auto items-start border-l-[1px];
          --transform: translateX(-50%);
        }
      }
      > :not(:first-child, :last-child) {
        > .connector-top {
          @apply border-r-[1px];
        }
      }
      > :last-child {
        > .connector-bridge {
          @apply hidden;
        }
        > .connector-top {
          @apply me-auto items-end rounded-tr-2xl border-r-[1px] border-t-[1px];
          --transform: translateX(50%);
        }
        > .connector-bottom {
          @apply me-auto items-end border-r-[1px];
          --transform: translateX(50%);
        }
      }
    }
  }
}

[dir='rtl'] {
  .connector-top {
    @apply flex flex-col items-end w-1/2 pointer-events-none rounded-none border-l-[1px] border-dashed border-slate-400 dark:border-slate-600;
    --transform: translateX(-50%);
  }

  .connector-bottom {
    @apply flex flex-col items-end w-1/2 pointer-events-none rounded-none h-8 border-l-[1px] border-dashed border-slate-400 dark:border-slate-600;
  }
  .branches {
    @apply flex justify-center;
    ::v-deep {
      > :first-child {
        > .connector-bridge {
          @apply hidden;
        }
        > .connector-top {
          @apply ms-auto items-start rounded-tr-2xl border-s-[1px] border-t-[1px];
          --transform: translateX(50%);
        }
        > .connector-bottom {
          @apply ms-auto items-start border-s-[1px];
          --transform: translateX(50%);
        }
      }
      > :not(:first-child, :last-child) {
        > .connector-top {
          @apply border-l-[1px];
        }
      }
      > :last-child {
        > .connector-bridge {
          @apply hidden;
        }
        > .connector-top {
          @apply me-auto items-end rounded-tl-2xl border-e-[1px] border-t-[1px];
          --transform: translateX(-50%);
        }
        > .connector-bottom {
          @apply me-auto items-end border-e-[1px];
          --transform: translateX(-50%);
        }
      }
    }
  }
}
</style>

<script>
import { useAlert } from 'dashboard/composables';
import WorkflowNodes from './Workflows.vue';
import AddWorkflowModal from './components/create/Index.vue';
import EditWorkflowModal from './components/edit/Index.vue';
import AddStageModal from './components/stage/create/Index.vue';
import EditStageModal from './components/stage/edit/Index.vue';

export default {
  components: {
    WorkflowNodes,
    AddWorkflowModal,
    EditWorkflowModal,
    AddStageModal,
    EditStageModal,
  },
  props: {
    bot: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showAddWorkflowModal: false,
      showEditWorkflowModal: false,
      selectedWorkflow: {},
      showDeleteWorkflowConfirmationPopup: false,
      showAddStageModal: false,
      showEditStageModal: false,
      selectedStage: {},
      showDeleteStageConfirmationPopup: false,
    };
  },
  computed: {
    deleteStageMessage() {
      return ` ${this.selectedStage.name}?`;
    },
    deleteWorkflowMessage() {
      return ` ${this.selectedWorkflow.name}?`;
    },
  },
  mounted() {
    this.$store.dispatch('wgptBotWorkflowStages/clear');
  },
  methods: {
    showAddWorkflowPopup() {
      this.showAddWorkflowModal = true;
    },
    hideAddWorkflowPopup() {
      this.showAddWorkflowModal = false;
    },
    showEditWorkflowPopup(selectedWorkflow) {
      this.selectedWorkflow = selectedWorkflow;
      this.showEditWorkflowModal = true;
    },
    hideEditWorkflowPopup() {
      this.showEditWorkflowModal = false;
    },
    showDeleteWorkflowPopup(selectedWorkflow) {
      this.selectedWorkflow = selectedWorkflow;
      this.showDeleteWorkflowConfirmationPopup = true;
    },
    closeDeleteWorkflowPopup() {
      this.showDeleteWorkflowConfirmationPopup = false;
    },
    async confirmWorkflowDeletion() {
      this.closeDeleteWorkflowPopup();
      try {
        const botId = this.$route.params.botId;
        const id = this.selectedWorkflow.id;

        await this.$store.dispatch('wgptBotWorkflows/delete', { botId, id });

        useAlert(
          this.$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.DELETE.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.DELETE.API.ERROR_MESSAGE')
        );
      }
    },

    showAddStagePopup(selectedWorkflow) {
      this.selectedWorkflow = selectedWorkflow;
      this.showAddStageModal = true;
    },
    hideAddStagePopup() {
      this.showAddStageModal = false;
    },
    showEditStagePopup(selectedStage, selectedWorkflow) {
      this.selectedWorkflow = selectedWorkflow;
      this.selectedStage = selectedStage;
      this.showEditStageModal = true;
    },
    hideEditStagePopup() {
      this.showEditStageModal = false;
    },
    showDeleteStagePopup(selectedStage, selectedWorkflow) {
      this.selectedWorkflow = selectedWorkflow;
      this.selectedStage = selectedStage;
      this.showDeleteStageConfirmationPopup = true;
    },
    closeDeleteStagePopup() {
      this.showDeleteStageConfirmationPopup = false;
    },
    async confirmStageDeletion() {
      this.closeDeleteStagePopup();
      try {
        const botId = this.$route.params.botId;
        const workflowId = this.selectedWorkflow.id;
        const id = this.selectedStage.id;

        await this.$store.dispatch('wgptBotWorkflowStages/delete', {
          botId,
          workflowId,
          id,
        });

        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.DELETE.API.SUCCESS_MESSAGE'
          )
        );
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.DELETE.API.ERROR_MESSAGE'
          )
        );
      }
    },
  },
};
</script>

<template>
  <div
    class="overflow-auto macro-gradient-radial dark:macro-dark-gradient-radial macro-gradient-radial-size h-full"
  >
    <WorkflowNodes
      :bot="bot"
      @add-workflow="showAddWorkflowPopup"
      @edit-workflow="showEditWorkflowPopup"
      @delete-workflow="showDeleteWorkflowPopup"
      @add-stage="showAddStagePopup"
      @edit-stage="showEditStagePopup"
      @delete-stage="showDeleteStagePopup"
    />

    <woot-modal
      v-model:show="showAddWorkflowModal"
      @close="hideAddWorkflowPopup"
    >
      <AddWorkflowModal @close="hideAddWorkflowPopup" />
    </woot-modal>

    <woot-modal
      v-model:show="showEditWorkflowModal"
      @close="hideEditWorkflowPopup"
    >
      <EditWorkflowModal
        :workflow="selectedWorkflow"
        :bot="bot"
        @close="hideEditWorkflowPopup"
      />
    </woot-modal>

    <woot-delete-modal
      v-model:show="showDeleteWorkflowConfirmationPopup"
      :on-close="closeDeleteWorkflowPopup"
      :on-confirm="confirmWorkflowDeletion"
      :message-value="deleteWorkflowMessage"
      :title="$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.DELETE.CONFIRM.TITLE')"
      :message="$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.DELETE.CONFIRM.YES')"
      :reject-text="$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.DELETE.CONFIRM.NO')"
    />

    <woot-modal v-model:show="showAddStageModal" @close="hideAddStagePopup">
      <AddStageModal :workflow="selectedWorkflow" @close="hideAddStagePopup" />
    </woot-modal>

    <woot-modal v-model:show="showEditStageModal" @close="hideEditStagePopup">
      <EditStageModal
        :workflow="selectedWorkflow"
        :stage="selectedStage"
        :bot="bot"
        @close="hideEditStagePopup"
      />
    </woot-modal>

    <woot-delete-modal
      v-model:show="showDeleteStageConfirmationPopup"
      :on-close="closeDeleteStagePopup"
      :on-confirm="confirmStageDeletion"
      :message-value="deleteStageMessage"
      :title="$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.DELETE.CONFIRM.TITLE')"
      :message="
        $t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.DELETE.CONFIRM.MESSAGE')
      "
      :confirm-text="
        $t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.DELETE.CONFIRM.YES')
      "
      :reject-text="$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.DELETE.CONFIRM.NO')"
    />
  </div>
</template>

<style scoped>
@tailwind components;
@layer components {
  .macro-gradient-radial {
    background-image: radial-gradient(#ebf0f5 1.2px, transparent 0);
  }
  .macro-dark-gradient-radial {
    background-image: radial-gradient(#293f51 1.2px, transparent 0);
  }
  .macro-gradient-radial-size {
    background-size: 1rem 1rem;
  }
}
</style>

export default {
  methods: {
    toTriggerLocale(label) {
      return this.$t(`WGPT_AUTOMATIONS.REGISTRY.TRIGGERS.${label}.LABEL`);
    },
    toActionLocale(label) {
      return this.$t(`WGPT_AUTOMATIONS.REGISTRY.ACTIONS.${label}.LABEL`);
    },
    t(key, defaultFallback) {
      if (!key) return defaultFallback;

      const translationKey = `WGPT_AUTOMATIONS.REGISTRY.${key}`;
      const translation = this.$t(translationKey, undefined, {
        missingWarn: false,
      });

      if (translation === translationKey) return defaultFallback;

      return translation;
    },
  },
};

<script>
import { useAlert } from 'dashboard/composables';
import ContactMultiselectDropdown from '../contacts/ContactMultiselectDropdown.vue';
import Dropdown from '../components/Dropdown.vue';

export default {
  components: {
    ContactMultiselectDropdown,
    Dropdown,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSearchDropdown: false,
    };
  },
  computed: {
    contactIds() {
      return this.card.config?.contacts ?? [];
    },
  },
  methods: {
    async onUpdate(selectedItems) {
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          config: {
            ...this.card.config,
            contacts: selectedItems,
          },
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.API.ERROR_MESSAGE')
        );
      }
    },
    openDropdown() {
      this.showSearchDropdown = true;
    },
    closeDropdown() {
      this.showSearchDropdown = false;
    },
    addItem({ id }) {
      const result = this.contactIds.concat(id);
      this.onUpdate(result);
    },
    removeItem({ id }) {
      const result = this.contactIds.filter(
        selectedContact => selectedContact !== id
      );
      this.onUpdate(result);

      if (result.length > 0) return;
      this.closeDropdown();
    },
  },
};
</script>

<template>
  <div class="relative">
    <li
      class="font-medium h-7 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md bg-slate-50 hover:bg-slate-100 dark:bg-slate-700 dark:hover:bg-slate-600 cursor-pointer"
      :class="{ disabled: disabled }"
      @click="openDropdown"
    >
      <a class="inline-flex text-left max-w-full w-full items-center">
        <div
          class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full gap-2"
        >
          <fluent-icon
            icon="contact-card"
            class="min-w-[1rem] mt-0.5 text-slate-700 dark:text-slate-100"
            size="14"
          />
          <span
            class="text-sm text-slate-700 dark:text-slate-100 overflow-hidden text-truncate"
          >
            {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.CONTACTS.LABEL') }}
          </span>
        </div>
      </a>
    </li>

    <Dropdown
      class="contact-dropdown"
      :show-dropdown="showSearchDropdown"
      @close="closeDropdown"
    >
      <ContactMultiselectDropdown
        :selected-ids="contactIds"
        @add="addItem"
        @remove="removeItem"
        @close="closeDropdown"
        @toggle="openDropdown"
      />
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
li.disabled {
  @apply opacity-50 pointer-events-none select-none;
}

.contact-dropdown ::v-deep {
  .dropdown-pane {
    @apply w-44;
  }
}
</style>

<script>
import { useAlert } from 'dashboard/composables';
import Attachment from './Attachment.vue';
import canDismissMixin from '../../mixins/preventDismissMixin';

export default {
  components: {
    Attachment,
  },
  mixins: [canDismissMixin],
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDeleteConfirmationPopup: false,
      selectedAttachment: {},
    };
  },
  computed: {
    preventDismiss() {
      return this.showDeleteConfirmationPopup;
    },
  },
  methods: {
    downloadAttachment(attachment) {
      const link = document.createElement('a');
      link.href = attachment.file_url;
      link.download = attachment.filename;
      link.click();
    },
    async deleteAttachment() {
      this.showDeleteConfirmationPopup = false;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const cardId = this.card.id;
        const id = this.selectedAttachment.id;
        await this.$store.dispatch('wgptBoardListCards/removeAttachment', {
          boardId,
          listId,
          cardId,
          id,
        });
        useAlert(
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.API.DELETE_SUCCESS'
          )
        );
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.API.DELETE_ERROR'
          );
        useAlert(errorMessage);
      }
    },
    showDeletePopup(attachment) {
      this.selectedAttachment = attachment;
      this.showDeleteConfirmationPopup = true;
    },
    hideDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
  },
};
</script>

<template>
  <div>
    <div class="flex gap-6">
      <fluent-icon icon="attach" class="min-w-[1rem] mt-1" size="20" />
      <div class="flex-1 w-full overflow-hidden text-wrap">
        <label>
          {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.LABEL') }}
        </label>

        <div class="flex gap-3 overflow-auto p-0.5">
          <Attachment
            v-for="attachment in card.attachments"
            :key="attachment.id"
            :attachment="attachment"
            :readonly="readonly"
            @download="downloadAttachment(attachment)"
            @delete="showDeletePopup(attachment)"
          />
        </div>
      </div>
    </div>

    <woot-delete-modal
      v-if="showDeleteConfirmationPopup"
      v-model:show="showDeleteConfirmationPopup"
      :on-close="hideDeletePopup"
      :on-confirm="deleteAttachment"
      :title="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.DELETE.TITLE')"
      :message="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.DELETE.DESC')"
      :confirm-text="
        $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.DELETE.DELETE')
      "
      :reject-text="
        $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.DELETE.CANCEL')
      "
    />
  </div>
</template>

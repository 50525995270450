<script>
import CustomField from './CustomField.vue';
import { useAlert } from 'dashboard/composables';
import FluentIcon from 'shared/components/FluentIcon/WgptIcon.vue';

export default {
  components: {
    CustomField,
    FluentIcon,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      attributeType: 'conversation_attribute',
      loading: {},
    };
  },
  computed: {
    customFields() {
      const attributes = this.$store.getters['attributes/getAttributesByModel'](
        this.attributeType
      );
      const customAttributes = this.card.config?.custom_fields || {};
      return this.customFieldKeys
        .map(key => {
          const attribute = attributes.find(
            ({ attribute_key }) => attribute_key === key
          );
          if (!attribute) return undefined;
          const value = customAttributes[key];
          return { ...attribute, value };
        })
        .filter(value => !!value);
    },
    customFieldKeys() {
      return this.board.config?.card_custom_fields?.fields || [];
    },
    hasAnyCustomFields() {
      return this.customFields.length > 0;
    },
    hasAnyLoading() {
      return Object.values(this.loading).some(value => value);
    },
  },
  methods: {
    async onUpdate(key, value) {
      if (this.readonly) return;

      let error;
      this.loading = { ...this.loading, [key]: true };
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const previousConfig = this.card.config;
        const previousAttributes = previousConfig.custom_fields ?? {};
        const data = {
          config: {
            custom_fields: {
              ...previousAttributes,
              [key]: value,
            },
          },
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (e) {
        error = e;
        useAlert(
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CUSTOM_FIELDS.API.ERROR_MESSAGE'
          )
        );
      }
      this.loading = { ...this.loading, [key]: false };
      if (error) throw error;
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="hasAnyCustomFields" class="mb-8">
    <div class="flex gap-6">
      <FluentIcon icon="check" class="min-w-[1rem] mt-1" size="20" />
      <div class="flex-1 w-full text-wrap">
        <div class="flex items-center gap-3">
          <label>
            {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CUSTOM_FIELDS.LABEL') }}
          </label>
          <div v-if="hasAnyLoading" class="text-center">
            <span class="spinner" />
          </div>
        </div>

        <div class="mt-4 grid grid-cols-3 gap-3 gap-y-3">
          <CustomField
            v-for="customField in customFields"
            :key="customField.attribute_key"
            :custom-field="customField"
            :attribute="customField"
            :readonly="readonly"
            :loading="loading[customField.attribute_key]"
            :on-update="onUpdate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spinner {
  @apply w-4 h-4 p-0;
  &::before {
    @apply w-4 h-4;
  }
}
</style>

<script>
import { mapGetters } from 'vuex';
import SettingsSection from 'dashboard/components/SettingsSection.vue';
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import { useAlert } from 'dashboard/composables';

export default {
  components: {
    LoadingState,
    SettingsSection,
  },
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedBotId: null,
    };
  },
  computed: {
    ...mapGetters({
      bots: 'wgptBots/getBots',
      uiFlags: 'wgptBots/getUIFlags',
    }),
    activeBot() {
      return this.$store.getters['wgptBots/getActiveBot'](this.inbox.id);
    },
  },
  watch: {
    activeBot() {
      this.selectedBotId = this.activeBot.id;
    },
  },
  mounted() {
    this.$store.dispatch('wgptBots/get');
    this.$store.dispatch('wgptBots/fetchBotInbox', this.inbox.id);
  },

  methods: {
    async updateActiveBot() {
      try {
        await this.$store.dispatch('wgptBots/setBotInbox', {
          inboxId: this.inbox.id,
          // Added this to make sure that empty values are not sent to the API
          botId: this.selectedBotId ? this.selectedBotId : undefined,
        });
        useAlert(this.$t('WGPT_BOTS.INBOX_BOT_CONFIGURATION.SUCCESS_MESSAGE'));
      } catch (error) {
        useAlert(this.$t('WGPT_BOTS.INBOX_BOT_CONFIGURATION.ERROR_MESSAGE'));
      }
    },
    async disconnectBot() {
      try {
        await this.$store.dispatch('wgptBots/disconnectBot', {
          inboxId: this.inbox.id,
        });
        useAlert(
          this.$t(
            'WGPT_BOTS.INBOX_BOT_CONFIGURATION.DISCONNECTED_SUCCESS_MESSAGE'
          )
        );
      } catch (error) {
        useAlert(
          error?.message ||
            this.$t(
              'WGPT_BOTS.INBOX_BOT_CONFIGURATION.DISCONNECTED_ERROR_MESSAGE'
            )
        );
      }
    },
  },
};
</script>

<template>
  <div class="mx-8">
    <LoadingState v-if="uiFlags.isFetching || uiFlags.isFetchingBot" />
    <form v-else class="mx-0 flex flex-wrap" @submit.prevent="updateActiveBot">
      <SettingsSection
        :title="$t('WGPT_BOTS.INBOX_BOT_CONFIGURATION.TITLE')"
        :sub-title="$t('WGPT_BOTS.INBOX_BOT_CONFIGURATION.DESC')"
      >
        <div class="w-3/5">
          <label>
            <select v-model="selectedBotId">
              <option value="" disabled selected>
                {{ $t('WGPT_BOTS.INBOX_BOT_CONFIGURATION.SELECT_PLACEHOLDER') }}
              </option>
              <option v-for="bot in bots" :key="bot.id" :value="bot.id">
                {{ bot.name }}
              </option>
            </select>
          </label>
          <div class="button-container">
            <woot-submit-button
              :button-text="$t('WGPT_BOTS.INBOX_BOT_CONFIGURATION.SUBMIT')"
              :loading="uiFlags.isSettingBot"
            />
            <woot-button
              type="button"
              :disabled="!selectedBotId"
              :loading="uiFlags.isDisconnecting"
              variant="smooth"
              color-scheme="alert"
              class="button--disconnect"
              @click="disconnectBot"
            >
              {{ $t('WGPT_BOTS.INBOX_BOT_CONFIGURATION.DISCONNECT') }}
            </woot-button>
          </div>
        </div>
      </SettingsSection>
    </form>
  </div>
</template>

<style scoped lang="scss">
.button--disconnect {
  @apply ml-2;
}
</style>

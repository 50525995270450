<script>
import SettingIntroBanner from 'dashboard/components/widgets/SettingIntroBanner.vue';
import BasicSettings from './basic/Index.vue';
import KnowledgeBase from './knowledgeBase/Index.vue';
import PreChat from './preChat/Index.vue';
import Workflows from './workflows/Index.vue';
import Inboxes from './inboxes/Index.vue';

export default {
  components: {
    BasicSettings,
    PreChat,
    KnowledgeBase,
    Workflows,
    Inboxes,
    SettingIntroBanner,
  },
  data() {
    return {
      selectedTabIndex: 0,
    };
  },
  computed: {
    selectedTabKey() {
      return this.tabs[this.selectedTabIndex]?.key;
    },
    tabs() {
      return [
        {
          key: 'basic',
          name: this.$t('WGPT_BOTS.EDIT.TABS.BASIC.NAME'),
        },
        {
          key: 'inboxes',
          name: this.$t('WGPT_BOTS.EDIT.TABS.INBOXES.NAME'),
        },
        {
          key: 'pre_chat',
          name: this.$t('WGPT_BOTS.EDIT.TABS.PRE_CHAT.NAME'),
        },
        {
          key: 'knowledge_base',
          name: this.$t('WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.NAME'),
        },
        {
          key: 'workflows',
          name: this.$t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.NAME'),
        },
      ];
    },
    botId() {
      return this.$route.params.botId;
    },
    bot() {
      return this.$store.getters['wgptBots/getBot'](this.botId);
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'wgpt_bots_edit') {
        this.fetchBot();
      }
    },
  },
  mounted() {
    this.fetchBot();
  },
  methods: {
    onTabChange(selectedTabIndex) {
      this.selectedTabIndex = selectedTabIndex;
    },
    fetchBot() {
      this.selectedTabIndex = 0;
      this.$store.dispatch('wgptBots/show', this.botId);
    },
  },
};
</script>

<template>
  <div
    class="flex-grow flex-shrink w-full min-w-0 pl-0 pr-0 overflow-auto bg-white settings dark:bg-slate-800 flex flex-col"
  >
    <SettingIntroBanner :header-image="bot.avatarUrl" :header-title="bot.name">
      <woot-tabs
        class="settings--tabs"
        :index="selectedTabIndex"
        :border="false"
        @change="onTabChange"
      >
        <woot-tabs-item
          v-for="(tab, index) in tabs"
          :key="tab.key"
          :index="index"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>
    </SettingIntroBanner>

    <div v-if="selectedTabKey === 'basic'" class="mx-8">
      <BasicSettings :bot="bot" />
    </div>

    <div v-if="selectedTabKey === 'inboxes'" class="mx-8">
      <Inboxes :bot="bot" />
    </div>

    <div v-if="selectedTabKey === 'pre_chat'">
      <PreChat :bot="bot" />
    </div>

    <div v-if="selectedTabKey === 'knowledge_base'">
      <KnowledgeBase :bot="bot" />
    </div>

    <div v-if="selectedTabKey === 'workflows'" class="h-full">
      <Workflows :bot="bot" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.settings--tabs {
  ::v-deep .tabs {
    @apply p-0;
  }
}
</style>

<script>
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';
import { useAlert } from 'dashboard/composables';

export default {
  components: {
    FluentIcon,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async copyLink(e) {
      e.preventDefault();
      await copyTextToClipboard(window.location.href);
      useAlert(
        this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.HEADER.COPY_SUCCESSFUL')
      );
    },
  },
};
</script>

<template>
  <div class="ltr:pl-14 rtl:pr-14 ml-1">
    <span
      class="rtl:px-1 text-xs font-bold text-slate-500 dark:text-slate-400 cursor-pointer hover:underline [&:not(:hover)>.icon]:hidden inline-flex items-center gap-1.5"
      @click="copyLink"
    >
      <span>{{ card.key }}</span>
      <FluentIcon icon="clipboard" class="icon" size="14" />
    </span>
  </div>
</template>

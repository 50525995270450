// export const standardFieldKeys = {
//   emailAddress: {
//     key: 'EMAIL_ADDRESS',
//   },
//   fullName: {
//     key: 'FULL_NAME',
//   },
//   phoneNumber: {
//     key: 'PHONE_NUMBER',
//   },
// };

export const getCustomFields = ({ standardFields, customAttributes }) => {
  let customFields = [];
  customAttributes.forEach(attribute => {
    const itemExist = standardFields.find(
      item =>
        item.key === attribute.attribute_key &&
        item.field_type === attribute.attribute_model
    );
    if (!itemExist) {
      customFields.push({
        field_type: attribute.attribute_model,
        key: attribute.attribute_key,
        name: attribute.attribute_display_name,
        type: attribute.attribute_display_type,
        description: attribute.attribute_description,
        values: attribute.attribute_values,
        enabled: false,
        regex_pattern: attribute.regex_pattern,
        regex_cue: attribute.regex_cue,
      });
    }
  });
  return customFields;
};

export const getPreChatFields = ({
  preChatFields = [],
  customAttributes = [],
}) => {
  let customFields = getCustomFields({
    standardFields: preChatFields,
    customAttributes,
  });

  return [...preChatFields, ...customFields];
};

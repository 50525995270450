import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import { emitter } from 'shared/helpers/mitt';

export default {
  mixins: [keyboardEventListenerMixins],
  watch: {
    preventDismiss(value) {
      emitter.emit('wgptBoardListCardPreventDismiss', value);
    },
  },
};

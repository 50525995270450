<script>
import MenuItem from 'dashboard/components/widgets/conversation/contextMenu/menuItem.vue';
import ContextMenu from 'dashboard/components/ui/ContextMenu.vue';

export default {
  components: {
    MenuItem,
    ContextMenu,
  },
  emits: ['delete', 'edit'],
  data() {
    return {
      contextMenuPosition: {},
      isOpen: false,
    };
  },
  methods: {
    handleOpen(e) {
      this.isOpen = true;
      const maxX = window.innerWidth - 208;
      this.contextMenuPosition = {
        x: Math.min(maxX, e.pageX || e.clientX),
        y: e.pageY || e.clientY,
      };
    },
    handleClose() {
      this.isOpen = false;
    },
    handleEdit(e) {
      this.$emit('edit', e);
      this.handleClose();
    },
    handleDelete(e) {
      this.$emit('delete', e);
      this.handleClose();
    },
  },
};
</script>

<template>
  <div class="context-menu">
    <woot-button
      class="absolute -translate-y-1/2 mx-1"
      icon="more-vertical"
      color-scheme="secondary"
      variant="clear"
      size="small"
      @click="handleOpen"
    />
    <ContextMenu
      v-if="isOpen"
      :x="contextMenuPosition.x"
      :y="contextMenuPosition.y"
      @close="handleClose"
    >
      <div class="menu-container">
        <MenuItem
          :option="{
            label: $t(
              'WGPT_BOTS.EDIT.TABS.WORKFLOWS.BUTTON_TEXT.EDIT_WORKFLOW'
            ),
          }"
          variant="icon"
          @click.stop="handleEdit"
        />
        <hr />
        <MenuItem
          :option="{
            label: $t(
              'WGPT_BOTS.EDIT.TABS.WORKFLOWS.BUTTON_TEXT.DELETE_WORKFLOW'
            ),
          }"
          variant="icon"
          @click.stop="handleDelete"
        />
      </div>
    </ContextMenu>
  </div>
</template>

<style lang="scss" scoped>
.menu-container {
  @apply p-1 bg-white dark:bg-slate-900 shadow-xl rounded-md;
  hr:first-child {
    @apply hidden;
  }
  hr {
    @apply m-1 border-b border-solid border-slate-50 dark:border-slate-800/50;
  }
}
</style>

<script>
import translateMixins from '../../../mixins/translateMixins';
import { useVuelidate } from '@vuelidate/core';
import { ALLOWED_FILE_TYPES } from 'shared/constants/messages';
import FileUpload from 'vue-upload-component';
import AttachmentPreview from './components/AttachmentsPreview.vue';
import { useAlert } from 'dashboard/composables';
import { MAXIMUM_FILE_UPLOAD_SIZE } from './helpers/constants';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';

export default {
  components: {
    FileUpload,
    AttachmentPreview,
  },
  mixins: [translateMixins],
  props: {
    value: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    fallbackLabel: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['change'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      updatedValue: this.value || [],
    };
  },
  computed: {
    automation() {
      return (
        this.$store.getters['wgptAutomations/getAutomation'](
          this.$route.params.automationId
        ) || {}
      );
    },
    automationFiles() {
      return this.automation.files ?? [];
    },
    attachedFiles() {
      return this.automationFiles
        .filter(({ id }) => this.updatedValue.includes(id))
        .map(resource => {
          return {
            id: resource.id,
            resource,
            thumb: resource.file_url,
          };
        });
    },
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
  },
  validations() {
    return {
      updatedValue: {
        required: updatedValue => {
          if (!this.required) return true;

          return updatedValue.length > 0;
        },
      },
    };
  },
  watch: {
    value: {
      handler(value) {
        this.updatedValue = value || [];
      },
      immediate: true,
    },
  },
  methods: {
    updateValue() {
      this.v$.$touch();
      this.$emit('change', this.updatedValue);
    },
    async attachFile(event) {
      try {
        const file = event.file;
        if (!file) return;

        if (!checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE))
          throw new Error('FILE_SIZE_ERROR');

        const data = {
          file,
          id: this.$route.params.automationId,
        };
        const fileInfo = await this.$store.dispatch(
          'wgptAutomations/addFile',
          data
        );

        this.updatedValue.push(fileInfo.id);
        this.updateValue();
      } catch (error) {
        if (error?.message === 'FILE_SIZE_ERROR') {
          useAlert(
            this.$t(
              'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.ATTACHMENT.API.FILE_SIZE_ERROR',
              {
                size: MAXIMUM_FILE_UPLOAD_SIZE,
              }
            )
          );
        } else {
          useAlert(
            this.$t(
              'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.ATTACHMENT.API.UPLOAD_ERROR_MESSAGE'
            )
          );
        }
      }
    },
    async removeAttachment(fileId) {
      try {
        const fileIdsBefore = this.updatedValue ?? [];
        this.updatedValue = fileIdsBefore.filter(id => id !== fileId);
        this.updateValue();
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.ATTACHMENT.API.REMOVE_ERROR_MESSAGE'
          )
        );
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="input-label" :class="{ error: v$.updatedValue.$error }">
      <label class="flex items-center gap-2">
        <div v-if="label" class="block truncate">
          {{ t(label, fallbackLabel) }}<span v-if="required">*</span>
        </div>
        <fluent-icon
          v-if="description"
          v-tooltip.top="{
            content: t(description),
            popperClass: 'max-w-[300px]',
            boundary: 'body',
          }"
          icon="info"
          size="14"
        />
      </label>
      <div>
        <div v-if="hasAttachments" class="overflow-y-auto mb-4 mt-1.5">
          <AttachmentPreview
            class="[&>.preview-item]:dark:bg-slate-700 flex-row flex-wrap gap-x-3 gap-y-1"
            :attachments="attachedFiles"
            @remove-attachment="removeAttachment"
          />
        </div>
        <FileUpload
          :size="4096 * 4096"
          :accept="allowedFileTypes"
          multiple
          drop
          :drop-directory="false"
          :data="{
            direct_upload_url: '/rails/active_storage/direct_uploads',
            direct_upload: true,
          }"
          class="!text-start"
          @input-file="attachFile"
        >
          <woot-button
            class-names="button--upload"
            icon="attach"
            emoji="📎"
            color-scheme="secondary"
            variant="smooth"
            size="small"
          >
            {{
              $t(
                'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.ATTACHMENT.SELECT'
              )
            }}
          </woot-button>
          <span
            class="text-xs font-medium text-slate-500 ltr:ml-1 rtl:mr-1 dark:text-slate-400"
          >
            {{
              $t(
                'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.DYNAMIC_FIELDS.ATTACHMENT.HELP_TEXT'
              )
            }}
          </span>
        </FileUpload>
      </div>

      <span v-if="v$.updatedValue.$error" class="message">
        {{ $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.EMPTY') }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.button--only-icon ::v-deep * {
  @apply pointer-events-none;
}

.error input {
  @apply border-red-500;
}

:is(.dark .error input) {
  @apply border-red-500;
}
</style>

import VariablesDropdown from './Index.vue';

export default {
  components: {
    VariablesDropdown,
  },
  props: {
    variables: {
      type: Array,
      default: () => [],
    },
    showVariablesSelector: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showVariablesDropdown: false,
      variablesFilter: '',
      variablesDropdownEvent: {},
    };
  },
  methods: {
    openVariablesDropdown(e) {
      if (!this.showVariablesSelector) return;

      this.variablesDropdownEvent = e;
      if (e.type === 'keyup') {
        if (e.key === '%') {
          this.showVariablesDropdown = true;
          this.variablesFilter = '%';
        } else if (e.key === '#') {
          this.showVariablesDropdown = true;
          this.variablesFilter = '#';
        } else if (e.key === 'Backspace') {
          this.closeVariablesDropdown();
        }
      } else {
        this.showVariablesDropdown = true;
        this.variablesFilter = '';
      }
    },
    closeVariablesDropdown() {
      this.showVariablesDropdown = false;
    },
    addVariable(variableName, variablePrefix = '%') {
      this.closeVariablesDropdown();

      const fieldEl = this.$refs.fieldEl;
      if (fieldEl) {
        fieldEl.focus();

        this.$nextTick(() => {
          const currentIndex = fieldEl.selectionStart;
          const value = fieldEl.value;
          const laggingChars = value.slice(0, currentIndex);
          const leadingChars = value.slice(currentIndex);
          const previousChar = value.charAt(currentIndex - 1);
          const variableChars =
            (previousChar === variablePrefix ? '' : variablePrefix) +
            `{${variableName}}`;
          const updatedValue = laggingChars + variableChars + leadingChars;
          this.onVariableAdd(updatedValue, variableName, variablePrefix);
          this.$nextTick(() => {
            const indexAfter = currentIndex + variableChars.length;
            fieldEl.setSelectionRange(indexAfter, indexAfter);
          });
        });
      } else {
        this.closeVariablesDropdown();

        const updatedValue = `{${variableName}}`;
        this.onVariableAdd(updatedValue, variableName, variablePrefix);
      }
    },
  },
};

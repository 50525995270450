<script>
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    cancelButtonText: {
      type: String,
      default: '',
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
      description: '',
    };
  },
  validations: {
    name: {
      required,
    },
    description: {
      required,
    },
  },
  methods: {
    handleSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      const data = {
        name: this.name,
        description: this.description,
      };

      this.onSubmit(data);
    },
    handleClose() {
      this.onClose();
    },
  },
};
</script>

<template>
  <form class="w-full" @submit.prevent="handleSubmit">
    <div>
      <label :class="{ error: v$.name.$error }">
        {{ $t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.CREATE.FORM.NAME.LABEL') }}
        <div class="flex relative">
          <input
            v-model="name"
            class="!my-1"
            type="text"
            @blur="v$.name.$touch"
          />
        </div>
        <span v-if="v$.name.$error" class="message">
          {{ $t('WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.CREATE.FORM.NAME.ERROR') }}
        </span>
      </label>
    </div>

    <div class="mt-4">
      <label :class="{ error: v$.description.$error }">
        {{
          $t(
            'WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.CREATE.FORM.DESCRIPTION.LABEL'
          )
        }}
        <textarea
          v-model.trim="description"
          rows="3"
          @blur="v$.description.$touch"
        />
        <span v-if="v$.description.$error" class="message">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.WORKFLOWS.STAGE.CREATE.FORM.DESCRIPTION.ERROR'
            )
          }}
        </span>
      </label>
    </div>

    <div class="mt-3 flex justify-end items-center py-2 px-0 gap-2 w-full">
      <woot-button
        :disabled="v$.$invalid || submitInProgress"
        :loading="submitInProgress"
      >
        {{ submitButtonText }}
      </woot-button>
      <woot-button class="button clear" @click.prevent="handleClose">
        {{ cancelButtonText }}
      </woot-button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import translateMixins from '../../../mixins/translateMixins';
import dynamicField from '../dynamicField/Index.vue';

export default {
  components: {
    DynamicField: dynamicField,
  },
  mixins: [translateMixins],
  props: {
    trigger: {
      type: Object,
      default: () => {},
    },
    disableTriggerChange: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['triggerChange'],
  data() {
    return {
      updatedType: this.trigger.type,
    };
  },
  computed: {
    ...mapGetters({
      triggers: 'wgptAutomationRegistry/getTriggers',
    }),
    triggerRegistry() {
      if (!this.updatedType) return {};
      return (
        this.$store.getters['wgptAutomationRegistry/getTrigger'](
          this.updatedType
        ) ?? {}
      );
    },
    fields() {
      return this.triggerRegistry.form_fields || [];
    },
    attributes() {
      return this.trigger.attributes || {};
    },
  },
  watch: {
    trigger(trigger) {
      if (trigger.type !== this.updatedType) {
        this.updatedType = trigger.type;
      }
    },
  },
  methods: {
    onChange(e) {
      const triggerName = e.target.value;
      const trigger = this.triggers[triggerName];
      const triggerUpdates = {
        type: triggerName,
        label: trigger.label,
      };

      const formFields = trigger.form_fields ?? [];
      formFields.forEach(field => {
        if (Object.keys(field).includes('default')) {
          const triggerAttributesBefore = triggerUpdates.attributes ?? {};
          triggerUpdates.attributes = {
            ...triggerAttributesBefore,
            [field.name]: field.default,
          };
        }
      });

      this.$emit('triggerChange', triggerUpdates);
    },
    isVisible(field) {
      const conditions = field.show_if ?? [];
      const results = conditions.map(({ field: fieldName, value }) => {
        const currentValue =
          this.resolveFieldValue(this.attributes, fieldName) ??
          this.getDefaultFieldValue(fieldName);
        if (currentValue == null) return false;
        if (value.includes('*'))
          return `${currentValue}`.includes(value.replace(/\*/g, ''));
        return currentValue === value;
      });
      return results.every(result => result);
    },
    getDefaultFieldValue(fieldName) {
      const field = this.fields.find(({ name }) => name === fieldName);
      return field?.default;
    },
    resolveFieldValue(obj, fieldName) {
      return fieldName.split('.').reduce((prev, curr) => {
        if (prev == null) return prev;
        return prev[curr];
      }, obj);
    },
    onFieldChange(key, value) {
      const attributesBefore = this.trigger?.attributes ?? {};
      const trigger = {
        attributes: {
          ...attributesBefore,
          [key]: value,
        },
      };
      this.$emit('triggerChange', trigger);
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="trigger">
    <div class="px-3 pt-4 pb-16">
      <h5
        class="grow text-base font-semibold text-black-900 dark:text-slate-200 select-none inline-flex items-center gap-1.5 overflow-hidden"
      >
        <span class="truncate">{{
          $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.TRIGGER.TITLE')
        }}</span>
      </h5>
      <p
        class="text-xs pb-4 border-b border-slate-100 dark:border-slate-800/50"
      >
        {{ $t('WGPT_AUTOMATIONS.EDITOR.SIDEBAR.TRIGGER.DESCRIPTION') }}
      </p>

      <label class="mt-8">
        <select
          v-model="updatedType"
          :disabled="disableTriggerChange"
          class="cursor-pointer disabled:cursor-not-allowed"
          @change="onChange"
        >
          <option value="" disabled selected>
            {{
              $t(
                'WGPT_AUTOMATIONS.EDITOR.SIDEBAR.TRIGGER.FORM.TRIGGER_TYPE.DEFAULT_OPTION'
              )
            }}
          </option>
          <option
            v-for="(triggerOption, key) in triggers"
            :key="key"
            :value="key"
          >
            {{ t(triggerOption.label) }}
          </option>
        </select>
      </label>

      <div class="custom-fields">
        <DynamicField
          v-for="(field, index) of fields"
          :key="index"
          :field="field"
          :value="attributes[field.name]"
          :visible="isVisible(field)"
          :show-variables-selector="false"
          @change="value => onFieldChange(field.name, value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MultiselectDropdown from './MultiselectDropdown.vue';
import FluentIcon from 'shared/components/FluentIcon/WgptIcon.vue';

export default {
  components: {
    MultiselectDropdown,
    FluentIcon,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['remove', 'add'],
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    addItem(item) {
      this.$emit('add', item);
      this.closeDropdown();
    },
    removeItem(item) {
      this.$emit('remove');
      this.closeDropdown(item);
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>

<template>
  <div>
    <woot-button
      class="absolute ltr:right-0 rtl:left-0 top-0 m-1 button--only-icon"
      size="small"
      color-scheme="secondary"
      variant="smooth"
      @click.prevent="toggleDropdown"
    >
      <FluentIcon icon="braces-variable" size="14" />
    </woot-button>
    <div
      v-if="showDropdown"
      v-on-clickaway="closeDropdown"
      class="dropdown-wrap"
    >
      <div
        :class="{ 'dropdown-pane--open': showDropdown }"
        class="dropdown-pane mt-2 right-0 top-1"
      >
        <MultiselectDropdown
          v-if="showDropdown"
          :options="options"
          :multiselector-title="
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.FORM.MULTI_SELECTOR.TITLE'
            )
          "
          :no-search-result="
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.FORM.MULTI_SELECTOR.SEARCH.NO_RESULTS'
            )
          "
          :input-placeholder="
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.CHUNKS.EDIT.FORM.MULTI_SELECTOR.SEARCH.PLACEHOLDER'
            )
          "
          @add="addItem"
          @remove="removeItem"
          @close="closeDropdown"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.button--only-icon ::v-deep {
  .button__content {
    @apply flex justify-center;
  }
}
</style>

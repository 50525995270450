<script>
import FilterItem from './FilterItem.vue';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';

export default {
  components: {
    FilterItem,
    FluentIcon,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      updatedValue: this.modelValue,
      allDateOptions: [
        {
          label: this.$t('WGPT_BOARDS.FILTER.DUE_DATES.OPTIONS.NO_DATES'),
          value: 'no_dates',
        },
        {
          label: this.$t('WGPT_BOARDS.FILTER.DUE_DATES.OPTIONS.OVERDUE'),
          value: 'overdue',
        },
        {
          label: this.$t('WGPT_BOARDS.FILTER.DUE_DATES.OPTIONS.DUE_NEXT_DAY'),
          value: 'due_next_day',
          group: 'due_dates',
        },
        {
          label: this.$t('WGPT_BOARDS.FILTER.DUE_DATES.OPTIONS.DUE_NEXT_WEEK'),
          value: 'due_next_week',
          group: 'due_dates',
        },
        {
          label: this.$t('WGPT_BOARDS.FILTER.DUE_DATES.OPTIONS.DUE_NEXT_MONTH'),
          value: 'due_next_month',
          group: 'due_dates',
        },
        {
          label: this.$t('WGPT_BOARDS.FILTER.DUE_DATES.OPTIONS.COMPLETE'),
          value: 'complete',
          group: 'complete',
        },
        {
          label: this.$t('WGPT_BOARDS.FILTER.DUE_DATES.OPTIONS.NOT_COMPLETE'),
          value: 'not_complete',
          group: 'complete',
        },
      ],
      showMoreOptions: false,
      limit: 3,
    };
  },
  computed: {
    dateOptions() {
      return this.allDateOptions.filter(({ value }, index) => {
        if (this.showMoreOptions || index < this.limit) return true;
        if (this.updatedValue.includes(value)) return true;
        return false;
      });
    },
  },
  watch: {
    updatedValue(value) {
      this.$emit('update:modelValue', value);
    },
    modelValue(value, valueBefore) {
      if (value.join() === valueBefore.join()) return;
      this.updatedValue = value;
    },
  },
  methods: {
    toggleCheckbox(option) {
      const group = option.group;

      // If the option is already selected, unselect it
      if (this.updatedValue.includes(option.value)) {
        this.updatedValue = this.updatedValue.filter(
          val => val !== option.value
        );
      } else {
        // If the option has a group, remove any previously selected value from the same group
        if (group) {
          this.updatedValue = this.updatedValue.filter(val => {
            const selectedOption = this.allDateOptions.find(
              opt => opt.value === val
            );
            return !selectedOption || selectedOption.group !== group;
          });
        }

        // Add the new value
        this.updatedValue.push(option.value);
      }
    },
  },
};
</script>

<template>
  <div>
    <label class="text-sm mb-2 text-slate-600 dark:text-slate-400">{{
      $t('WGPT_BOARDS.FILTER.DUE_DATES.TITLE')
    }}</label>
    <FilterItem
      v-for="option in dateOptions"
      :key="option.value"
      class="group py-1.5"
    >
      <template #start>
        <input
          type="checkbox"
          :value="option.value"
          :checked="updatedValue.includes(option.value)"
          class="group-hover:bg-slate-50 group-hover:dark:bg-slate-800/50 me-1"
          @change="toggleCheckbox(option)"
        />
      </template>
      <span>{{ option.label }}</span>
    </FilterItem>

    <woot-button
      v-if="!showMoreOptions"
      variant="link"
      class-names="!ms-6 !mt-2 [&>*]:pointer-events-none"
      @click="showMoreOptions = true"
    >
      {{ $t('WGPT_BOARDS.FILTER.BUTTON_TXT.SHOW_MORE_OPTIONS') }}
      <FluentIcon icon="chevron-down" size="18" />
    </woot-button>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import ParameterRow from '../../../../components/ParameterRow.vue';

export default {
  components: {
    ParameterRow,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    cancelButtonText: {
      type: String,
      default: '',
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    const formData = this.formData || {};

    const { name = '', description = '' } = formData;

    const parameters = [];
    Object.keys(formData.parameters).forEach((key, index) => {
      parameters.push({
        key: key ?? '',
        value: formData.parameters[key] ?? '',
        disabled: true,
        index,
      });
    });

    return {
      name,
      description,
      parameters,
    };
  },
  validations() {
    return {
      name: {
        required,
      },
      description: {},
      parameters: {
        hasDuplicates: value => {
          const keys = value.map(p => p.key);
          return !keys.some((key, index) => keys.indexOf(key) !== index);
        },
      },
    };
  },
  methods: {
    addParameter() {
      const parameter = {
        key: '',
        value: '',
        index: this.parameters.length,
      };
      this.parameters.push(parameter);
    },
    removeParameter(index) {
      this.parameters = this.parameters.filter(
        parameter => parameter.index !== index
      );
    },
    handleSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) return;

      const source = {
        name: this.name,
        description: this.description,
        parameters: {},
      };

      this.parameters.forEach(parameter => {
        source.parameters[parameter.key] = parameter.value;
      });

      this.onSubmit(source);
    },
    handleClose() {
      this.onClose();
    },
    parameterHasDuplicate(index) {
      const key = this.parameters[index].key;
      const keys = this.parameters.map(p => p.key);
      return keys.filter(k => k === key).length > 1;
    },
    onParameterChange(index, parameter) {
      this.parameters = this.parameters.map((parameterBefore, i) => {
        if (index !== i) return parameterBefore;
        return { ...parameterBefore, ...parameter };
      });
    },
  },
};
</script>

<template>
  <form class="mx-0 flex flex-wrap" @submit.prevent="handleSubmit">
    <div class="w-full">
      <label :class="{ error: v$.name.$error }">
        {{
          $t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.EDIT.FORM.NAME.LABEL'
          )
        }}
        <input
          v-model="name"
          class="!mt-0 !mb-1"
          type="text"
          @blur="v$.name.$touch"
        />
        <span v-if="v$.name.$error" class="message">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.EDIT.FORM.NAME.ERROR'
            )
          }}
        </span>
      </label>
    </div>

    <div class="mt-3 w-full">
      <label :class="{ error: v$.description.$error }">
        {{
          $t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.EDIT.FORM.DESCRIPTION.LABEL'
          )
        }}
        <textarea
          v-model="description"
          class="!mt-0 !mb-1"
          rows="2"
          type="text"
          @blur="v$.description.$touch"
        />
        <span v-if="v$.description.$error" class="message">
          {{
            $t(
              'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.EDIT.FORM.DESCRIPTION.ERROR'
            )
          }}
        </span>
      </label>
    </div>

    <div class="w-full mt-4">
      <label>
        <span>{{
          $t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.EDIT.FORM.PARAMETERS.LABEL'
          )
        }}</span>
      </label>
    </div>
    <ParameterRow
      v-for="(parameter, index) in parameters"
      :key="parameter.index"
      :value="parameter"
      :has-duplicate="parameterHasDuplicate(index)"
      :show-label="index > 0"
      :disabled="parameter.disabled"
      @remove="removeParameter(parameter.index)"
      @change="e => onParameterChange(index, e)"
    />

    <div class="mt-2 -ml-1">
      <woot-button
        size="small"
        variant="link"
        icon="add"
        type="button"
        @click="addParameter"
      >
        {{
          $t(
            'WGPT_BOTS.EDIT.TABS.KNOWLEDGE_BASE.TABS.ITEMS.EDIT.ADD_PARAMETER_BTN_TXT'
          )
        }}
      </woot-button>
    </div>

    <div class="mt-3 flex justify-end items-center py-2 px-0 gap-2 w-full">
      <woot-button
        :disabled="v$.$invalid || submitInProgress"
        data-testid="source-submit"
      >
        {{ submitButtonText }}
      </woot-button>
      <woot-button class="button clear" @click.prevent="onClose">
        {{ cancelButtonText }}
      </woot-button>
    </div>
  </form>
</template>

import { useVuelidate } from '@vuelidate/core';
import { helpers, required as requiredValidator } from '@vuelidate/validators';

function validateCondition(condition, comparisonOperators) {
  if (!requiredValidator.$validator(condition.variable_name)) {
    throw new Error('Condition: variable_name field is required');
  }

  const comparisonOperator = condition.operator;
  if (!requiredValidator.$validator(comparisonOperator)) {
    throw new Error('Condition: operator field is required');
  }

  const comparisonOperatorRegistry = comparisonOperators.find(
    item => item.value === comparisonOperator
  );
  if (!comparisonOperatorRegistry) {
    throw new Error(
      `Condition: comparison operator '${comparisonOperator}' is invalid`
    );
  }

  if (!comparisonOperatorRegistry.no_value) {
    if (!requiredValidator.$validator(condition.value)) {
      throw new Error('Condition: value field is required');
    }
  }
}

function validateConditions(conditions, comparisonOperators) {
  if (conditions) {
    conditions.forEach(condition => {
      validateCondition(condition, comparisonOperators);
    });
  }
}

function validateBranch(branch, logicalOperators, comparisonOperators) {
  const logicalOperator = branch.logical_operator;
  if (!logicalOperator) {
    throw new Error('Condition: logical_operator field is required');
  }
  if (!logicalOperators.includes(logicalOperator)) {
    throw new Error(
      `Condition: logical operator '${logicalOperator}' is invalid. Valid operators are ${logicalOperators.join(
        ', '
      )}`
    );
  }

  validateConditions(branch.conditions, comparisonOperators);
}

const conditionValidation = registries =>
  helpers.withParams(
    {
      value: registries,
    },
    step => {
      try {
        const [logicalOperatorsRegistry, comparisonOperators] = registries;
        const logicalOperators = logicalOperatorsRegistry.map(
          item => item.value
        );
        const branches = step.branches ?? [];
        branches.forEach(branch => {
          validateBranch(branch, logicalOperators, comparisonOperators);
        });
        return true;
      } catch (error) {
        return false;
      }
    }
  );

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    comparisonOperators() {
      return this.$store.getters[
        'wgptAutomationRegistry/getComparisonOperators'
      ];
    },
    logicalOperators() {
      return this.$store.getters['wgptAutomationRegistry/getLogicalOperators'];
    },
  },
  validations() {
    return {
      step: {
        conditionValidation: conditionValidation([
          this.logicalOperators,
          this.comparisonOperators,
        ]),
      },
    };
  },
};

<script>
import CustomAttribute from './CustomAttribute.vue';

export default {
  components: {
    CustomAttribute,
  },
  props: {
    attribute: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    onUpdate: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<template>
  <div :class="attribute.attribute_display_type">
    <CustomAttribute
      :attribute-key="attribute.attribute_key"
      :attribute-type="attribute.attribute_display_type"
      :values="attribute.attribute_values"
      :label="attribute.attribute_display_name"
      :description="attribute.attribute_description"
      :value="attribute.value"
      :attribute-regex="attribute.regex_pattern"
      :regex-cue="attribute.regex_cue"
      :readonly="readonly"
      :loading="loading"
      :on-update="onUpdate"
    />
  </div>
</template>

<script>
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import { useUISettings } from 'dashboard/composables/useUISettings';
import getUuid from 'widget/helpers/uuid';

export default {
  mixins: [keyboardEventListenerMixins],
  props: {
    showDropdown: {
      type: Boolean,
      default: false,
    },
    event: {
      type: [Object, Event],
      default: () => {},
    },
    anchor: {
      type: String,
      default: 'top',
    },
    position: {
      type: String,
      default: '',
    },
    gap: {
      type: Number,
      default: 0,
    },
    showBackdrop: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  setup() {
    const { uiSettings, updateUISettings } = useUISettings();
    return {
      uiSettings,
      updateUISettings,
    };
  },
  data() {
    return {
      dynamicStyle: {},
      stacks: 0,
      id: getUuid(),
    };
  },
  computed: {
    preventDismiss() {
      return this.showDropdown;
    },
  },
  watch: {
    showDropdown(value) {
      const stackBefore = this.uiSettings.wgpt_dropdown_stack ?? [];
      if (value) {
        this.updateUISettings({
          wgpt_dropdown_stack: stackBefore.concat(this.id),
        });
      } else {
        this.updateUISettings({
          wgpt_dropdown_stack: stackBefore.filter(stack => stack !== this.id),
        });
      }
    },
    event(e) {
      this.$nextTick(() => {
        const bounds = e.target.getBoundingClientRect();
        const direction = getComputedStyle(e.target).direction;
        const eventX =
          direction === 'rtl'
            ? document.body.clientWidth - bounds.width - bounds.x
            : bounds.x;
        const eventY =
          this.anchor === 'top' ? bounds.y : bounds.y + bounds.height;
        const gap = this.gap;

        const dropdownPane = this.$refs.dropdownPane;
        const dropdownPaneBottom = dropdownPane.clientHeight + eventY;
        const dropdownPaneEnd = dropdownPane.clientWidth + eventX;
        const maxBottom = document.body.clientHeight;
        const maxEnd = document.body.clientWidth;

        const dynamicStyle = {};

        if (this.position.includes('start')) {
          dynamicStyle['--right'] = `calc(100% - ${eventX}px)`;
        } else if (dropdownPaneEnd > maxEnd) {
          const offsetX = bounds.width;
          dynamicStyle['--right'] = `calc(100% - ${eventX}px - ${offsetX}px)`;
        } else {
          dynamicStyle['--left'] = `${eventX}px`;
        }

        if (dropdownPaneBottom > maxBottom) {
          const offsetY =
            this.anchor === 'top' ? bounds.height : -bounds.height;
          dynamicStyle['--bottom'] =
            `calc(100% - ${eventY}px - ${offsetY}px + ${gap}px)`;
        } else {
          dynamicStyle['--top'] = `calc(${eventY}px + ${gap}px)`;
        }

        this.dynamicStyle = dynamicStyle;
      });
    },
  },
  methods: {
    closeDropdown() {
      if (!this.showDropdown) return;

      const stack = this.uiSettings.wgpt_dropdown_stack ?? [];
      const top = stack[stack.length - 1];
      if (top !== this.id) return;

      this.$emit('close');
    },
    getKeyboardEvents() {
      return {
        Escape: {
          action: e => this.closeDropdown(e),
          allowOnFocusedInput: true,
        },
      };
    },
  },
};
</script>

<template>
  <div>
    <div
      v-show="showDropdown"
      class="dropdown-wrap pointer-events-none"
      :style="dynamicStyle"
    >
      <div
        v-if="showBackdrop"
        class="modal-mask !bg-transparent cursor-pointer pointer-events-auto"
        @click="closeDropdown"
      />
      <div
        ref="dropdownPane"
        :class="{ 'dropdown-pane--open': showDropdown }"
        class="dropdown-pane !p-0 pointer-events-auto"
      >
        <slot v-if="showDropdown" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-wrap {
  @apply fixed z-50 top-0 bottom-0 start-0 end-0;
}

.dropdown-pane {
  @apply w-max;
  top: var(--top, auto);
  bottom: var(--bottom, auto);
  inset-inline-start: var(--left, auto);
  inset-inline-end: var(--right, auto);
}
</style>

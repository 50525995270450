<script>
import { useAlert } from 'dashboard/composables';
import { mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  emits: ['close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
      description: '',
    };
  },
  validations: {
    name: {
      required,
    },
    description: {},
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoards/getUIFlags',
    }),
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async onSubmit() {
      try {
        if (this.v$.$invalid) {
          this.v$.$touch();
          return;
        }

        const data = {
          name: this.name,
          description: this.description,
        };
        const newBoard = await this.$store.dispatch('wgptBoards/create', data);

        useAlert(this.$t('WGPT_BOARDS.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
        this.$router.replace({
          name: 'board_item',
          params: { boardId: newBoard.id },
        });
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_BOARDS.ADD.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
  },
};
</script>

<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('WGPT_BOARDS.ADD.TITLE')"
      :header-content="$t('WGPT_BOARDS.ADD.DESC')"
    />
    <form class="w-full" @submit.prevent="onSubmit">
      <div>
        <label :class="{ error: v$.name.$error }">
          {{ $t('WGPT_BOARDS.ADD.FORM.NAME.LABEL') }}
          <woot-input
            v-model.trim="name"
            data-testid="board-name"
            @input="v$.name.$touch"
          />
          <span v-if="v$.name.$error" class="message">
            {{ $t('WGPT_BOARDS.ADD.FORM.NAME.ERROR') }}
          </span>
        </label>
      </div>

      <div>
        <label :class="{ error: v$.description.$error }">
          {{ $t('WGPT_BOARDS.ADD.FORM.DESCRIPTION.LABEL') }}
          <textarea
            v-model.trim="description"
            data-testid="board-description"
            rows="3"
            @input="v$.description.$touch"
          />
        </label>
      </div>

      <div class="flex justify-end items-center py-2 px-0 gap-2 w-full">
        <woot-button
          :is-loading="uiFlags.isCreating"
          data-testid="board-submit"
        >
          {{ $t('WGPT_BOARDS.ADD.FORM.CREATE') }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('WGPT_BOARDS.ADD.FORM.CANCEL') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

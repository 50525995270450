<script>
import { defineAsyncComponent } from 'vue';
import { useUISettings } from 'dashboard/composables/useUISettings';
import Sidebar from './components/sidebarComponents/Sidebar.vue';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector.vue';
import AddAccountModal from 'dashboard/components/layout/sidebarComponents/AddAccountModal.vue';
import WootKeyShortcutModal from 'dashboard/components/widgets/modal/WootKeyShortcutModal.vue';
import AddBoardModal from './components/AddBoard.vue';
import EditBoardModal from './components/EditBoard.vue';
import DeleteBoardModal from './components/DeleteBoard.vue';
import CloseBoardModal from './components/CloseBoard.vue';
import FilterBoard from './components/FilterBoard/Index.vue';
import BoardHeader from './Header.vue';
import Dropdown from './components/Dropdown.vue';
const CommandBar = defineAsyncComponent(
  () => import('dashboard/routes/dashboard/commands/commandbar.vue')
);

export default {
  components: {
    BoardHeader,
    Sidebar,
    CommandBar,
    AccountSelector,
    AddAccountModal,
    WootKeyShortcutModal,
    AddBoardModal,
    EditBoardModal,
    DeleteBoardModal,
    CloseBoardModal,
    FilterBoard,
    Dropdown,
  },
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    showSearchbox: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { uiSettings, updateUISettings } = useUISettings();
    return {
      uiSettings,
      updateUISettings,
    };
  },
  data() {
    return {
      showAccountModal: false,
      showCreateAccountModal: false,
      showAddBoardModal: false,
      showBoardSettingsPopup: false,
      showCloseBoardConfirmationPopup: false,
      showDeleteBoardConfirmationPopup: false,
      showShortcutModal: false,
      selectedBoard: {},
      displayLayoutType: '',
      showFiltersModal: false,
      event: {},
      modalStacks: 0,
    };
  },
  computed: {
    filters() {
      return this.$store.getters['wgptBoardListCards/getFilters'](this.boardId);
    },
    hasAppliedFilters() {
      return this.$store.getters['wgptBoardListCards/hasFilters'](this.boardId);
    },
    currentRoute() {
      return ' ';
    },
    isSidebarOpen() {
      const { show_secondary_sidebar: showSecondarySidebar } = this.uiSettings;
      return showSecondarySidebar;
    },
    boardId() {
      return this.$route.params.boardId;
    },
    board() {
      return this.$store.getters['wgptBoards/getBoard'](this.boardId);
    },
    boardHeader() {
      if (this.headerTitle) return this.$t(this.headerTitle);
      if (this.board) return this.board.name;
      return '';
    },
    deleteBoardConfirmText() {
      return this.$t('WGPT_BOARDS.DELETE.CONFIRM.YES');
    },
    deleteBoardRejectText() {
      return this.$t('WGPT_BOARDS.DELETE.CONFIRM.NO');
    },
    deleteBoardMessage() {
      return ` ${this.board?.name ?? 'this board'}?`;
    },
  },
  methods: {
    toggleSidebar() {
      this.updateUISettings({
        show_secondary_sidebar: !this.isSidebarOpen,
        previously_used_sidebar_view: !this.isSidebarOpen,
      });
    },
    openCreateAccountModal() {
      this.showAccountModal = false;
      this.showCreateAccountModal = true;
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
    },
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleKeyShortcutModal() {
      this.showShortcutModal = true;
    },
    closeKeyShortcutModal() {
      this.showShortcutModal = false;
    },
    showAddBoardPopup() {
      this.showAddBoardModal = true;
    },
    hideAddBoardPopup() {
      this.showAddBoardModal = false;
    },
    openBoardSettingsPopup(board) {
      this.selectedBoard = board;
      this.showBoardSettingsPopup = true;
    },
    hideBoardSettingsPopup() {
      this.showBoardSettingsPopup = false;
    },
    openDeleteBoardPopup() {
      this.hideBoardSettingsPopup();
      this.showDeleteBoardConfirmationPopup = true;
    },
    closeDeleteBoardPopup() {
      this.showDeleteBoardConfirmationPopup = false;
    },
    openCloseBoardPopup() {
      this.hideBoardSettingsPopup();
      this.showCloseBoardConfirmationPopup = true;
    },
    closeCloseBoardPopup() {
      this.showCloseBoardConfirmationPopup = false;
    },
    openFiltersModal(e) {
      this.event = e;
      this.showFiltersModal = true;
    },
    closeFiltersModal() {
      if (this.modalStacks > 1) return;
      this.showFiltersModal = false;
    },
  },
};
</script>

<template>
  <div
    class="app-wrapper h-full flex-grow-0 min-h-0 w-full max-w-full ml-auto mr-auto flex flex-wrap dark:text-slate-300"
  >
    <Sidebar
      :route="currentRoute"
      :show-secondary-sidebar="isSidebarOpen"
      @toggle-account-modal="toggleAccountModal"
      @open-key-shortcut-modal="toggleKeyShortcutModal"
      @close-key-shortcut-modal="closeKeyShortcutModal"
      @show-add-board-popup="showAddBoardPopup"
      @show-delete-board-popup="openDeleteBoardPopup"
      @show-board-settings-popup="openBoardSettingsPopup"
    />
    <section class="flex h-full min-h-0 overflow-hidden flex-1 px-0">
      <div
        class="flex flex-1 h-full justify-between flex-col m-0 bg-slate-25 dark:bg-slate-900 overflow-hidden"
      >
        <BoardHeader
          :header-title="boardHeader"
          :show-edit-button="!!board.id"
          :show-searchbox="showSearchbox"
          :filters="filters"
          :has-applied-filters="hasAppliedFilters"
          @edit="openBoardSettingsPopup"
          @open-filters-modal="openFiltersModal"
        />
        <div class="flex-1 overflow-auto w-full">
          <router-view @add-board="showAddBoardPopup" />
        </div>
      </div>

      <CommandBar />
      <AccountSelector
        :show-account-modal="showAccountModal"
        @close-account-modal="toggleAccountModal"
        @show-create-account-modal="openCreateAccountModal"
      />
      <AddAccountModal
        :show="showCreateAccountModal"
        @close-account-create-modal="closeCreateAccountModal"
      />
      <WootKeyShortcutModal
        v-model:show="showShortcutModal"
        @close="closeKeyShortcutModal"
        @clickaway="closeKeyShortcutModal"
      />
      <woot-modal v-model:show="showAddBoardModal" @close="hideAddBoardPopup">
        <AddBoardModal @close="hideAddBoardPopup" />
      </woot-modal>

      <woot-modal
        v-model:show="showBoardSettingsPopup"
        class="edit-board-woot-modal"
        @close="hideBoardSettingsPopup"
      >
        <EditBoardModal
          :board="selectedBoard"
          @close="openCloseBoardPopup"
          @dismiss="hideBoardSettingsPopup"
          @delete="openDeleteBoardPopup"
        />
      </woot-modal>

      <woot-modal
        v-model:show="showDeleteBoardConfirmationPopup"
        @close="closeDeleteBoardPopup"
      >
        <DeleteBoardModal
          :board="selectedBoard"
          @close="closeDeleteBoardPopup"
        />
      </woot-modal>

      <woot-modal
        v-model:show="showCloseBoardConfirmationPopup"
        :on-close="closeCloseBoardPopup"
      >
        <CloseBoardModal :board="selectedBoard" @close="closeCloseBoardPopup" />
      </woot-modal>

      <Dropdown
        :show-dropdown="showFiltersModal"
        :event="event"
        :gap="8"
        anchor="bottom"
        @close="closeFiltersModal"
      >
        <FilterBoard
          :board="board"
          :initial-filters="filters"
          @close="closeFiltersModal"
        />
      </Dropdown>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.edit-board-woot-modal {
  @apply overflow-auto flex-wrap pt-14 pb-28;
  ::v-deep {
    .modal-container {
      @apply w-[37rem] max-h-none overflow-visible;
    }
  }
}
</style>

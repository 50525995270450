<script>
import translateMixins from '../../../mixins/translateMixins';
import RichtextEditor from './components/RichtextEditor.vue';
import { requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: {
    RichtextEditor,
  },
  mixins: [translateMixins],
  props: {
    value: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    defaultValue: {
      type: [String, Number, Date, Boolean, Object, Array],
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    fallbackLabel: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    variables: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      updatedValue: this.value ?? this.defaultValue,
    };
  },
  validations() {
    return {
      updatedValue: {
        required: requiredIf(this.required),
      },
    };
  },
  watch: {
    value(value) {
      this.updatedValue = value;
    },
  },
  methods: {
    updateValue() {
      this.v$.$touch();
      this.$emit('change', this.updatedValue);
    },
  },
};
</script>

<template>
  <div>
    <div class="richtext-label" :class="{ error: v$.updatedValue.$error }">
      <label class="flex items-center gap-2">
        <div v-if="label" class="block truncate">
          {{ t(label, fallbackLabel) }}<span v-if="required">*</span>
        </div>
        <fluent-icon
          v-if="description"
          v-tooltip.top="{
            content: t(description),
            popperClass: 'max-w-[300px]',
            boundary: 'body',
          }"
          icon="info"
          size="14"
        />
      </label>

      <div class="mb-4">
        <RichtextEditor
          v-model="updatedValue"
          class="richtext-editor"
          :placeholder="t(placeholder)"
          :variables="variables"
          @blur="v$.updatedValue.$touch"
          @input="updateValue"
        />
      </div>

      <span v-if="v$.updatedValue.$error" class="message">
        <template v-if="v$.updatedValue.required.$invalid">{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.REQUIRED')
        }}</template>
        <template v-else>{{
          $t('WGPT_AUTOMATIONS.REGISTRY.FORM.ERRORS.INVALID')
        }}</template>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.richtext-editor {
  @apply px-2 rounded-md break-words border border-slate-200 dark:border-slate-600 has-[:focus]:border-woot-500 dark:has-[:focus]:border-woot-600 transition-colors duration-[0.25s] ease-[ease-in-out];
  ::v-deep .ProseMirror-menubar {
    @apply -ms-1;
  }
}

.error .richtext-editor {
  @apply border-red-500;
}
</style>

<script>
import LabelDropdown from 'shared/components/ui/label/LabelDropdown.vue';
import { useAdmin } from 'dashboard/composables/useAdmin';
import { useAlert } from 'dashboard/composables';
import Dropdown from '../components/Dropdown.vue';

export default {
  components: {
    LabelDropdown,
    Dropdown,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isAdmin } = useAdmin();
    return {
      isAdmin,
    };
  },
  data() {
    return {
      showSearchDropdownLabel: false,
    };
  },
  computed: {
    savedLabels() {
      // const availableCardLabels = this.card.config?.labels ?? [];
      const availableCardLabels = this.card.labels ?? [];
      return this.labels.filter(label =>
        availableCardLabels.includes(label.title)
      );
    },
    selectedLabels() {
      // const availableCardLabels = this.card.config?.labels ?? [];
      const availableCardLabels = this.card.labels ?? [];
      return availableCardLabels;
    },
  },
  methods: {
    async onUpdateLabels(selectedLabels) {
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const originalId = this.card.originalId;
        await this.$store.dispatch('wgptBoardListCards/updateLabels', {
          boardId,
          listId,
          id,
          originalId,
          labels: selectedLabels,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.API.ERROR_MESSAGE')
        );
      }
    },
    addItem(value) {
      const result = this.savedLabels.map(item => item.title);
      result.push(value.title);
      this.onUpdateLabels(result);
    },
    removeItem(value) {
      const result = this.savedLabels
        .map(label => label.title)
        .filter(label => label !== value);
      this.onUpdateLabels(result);
    },
    openDropdownLabel() {
      this.showSearchDropdownLabel = true;
    },
    closeDropdownLabel() {
      this.showSearchDropdownLabel = false;
    },
  },
};
</script>

<template>
  <div class="relative">
    <li
      class="font-medium h-7 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md bg-slate-50 hover:bg-slate-100 dark:bg-slate-700 dark:hover:bg-slate-600 cursor-pointer"
      :class="{ disabled: disabled }"
      @click="openDropdownLabel"
    >
      <a class="inline-flex text-left max-w-full w-full items-center">
        <div
          class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full gap-2"
        >
          <fluent-icon
            icon="tag"
            class="min-w-[1rem] mt-0.5 text-slate-700 dark:text-slate-100"
            size="14"
          />
          <span
            class="text-sm text-slate-700 dark:text-slate-100 overflow-hidden text-truncate"
          >
            {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.LABELS.LABEL') }}
          </span>
        </div>
      </a>
    </li>

    <Dropdown
      :show-dropdown="showSearchDropdownLabel"
      @close="closeDropdownLabel"
    >
      <LabelDropdown
        :account-labels="labels"
        :selected-labels="selectedLabels"
        :allow-creation="isAdmin"
        @add="addItem"
        @remove="removeItem"
      />
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
li.disabled {
  @apply opacity-50 pointer-events-none select-none;
}
</style>

<script>
import { useAdmin } from 'dashboard/composables/useAdmin';
import { mapGetters } from 'vuex';
import FilterItem from './FilterItem.vue';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';
import LabelDropdown from 'shared/components/ui/label/LabelDropdown.vue';
import Dropdown from '../Dropdown.vue';
import { FILTER_ITEM_MAX_LENGTH } from './helpers/constant';

export default {
  components: {
    FilterItem,
    FluentIcon,
    LabelDropdown,
    Dropdown,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const { isAdmin } = useAdmin();
    return {
      isAdmin,
    };
  },
  data() {
    return {
      updatedValue: this.modelValue,
      showContactDropdown: false,
      showSearchDropdownLabel: false,
      event: {},
    };
  },
  computed: {
    ...mapGetters({
      allLabels: 'labels/getLabels',
    }),
    labels() {
      return this.allLabels.slice(0, FILTER_ITEM_MAX_LENGTH);
    },
    unloadedLabels() {
      return this.updatedValue.filter(
        title => !this.labels.find(label => label.title === title)
      );
    },
    hasAnyItem() {
      return this.labels.length > 0;
    },
    showBadge() {
      return this.unloadedLabels.length > 0;
    },
    showMoreOptions() {
      return this.allLabels.length > FILTER_ITEM_MAX_LENGTH;
    },
  },
  watch: {
    updatedValue(value) {
      this.$emit('update:modelValue', value);
    },
    modelValue(value, oldValue) {
      if (value.join() === oldValue.join()) return;
      this.updatedValue = value;
    },
  },
  methods: {
    openDropdownLabel(e) {
      this.event = e;
      this.showSearchDropdownLabel = true;
    },
    closeDropdownLabel() {
      this.showSearchDropdownLabel = false;
    },
    addItem(value) {
      this.updatedValue.push(value.title);
    },
    removeItem(value) {
      this.updatedValue = this.updatedValue.filter(label => label !== value);
    },
  },
};
</script>

<template>
  <div v-show="hasAnyItem">
    <label class="text-sm mb-2 text-slate-600 dark:text-slate-400">{{
      $t('WGPT_BOARDS.FILTER.LABELS.TITLE')
    }}</label>
    <FilterItem v-for="label in labels" :key="label.id" class="group py-1.5">
      <template #start>
        <input
          v-model="updatedValue"
          type="checkbox"
          :value="label.title"
          class="group-hover:bg-slate-50 group-hover:dark:bg-slate-800/50 me-1"
        />
      </template>
      <woot-label
        :key="label.id"
        :title="label.title"
        :description="label.description"
        :show-close="false"
        :bg-color="label.color + '9F'"
        class="!w-full !h-8 !m-0"
      />
    </FilterItem>

    <woot-button
      v-if="showMoreOptions"
      variant="link"
      class-names="!ms-6 !mt-2 [&>*]:pointer-events-none"
      @click="openDropdownLabel"
    >
      <div class="flex items-center gap-2">
        <div v-if="showBadge" class="w-[24px] flex justify-center">
          <div class="badge">
            <span>
              {{ unloadedLabels.length }}
            </span>
          </div>
        </div>
        {{ $t('WGPT_BOARDS.FILTER.BUTTON_TXT.SHOW_MORE_OPTIONS') }}
        <FluentIcon icon="chevron-down" size="18" />
      </div>
    </woot-button>

    <Dropdown
      :show-dropdown="showSearchDropdownLabel"
      :event="event"
      @close="closeDropdownLabel"
    >
      <div class="p-2">
        <LabelDropdown
          :account-labels="allLabels"
          :selected-labels="updatedValue"
          :allow-creation="isAdmin"
          @add="addItem"
          @remove="removeItem"
        />
      </div>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
.badge {
  @apply inline-block bg-slate-50 dark:bg-woot-800 rounded-md text-slate-600 dark:text-slate-100 h-5 text-xxs font-semibold px-1 min-w-[16px] text-center;
}
</style>

<script>
import { useAdmin } from 'dashboard/composables/useAdmin';
import LabelDropdown from 'shared/components/ui/label/LabelDropdown.vue';
import { useAlert } from 'dashboard/composables';
import Dropdown from '../components/Dropdown.vue';

export default {
  components: {
    LabelDropdown,
    Dropdown,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isAdmin } = useAdmin();
    return {
      isAdmin,
    };
  },
  data() {
    return {
      showSearchDropdownLabel: false,
    };
  },
  computed: {
    savedLabels() {
      const availableCardLabels = this.card.labels ?? [];
      return this.labels.filter(label =>
        availableCardLabels.includes(label.title)
      );
    },
    selectedLabels() {
      const availableCardLabels = this.card.labels ?? [];
      return availableCardLabels;
    },
  },
  methods: {
    async onUpdateLabels(selectedLabels) {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const originalId = this.card.originalId;
        await this.$store.dispatch('wgptBoardListCards/updateLabels', {
          boardId,
          listId,
          id,
          originalId,
          labels: selectedLabels,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.API.ERROR_MESSAGE')
        );
      }
    },
    addItem(value) {
      const result = this.savedLabels.map(item => item.title);
      result.push(value.title);
      this.onUpdateLabels(result);
    },
    removeItem(value) {
      const result = this.savedLabels
        .map(label => label.title)
        .filter(label => label !== value);
      this.onUpdateLabels(result);
    },
    toggleLabels() {
      this.showSearchDropdownLabel = !this.showSearchDropdownLabel;
    },
    closeDropdownLabel() {
      this.showSearchDropdownLabel = false;
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="savedLabels.length > 0">
    <label>
      {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.LABELS.LABEL') }}
    </label>

    <div class="flex overflow-hidden flex-wrap">
      <woot-label
        v-for="label in savedLabels"
        :key="label.id"
        :title="label.title"
        :description="label.description"
        :show-close="!readonly"
        :bg-color="label.color + '9F'"
        class="h-auto box-content"
        @remove="removeItem"
      />
      <woot-button
        v-if="!readonly"
        size="small"
        data-testid="board-add-list-card-label-add-cancel"
        color-scheme="secondary"
        variant="smooth"
        icon="add"
        class="mt-px mb-1"
        @click="toggleLabels"
      />
    </div>

    <Dropdown
      :show-dropdown="showSearchDropdownLabel"
      @close="closeDropdownLabel"
    >
      <LabelDropdown
        :account-labels="labels"
        :selected-labels="selectedLabels"
        :allow-creation="isAdmin"
        @add="addItem"
        @remove="removeItem"
      />
    </Dropdown>
  </div>
</template>

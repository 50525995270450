<script setup>
import { defineProps, defineModel, defineEmits } from 'vue';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['blur', 'update:modelValue']);

const model = defineModel({
  type: String,
  default: '',
});

const onInput = ev => {
  const formattedValue = ev.target.value
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '_');
  emit('update:modelValue', formattedValue);
};
</script>

<template>
  <input
    v-model="model"
    :disabled="disabled"
    type="text"
    @input="onInput"
    @blur="emit('blur')"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';

export default {
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    activity: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close'],
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardListCardActivities/getUIFlags',
    }),
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async onSubmit() {
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const cardId = this.card.id;
        const id = this.activity.id;
        await this.$store.dispatch('wgptBoardListCardActivities/delete', {
          boardId,
          listId,
          cardId,
          id,
        });
        useAlert(
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.API.SUCCESS_MESSAGE'
          )
        );
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.API.ERROR_MESSAGE'
          )
        );
      } finally {
        this.onClose();
      }
    },
  },
};
</script>

<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="
        $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.TITLE')
      "
      :header-content="
        $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.DESC')
      "
    />
    <form class="mx-0 flex flex-wrap w-full" @submit.prevent="onSubmit">
      <div class="flex justify-end items-center py-2 px-0 gap-2 w-full">
        <woot-button
          :is-disabled="uiFlags.isDeleting"
          :is-loading="uiFlags.isDeleting"
          data-testid="board-submit"
          color-scheme="alert"
        >
          {{
            $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.BUTTON_TEXT')
          }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.CANCEL') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import canDismissMixin from 'shared/mixins/wgptPreventDismissMixin';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';

export default {
  mixins: [canDismissMixin, keyboardEventListenerMixins],
  props: {
    showDropdown: {
      type: Boolean,
      default: false,
    },
    event: {
      type: [Object, Event],
      default: () => {},
    },
    anchor: {
      type: String,
      default: 'top',
    },
    position: {
      type: String,
      default: '',
    },
    gap: {
      type: Number,
      default: 0,
    },
    showBackdrop: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      dynamicStyle: {},
    };
  },
  computed: {
    preventDismiss() {
      return this.showDropdown;
    },
  },
  watch: {
    event: {
      handler({ target }) {
        this.$nextTick(() => {
          if (!target) return;
          const bounds = target.getBoundingClientRect();
          const direction = getComputedStyle(target).direction;
          const eventX =
            direction === 'rtl'
              ? document.body.clientWidth - bounds.width - bounds.x
              : bounds.x;
          const eventY =
            this.anchor === 'top' ? bounds.y : bounds.y + bounds.height;
          const gap = this.gap;

          const dropdownPane = this.$refs.dropdownPane;
          const dropdownPaneBottom = dropdownPane.clientHeight + eventY;
          const dropdownPaneEnd = dropdownPane.clientWidth + eventX;
          const maxBottom = document.body.clientHeight;
          const maxEnd = document.body.clientWidth;

          const dynamicStyle = {};

          if (this.position.includes('start')) {
            dynamicStyle['--right'] = `calc(100% - ${eventX}px)`;
          } else if (dropdownPaneEnd > maxEnd) {
            const offsetX = bounds.width;
            dynamicStyle['--right'] = `calc(100% - ${eventX}px - ${offsetX}px)`;
          } else {
            dynamicStyle['--left'] = `${eventX}px`;
          }

          if (dropdownPaneBottom > maxBottom) {
            const offsetY =
              this.anchor === 'top' ? bounds.height : -bounds.height;
            dynamicStyle['--bottom'] =
              `calc(100% - ${eventY}px - ${offsetY}px + ${gap}px)`;
          } else {
            dynamicStyle['--top'] = `calc(${eventY}px + ${gap}px)`;
          }

          this.dynamicStyle = dynamicStyle;
        });
      },
      immediate: true,
    },
  },
  methods: {
    closeDropdown() {
      this.$emit('close');
    },
    getKeyboardEvents() {
      return {
        Escape: {
          action: () => this.closeDropdown(),
          allowOnFocusedInput: true,
        },
      };
    },
  },
};
</script>

<template>
  <div>
    <div
      v-show="showDropdown"
      class="dropdown-wrap pointer-events-none"
      :style="dynamicStyle"
    >
      <div
        v-if="showBackdrop"
        class="modal-mask !bg-transparent cursor-pointer pointer-events-auto"
        @click="closeDropdown"
      />
      <div
        ref="dropdownPane"
        :class="{ 'dropdown-pane--open': showDropdown }"
        class="dropdown-pane !p-0 pointer-events-auto"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-wrap {
  @apply fixed z-50 top-0 bottom-0 start-0 end-0;
}

.dropdown-pane {
  @apply w-max;
  top: var(--top, auto);
  bottom: var(--bottom, auto);
  inset-inline-start: var(--left, auto);
  inset-inline-end: var(--right, auto);
}
</style>

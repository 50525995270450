<script>
import { ref } from 'vue';
import ContextMenu from 'dashboard/components/ui/ContextMenu.vue';
import MenuItem from '../../../../../../../../components/widgets/conversation/contextMenu/menuItem.vue';

export default {
  components: {
    MenuItem,
    ContextMenu,
  },
  props: {
    status: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['delete', 'toggleStatus', 'edit'],
  setup() {
    const contextMenuPosition = ref({});
    const isOpen = ref(false);
    return {
      contextMenuPosition,
      isOpen,
    };
  },
  computed: {
    statusLabel() {
      return this.status === 'draft' ? 'Enable' : 'Disable';
    },
  },
  methods: {
    handleOpen(e) {
      this.isOpen = true;
      const maxX = window.innerWidth - 208;
      this.contextMenuPosition = {
        x: Math.min(maxX, e.pageX || e.clientX),
        y: e.pageY || e.clientY,
      };
    },
    handleClose() {
      this.isOpen = false;
    },
    handleEdit(e) {
      this.$emit('edit', e);
      this.handleClose();
    },
    handleToggleStatus(e) {
      this.$emit('toggleStatus', e);
      this.handleClose();
    },
    handleDelete(e) {
      this.$emit('delete', e);
      this.handleClose();
    },
  },
};
</script>

<template>
  <div class="context-menu">
    <woot-button
      icon="more-vertical"
      color-scheme="secondary"
      variant="clear"
      size="small"
      @click="handleOpen"
    />
    <ContextMenu
      v-if="isOpen"
      :x="contextMenuPosition.x"
      :y="contextMenuPosition.y"
      @close="handleClose"
    >
      <div class="menu-container">
        <MenuItem
          :option="{ label: 'Edit' }"
          variant="icon"
          @click.stop="handleEdit"
        />
        <MenuItem
          :option="{ label: statusLabel }"
          variant="icon"
          @click.stop="handleToggleStatus"
        />
        <hr />
        <MenuItem
          :option="{ label: 'Delete' }"
          variant="icon"
          @click.stop="handleDelete"
        />
      </div>
    </ContextMenu>
  </div>
</template>

<style lang="scss" scoped>
.menu-container {
  @apply p-1 bg-white dark:bg-slate-900 shadow-xl rounded-md;
  hr:first-child {
    @apply hidden;
  }
  hr {
    @apply m-1 border-b border-solid border-slate-50 dark:border-slate-800/50;
  }
}
</style>

import {
  email as emailValidator,
  url as urlValidator,
  required as requiredValidator,
  maxValue as maxValueValidator,
  minValue as minValueValidator,
  maxLength as maxLengthValidator,
  minLength as minLengthValidator,
} from '@vuelidate/validators';

function containsVariable(value) {
  if (
    value === null ||
    value === undefined ||
    Array.isArray(value) ||
    typeof value === 'object' ||
    typeof value === 'number'
  )
    return false;
  return /%{.*}/.test(value);
}

function getFieldValueByPath(field, path) {
  const pathParts = path.split('.');
  let currentField = field;
  pathParts.forEach(pathPart => {
    currentField = currentField[pathPart];
  });
  return currentField;
}

function getUniqueFieldKeys(fields, prefix = null) {
  const fieldNames = [];
  fields.forEach(field => {
    if (field.type === 'group') {
      const childFieldNames = getUniqueFieldKeys(field.controls, field.name);
      fieldNames.push(
        ...childFieldNames.map(childFieldName =>
          prefix ? `${prefix}.${childFieldName}` : childFieldName
        )
      );
      return;
    }
    if (field.unique) fieldNames.push(field.name);
  });
  return fieldNames;
}

function getDuplicateUniqueKeys(uniqueKeys, value) {
  return uniqueKeys.filter(uniqueKey => {
    const values = value.map(valueItem =>
      getFieldValueByPath(valueItem, uniqueKey)
    );
    const filteredValues = values.filter(
      val => val !== null && val !== undefined
    );
    return filteredValues.length !== new Set(filteredValues).size;
  });
}

function validateRequired(field, value) {
  if (!field.required) return;
  if (field.type === 'checkbox') {
    if (value === true) return;
  } else if (requiredValidator.$validator(value)) return;
  throw new Error(`'${field.name}' is required, but it was ${value}`);
}

function validateMin(field, value) {
  if (field.min == null) return;
  if (containsVariable(value)) return;
  if (['array', 'multiselect'].includes(field.type)) {
    if (minLengthValidator(field.min).$validator(value)) return;
    throw new Error(
      `'${field.name}' must have at least ${field.min} items, but it had ${value.length}`
    );
  } else if (field.type === 'number') {
    if (minValueValidator(field.min).$validator(value)) return;
    throw new Error(
      `'${field.name}' must be at least ${field.min}, but it was ${value}`
    );
  }
}

function validateMax(field, value) {
  if (field.max == null) return;
  if (containsVariable(value)) return;

  if (['array', 'multiselect'].includes(field.type)) {
    if (maxLengthValidator(field.max).$validator(value)) return;
    throw new Error(
      `'${field.name}' must have at most ${field.max} items, but it had ${value.length}`
    );
  } else if (field.type === 'number') {
    if (maxValueValidator(field.max).$validator(value)) return;
    throw new Error(
      `'${field.name}' must be at most ${field.max}, but it was ${value}`
    );
  }
}

function validateEmailField(field, value) {
  if (field.type !== 'email') return;
  if (containsVariable(value)) return;
  if (emailValidator.$validator(value)) return;
  throw new Error(`'${field.name}' must be a valid email, but it was ${value}`);
}

function validateUrlField(field, value) {
  if (field.type !== 'url') return;
  if (containsVariable(value)) return;
  if (urlValidator.$validator(value)) return;
  throw new Error(`'${field.name}' must be a valid URL, but it was ${value}`);
}

function validateArrayField(field, value) {
  if (field.type === 'array') {
    const arrayFieldControl = field.controls[0];
    value = value || [];
    value.forEach(valueItem => {
      // eslint-disable-next-line no-use-before-define
      validateField(arrayFieldControl, valueItem);
    });
    const uniqueKeys = getUniqueFieldKeys(arrayFieldControl.controls);
    const duplicateUniqueKeys = getDuplicateUniqueKeys(uniqueKeys, value);
    if (duplicateUniqueKeys.length > 0) {
      throw new Error(
        `'${
          field.name
        }' contains duplicate values for unique keys: ${duplicateUniqueKeys.join(
          ', '
        )}`
      );
    }
  }
}

function validateGroupField(field, value) {
  value = value || {};
  if (field.type === 'group') {
    field.controls.forEach(fieldItem => {
      // eslint-disable-next-line no-use-before-define
      validateField(fieldItem, value[fieldItem.name]);
    });
  }
}

export function validateField(field, value) {
  validateRequired(field, value);
  if (value !== null && value !== undefined) {
    validateMin(field, value);
    validateMax(field, value);
    validateEmailField(field, value);
    validateUrlField(field, value);
  }
  validateArrayField(field, value);
  validateGroupField(field, value);
}

export function skipValidation(field, value) {
  const conditions = field.show_if ?? [];
  const shown = conditions.every(condition => {
    const actualValue = value[condition.field];
    const compareValue = condition.value;
    return actualValue === compareValue;
  });
  return !shown;
}
